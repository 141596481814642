import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { targetSettingData } from "@core/models/api.model";
import { environment } from "@env/environment";
import { env } from "process";
import { Observable } from "rxjs";

export const LOCATIONS = "/warehouse-service/locations";
export const PIP_RECON = "/pip-service/pip/breakfinder";
export const PIP_UNIT_VALUE = "/pip-service/pip/getPipUnitValue/";
export const STATIC_COMMODITIES = "/common-service/staticdata/commodities";
export const BREAK_REASON = "/common-service/staticdata/getBreakReasons";
export const WAREHOUSE = "/warehouse-service/warehouses/findByParams";
export const EXPORT = "/pip-service/pip/breakfinder/export";
export const STATIC_REGIONS = "/common-service/staticdata/regions";
export const USERS = "/common-service/users";
export const DOC_USER_REGION = "/dashboard-service/document/userregion";
export const EVENT_USER_REGION = "/cmtk-transit/transit/event/userregion";
export const EDIT_RECON_DATA = "/pip-service/pip/editPipReconData/";
export const REGISTER_ENTITY = "/warehouse-service/entities/register";
export const SUSTAINILITY_PROFILE =
  "/inventory-service/sustainability/profilepage";
export const PUBLISH_PUBLIC_VIEW = "/inventory-service/publicView/generate";
export const TARGET_SETTING_LIST = "/admin-service/producers/targets/fetch";
export const CREATE_STANDARD_TARGET = "/admin-service/producers/targets/add";
export const TARGETS_LIST = "/admin-service/targets/fetch";
export const UPDATE_TARGET = "/admin-service/producers/targets/update/";
export const DELETE_STANDARD_TARGET =
  "/admin-service/producers/targets/delete/";
export const TARGET_GROUP = "/admin-service/targets/group";
export const GDL_METAlS = "/common-service/product/spec/metals";
export const GDL_LOCATIONS = "/common-service/gdls/locations";
export const GDL_DATA = "/doc-upload-service/warehouse/inventory/get";
export const SAVE_GDL_DATA = "/doc-upload-service/warehouse/inventory/add";
export const SUBMIT_GDL_DATA = "/doc-upload-service/warehouse/inventory/submit";
export const EXPORT_REPORT = "/dashboard-service/reconciled/inventory/export";
export const EXPORT_REPORT_INV = "/inventory-service/current/inventory/export";
export const RESET_INV = "/inventory-service/current/inventory/reset";
@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(private http: HttpClient) {}

  getLocations(): any {
    return this.http.get(`${environment.apiUrl}${LOCATIONS}`);
  }

  getGDLData(entityId, inventoryDate): any {
    let params = new HttpParams();
    params = params.append("entityId", entityId);
    params = params.append("inventoryDate", inventoryDate);
    return this.http.get(`${environment.apiUrl}${GDL_DATA}`, { params });
  }

  getPipBreakfinder(id) {
    return this.http.get(`${environment.apiUrl}${PIP_RECON}`, {
      params: new HttpParams().set("id", id),
    });
  }

  getPipGetPipUnitValueById(id) {
    return this.http.get(`${environment.apiUrl}${PIP_UNIT_VALUE}${id}`);
  }

  getStaticdataCommodities(): any {
    return this.http.get(`${environment.apiUrl}${STATIC_COMMODITIES}`);
  }

  getStaticdataGetBreakReasons(): any {
    return this.http.get(`${environment.apiUrl}${BREAK_REASON}`);
  }

  getWarehousesFindByParams(city): any {
    return this.http.get(`${environment.apiUrl}${WAREHOUSE}`, {
      params: new HttpParams().set("city", city),
    });
  }

  getPipBreakfinderExport(id: any): any {
    return this.http.get(`${environment.apiUrl}${EXPORT}`, {
      params: new HttpParams().set("id", id),
    });
  }

  truncateString(item, length) {
    return item.length > length ? item.substring(0, length) + "..." : item;
  }

  getStaticdataRegions(): any {
    return this.http.get(`${environment.apiUrl}${STATIC_REGIONS}`);
  }

  getUsers(name?: string, regions?: string) {
    let params = new HttpParams();
    params = params.append("name", name);
    params = params.append("regions", regions);
    return this.http.get(`${environment.apiUrl}${USERS}`, { params });
  }

  putDocumentUserregion(assignDocument) {
    return this.http.put(
      `${environment.apiUrl}${DOC_USER_REGION}`,
      assignDocument
    );
  }

  putTransitEventUserregion(assignUserRegionToEvent) {
    return this.http.put(
      `${environment.apiUrl}${EVENT_USER_REGION}`,
      assignUserRegionToEvent
    );
  }

  putPipEditPipReconDataById(id, pipRecon) {
    return this.http.put(
      `${environment.apiUrl}${EDIT_RECON_DATA}${id}`,
      pipRecon
    );
  }

  createEntity(params: any) {
    return this.http.post(`${environment.apiUrl}${REGISTER_ENTITY}`, params);
  }

  getSustanibilityProfile(filters, pagination): any {
    let params = new HttpParams();
    params = params.append("fromPage", pagination.page);
    params = params.append("pageSize", pagination.size);
    return this.http.post(
      `${environment.apiUrl}${SUSTAINILITY_PROFILE}`,
      filters,
      {
        params,
      }
    );
  }

  getTargetSettingList(): Observable<targetSettingData[]> {
    return this.http.get<targetSettingData[]>(
      `${environment.apiUrl}${TARGET_SETTING_LIST}`
    );
  }

  createStandardTarget(payload): any {
    return this.http.post(
      `${environment.apiUrl}${CREATE_STANDARD_TARGET}`,
      payload
    );
  }

  updateTarget(payload, targetId) {
    return this.http.put(
      `${environment.apiUrl}${UPDATE_TARGET}${targetId}`,
      payload
    );
  }

  getTargetsList(status?): any {
    let params = new HttpParams();
    if (status) {
      params = params.append("status", status);
    }
    return this.http.get(`${environment.apiUrl}${TARGETS_LIST}`, { params });
  }

  publishData(filters) {
    return this.http.post(
      `${environment.apiUrl}${PUBLISH_PUBLIC_VIEW}`,
      filters
    );
  }

  deleteStandardTarget(id) {
    return this.http.delete(
      `${environment.apiUrl}${DELETE_STANDARD_TARGET}${id}`
    );
  }

  getTargetsListGroup() {
    let params = new HttpParams();
    params = params.append("status", "ACTIVE");
    return this.http.get(`${environment.apiUrl}${TARGET_GROUP}`, { params });
  }

  getGDLColumns(): any {
    return this.http.get(`${environment.apiUrl}${GDL_METAlS}`);
  }

  getGDLLocations(): any {
    return this.http.get(`${environment.apiUrl}${GDL_LOCATIONS}`);
  }

  saveGdlData(payload) {
    return this.http.post(`${environment.apiUrl}${SAVE_GDL_DATA}`, payload);
  }

  submitGDLData(payload) {
    return this.http.post(`${environment.apiUrl}${SUBMIT_GDL_DATA}`, payload);
  }

  generateExportReport(payload): any {
    return this.http.post(`${environment.apiUrl}${EXPORT_REPORT}`, payload, {
      responseType: "blob",
    });
  }

  generateExportReportInventory(payload): any {
    return this.http.post(
      `${environment.apiUrl}${EXPORT_REPORT_INV}`,
      payload,
      { responseType: "blob" }
    );
  }

  resetData(inventoryDate): any {
    let params = new HttpParams();
    params = params.append("inventoryDate", inventoryDate);
    return this.http.get(`${environment.apiUrl}${RESET_INV}`, {
      params,
    });
  }

  generateExcel(entityId, inventoryDate): any {
    let params = new HttpParams();
    params = params.append("inventoryDate", inventoryDate);
    params = params.append("entityId", entityId);
    return this.http.get(
      `${environment.apiUrl}/doc-upload-service/warehouse/inventory/generate`,
      { params }
    );
  }
}
