<div class="textarea-container">
    <textarea id="custom-textarea" [formControl]="formControl" [formlyAttributes]="field" (focus)="onFocus()"
        (blur)="onBlur()"
        [ngClass]="{'textarea-error': formControl.invalid && (formControl.dirty || formControl.touched)}" #textarea>
    </textarea>
    <label *ngIf="showPlaceholder" for="custom-textarea" class="textarea-placeholder">
        Example(s): <br>
        <ul>
            <li>
                Executive Director, logistics manager. Certificates of Analysis, review and download digital COA
                records.
            </li>
            <li>Sustainability Manager, append and review sustainability data.</li>
        </ul>
    </label>
</div>