import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  producerProfileBrandPublicViewListingFilters,
  producerProfileDisclosurePublicViewListingFilters,
  producerProfileTargetPublicViewListingFilters,
} from "@core/models/filter-types.model";
import { environment } from "@env/environment";
import { SustainabilityDisclosurePublicService } from "@module/sustainability-disclosure-public-view/services/sustainability-disclosure-public.service";
import {
  disclosurePublicListTableConfig,
  producerProfilePublicListTableConfig,
  targetsPublicListTableConfig,
} from "@module/sustainability-disclosure-public-view/sustainability-disclosure-public-view.const";
import { ITableViewConfig } from "@shared/models/table-view.model";
import {
  DELIVERABLE_VALUES,
  NET_ZERO,
  SIDE_BY_SIDE_TEXT,
  meterColors,
  physicalOperationsEmail,
  sustainabilityEmail,
} from "app/app.const";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PaginationInstance } from "ngx-pagination";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { addiotnalDocsTableConfig } from "@module/create-import/create-import-config";
import { CoreService } from "@core/services/core.service";
@Component({
  selector: "app-producer-profile",
  templateUrl: "./producer-profile.component.html",
  styleUrls: ["./producer-profile.component.scss"],
})
export class ProducerProfileComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<any>();
  producers: any;
  producer: any;
  brandTableConfig: ITableViewConfig;
  disclosureTableConfig: ITableViewConfig;
  targetTableConfig: ITableViewConfig;
  producerProfileData: any;
  disclosureData: any;
  brandFilters = producerProfileBrandPublicViewListingFilters;
  disclosureFilters = producerProfileDisclosurePublicViewListingFilters;
  targetsFilters = producerProfileTargetPublicViewListingFilters;
  selectedProducer: any;
  selectedThemeFilterName: string;
  env: { production: boolean; apiUrl: string; serviceUrl: string };
  filteredDisclosureData: any;
  filteredBrandData: any;
  filteredProducers: any;
  noRecordMessage: string;
  sustainabilitySupportEmail: string;

  @ViewChild("proofPointTemplate", { static: true })
  proofPointTemplate: TemplateRef<any>;
  @ViewChild("metricTemplate", { static: true })
  metricTemplate: TemplateRef<any>;
  @ViewChild("stickyMenu", { static: false }) menuElement: ElementRef;
  @ViewChild("brandData", { static: false }) brandDetails: ElementRef;
  @ViewChild("sustainabilityData", { static: false })
  sustainabilityData: ElementRef;
  @ViewChild("proofPointTargetsTemplate", { static: false })
  proofPointTargetsTemplate: ElementRef;
  @ViewChild("sustainabilityTargets", { static: false })
  sustainabilityTargets: ElementRef;
  @ViewChild("progressTemplate", { static: false })
  progressTemplate: ElementRef;
  @ViewChild("targetMetricTemplate", { static: false })
  targetMetricTemplate: ElementRef;
  @ViewChild("proofDocsActionTemplate", { static: true })
  public proofDocsActionTemplate: TemplateRef<any>;

  howToaAddYourCompanyDialog: boolean;
  physicalOperationsEmailLink: string;
  physicalOperationsEmail: string;
  public config: PaginationInstance = {
    id: "custom",
    itemsPerPage: 12,
    currentPage: 1,
  };
  menuItems: {
    moduleLabel: string;
    moduleName: string;
    scrollTo: string;
    active: boolean;
  }[];

  sticky: boolean = false;
  elementPosition: any;
  brandPosition: any;
  sustainabilityPosition: any;
  brandSectionHeight: any;
  sustainabilitySectionHeight: any;
  tabClicked: boolean;
  selectedTab: any;
  targetsData: any;
  targetsPosition: any;
  targetsSectionHeight: any;
  proofDocsConfig: ITableViewConfig;
  viewAProofDocsDialog: boolean;
  proofDocsData: any;
  previousURL: any;
  currentURL: any;
  navigationEndCounter: any = 0;
  filteredTargetsData: any;
  howDialCalculatedDialog: boolean;
  sustainabilityEmail: string;
  infoTriggers = false;
  sideBySideText = SIDE_BY_SIDE_TEXT;
  selectedTargetThemeFilterName: any;

  constructor(
    private sustainabilityDisclosureLiveService: SustainabilityDisclosurePublicService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private coreService: CoreService
  ) {
    titleService.setTitle("Portal - LMEpassport - Producer Profile");
  }

  ngOnInit(): void {
    this.sustainabilitySupportEmail = `mailto: ${sustainabilityEmail}`;
    this.sustainabilityEmail = sustainabilityEmail;
    this.physicalOperationsEmail = physicalOperationsEmail;
    this.physicalOperationsEmailLink = `mailto: ${physicalOperationsEmail}`;
    this.env = environment;
    this.sustainabilityDisclosureLiveService
      .getProducers()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.producers = _.orderBy(res, ["entityName"], ["asc"]);
        this.producers = this.producers.filter(
          (producer) =>
            producer.status === "ACTIVE" ||
            producer.status === "PRE_REGISTER" ||
            producer.status === "PENDING_APPROVAL" ||
            producer.status === "NEED_MORE_INFO"
        );
        this.filteredProducers = _.cloneDeep(this.producers);
        if (this.activatedRoute.snapshot.url[2]) {
          let producerId = this.activatedRoute.snapshot.url[2].path;
          let producerIdx = this.filteredProducers.findIndex(
            (producer) => parseInt(producerId) === producer.id
          );

          if (producerIdx !== -1) {
            this.producerChange(this.filteredProducers[producerIdx]);
            this.setMenu();
          } else {
            this.router.navigate(["/public/producer-profile"]);
          }
        }
      });
  }

  setMenu() {
    this.menuItems = [
      {
        moduleLabel: "BRAND INFORMATION",
        moduleName: "BRAND_COMPANY",
        scrollTo: "brandData",
        active: false,
      },
      {
        moduleLabel: "SUSTAINABILITY CREDENTIALS",
        moduleName: "SUSTAINABILITY_CREDENTIALS",
        scrollTo: "sustainabilityData",
        active: false,
      },
      {
        moduleLabel: "Targets, goals and commitments",
        moduleName: "SUSTAINABILITY_TARGETS",
        scrollTo: "sustainabilityTargets",
        active: false,
      },
    ];
  }

  @HostListener("window:scroll", ["$event"])
  handleScroll(event) {
    if (!this.selectedProducer) {
      return;
    }
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }

    !this.tabClicked && this.activateTab();
  }

  activateTab() {
    const scrollY = window.pageYOffset;
    this.deactivateAll();
    if (
      scrollY >= this.brandPosition - 50 &&
      scrollY <= this.brandSectionHeight + this.brandPosition - 50
    ) {
      let brandsIndex = this.menuItems.findIndex(
        (item) => item.moduleName === "BRAND_COMPANY"
      );
      this.menuItems[brandsIndex].active = true;
      this.selectedTab = "brandData";
    } else if (
      scrollY >= this.sustainabilityPosition - 50 &&
      scrollY <=
        this.sustainabilityPosition + this.sustainabilitySectionHeight - 50
    ) {
      let sustainabilityIndex = this.menuItems.findIndex(
        (item) => item.moduleName === "SUSTAINABILITY_CREDENTIALS"
      );
      this.menuItems[sustainabilityIndex].active = true;
      this.selectedTab = "sustainabilityData";
    } else if (
      scrollY >= this.targetsPosition - 50 &&
      scrollY <= this.targetsPosition + this.targetsSectionHeight - 50
    ) {
      let targetsIndex = this.menuItems.findIndex(
        (item) => item.moduleName === "SUSTAINABILITY_TARGETS"
      );
      this.menuItems[targetsIndex].active = true;
      this.selectedTab = "sustainabilityTargets";
    } else {
      this.selectedTab = null;
    }
  }

  deactivateAll() {
    this.menuItems.forEach((item) => {
      item.active = false;
    });
  }

  scrollToElement(e, $element, index): any {
    e.preventDefault();
    e.stopImmediatePropagation();
    if (this.selectedTab === $element) {
      return false;
    }
    this.selectedTab = $element;
    let element;
    this.deactivateAll();
    this.menuItems[index].active = true;
    if ($element === "brandData") {
      element = this.brandDetails.nativeElement;
    } else if ($element === "sustainabilityData") {
      element = this.sustainabilityData.nativeElement;
    } else if ($element === "sustainabilityTargets") {
      element = this.sustainabilityTargets.nativeElement;
    } else {
      this.redirectToUrlOrDoc(e);
      return;
    }

    const headerOffset = 45;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition - 40,
      behavior: "smooth",
    });

    this.tabClicked = true;

    setTimeout(() => {
      this.tabClicked = false;
    }, 1000);
  }

  selectTheme(theme) {
    this.selectedThemeFilterName = theme;
    this.filteredDisclosureData = this.disclosureData.filter(
      (row) =>
        row.themeId.themeName.toLowerCase() === theme.toLowerCase() &&
        this.selectedProducer.id === row.entity.id
    );
  }

  selectThemeTarget(theme) {
    this.selectedTargetThemeFilterName = theme;
    this.filteredTargetsData = this.targetsData.filter(
      (row) =>
        row.themeName.toLowerCase() === theme.toLowerCase() &&
        this.selectedProducer.id === row.entityId
    );
  }

  producerChange(data) {
    this.selectedProducer = data;
    this.producer = data.id;
    this.activatedRoute.snapshot.url[2] &&
      this.sustainabilityDisclosureLiveService.resetFilters();
    let name = this.selectedProducer.entityName
      .trim()
      .replace(/\s+/g, "-")
      .toLowerCase();
    this.router.navigate([
      `/public/producer-profile/${name}/${this.selectedProducer.id}`,
    ]);
    this.disclosureLevelData();
    this.brandLevelData();
    this.targetsLevelData();
    this.setDimensions();
    this.setMenu();
    this.selectedTab = null;
  }

  setDimensions() {
    setTimeout(() => {
      this.menuElement &&
        (this.elementPosition = this.menuElement.nativeElement.offsetTop);

      if (this.brandDetails) {
        this.brandPosition = this.brandDetails.nativeElement.offsetTop;
        this.brandSectionHeight = this.brandDetails.nativeElement.offsetHeight;
      }

      if (this.sustainabilityData) {
        this.sustainabilityPosition =
          this.sustainabilityData.nativeElement.offsetTop;
        this.sustainabilitySectionHeight =
          this.sustainabilityData.nativeElement.offsetHeight;
      }

      if (this.sustainabilityTargets) {
        this.targetsPosition =
          this.sustainabilityTargets.nativeElement.offsetTop;
        this.targetsSectionHeight =
          this.sustainabilityTargets.nativeElement.offsetHeight;
      }
    }, 0);
  }

  producerSearch(event) {
    this.filteredProducers = this.producers.filter((row) =>
      row.entityName.toLowerCase().includes(event.term)
    );
  }

  resetSelectedProducer(e) {
    e.preventDefault();
    this.selectedProducer = null;
    this.filteredProducers = _.cloneDeep(this.producers);
    this.producer = null;
    this.router.navigate([`/public/producer-profile`]);
  }

  brandLevelData() {
    const { columns, ...config } = producerProfilePublicListTableConfig;
    const _columns = [...producerProfilePublicListTableConfig.columns];

    this.brandTableConfig = {
      ...config,
      ...{ columns: _columns },
    };

    this.sustainabilityDisclosureLiveService
      .getBrandData()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.producerProfileData = res.filter(
          (row) =>
            row.masterProducerName.toLowerCase() ===
            this.selectedProducer.entityName.toLowerCase()
        );
        this.producerProfileData.forEach((element) => {
          element["asset"] = element.asset ? element.asset : "N/A";
          let brandStatusId = DELIVERABLE_VALUES.findIndex(
            (brandStatus) => brandStatus.id === element["brandStatus"]
          );
          element["brandStatus"] =
            brandStatusId !== -1
              ? DELIVERABLE_VALUES[brandStatusId].value
              : "N/A";
        });
        this.filteredBrandData = _.cloneDeep(this.producerProfileData);
      });
  }

  disclosureLevelData() {
    const { columns, ...config } = disclosurePublicListTableConfig;
    const _columns = [...disclosurePublicListTableConfig.columns];
    _columns.splice(6, 0, {
      header: "Proof Point",
      width: "350px",
      templateRef: this.proofPointTemplate,
    });
    _columns.splice(7, 0, {
      header: "Metric",
      width: "150px",
      templateRef: this.metricTemplate,
    });
    this.disclosureTableConfig = {
      ...config,
      ...{ columns: _columns },
    };

    this.sustainabilityDisclosureLiveService
      .getDisclosureData()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.disclosureData = this.filterDisclosureData(res);

        this.setData();

        this.noRecordMessage =
          "The producer has not uploaded any sustainability data.";
      });
  }

  targetsLevelData() {
    const { columns, ...config } = targetsPublicListTableConfig;
    const _columns = [...targetsPublicListTableConfig.columns];
    _columns.splice(1, 0, {
      header: "Metric",
      field: "targetName",
      width: "120px",
      templateRef: this.targetMetricTemplate,
    });

    _columns.splice(2, 0, {
      header: "On Track?",
      field: "progress",
      width: "80px",
      templateRef: this.progressTemplate,
    });

    _columns.splice(3, 0, {
      header: "Proof Point and Justification",
      width: "250px",
      templateRef: this.proofPointTargetsTemplate,
    });

    this.targetTableConfig = {
      ...config,
      ...{ columns: _columns },
    };

    this.sustainabilityDisclosureLiveService
      .getTargetsData()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.targetsData = res.filter(
          (row) => row.entityId === this.selectedProducer.id
        );
        this.setTargetsData();
      });
  }

  setMeterChart(row, i) {
    row.progress = {
      chart: {
        lowerLimit: "-40",
        upperLimit: "40",
        theme: "fusion",
        showTickMarks: "0",
        showToolTip: "0",
      },
      colorRange: {
        color: [
          {
            minvalue: "-40",
            maxvalue: "-30",
            code: meterColors[0],
          },
          {
            minvalue: "-30",
            maxvalue: "-20",
            code: meterColors[1],
          },
          {
            minvalue: "-20",
            maxvalue: "-10",
            code: meterColors[2],
          },
          {
            minvalue: "-10",
            maxvalue: "0",
            code: meterColors[3],
          },
          {
            minvalue: "0",
            maxvalue: "10",
            code: meterColors[4],
          },
          {
            minvalue: "10",
            maxvalue: "20",
            code: meterColors[5],
          },
          {
            minvalue: "20",
            maxvalue: "30",
            code: meterColors[6],
          },
          {
            minvalue: "30",
            maxvalue: "40",
            code: meterColors[7],
          },
        ],
      },
      dials: {
        dial: [
          {
            value: "20",
          },
        ],
      },
    };
    row.progress.dials.dial[0].value = row.percentageAchieved;
    let yearDiff = row.targetToYear - row.targetFromYear;
    let currentYearDiff = new Date().getFullYear() - row.targetFromYear;
    let diff =
      Number((currentYearDiff / yearDiff).toFixed(2)) *
      row.targetGoal?.replace("%", "");
    let dialPercentage = Math.abs(
      ((diff - row.percentageAchieved) / diff) * 100
    );
    let calculatedValue =
      diff > row.percentageAchieved
        ? -Math.abs(dialPercentage)
        : dialPercentage;

    if (calculatedValue > Number(row.progress.chart.upperLimit)) {
      calculatedValue = Number(row.progress.chart.upperLimit);
    } else if (calculatedValue < Number(row.progress.chart.lowerLimit)) {
      calculatedValue = Number(row.progress.chart.lowerLimit);
    }

    row.progress.dials.dial[0].value = calculatedValue.toFixed(2);

    /* let colorRange = 100 / 8;

    let prevMinVal = 0;
    let prevMaxVal = colorRange;
    row.progress.colorRange.color = [];
    for (let j = 0; j <= 7; j++) {
      row.progress.colorRange.color.push({
        minValue: prevMinVal,
        maxValue: prevMaxVal,
        code: meterColors[j],
      });

      prevMinVal = prevMaxVal;
      prevMaxVal = prevMaxVal + colorRange;
    } */
  }

  viewProofDocs(event, rowData) {
    if (event.key && event.key !== "Enter") {
      return;
    }
    this.viewAProofDocsDialog = true;

    const { columns, ...config } = addiotnalDocsTableConfig;
    const _columns = [...addiotnalDocsTableConfig.columns];

    this.proofDocsConfig = {
      ...config,
      ...{ columns: _columns },
    };

    _columns.push({
      header: "Actions",
      width: "150px",
      templateRef: this.proofDocsActionTemplate,
    });

    const documentsList = [];
    rowData.documents.forEach((element) => {
      if (element?.name || element?.proofUrl) {
        element["fileName"] = element["name"]
          ? element["name"]
          : element["proofUrl"];
        documentsList.push(element);
      }
    });
    this.proofDocsData = documentsList;
  }

  downloadProofDoc(rowData) {
    window.open(
      `${environment.apiUrl}/home-service/publicView/document/download?uuid=${rowData.uuid}&name=${rowData.name}`
    );
  }

  setTargetsData() {
    if (this.targetsData && this.targetsData.length) {
      this.targetsData.forEach((element, i) => {
        const netZeroExists = element.targetName
          ?.toLowerCase()
          .includes(NET_ZERO);
        if (!element?.targetId) {
          element[
            "targets"
          ] = `Producer ${element.entityName} will ${element.name} by ${element.targetToYear}`;
        } else if (netZeroExists) {
          element[
            "targets"
          ] = `Producer ${element.entityName} their ${element.targetName} by ${element.targetToYear} compared to baseline year of ${element.targetFromYear}`;
        } else {
          element[
            "targets"
          ] = `Producer ${element.entityName} will ${element.targetDirection} their ${element.targetName} ${element.targetGoal} by ${element.targetToYear} compared to baseline year of ${element.targetFromYear}`;
        }
        element["progress"] = this.getProgressColor(element);
        this.setMeterChart(element, i);
      });
    } else {
      this.targetsData = [];
    }

    this.filteredTargetsData = _.cloneDeep(this.targetsData);
  }

  getProgressColor(element) {
    let upperLimit = element.targetGoal;
    let colorRange = parseInt(upperLimit?.replace("%", "")) / 8;

    let prevMinVal = 0;
    let prevMaxVal = colorRange;
    let color = [];
    for (let i = 0; i <= 7; i++) {
      color.push({
        minValue: prevMinVal,
        maxValue: prevMaxVal,
        code: meterColors[i],
      });

      prevMinVal = prevMaxVal;
      prevMaxVal = prevMaxVal + colorRange;
    }

    let colorCode = "";
    color.forEach((c) => {
      if (
        element.percentageAchieved > c.minValue &&
        element.percentageAchieved <= c.maxValue
      ) {
        colorCode = c.code;
      }
    });

    return colorCode;
  }

  setData() {
    if (this.disclosureData && this.disclosureData.length) {
      this.disclosureData.forEach((element) => {
        element["disclosure"] = element.disclosureId.name;
        element["asset"] = element.asset ? element.asset : "N/A";
        element["brand"] = element.brand ? element.brand : "N/A";
        if (element.disclosureId.type === "Metric") {
          element[
            "metric"
          ] = `${element.metricPercentage} ${element.disclosureId.metricLevel}`;
        } else {
          element["metric"] = "N/A";
        }
      });
      this.filteredDisclosureData = _.cloneDeep(this.disclosureData);
    } else {
      this.filteredDisclosureData = [];
    }
  }

  filterDisclosureData(res) {
    return res.filter((row) => {
      if (row.entity && row.entity.id) {
        return row.entity.id === this.selectedProducer.id;
      } else {
        return false;
      }
    });
  }

  onFilterChangedBrand(filters) {
    let filteredData: any;

    if (filters.freeText) {
      const dataToFilter = filteredData || this.producerProfileData;
      filteredData = dataToFilter.filter((row) => {
        return (
          (row.asset &&
            row.asset.toLowerCase().includes(filters.freeText.toLowerCase())) ||
          (row.brand &&
            row.brand.toLowerCase().includes(filters.freeText.toLowerCase())) ||
          (row.metal &&
            row.metal.toLowerCase().includes(filters.freeText.toLowerCase())) ||
          (row.country &&
            row.country.toLowerCase().includes(filters.freeText.toLowerCase()))
        );
      });
    }

    this.filteredBrandData = filteredData || this.producerProfileData;
  }

  onFilterChangedTargets(filters) {
    let filteredData: any;

    if (filters.freeText) {
      const dataToFilter = filteredData || this.targetsData;
      filteredData = dataToFilter.filter((row) => {
        return (
          (row.targets &&
            row.targets
              .toLowerCase()
              .includes(filters.freeText.toLowerCase())) ||
          (row.targetComment &&
            row.targetComment
              .toLowerCase()
              .includes(filters.freeText.toLowerCase()))
        );
      });
    }

    this.filteredTargetsData = filteredData || this.targetsData;
  }

  onFilterChangedDisclosure(filters) {
    let filteredData: any;

    if (filters.freeText) {
      const dataToFilter = filteredData || this.disclosureData;
      filteredData = dataToFilter.filter((row) => {
        return (
          (row.asset &&
            row.asset.toLowerCase().includes(filters.freeText.toLowerCase())) ||
          (row.disclosure &&
            row.disclosure
              .toLowerCase()
              .includes(filters.freeText.toLowerCase())) ||
          (row.level &&
            row.level.toLowerCase().includes(filters.freeText.toLowerCase()))
        );
      });
    }

    this.filteredDisclosureData = filteredData || this.disclosureData;
  }

  disclosureFilterCleared() {
    if (this.selectedThemeFilterName) {
      this.selectedThemeFilterName = null;
      this.filteredDisclosureData = _.cloneDeep(this.disclosureData);
    }
  }

  targetFilterCleared() {
    if (this.selectedTargetThemeFilterName) {
      this.selectedTargetThemeFilterName = null;
      this.filteredTargetsData = _.cloneDeep(this.targetsData);
    }
  }

  downloadDocument(rowData, type) {
    window.open(
      `${environment.apiUrl}/home-service/publicView/document/download?uuid=${rowData.proofDocument.uuid}&name=${rowData.proofDocument.fileName}`
    );
  }

  howDialCalculated() {
    this.howDialCalculatedDialog = true;
  }

  redirectToUrl(event, url) {
    event.preventDefault();
    if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url;
    }
    return window.open(url);
  }

  howToaAddYourCompany() {
    this.howToaAddYourCompanyDialog = true;
  }

  redirectToUrlOrDoc(e) {
    e.preventDefault();
    if (this.selectedProducer.sustainabilityUrl) {
      this.redirectToUrl(e, this.selectedProducer.sustainabilityUrl);
    } else {
      const sustainailityDocData =
        this.selectedProducer.sustainabilityDocument.split("|");
      const uuid = sustainailityDocData[0];
      const fileName = sustainailityDocData[1];
      window.open(
        `${environment.apiUrl}/home-service/publicView/document/download?uuid=${uuid}&name=${fileName}`
      );
    }
  }

  redirectToComparison() {
    localStorage.setItem("producerId", this.producer);
    this.coreService.publicRoutePath.next(
      "/public/metric-comparison/brand-comparison"
    );
    this.router.navigate(["/public/metric-comparison/brand-comparison"]);
  }

  onRowSelect(selectedMetric) {
    localStorage.setItem("selectedMetric", JSON.stringify(selectedMetric));
    this.coreService.publicRoutePath.next(
      "/public/metric-comparison/brand-comparison"
    );
    this.router.navigate(["/public/metric-comparison/brand-comparison"]);
  }

  infoSideBySide() {
    this.infoTriggers = true;
  }

  dailogClose() {
    this.infoTriggers = false;
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
    this.navigationEndCounter = 0;
    this.coreService.countDown.next(null);
  }
}
