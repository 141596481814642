import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "@env/environment";

@Component({
  selector: "app-preview-old-new-doc",
  templateUrl: "./preview-old-new-doc.component.html",
  styleUrls: ["./preview-old-new-doc.component.scss"],
})
export class PreviewOldNewDocComponent implements OnInit {
  @Input() previewOldNewDoc: boolean;
  @Input() oldDocId: number;
  @Input() newDocId: number;
  @Input() oldCoaDateStr: string;
  @Input() errorMessage?: boolean;
  @Input() coaRef?: string;

  @Output() hideOldNewDialog = new EventEmitter<any>();
  @Output() newCoaSelected = new EventEmitter<any>();
  @Output() sameDoc = new EventEmitter<any>();
  @Output() sameDate = new EventEmitter<any>();

  documentUrl: string;
  docTypes = [
    { name: "Your Document", id: "new" },
    { name: "Previously registered", id: "old" },
  ];
  docType: string;
  message: string;

  constructor() {}

  ngOnInit() {
    this.errorMessage &&
      (this.message = `The COA date selected does not match the COA date ${this.oldCoaDateStr} already registered for this COA Reference. Please select an action:`);
    this.docType = "new";
    this.documentUrl = `${environment.apiUrl}/doc-upload-service/documentfiles/${this.newDocId}?channel=PREVIEW&coaRef=${this.coaRef}`;
  }

  onDocTypeChange() {
    if (this.docType === "new") {
      this.documentUrl = `${environment.apiUrl}/doc-upload-service/documentfiles/${this.newDocId}?channel=PREVIEW&coaRef=${this.coaRef}`;
    } else {
      this.documentUrl = `${environment.apiUrl}/doc-upload-service/documentfiles/${this.oldDocId}?channel=PREVIEW&coaRef=${this.coaRef}`;
    }
  }

  hideEditPreview() {
    this.hideOldNewDialog.emit();
  }

  selectNewCoaRef() {
    this.newCoaSelected.emit();
  }

  docMatches() {
    this.sameDoc.emit();
  }

  docDateMatches() {
    this.sameDate.emit();
  }
}
