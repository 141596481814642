import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { AbstractPaginationService } from "@shared/services/abstract-pagination.service";
import { BehaviorSubject, Observable } from "rxjs";

export const UPLOAD_COA =
  "/doc-upload-service/documentfiles/upload?doctype=COA";
export const UPLOAD_BASIC_ENHANCED = "/doc-upload-service/documentfiles/upload";
export const GET_RECORDS = "/dashboard-service/dashboard/getFilteredData";
export const BYPASS = "/inference-service/document/bypassInference/";
export const REVIEW_RECORDS = "/dashboard-service/records/";
export const APPROVE_RECORD = "/dashboard-service/records/approve/";
export const APPROVED_RECORDS = "/inventory-service/records/getApproved";
export const SEARCH_RECORDS =
  "/dashboard-service/records/search?isUIRequest=true";
export const TO_DO_LIST = "/inventory-service/user/records";
export const ADD_FLAG = "/dashboard-service/records/addFlag";
export const EXPORT = "/inventory-service/approved/records/export";
export const ADD_TO_LIST = "/inventory-service/user/addRecord";
export const ADD_LOCK = "/inference-service/document/lock/";
export const CHEMICAL_COMPOSITION = "/dashboard-service/records/compositions/";
export const UPDATE_STATUS = "/dashboard-service/records/updateStatus";
export const AMEND = "/dashboard-service/records/amend";
export const ASSIGN = "/dashboard-service/records/assign";
export const BULK_APPROVE = "/dashboard-service/records/approve";
export const GET_APPROVED_EDIT = "/inventory-service/records/";
export const AMEND_APPROVED = "/inventory-service/records/update";
export const REQUEST_UPDATE = "/inventory-service/recordAmend/add";
export const VALIDATE = "/doc-upload-service/documentfiles/validate";
export const EXPORT_REVIEW = "/dashboard-service/record/export/";
export const INDUSTRYGRADE = "/common-service/producers/getIndustryGrade";
export const ADDITIONAL_DOCS = "/doc-upload-service/documentfiles/childDocs/";
export const APPROVED_DOWNLOAD = "/dashboard-service/record/single/export/";
export const SEARCH_APPROVED_RECORDS =
  "/inventory-service/records/searchApproved";
export const THEMES = "/admin-service/themes/get?status=ACTIVE";
export const APPEND_THEME = "/inventory-service/themes/addTheme";
export const DELETE_TO_DO = "/inventory-service/user/deleteRecord/";
export const DELETE_RECORD = "/inference-service/docRecord/delete/";
export const UPDATE_DISCLOSURE = "/inventory-service/themes/update/";
export const DELETE_APPROVED = "/inventory-service/records/delete";
export const UPDATE_DISCLOSURE_NEW =
  "/inventory-service/sustainability/update/";
export const GENERATE_REPORT = "/report-service/reports/generate";
export const GENERATE_REPORT_MY_RECORDS =
  "/report-service/reports/myrecords/generate";
export const STORE_FILTERS = "/inventory-service/storedfilters/passportids";
export const STORE_FILTERS_COA_REF =
  "/inventory-service/storedfilters/coareferences";
export const TABLE_DATA = "/admin-service/settings/records";
export const VALIDATE_DOC = "/doc-upload-service/documents/validateDoc";
@Injectable({
  providedIn: "root",
})
export class CreateImportService extends AbstractPaginationService {
  refresh = new BehaviorSubject(null);
  hideUploadDialog = new BehaviorSubject(null);
  unlock = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    super();
  }

  upload(file, pcr, brand): Observable<any> {
    const formData = new FormData();
    formData.append("file", file, file.name);

    let params = new HttpParams();
    if (pcr && brand) {
      params = params.append("pcr", pcr);
      params = params.append("brand", brand);
    }
    return this.http.post(`${environment.apiUrl}${UPLOAD_COA}`, formData, {
      params,
    });
  }

  uploadBasicEnhanced(file, type): Observable<any> {
    const formData = new FormData();
    formData.append("file", file, file.name);

    let params = new HttpParams();
    if (type === "enhanced") {
      params = params.append("doctype", "ENHANCED-RECORDS");
    } else {
      params = params.append("doctype", "BASIC-RECORDS");
    }
    params = params.append("validated", "true");
    return this.http.post(
      `${environment.apiUrl}${UPLOAD_BASIC_ENHANCED}`,
      formData,
      { params }
    );
  }

  getRecords(payload, pagination): Observable<any> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post(`${environment.apiUrl}${GET_RECORDS}`, payload, {
      params,
    });
  }

  refreshLisitng() {
    this.refresh.next("refresh");
  }

  byPass(id) {
    return this.http.get(`${environment.apiUrl}${BYPASS}${id}`);
  }

  getReviewRecords(id: any, coaRef: any, coaDocument): any {
    let payload = {};
    if (coaDocument) {
      payload = {
        coaDocument,
      };
    } else {
      payload = {
        coaReference: coaRef,
      };
    }

    return this.http.post(
      `${environment.apiUrl}${REVIEW_RECORDS}${id}`,
      payload
    );
  }

  approveRecord(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}${APPROVE_RECORD}${id}`);
  }

  hideUpload() {
    this.hideUploadDialog.next(true);
  }

  getApprovedRecords(filters, pagination): any {
    let params = new HttpParams();
    params = params.append("fromPage", pagination.page);
    params = params.append("pageSize", pagination.size);
    return this.http.post(`${environment.apiUrl}${APPROVED_RECORDS}`, filters, {
      params,
    });
  }

  searchApprovedRecords(filters, pagination): any {
    let params = new HttpParams();
    params = params.append("fromPage", pagination.page);
    params = params.append("pageSize", pagination.size);
    return this.http.post(
      `${environment.apiUrl}${SEARCH_APPROVED_RECORDS}`,
      filters,
      { params }
    );
  }

  storeFilter(lmepassportIds): any {
    return this.http.post(
      `${environment.apiUrl}${STORE_FILTERS}`,
      lmepassportIds
    );
  }

  storeFilterCoaRef(coaReferenceNo) {
    return this.http.post(
      `${environment.apiUrl}${STORE_FILTERS_COA_REF}`,
      coaReferenceNo
    );
  }

  searchRecords(search): any {
    return this.http.post(`${environment.apiUrl}${SEARCH_RECORDS}`, search);
  }

  getToDoList(pagination, filters, entityId?: any): any {
    let params = new HttpParams();
    params = params.append("fromPage", pagination.page);
    params = params.append("pageSize", pagination.size);

    if (entityId) {
      params = params.append("entityId", entityId);
    }

    return this.http.post<any>(`${environment.apiUrl}${TO_DO_LIST}`, filters, {
      params,
    });
  }

  addFlag(id, comment) {
    return this.http.put(`${environment.apiUrl}${ADD_FLAG}`, [{ id, comment }]);
  }

  export(filters): any {
    return this.http.post(`${environment.apiUrl}${EXPORT}`, filters);
  }

  addToDoList(payload): any {
    return this.http.post(`${environment.apiUrl}${ADD_TO_LIST}`, payload);
  }

  addLock(uuid, unlock?) {
    if (unlock) {
      return this.http.put(
        `${environment.apiUrl}${ADD_LOCK}?uuid=${uuid}&unlock=true`,
        {}
      );
    } else {
      return this.http.put(`${environment.apiUrl}${ADD_LOCK}?uuid=${uuid}`, {});
    }
  }

  getChemicalComposition(id): any {
    return this.http.get(`${environment.apiUrl}${CHEMICAL_COMPOSITION}${id}`);
  }

  updateStatus(ids): any {
    return this.http.put(`${environment.apiUrl}${UPDATE_STATUS}`, ids);
  }

  updateReview1(payload) {
    return this.http.put(`${environment.apiUrl}${AMEND}`, payload);
  }

  assignRecord(payload) {
    return this.http.put(`${environment.apiUrl}${ASSIGN}`, payload);
  }

  approveBulk(payload): any {
    return this.http.put(`${environment.apiUrl}${BULK_APPROVE}`, payload);
  }

  getApprovedRecordEdit(docId, recordId) {
    return this.http.get(
      `${environment.apiUrl}${GET_APPROVED_EDIT}${docId}/${recordId}`
    );
  }

  updateApproved(payload) {
    return this.http.put(`${environment.apiUrl}${AMEND_APPROVED}`, payload);
  }

  requestUpdate(payload) {
    return this.http.post(`${environment.apiUrl}${REQUEST_UPDATE}`, payload);
  }

  validate(file, type): any {
    const formData = new FormData();
    formData.append("file", file, file.name);

    let params = new HttpParams();
    if (type === "enhanced") {
      params = params.append("doctype", "ENHANCED-RECORDS");
    } else {
      params = params.append("doctype", "BASIC-RECORDS");
    }

    return this.http.post(`${environment.apiUrl}${VALIDATE}`, formData, {
      params,
    });
  }

  exportReviewRecords(uuid, coaDocument): any {
    return this.http.post(
      `${environment.apiUrl}${EXPORT_REVIEW}${uuid}/${coaDocument}`,
      {}
    );
  }

  unlockDoc(uuid) {
    this.unlock.next(uuid);
  }

  getIndustryGrade(metal): any {
    let params = new HttpParams();
    params = params.append("metal", metal);
    return this.http.get(`${environment.apiUrl}${INDUSTRYGRADE}`, { params });
  }

  getAddionalDocs(docId, coaReference): any {
    return this.http.post(`${environment.apiUrl}${ADDITIONAL_DOCS}${docId}`, {
      coaReference,
    });
  }

  exportApprovedRecords(docId, recordId): any {
    return this.http.post(
      `${environment.apiUrl}${APPROVED_DOWNLOAD}${docId}/${recordId}`,
      {}
    );
  }

  getThemes(): any {
    return this.http.get(`${environment.apiUrl}${THEMES}`);
  }

  appendTheme(payload): any {
    return this.http.post(`${environment.apiUrl}${APPEND_THEME}`, payload);
  }

  deleteToDoItem(id) {
    return this.http.delete(`${environment.apiUrl}${DELETE_TO_DO}${id}`);
  }

  deleteRecord(uuid, coaDocument) {
    return this.http.post(`${environment.apiUrl}${DELETE_RECORD}${uuid}`, {
      coaDocument,
    });
  }

  updateDisclosure(payload, id) {
    return this.http.post(
      `${environment.apiUrl}${UPDATE_DISCLOSURE}${id}`,
      payload
    );
  }

  updateDisclosureVersion2(payload, id) {
    return this.http.put(
      `${environment.apiUrl}${UPDATE_DISCLOSURE_NEW}${id}`,
      payload
    );
  }

  deleteApprovedRecords(payload) {
    return this.http.post(`${environment.apiUrl}${DELETE_APPROVED}`, payload);
  }

  generateReport(filters, reportType): any {
    let payload = {
      filters,
      reportType,
    };
    return this.http.post(`${environment.apiUrl}${GENERATE_REPORT}`, payload);
  }

  generateReportMyRecords(filters, reportType): any {
    let payload = {
      filters,
      reportType,
    };
    return this.http.post(
      `${environment.apiUrl}${GENERATE_REPORT_MY_RECORDS}`,
      payload
    );
  }

  getTableConfig(tabName): any {
    return this.http.get(
      `${environment.apiUrl}${TABLE_DATA}?tabName=${tabName}`
    );
  }

  validateDocument(file, fileType?): any {
    const formData = new FormData();
    formData.append("file", file, file.name);
    let params = new HttpParams();
    fileType && (params = params.append("docType", fileType));
    return this.http.post(`${environment.apiUrl}${VALIDATE_DOC}`, formData, {
      params,
    });
  }
}
