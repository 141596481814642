import { Component, OnInit } from "@angular/core";
import { FieldArrayType, FormlyFormBuilder } from "@ngx-formly/core";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "formly-repeat-section",
  providers: [ConfirmationService],
  template: `
    <div>
      <button
        style="margin-bottom: 10px;"
        class="btn btn-primary"
        type="button"
        (click)="add()"
        attr.id="{{ to.id }}"
      >
        {{ to.addText }}
      </button>
    </div>
    <div
      *ngFor="let field of field.fieldGroup; let i = index; let l = count"
      class="row"
    >
      <formly-field class="col" [field]="field"></formly-field>
      <div class="col-sm-2 d-flex align-items-center">
        <i
          (click)="onRemove(i, field)"
          class="fa fa-trash action text-center"
          aria-hidden="true"
          title="Delete"
          style="font-size: 1rem;
          cursor: pointer;"
        ></i>
      </div>
    </div>
    <p-confirmDialog
      appendTo="body"
      [style]="{ width: '27vw' }"
    ></p-confirmDialog>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {
  constructor(
    private confirmationService: ConfirmationService,
    builder: FormlyFormBuilder
  ) {
    super(builder);
  }

  onRemove(i, field) {
    if (this.model[i].hasOwnProperty("id")) {
      this.confirmationService.confirm({
        message: `
        Are you sure you want to delete this metric, standard or certification? All the appended disclosures/records associated to this metric, standard or certification will be deleted.`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          super.remove(i);
        },
      });
    } else {
      super.remove(i);
    }
  }
}
