<p-dialog
  header="Value-add Themes and Disclosures Details"
  [modal]="true"
  [(visible)]="valueAddThemeDialog"
  [style]="{ width: '80vw' }"
  (onHide)="onValueAddThemeHide()"
>
  <app-table-simple [config]="tableConfig" [tableData]="valueAddThemesData">
  </app-table-simple>
  <p-footer>
    <button class="btn btn-primary" (click)="onValueAddThemeHide()">Ok</button>
  </p-footer>
</p-dialog>

<ng-template #proofPointTemplate let-rowData>
  {{ rowData.proofText === "null" ? "" : rowData.proofText }}
  <a
    class="proof-url"
    (click)="redirectToUrl($event, rowData.proofUrl)"
    href="#"
    >{{ rowData.proofUrl === "null" ? "" : rowData.proofUrl }}</a
  >
  &nbsp;
  <i
    *ngIf="rowData.proofDocument"
    (click)="downloadDocument(rowData, 'PROOF_POINT')"
    class="fa fa-file"
    aria-hidden="true"
    title="Download"
    style="cursor: pointer"
  ></i>
</ng-template>

<ng-template #frameworkTemplate let-rowData>
  <ng-container *ngIf="rowData.frameworkAsProof; else FRAME_WORK">
    Same as Proof Point
  </ng-container>
  <ng-template #FRAME_WORK>
    {{ rowData.frameworkText === "null" ? "" : rowData.frameworkText }}
    <a
      class="proof-url"
      (click)="redirectToUrl($event, rowData.frameworkUrl)"
      href="#"
      >{{ rowData.frameworkUrl === "null" ? "" : rowData.frameworkUrl }}</a
    >
    &nbsp;<i
      *ngIf="rowData.frameworkDocument"
      (click)="downloadDocument(rowData, 'FRAMEWORK')"
      class="fa fa-file"
      aria-hidden="true"
      title="Download"
      style="cursor: pointer"
    ></i>
  </ng-template>
</ng-template>

<ng-template #actionTemplate let-rowData>
  <i
    role="button"
    tabindex="0"
    (click)="editDisclosure(rowData)"
    class="fa fa-pencil-square-o action mr-2 text-center"
    aria-hidden="true"
    title="View and Edit"
  ></i>
  <i
    (click)="confirmDeleteDisclosure(rowData)"
    class="fa fa-trash action text-center"
    aria-hidden="true"
    title="Delete"
  ></i>
</ng-template>

<app-append
  *ngIf="appendDialog"
  [appendDialog]="appendDialog"
  [appendedRowData]="appendedRowData"
  editAppended="true"
  (hideAppendDialog)="onHideAppendDialog()"
></app-append>

<p-confirmDialog [style]="{ width: '27vw' }"></p-confirmDialog>
