import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Dialog } from "primeng/dialog";

@Component({
  selector: "app-redact-file",
  templateUrl: "./redact-file.component.html",
  styleUrls: ["./redact-file.component.scss"],
})
export class RedactFileComponent implements OnInit, AfterViewInit {
  @Input() redactDialog: boolean;
  @Input() fileToRedact: any;

  @Output() redactHide = new EventEmitter<any>();
  @Output() redactComplete = new EventEmitter<any>();

  @ViewChild(Dialog, { static: false }) dialog: Dialog;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.dialog.maximize();
    });
  }

  onRedactHide(e) {
    this.redactHide.emit(e);
  }

  onRedact(e) {
    this.redactComplete.emit(this.fileToRedact);
  }

  redactResultHandler(event) {
    const blob = new Blob([event], { type: "application/pdf" });
    this.fileToRedact = this.blobToFile(blob, this.fileToRedact.name);
  }

  blobToFile(theBlob: Blob, fileName: string): File {
    let b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return <File>theBlob;
  }
}
