import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { IDisclosure, IUser } from "@core/models/api.model";
import { IPagination } from "@core/models/app.interface";
import { approvedRecordListingFilters } from "@core/models/filter-types.model";
import { CoreService } from "@core/services/core.service";
import { environment } from "@env/environment";
import { CreateImportService } from "@module/create-import/services/create-import.service";
import { EntityService } from "@module/platform-admin/services/entity.service";
import { UserAdminService } from "@module/user-admin/services/user-admin.service";
import { ITableViewConfig } from "@shared/models/table-view.model";
import {
  COA_REFERENCE_SEARCH_LIMIT,
  DOWNLOAD_LIMIT_COUNT,
  LME_PASSPORT_ID_SEARCH_LIMIT,
  STORE_COA_LIMIT,
  STORE_LME_PASSPORT_LIMIT,
} from "app/app.const";
import * as _ from "lodash";
import * as moment from "moment";
import { ConfirmationService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-approved-records",
  templateUrl: "./approved-records.component.html",
  styleUrls: ["./approved-records.component.scss"],
  providers: [ConfirmationService],
})
export class ApprovedRecordsComponent implements OnInit, OnDestroy {
  @Input() parent?: string;
  @Input() metalFilter?: any;
  @Input() companyFilter?: any;

  tableConfig: ITableViewConfig;
  private _destroy$ = new Subject<any>();
  private _stopPrevious$ = new Subject<any>();
  pagination = {
    page: 0,
    size: 20,
  } as IPagination;
  public firstItemIndex = 0;
  approvedRecords = { metaData: { totalElements: 0 }, content: [] };
  filters = _.cloneDeep(approvedRecordListingFilters);

  @ViewChild("additionalDocumentsTemplate", { static: true })
  additionalDocumentsTemplate: TemplateRef<any>;
  @ViewChild("actionTemplate", { static: true })
  actionTemplate: TemplateRef<any>;
  @ViewChild("passportTemplate", { static: true })
  passportTemplate: TemplateRef<any>;
  @ViewChild("ValueAddThemesTemplate", { static: true })
  ValueAddThemesTemplate: TemplateRef<any>;

  appliedFilters: any;
  reviewRecordDialog: boolean;
  reviewData: Object;
  user: IUser;
  reviewStatus: any;
  viewAdditionalDocsDialog: boolean;
  additionalDocumentId: any;
  selectedApprovedRecords = [];
  appendDialog: boolean;
  valueAddThemeDialog: boolean;
  appendId: any;
  disclosures: IDisclosure[];
  coaReference: any;
  actionItems: any;
  actionModel: any = [];
  allRecords: boolean;
  filterApplied: boolean;
  results: any;
  totalElements: any;
  aprovedParent: string;
  headerCheckBoxSelected = false;

  constructor(
    private createImportService: CreateImportService,
    private coreService: CoreService,
    private userAdminService: UserAdminService,
    private entityService: EntityService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.aprovedParent = "approvedRecord";
    this.user = this.coreService.getLoggedInuserDetails();
    this.setTableConfig();

    this.setFilters();
    this.getDisclosures();
    this.setActionItems();
  }

  setTableConfig() {
    this.createImportService
      .getTableConfig("APPROVE_RECORDS")
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        const { columns, ...config } = res.config;
        let _columns = [...res.config.columns];
        _columns = _.orderBy(_columns, ["order"], ["asc"]);
        config.paginator = res.config.pagination;
        delete config.pagination;

        this.tableConfig = {
          ...config,
          ...{ columns: _columns },
        };

        _columns.push({
          header: "Value-Add Themes and Disclosures",
          width: "200px",
          templateRef: this.ValueAddThemesTemplate,
        });

        if (!this.parent) {
          _columns.push({
            header: "Actions",
            width: "200px",
            templateRef: this.actionTemplate,
          });
        }

        if (this.user.platformAdmin || this.parent) {
          _columns.splice(0, 0, {
            checkboxSelect: true,
            width: "3em",
          });
        }

        if (res.filterConfig && res.filterConfig.filters.length > 0) {
          this.filters = res.filterConfig;
          this.filters["filterListsModel"] = _.cloneDeep(
            this.filters["filters"]
          );
          delete this.filters["filters"];
          this.filters["filterListsModel"] = _.orderBy(
            this.filters["filterListsModel"],
            ["order"],
            ["asc"]
          );

          if (!this.user.platformAdmin) {
            let companyIdx = this.filters.filterListsModel.findIndex(
              (filter) => filter.itemsType === "companyTypeActiveSuspended"
            );
            companyIdx !== -1 &&
              this.filters.filterListsModel.splice(companyIdx, 1);

            let stakeholderIdx = this.filters.filterListsModel.findIndex(
              (filter) => filter.itemsType === "stakeHolderType"
            );
            stakeholderIdx !== -1 &&
              this.filters.filterListsModel.splice(stakeholderIdx, 1);
          }

          if (this.parent === "append") {
            this.filters.filterListsModel =
              this.filters.filterListsModel.filter(
                (elem) => elem.itemsType === "brand"
              );
            this.filters.dateRange = false;
            this.filters.disclosures = false;
          }
        }
      });
  }

  setActionItems() {
    this.actionItems = [
      "Edit/Review Record",
      "View Additional Documents",
      "Export Original COA",
    ];
  }

  setFilters() {
    this.user = this.coreService.getLoggedInuserDetails();
    if (this.user.platformAdmin) {
      this.appliedFilters = [];
    } else {
      this.appliedFilters = [
        {
          filterName: "ENTITY_ID",
          values: [this.user.entityId],
        },
      ];
    }

    /* if (this.parent === "append") {
      this.appliedFilters.push({
        filterName: "PRODUCER_COA_TYPE",
        values: [],
      });
    } */

    if (this.metalFilter) {
      this.appliedFilters.push({
        filterName: "METAL",
        values: this.metalFilter,
      });
    }

    if (this.companyFilter) {
      this.appliedFilters.push({
        filterName: "BY_COMPANY_NAME",
        values: [this.companyFilter],
      });
    }
  }

  getDisclosures() {
    this.entityService
      .getDisclosures()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        res.forEach((disclosure) => {
          disclosure["themeSubTheme"] = disclosure["subThemeName"]
            ? `${disclosure["themeName"]} - ${disclosure["subThemeName"]}`
            : disclosure["themeName"];
        });
        this.disclosures = res.filter((disc) => disc.status !== "DELETED");
      });
  }

  onPaginationChanged(pagination: IPagination) {
    this.pagination = pagination;
    this.firstItemIndex = this.pagination.page * this.pagination.size;
    this.getSearchData(this.appliedFilters);
  }

  loadData(from) {
    let lmePassportIdIndex = this.appliedFilters.findIndex(
      (filter) => filter.filterName === "LME_PASSPORT_ID"
    );
    if (
      this.appliedFilters[lmePassportIdIndex]["values"].length >
        STORE_LME_PASSPORT_LIMIT &&
      from === "filter"
    ) {
      this.createImportService
        .storeFilter(this.appliedFilters[lmePassportIdIndex]["values"])
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          let filters = _.clone(this.appliedFilters);
          filters.push({
            filterName: "STORED_PASSPORT_ID",
          });
          filters = filters.filter(
            (filterElement) => filterElement.filterName !== "LME_PASSPORT_ID"
          );
          setTimeout(() => {
            this.getSearchData(filters);
          }, 1000);
        });
    } else if (
      this.appliedFilters[lmePassportIdIndex]["values"].length >
        STORE_LME_PASSPORT_LIMIT &&
      from === "page"
    ) {
      let filters = _.clone(this.appliedFilters);
      filters.push({
        filterName: "STORED_PASSPORT_ID",
      });
      filters = filters.filter(
        (filterElement) => filterElement.filterName !== "LME_PASSPORT_ID"
      );
      this.getSearchData(filters);
    } else {
      this.getSearchData(this.appliedFilters);
    }
  }

  loadDataMultipleCoa(from) {
    let coaReferenceIndex = this.appliedFilters.findIndex(
      (filter) => filter.filterName === "COA_REFERENCE"
    );
    if (
      this.appliedFilters[coaReferenceIndex]["values"].length >
        STORE_COA_LIMIT &&
      from === "filter"
    ) {
      this.createImportService
        .storeFilterCoaRef(this.appliedFilters[coaReferenceIndex]["values"])
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          let filters = _.clone(this.appliedFilters);
          filters.push({
            filterName: "STORED_COA_REFERENCE",
          });
          filters = filters.filter(
            (filterElement) => filterElement.filterName !== "COA_REFERENCE"
          );
          setTimeout(() => {
            this.getSearchData(filters);
          }, 1000);
        });
    } else if (
      this.appliedFilters[coaReferenceIndex]["values"].length >
        STORE_COA_LIMIT &&
      from === "page"
    ) {
      let filters = _.clone(this.appliedFilters);
      filters.push({
        filterName: "STORED_COA_REFERENCE",
      });
      filters = filters.filter(
        (filterElement) => filterElement.filterName !== "COA_REFERENCE"
      );
      this.getSearchData(filters);
    } else {
      this.getSearchData(this.appliedFilters);
    }
  }

  getSearchData(filters) {
    let clonedFilter = this.generateClonedFilter(filters);
    this._stopPrevious$.next(true);
    this.createImportService
      .getApprovedRecords(clonedFilter, this.pagination)
      .pipe(takeUntil(this._destroy$), takeUntil(this._stopPrevious$))
      .subscribe(
        (res) => {
          this.setInitialData(res);
          this.approvedRecords["content"] = res.results;
          if (this.parent === "append") {
            this.approvedRecords["content"] = this.approvedRecords[
              "content"
            ].filter((row) => row.status === "APPROVED");
          }
          this.approvedRecords["metadata"] = {
            totalElements: res.totalElements ? res.totalElements : 0,
          };
          this.results = res.results;
          this.totalElements = res.totalElements ? res.totalElements : 0;
          this.checkAllRecords(res);
        },
        (err) => {
          if (
            err.status === 400 &&
            err.error.message.includes("SQLGrammarException")
          ) {
            this.coreService.showMessage({
              key: "tc",
              severity: "error",
              summary: "Error",
              detail: `Exceeded limit of 1,000 rows, please use comma separated for multiple IDs exceeding this limit`,
            });
          }
        }
      );
  }

  checkAllRecords(res) {
    if (this.headerCheckBoxSelected) {
      this.selectedApprovedRecords = res.results;
    } else {
      this.selectedApprovedRecords = [];
    }
  }

  generateClonedFilter(filters) {
    let clonedFilter = _.cloneDeep(filters);

    let lmePassportIdIndex = clonedFilter.findIndex(
      (filter) => filter.filterName === "LME_PASSPORT_ID"
    );
    if (
      lmePassportIdIndex !== -1 &&
      clonedFilter[lmePassportIdIndex]["values"].length >
        STORE_LME_PASSPORT_LIMIT
    ) {
      clonedFilter.push({
        filterName: "STORED_PASSPORT_ID",
      });
      clonedFilter = clonedFilter.filter(
        (filterElement) => filterElement.filterName !== "LME_PASSPORT_ID"
      );
    }

    let coaReferenceIndex = clonedFilter.findIndex(
      (filter) => filter.filterName === "COA_REFERENCE"
    );

    if (
      coaReferenceIndex !== -1 &&
      clonedFilter[coaReferenceIndex]["values"].length > STORE_COA_LIMIT
    ) {
      clonedFilter.push({
        filterName: "STORED_COA_REFERENCE",
      });
      clonedFilter = clonedFilter.filter(
        (filterElement) => filterElement.filterName !== "COA_REFERENCE"
      );
    }

    return clonedFilter;
  }

  setInitialData(res) {
    res.results.forEach((row) => {
      if (row.recordType === "BASIC") {
        row.scrCompliantStr = "NA";
      }

      let originalcoaDateStr = row.coaDateStr;
      if (row.coaDateStr) {
        row.coaDateStr = moment(row.coaDateStr, "DD-MM-YYYY").toDate();

        if (isNaN(row.coaDateStr)) {
          row.coaDateStr = moment(originalcoaDateStr, "DD-MMM-YYYY").toDate();
        }

        if (isNaN(row.coaDateStr)) {
          row.coaDateStr = moment(originalcoaDateStr, "DD-MM-YYYY").toDate();
        }

        if (isNaN(row.coaDateStr)) {
          row.coaDateStr = moment(originalcoaDateStr, "DD-M-YYYY").toDate();
        }
      }
    });
  }

  onFilterChanged(filters) {
    this.selectedApprovedRecords = [];
    this.headerCheckBoxSelected = false;
    this.firstItemIndex = 0;
    this.pagination.page = this.firstItemIndex;
    this.appliedFilters = [];

    let multipleLmepassportIds = false;
    let multipleCoa = false;
    let lmePassportLength = 0;
    let coaReferenceLength = 0;

    if (this.parent === "append") {
      this.appliedFilters.push({
        filterName: "PRODUCER_COA_TYPE",
        values: [],
      });

      if (this.metalFilter) {
        this.appliedFilters.push({
          filterName: "METAL",
          values: this.metalFilter,
        });
      }

      if (this.companyFilter) {
        this.appliedFilters.push({
          filterName: "BY_COMPANY_NAME",
          values: [this.companyFilter],
        });
      }
    }

    for (const key in filters) {
      if (
        key !== "freeText" &&
        key !== "disclosure" &&
        key !== "dateFrom" &&
        key !== "dateTo" &&
        key !== "COA_REFERENCE_NUMBER" &&
        key !== "LME_PASSPORT_ID" &&
        filters[key]
      ) {
        this.appliedFilters.push({
          filterName: key,
          values: [filters[key]],
        });
      } else if (key === "disclosure") {
        this.setDisclosureFilter(filters);
      } else if (key === "freeText") {
        filters[key] &&
          this.appliedFilters.push({
            filterName: "FREE_TEXT",
            values: [filters[key]],
          });
      } else if (key === "dateFrom" && filters.dateFrom) {
        this.appliedFilters.push({
          filterName: "dateFrom",
          values: [moment(filters[key]).format("YYYY-MM-DDT00:00:00")],
        });
      } else if (key === "dateTo" && filters.dateTo) {
        this.appliedFilters.push({
          filterName: "dateTo",
          values: [moment(filters[key]).format("YYYY-MM-DDT23:59:59")],
        });
      } else if (key === "LME_PASSPORT_ID" && filters.LME_PASSPORT_ID) {
        let splittedArr = filters[key].split(",").map((item) => item.trim());
        splittedArr = splittedArr.filter((elem) => elem !== "");
        lmePassportLength = splittedArr.length;
        splittedArr = [...new Set(splittedArr)];
        multipleLmepassportIds = splittedArr.length > 1;
        this.appliedFilters.push({
          filterName: key,
          values: splittedArr,
        });
      } else if (
        key === "COA_REFERENCE_NUMBER" &&
        filters.COA_REFERENCE_NUMBER
      ) {
        let splittedArr = filters[key].split(",").map((item) => item.trim());
        splittedArr = splittedArr.filter((elem) => elem !== "");
        coaReferenceLength = splittedArr.length;
        splittedArr = [...new Set(splittedArr)];
        multipleCoa = splittedArr.length > 1;
        this.appliedFilters.push({
          filterName: "COA_REFERENCE",
          values: splittedArr,
        });
      }
    }

    if (!this.user.platformAdmin) {
      this.appliedFilters.push({
        filterName: "ENTITY_ID",
        values: [this.user.entityId],
      });
    }

    if (multipleCoa && multipleLmepassportIds) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail:
          "You can only search one COA reference in conjunction with multiple LMEpassport ID.",
      });

      return false;
    }

    if (coaReferenceLength > COA_REFERENCE_SEARCH_LIMIT) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Maximum allowed limit for COA reference search is 10,000",
      });
      return;
    }

    if (lmePassportLength > LME_PASSPORT_ID_SEARCH_LIMIT) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Maximum allowed limit for LME passport Id search is 100,000",
      });
      return;
    }

    if (multipleLmepassportIds) {
      this.loadData("filter");
    } else if (multipleCoa) {
      this.loadDataMultipleCoa("filter");
    } else {
      this.getSearchData(this.appliedFilters);
    }
  }

  setDisclosureFilter(filters) {
    let disclosures = [];
    filters["disclosure"].forEach((elem) => {
      if (elem.hasOwnProperty("disclosureId")) {
        disclosures.push(elem.disclosureId);
      } else {
        let filteredDisc = this.disclosures
          .filter(
            (disclosure) => disclosure.themeSubTheme === elem.themeSubTheme
          )
          .map((disclosure) => disclosure.disclosureId);

        disclosures.push(filteredDisc);
      }
    });

    this.appliedFilters.push({
      filterName: "DISCLOSURE_ID",
      values: _.flatten(disclosures),
    });

    if (typeof filters["disclosure"][0] === "string") {
      filters["disclosure"] = this.disclosures.filter((elem) => {
        return _.flatten(disclosures).includes(elem.disclosureId.toString());
      });
    } else {
      filters["disclosure"] = this.disclosures.filter((elem) => {
        return _.flatten(disclosures).includes(elem.disclosureId);
      });
    }
  }

  onRowSelect(selectedApprovedRows) {
    this.selectedApprovedRecords = _.cloneDeep(selectedApprovedRows);
    if (
      this.selectedApprovedRecords.length ===
      this.approvedRecords.content.length
    ) {
      this.headerCheckBoxSelected = true;
    } else {
      this.headerCheckBoxSelected = false;
    }

    if (
      this.selectedApprovedRecords.length ===
      this.approvedRecords["content"].length
    ) {
      this.filterApplied = false;
      this.appliedFilters.forEach((filter) => {
        if (
          filter.values.length &&
          // filter.filterName !== "ENTITY_ID" &&
          filter.values[0]
        ) {
          this.filterApplied = true;
        }
      });
    }
  }

  append() {
    if (!this.selectedApprovedRecords.length) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please select at least one record to append.",
      });

      return false;
    }

    this.appendDialog = true;
  }

  selectAllRecords(event) {
    event.preventDefault();
    this.allRecords = true;
  }

  clearSelection(event) {
    event.preventDefault();
    this.selectedApprovedRecords = [];
    this.allRecords = false;
  }

  onHideAppendDialog(event) {
    this.appendDialog = false;
    event && this.getSearchData(this.appliedFilters);
    this.selectedApprovedRecords = [];
    this.allRecords = false;
  }

  showSearchResults() {}

  reviewRecord(event) {}

  exportApprovedRecords() {
    this.createImportService
      .export(this.appliedFilters)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          if (res.uuid) {
            window.open(
              `${environment.apiUrl}/inventory-service/approved/records/download?reportId=${res.uuid}`
            );
          }
        },
        (err) => {
          let errorMessage = err.error.message.replace(
            "com.markit.cmtk.exception.CMTKException:",
            ""
          );
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: errorMessage,
          });
        }
      );
  }

  exportCoa(event, rowData) {
    if (event.key && event.key !== "Enter") {
      return;
    }
    window.open(
      `${environment.apiUrl}/doc-upload-service/documentfiles/${
        rowData.documentId
      }?channel=APPROVED_RECORD&coaRef=${encodeURIComponent(
        rowData.coaReference
      )}`
    );
  }

  editApproved(row) {
    /* if (event.key && event.key !== "Enter") {
      return;
    } */
    if (row.status !== "DUPLICATE_APPROVED") {
      this.createImportService
        .getApprovedRecordEdit(row.documentId, row.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          this.reviewData = res;
          this.reviewRecordDialog = true;
          this.reviewStatus = row.status;
        });
    }
  }

  hideReviewRecord() {
    this.reviewRecordDialog = false;
    this.getSearchData(this.appliedFilters);
  }

  onReviewSave(event) {
    this.hideReviewRecord();
    this.coreService.showMessage({
      key: "tc",
      severity: "success",
      summary: "Success",
      detail: "Record saved successfully.",
    });
  }

  onEditComplete(id) {
    if (!this.user.platformAdmin && !this.user.entityAdmin) {
      this.userAdminService
        .updateCompleted(id)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => console.log(res));
    }
  }

  viewAddionalCoaDocs(event, rowData) {
    /* if (event.key && event.key !== "Enter") {
      return;
    }
    event.preventDefault();
    event.stopPropagation(); */
    this.viewAdditionalDocsDialog = true;
    this.coaReference = rowData.coaReference;
    this.additionalDocumentId = rowData.documentId;
  }

  hideAdditionalDocsDialog() {
    this.viewAdditionalDocsDialog = false;
  }

  viewValueAddThemes(event, row) {
    if (event.key && event.key !== "Enter") {
      return;
    }
    this.valueAddThemeDialog = true;
    this.appendId = row.id;
  }

  onValueAddThemeDialogHide() {
    this.valueAddThemeDialog = false;
  }

  triggerActionController(event, rowData) {
    this.resetSelection(rowData.id);
    switch (event) {
      case "Export Original COA":
        this.exportCoa(event, rowData);
        break;
      case "Edit/Review Record":
        this.editApproved(rowData);
        break;
      case "View Additional Documents":
        this.viewAddionalCoaDocs(event, rowData);
        break;
      default:
        break;
    }
  }

  resetSelection(id) {
    setTimeout(() => {
      this.actionModel[id] = null;
      (document.activeElement as HTMLElement).blur();
    }, 0);
  }

  deleteRecordsConfirm() {
    if (!this.selectedApprovedRecords.length) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please select at least one record to delete.",
      });

      return false;
    }

    this.confirmationService.confirm({
      message: `
      Are you sure you want to delete the selected record(s)?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteRecords();
      },
    });
  }

  deleteRecords() {
    let approvedRecord = this.allRecords
      ? []
      : this.selectedApprovedRecords.map((approved) => approved.id);
    let payload = {
      approvedRecord,
      filters: this.appliedFilters,
      allRecords: this.allRecords ? this.allRecords : false,
    };

    this.createImportService
      .deleteApprovedRecords(payload)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          if (res) {
            this.coreService.showMessage({
              key: "tc",
              severity: "success",
              summary: "Success",
              detail: "Record(s) deleted successfully.",
            });
            this.getSearchData(this.appliedFilters);
          }
        },
        (err) => {
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: err.error.message,
          });
        }
      );
  }

  downloadReport() {
    if (this.totalElements > DOWNLOAD_LIMIT_COUNT) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail:
          "Please filter records further to download report, 500,000 records can be downloaded at once",
      });
      return;
    }

    let filters = _.cloneDeep(this.appliedFilters);

    let lmePassportIdIndex = this.appliedFilters.findIndex(
      (filter) => filter.filterName === "LME_PASSPORT_ID"
    );
    if (
      lmePassportIdIndex !== -1 &&
      this.appliedFilters[lmePassportIdIndex]["values"].length >
        STORE_LME_PASSPORT_LIMIT
    ) {
      filters.push({
        filterName: "STORED_PASSPORT_ID",
        values: [],
      });
      filters = filters.filter(
        (filterElement) => filterElement.filterName !== "LME_PASSPORT_ID"
      );
    }

    let coaReferenceIndex = this.appliedFilters.findIndex(
      (filter) => filter.filterName === "COA_REFERENCE"
    );
    if (
      coaReferenceIndex !== -1 &&
      this.appliedFilters[coaReferenceIndex]["values"].length > STORE_COA_LIMIT
    ) {
      filters.push({
        filterName: "STORED_COA_REFERENCE",
        values: [],
      });
      filters = filters.filter(
        (filterElement) => filterElement.filterName !== "COA_REFERENCE"
      );
    }

    this.createImportService
      .generateReport(filters, "APPROVED_RECORDS")
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        if (res.processing) {
          this.coreService.showMessage({
            key: "tc",
            severity: "info",
            detail:
              "LMEpassport is processing a previous report request, this may take several minutes. You will be notified once the report is ready to be saved to your local drive. Please do not click ‘Download Report’ again.",
          });

          return;
        }
        if (!res.sync) {
          this.coreService.showMessage({
            key: "tc",
            severity: "info",
            detail:
              "The requested report contains a large amount of data and will take several minutes to prepare. You will be notified once the report is ready to be saved to your local drive. Please do not click ‘Download Report’ again.",
          });
        } else {
          window.open(
            `${environment.apiUrl}/report-service/reports/download/${res.id}`
          );
        }
      });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
