<div>
  <footer class="footer">
    <div class="footerContent">
      <div class="row">
        <div class="col-lg-12 mb-3">
          <nav class="
              navbar
              navbar-toggleable-md
              navbar-main
              navbar-expand-md
              navbar-dark
            ">
            <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
              data-target="#footerbarNavAltMarkup" aria-controls="footerbarNavAltMarkup" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="footerbarNavAltMarkup">
              <ul class="navbar-nav mr-auto">
                <li class="nav-item" *ngFor="let menuItem of menuItems">
                  <a id="{{ menuItem.id }}" class="nav-link" wcag-dummy-link-target
                    (click)="navigateFooter($event, menuItem.name)" href="#">{{ menuItem.name }}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="row mb-3">
        <div class="legal col-lg-10">
          <article>
            &copy; {{ currentDate | date: "yyyy" }} The London Metal Exchange -
            an HKEX Company, and its licensor, IHS Markit. All rights reserved.
          </article>
        </div>
      </div>
      <div class="ihs-logo">
        <img alt="IHS Markit Logo" class="logo" src="assets/img/sp-ihs-logo.png" />
      </div>
    </div>
  </footer>
</div>