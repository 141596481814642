<div *ngIf="categoryType === 'private'">
  <app-header *ngIf="user" [user]="user"></app-header>
  <div class="page-content" id="main-content" tabindex="-1">
    <router-outlet *ngIf="user"></router-outlet>
  </div>
  <app-footer *ngIf="user"></app-footer>
</div>

<div class="public-wrapper" *ngIf="categoryType === 'public'">
  <app-header-login [user]="user" [menuItems]="publicMenuItems"></app-header-login>
  <div *ngIf="user" class="container-fluid">
    <div class="mt-3 account-title">
      <a href="#" class="arrow-back" wcag-dummy-link-target role="button" aria-label="Back"
        (click)="returnPreviousLocation($event)" (keypress)="returnPreviousLocation($event)">
        <i class="fa fa-chevron-left float-left back-icon"></i>
        <span style="display: none">back</span>
      </a>
      <h2 class="float-left">
        Dashboard
      </h2>
      <div class="text-right">
        <div class="d-inline-block"></div>
      </div>
    </div>
  </div>
  <div *ngIf="publicMenuItems.length" class="page-content" id="main-content" tabindex="-1">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>

<p-toast position="top-center" key="tc"></p-toast>
<p-dialog header="Ending Session" [modal]="true" [(visible)]="showTimedOutDialog" [closeOnEscape]="false"
  [closable]="false" [style]="{ width: '40vw' }">
  <ng-container *ngIf="!sessionExpired; else SESSION_EXPIRED">
    <span>Your session is about to expire in
      <strong>{{ counter$ | async }} seconds</strong>.<br />
      Click continue to remain in the application or you can press logout to
      logout</span>
  </ng-container>

  <ng-template #SESSION_EXPIRED> Your session has expired. </ng-template>
  <p-footer>
    <button class="btn btn-primary" *ngIf="!sessionExpired" (click)="resetSession()">
      Continue
    </button>
    <button class="btn btn-danger" (click)="logOut()">Logout</button>
  </p-footer>
</p-dialog>

<app-terms-and-conditions *ngIf="showTandCDialog" [showTandCDialog]="showTandCDialog" (hideTCDialog)="hideTCDialog()">
</app-terms-and-conditions>

<p-dialog [header]="notifHeader" [modal]="true" [(visible)]="notificationDialog" [style]="{ width: '40vw' }">
  <ng-container *ngIf="!bothTypeMessage;else BOTH_MESSAGE">
    <p [ngClass]="{
      success: notifType === 'SUCCESS',
      error: notifType === 'ERROR'
    }" [innerHtml]="notifMessage">
    </p>
  </ng-container>
  <ng-template #BOTH_MESSAGE>
    <p class="success">
      {{ notifMessage[0] }}
    </p>
    <p class="error">
      {{ notifMessage[1] }}
    </p>
  </ng-template>
  <p-footer>
    <button class="btn btn-primary" (click)="notificationDialog = false">
      Ok
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Notification from Platform Admin" [modal]="true" [(visible)]="infoDialog" [style]="{ width: '40vw' }"
  [closeOnEscape]="false" [closable]="false">
  <p>
    {{ notifMessage }}
  </p>
  <p-footer>
    <button class="btn btn-primary" (click)="acknowledge()">Ok</button>
  </p-footer>
</p-dialog>

<p-dialog header="Notification(s) from Platform Admin" [modal]="true" [closeOnEscape]="false" [closable]="false"
  [(visible)]="bulkNotificationsDialog" *ngIf="bulkNotificationsDialog" [style]="{ width: '40vw' }">
  <app-table-simple [config]="tableConfig" [tableData]="messages"></app-table-simple>
  <p-footer>
    <button class="btn btn-primary" (click)="acknowledge()">Ok</button>
  </p-footer>
</p-dialog>

<p-dialog [header]="notifHeader" [modal]="true" [(visible)]="suspiciousNnotificationDialog" [style]="{ width: '40vw' }"
  *ngIf="suspiciousNnotificationDialog">
  <p class="mt-2 mb-2">
    The following PCR number(s) have remained pending in the To-Do List for more
    than {{ suspiciousActivityForTodo.days }} day(s).
  </p>

  <app-table-simple [config]="suspicioustableConfig"
    [tableData]="suspiciousActivityForTodo.suspiciousCompanies"></app-table-simple>
  <p-footer>
    <button class="btn btn-primary" (click)="suspiciousNnotificationDialog = false">
      Ok
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Permissions Updated" [modal]="true" [(visible)]="refreshWindowDialog" [closeOnEscape]="false"
  [closable]="false" [style]="{ width: '40vw' }" [baseZIndex]="10000">
  <p>Your permissions have been updated, kindly refresh</p>

  <p-footer>
    <button class="btn btn-primary" (click)="refreshPage()">Refresh</button>
  </p-footer>
</p-dialog>

<p-dialog header="Report Available" [modal]="true" [(visible)]="reportDownloadDialog" [closeOnEscape]="false"
  [closable]="false" [style]="{ width: '40vw' }">
  <p>
    Your requested report is now available to download. Please click on the "Download" button to download it.
  </p>

  <p-footer>
    <button class="btn btn-primary" (click)="downloadReport()">Download</button>
  </p-footer>
</p-dialog>

<p-dialog header="Account suspended" [modal]="true" [(visible)]="accountSuspendedDialog" [closeOnEscape]="false"
  [closable]="false" [style]="{ width: '40vw' }">
  <p>Your account has been suspended, kindly contact <a [attr.href]="supportEmail" class="contact-us">platform admin</a>
    to restore your account</p>
  <p-footer>
    <button class="btn btn-danger" (click)="logOut()">Logout</button>
  </p-footer>
</p-dialog>