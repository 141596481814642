<p-dialog
  header="Preview Doc"
  [(visible)]="previewOldNewDoc"
  [focusOnShow]="false"
  showEffect="fade"
  [modal]="true"
  [style]="{ width: '55%' }"
  (onHide)="hideEditPreview()"
>
  <div class="row">
    <div class="col-md-3"><p>Select the document type to review</p></div>
    <div class="col-md-9">
      <ng-select
        [items]="docTypes"
        bindLabel="name"
        bindValue="id"
        [(ngModel)]="docType"
        (change)="onDocTypeChange()"
      ></ng-select>
    </div>
  </div>
  <p *ngIf="errorMessage" class="error-message">{{ message }}</p>
  <div clas="viewer mb-3">
    <pdf-viewer
      [src]="documentUrl"
      [render-text]="true"
      style="display: block"
    ></pdf-viewer>
  </div>
  <p-footer>
    <ng-container *ngIf="!errorMessage; else COA_DATE_BUTTONS">
      <button
        class="btn btn-primary"
        (click)="docMatches()"
        pTooltip="Add records to this COA Reference"
        tooltipPosition="bottom"
      >
        Yes it matches
      </button>
      <button
        class="btn btn-primary"
        (click)="selectNewCoaRef()"
        pTooltip="I need to select a new COA Reference"
        tooltipPosition="bottom"
      >
        It's a different document
      </button>
    </ng-container>
    <ng-template #COA_DATE_BUTTONS>
      <button
        class="btn btn-primary"
        (click)="docDateMatches()"
        pTooltip="To match the registered COA date and Approve"
        tooltipPosition="bottom"
      >
        Update my records
      </button>
      <button
        class="btn btn-primary"
        (click)="selectNewCoaRef()"
        pTooltip="I need to register these records under a new COA Reference"
        tooltipPosition="bottom"
      >
        The dates don't match
      </button>
    </ng-template>
    <button class="btn btn-secondary" (click)="hideEditPreview()">
      Cancel
    </button>
  </p-footer>
</p-dialog>
