import {
  ITableViewConfig,
  ROWS_PER_PAGE,
} from "@shared/models/table-view.model";

export const myRecordsListingTableConfig: ITableViewConfig = {
  rowsPerPage: ROWS_PER_PAGE,
  columns: [
    {
      field: "type",
      header: "Record Type",
      width: "130px",
    },
    {
      field: "passportId",
      header: "LME Passport Id",
      width: "90px",
    },
    {
      field: "stakeholderType",
      header: "Stakeholder Type",
      width: "90px",
    },
    {
      field: "producerName",
      header: "Producer",
      width: "120px",
    },
    {
      field: "smelter",
      header: "Smelter",
      width: "120px",
    },
    {
      field: "coaReference",
      header: "Certificate of Analysis Reference No.",
      width: "120px",
    },
    {
      field: "coaDate",
      header: "Certificate of Analysis Date",
      width: "120px",
    },
    {
      field: "metal",
      header: "Metal",
      width: "120px",
    },
    {
      field: "brand",
      header: "Brand",
      width: "120px",
    },
    {
      field: "packingForm",
      header: "Packing Form",
      width: "120px",
    },
    {
      field: "productionDate",
      header: "Date of Production",
      width: "120px",
    },
    {
      field: "productionCountry",
      header: "Country of Production",
      width: "120px",
    },
    {
      field: "shape",
      header: "Product Shape",
      width: "120px",
    },
    {
      field: "quantity",
      header: "Quantity(pieces)",
      width: "120px",
    },
    {
      field: "grossWeight",
      header: "Total Gross Weight (MT)",
      width: "120px",
    },
    {
      field: "netWeight",
      header: "Total Net Weight (MT)",
      width: "120px",
    },
    {
      field: "industryStd",
      header: "Industry Standards Met",
      width: "120px",
    },
    {
      field: "permissibleGrade",
      header: "Permissible Grade",
      width: "120px",
    },
    {
      field: "scrCompliant",
      header: "SCR Compliant",
      width: "120px",
    },
  ],
};

export const searchRecordsListingTableConfig: ITableViewConfig = {
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  columns: [
    {
      field: "recordType",
      header: "Record Type",
      width: "130px",
      sortField: "recordType",
    },
    {
      field: "passportId",
      header: "LME Passport Id",
      width: "90px",
    },
    {
      field: "stakeHolder",
      header: "Stakeholder Type",
      width: "120px",
      sortField: "stakeHolder",
    },
    {
      field: "producerName",
      header: "Producer",
      width: "120px",
    },
    {
      field: "smelter",
      header: "Smelter",
      width: "120px",
    },
    {
      field: "coaReference",
      header: "Certificate of Analysis Reference No.",
      width: "120px",
    },
    {
      field: "coaDate",
      header: "Certificate of Analysis Date",
      width: "120px",
      dateTime: true,
    },
    {
      field: "commodity",
      header: "Metal",
      width: "120px",
    },
    {
      field: "brand",
      header: "Brand",
      width: "120px",
    },
  ],
};

export const valueAddThemesListingTableConfig: ITableViewConfig = {
  rowsPerPage: ROWS_PER_PAGE,
  columns: [
    {
      field: "themeSubTheme",
      header: "Theme",
      width: "200px",
    },
    {
      field: "disclosureName",
      header: "Disclosure",
      width: "140px",
    },
    {
      field: "themeType",
      header: "Type",
      width: "120px",
    },
    {
      field: "value",
      header: "Value",
      width: "120px",
    },
  ],
};

export const appendedThemesListingTableConfig: ITableViewConfig = {
  rowsPerPage: ROWS_PER_PAGE,
  paginator: true,
  columns: [
    {
      field: "themeSubTheme",
      header: "Theme & Sub-Theme(s)",
      width: "200px",
      sortField: "themeSubTheme",
    },
    {
      field: "disclosureName",
      header: "Disclosure",
      width: "140px",
      sortField: "disclosureName",
    },
    {
      field: "metals",
      header: "Metal",
      width: "200px",
    },
    {
      field: "inheritance",
      header: "Inheritance",
      width: "140px",
    },
    {
      field: "level",
      header: "Level",
      width: "140px",
      sortField: "level",
    },
    {
      field: "themeType",
      header: "Type",
      width: "120px",
      sortField: "themeType",
    },
    {
      field: "value",
      header: "User Input",
      width: "120px",
    },
    {
      field: "metricLabel",
      header: "Metric Label / unit",
      width: "120px",
    },
    {
      field: "validity",
      header: "Validity",
      width: "240px",
    },
    {
      field: "createdBy",
      header: "Appended By",
      width: "250px",
    },
    {
      field: "createdOn",
      header: "Appended Date",
      width: "200px",
      dateTimeStamp: true,
    },
  ],
};

export const recordsListingTableConfig: ITableViewConfig = {
  rowsPerPage: 20,
  paginator: true,
  columns: [
    {
      field: "passportId",
      header: "Passport Id",
      width: "200px",
      sortField: "passportId",
    },
  ],
};
