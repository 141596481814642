<ng-container *ngIf="!virtualScroll; else VIRTUAL_SCROLL">
  <p-table [columns]="config.columns" [value]="tableData" [scrollable]="true" scrollHeight="calc(100vh - 250px)"
    [style]="{ width: '100%' }" [ngClass]="{'lme': true,  'chemical-comp-table': from === 'chemicalComposition'}"
    [paginator]="true" [rows]="rows" [(first)]="first" [(selection)]="selections"
    [rowsPerPageOptions]="[10, 20, 50, 100]" paginatorDropdownAppendTo="body" [responsive]="true" #dt
    inputId="selectAll" [resetPageOnSort]="false">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [ngStyle]="{'min-width': from === 'producerProfileSustainability' || from === 'activeSuspended' || from === 'managePermissions' || from === 'pending' || from === 'terminated' ? null : col.width, 'width': from === 'producerProfileSustainability' || from === 'activeSuspended' || from === 'managePermissions' || from === 'pending' || from === 'terminated' ? col.width : null}"
          [pSortableColumn]="col.sortField" *ngFor="let col of columns">
          <ng-container *ngIf="
              col.checkboxSelect && !config.headerCheckboxDisabled;
              else HEADER_FIELD
            ">
            <p-tableHeaderCheckbox wcag-label (click)="selected()"></p-tableHeaderCheckbox>
          </ng-container>
          <ng-template #HEADER_FIELD>
            <span [attr.cy-test]="col.header" id="{{col.header}}">{{ col.header }}</span>
            <p-sortIcon id="{{ col.field }}" [field]="col.field" *ngIf="col.sortField"></p-sortIcon>
          </ng-template>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
      <tr [attr.id]="rowData?.id" [pSelectableRowDisabled]="rowData.entityStatus === 'SUSPENDED'"
        [pSelectableRow]="rowData" [ngClass]="{ 'disabled-row': rowData.status === 'DUPLICATE_REVIEW' }">
        <td [attr.cy-test]="col.field + '_cell'" *ngFor="let col of columns">
          <ng-container *ngIf="col.checkboxSelect; else ROW_FIELD">
            <p-tableCheckbox [attr.id]="'checkbox_' + rowIndex" *ngIf="rowData.status !== 'DUPLICATE_REVIEW'"
              [value]="rowData" (click)="selected(rowData)" [disabled]="
                rowData.entityStatus === 'SUSPENDED' ||
                (rowData.status === 'APPROVED' && from !== 'searchMyRecords') ||
                ((rowData.status === 'REVIEW1' ||
                  rowData.status === 'REVIEW2') &&
                  from === 'searchResults')
              " wcag-label></p-tableCheckbox>
          </ng-container>
          <ng-template #ROW_FIELD>
            <ng-container *ngIf="!col.templateRef; else CUSTOM_CONTENT">
              <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">
                <ng-container *ngIf="col.field !== 'status'; else STATUS">
                  <ng-container *ngIf="!col.dateTimeStamp; else DATE_TIMESTAMP">
                    <ng-container *ngIf="!col.numeric; else NUMERIC">
                      {{ rowData[col.field] }}
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>
          <ng-template #STATUS>
            <span *ngIf="rowData.status !== 'DUPLICATE_REVIEW'">
              {{ rowData[col.field] }}
            </span>
          </ng-template>
          <ng-template #DATETIME_CONTENT>
            <ng-container *ngIf="rowData[col.field];else NA">{{ rowData[col.field] | date: "dd MMMM yyyy"
              }}</ng-container>
            <ng-template #NA>N/A</ng-template>
          </ng-template>
          <ng-template #DATE_TIMESTAMP>
            {{ rowData[col.field] | date: "dd MMMM, yyyy h:mm:ss a" }}
          </ng-template>
          <ng-template #CUSTOM_CONTENT>
            <ng-container *ngTemplateOutlet="
                col.templateRef;
                context: { $implicit: rowData, idx: rowIndex }
              "></ng-container>
          </ng-template>
          <ng-template #NUMERIC>
            {{ rowData[col.field] | customFormat }}
          </ng-template>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">{{noRecordMessage || 'No records found' }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorright">
      Total Records:
      {{ tableData ? tableData?.length : 0 | number: "1.0":"en-US" }}
    </ng-template>
  </p-table>
</ng-container>

<ng-template #VIRTUAL_SCROLL>
  <p-table [columns]="config.columns" [value]="tableData" [scrollable]="true" [rows]="10" [(selection)]="selections"
    [virtualScroll]="true" [virtualScrollItemSize]="46" scrollHeight="calc(100vh - 250px)" [style]="{ width: '100%' }"
    [ngClass]="'lme'" [resetPageOnSort]="false">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th [ngStyle]="{'min-width': col.width, 'max-width': col.width}" *ngFor="let col of columns">
          <ng-container *ngIf="
              col.checkboxSelect && !config.headerCheckboxDisabled;
              else HEADER_FIELD
            ">
            <p-checkbox id="selectAll" [(ngModel)]="headercheckBox" [binary]="true"
              (onChange)="selectAllVirtualScrollTable()"></p-checkbox>
          </ng-container>
          <ng-template #HEADER_FIELD>
            <span [attr.cy-test]="col.header" id="{{col.header}}">{{ col.header }}</span>
            <p-sortIcon id="{{ col.field }}" [field]="col.field" *ngIf="col.sortField"></p-sortIcon>
          </ng-template>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
      <tr [attr.id]="rowData?.id" style="height: 40px">
        <td [attr.cy-test]="col.field + '_cell'" *ngFor="let col of columns">
          <ng-container *ngIf="col.checkboxSelect; else ROW_FIELD">
            <p-tableCheckbox *ngIf="rowData.status !== 'DUPLICATE_REVIEW'" [value]="rowData" (click)="selected(rowData)"
              [disabled]="
                rowData.entityStatus === 'SUSPENDED' ||
                (rowData.status === 'APPROVED' && from !== 'searchMyRecords') ||
                ((rowData.status === 'REVIEW1' ||
                  rowData.status === 'REVIEW2') &&
                  from === 'searchResults')
              " wcag-label></p-tableCheckbox>
          </ng-container>
          <ng-template #ROW_FIELD>
            <ng-container *ngIf="!col.templateRef; else CUSTOM_CONTENT">
              {{ rowData[col.field] }}
            </ng-container>
          </ng-template>
          <ng-template #CUSTOM_CONTENT>
            <ng-container *ngTemplateOutlet="
                col.templateRef;
                context: { $implicit: rowData, idx: rowIndex }
              "></ng-container>
          </ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>