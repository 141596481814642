import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IUser, IUsers } from "@core/models/api.model";
import { CoreService } from "@core/services/core.service";
import { MenuItem } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-header-login",
  templateUrl: "./header-login.component.html",
  styleUrls: ["./header-login.component.scss"],
})
export class HeaderLoginComponent implements OnInit {
  @Input() menuItems?: any;
  @Input() user: IUser;

  private _destroy$ = new Subject<any>();
  lastLogin: any;
  fullName: string;
  mobileName: string;
  items: MenuItem[];

  constructor(
    private coreService: CoreService,
    @Inject(DOCUMENT) private document: any,
    private router: Router
  ) {}

  ngOnInit() {
    this.userLoaded();
  }

  userLoaded() {
    this.coreService
      .getLastLogin()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.lastLogin = res.hasOwnProperty("lastLogin") ? res.lastLogin : null;
        this.coreService.idleTime = parseInt(res?.logoutTime);
        if(this.coreService.idleTime){
          this.coreService.setTimer();
        }
        if (this.user) {
          this.fullName = `${this.user.firstName} ${this.user.lastName}`;
          this.mobileName = `${this.user.firstName
            .substring(0, 1)
            .toUpperCase()} ${this.user.lastName
            .substring(0, 1)
            .toUpperCase()}`;
        }
        let splitUrl = this.router.url.split("/");
        this.items = [
          {
            label: "Logout",
            icon: "pi pi-sign-out",
            command: () => {
              this.logout();
            },
          },
        ];
      });
  }

  logout() {
    window.location.href = `https://${window.location.hostname}/redirect_uri?logout=https://${window.location.hostname}/`;
  }

  login(event) {
    event.preventDefault();
    const d = new Date();
    // this.document.location.href = "/index.html?" + d.getTime();
    window.location.href = "/login.html?" + d.getTime();
  }

  skipToMain(event) {
    if (event && event.key === "Enter") {
      event && event.preventDefault();
      document.getElementById("main-content").focus();
    }
  }

  getSelectedPath(routePath){
    this.coreService.publicRoutePath.next(routePath);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
    this.coreService.countDown.next(null);
  }
}
