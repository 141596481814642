import { Component } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "app-formly-fieldset",
  template: `
    <p-fieldset [legend]="to.label">
      <ng-container #fieldComponent></ng-container>
    </p-fieldset>
  `,
})
export class FormlyFieldsetComponent extends FieldWrapper {}
