<div class="disclosure-container">
    <p style="font-size: 1rem" class="mt-3 mb-3">This page enables users to directly compare metrics that the LME have
        available on LMEpassport
        for each metal group, for differing brands, producers or assets. At present, the LME have a number of emission
        methodologies available to compare, with the aim of building this out to a number of other metrics in the
        future.
        <span class="click-metal">Please click on a metal and then select the metric that you would like to
            compare.</span>
    </p>
    <h4 class="headings">Metals</h4>
    <div class="cards_wrap">
        <div *ngFor="let metal of metals" [ngClass]="{'selected': selectedMetal === metal}" class="card_item"
            (click)="selectMetal(metal)">
            {{metal}}
        </div>
    </div>
    <ng-container *ngIf="selectedMetal">
        <ng-container *ngIf="disclosureList?.length > 0; else NO_METRIC">
            <div class="row mt-3 mb-5">
                <div class="col-md-12">
                    <h4 class="headings mt-3 mb-2">Metrics</h4>
                    <ng-select [(ngModel)]="disclosure" (change)="disclosureChange($event)" placeholder="Select Metric"
                        [items]="disclosureList" bindLabel="disclosureId.name" bindValue="disclosureId.id">
                    </ng-select>
                </div>
            </div>
            <ng-container *ngIf="selectedMetal && selectedDisclosure?.metricLevel; else NO_GRAPH_DATA">
                <h4 class="mb-5 mt-3 headings">Graphical Representation</h4>
                <div class="row mt-3 mb-5">
                    <div class="col-md-12">
                        <p-chart [responsive]="true" type="bar" [data]="chartData" [options]="projectBarDataOptions">
                        </p-chart>
                    </div>
                </div>
                <div class="row  mt-3 mb-5">
                    <div class="col-md-12" style="text-align: right;">
                        <div class="row">
                            <div class="col showing-count">
                                <span *ngIf="dataToFilter.length > pagingState.size">Showing
                                    {{pagingState.start + 1}} -
                                    {{dataToFilter.length > pagingState.size + pagingState.start ? pagingState.size +
                                    pagingState.start : dataToFilter.length}} of {{dataToFilter.length}}</span>
                            </div>
                            <div class="col paginator">
                                <p-paginator [first]="pagingState.start" pageLinkSize="2" styleClass="bar-paginator"
                                    [rows]="pagingState.size" [alwaysShow]="false"
                                    (onPageChange)="paginateBarChart($event)" [totalRecords]="dataToFilter.length">
                                </p-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #NO_METRIC>
            <p class="mt-5">There are currently no metrics available for comparison this particular metal. If you would
                like to see a
                particular metric, please contact <a [attr.href]="sustainabilityEmail"
                    class="contact-us">{{sustainabilityEmailWithoutMailTo}}</a></p>
        </ng-template>
        <ng-template #NO_GRAPH_DATA>
            <p class="mt-5">
                There is currently no data available for this particular metric
            </p>
        </ng-template>
    </ng-container>
</div>