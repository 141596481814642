<p-dialog
  header="Additional Documents"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [(visible)]="viewAdditionalDocsDialog"
  (onHide)="onAdditionalDocsDialogHide($event)"
>
  <app-table-simple
    [config]="additonalDocsConfig"
    [tableData]="additionalDocsData"
  >
  </app-table-simple>

  <p-footer>
    <button class="btn btn-primary" (click)="onAdditionalDocsDialogHide(true)">
      Ok
    </button>
  </p-footer>
</p-dialog>

<ng-template #additionalDocsActionTemplate let-rowData>
  <i
    (click)="downloadAdditionalDoc(rowData)"
    class="fa fa-download"
    aria-hidden="true"
    title="Download"
    style="cursor: pointer"
  ></i>
</ng-template>
