import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IChemicalComposition } from "@core/models/api.model";
import { chemicalCompositionDataTableConfig } from "@module/create-import/create-import-config";
import { ITableViewConfig } from "@shared/models/table-view.model";

@Component({
  selector: "app-chemical-composition",
  templateUrl: "./chemical-composition.component.html",
  styleUrls: ["./chemical-composition.component.scss"],
})
export class ChemicalCompositionComponent implements OnInit {
  @Input() chemicalComposition: any;
  @Input() reviewChemicalCompDialog: boolean;
  @Input() castReference: string;

  @Output() onChemicalCompHide = new EventEmitter<any>();

  tableConfig: ITableViewConfig;
  tableData: IChemicalComposition[];

  constructor() {}

  ngOnInit() {
    const { columns, ...config } = chemicalCompositionDataTableConfig;
    const _columns = [...chemicalCompositionDataTableConfig.columns];

    this.tableConfig = {
      ...config,
      ...{ columns: _columns },
    };

    this.tableData = [];

    for (let key in this.chemicalComposition) {
      switch (key.toLowerCase()) {
        case "others_total":
          this.chemicalComposition["Others Total"] =
            this.chemicalComposition["others_total"];
          delete this.chemicalComposition["others_total"];
          key = "Others Total";
          break;
        case "others_each":
          this.chemicalComposition["Others Each"] =
            this.chemicalComposition["others_each"];
          delete this.chemicalComposition["others_each"];
          key = "Others Each";
          break;
        case "total_all_impurities":
          this.chemicalComposition["Total All Impurities"] =
            this.chemicalComposition["total_all_impurities"];
          delete this.chemicalComposition["total_all_impurities"];
          key = "Total All Impurities";
          break;
        default:
          break;
      }
      this.tableData.push({
        element: key,
        composition: this.chemicalComposition[key],
      });
    }

    this.tableData = this.tableData.filter((data) => data.composition);
  }

  hideChemicalComp(event) {
    this.onChemicalCompHide.emit();
  }

  returnZero() {
    return 0;
  }
}
