import { DecimalPipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { LookupService } from "@core/services/lookup.service";
import { SustainabilityDisclosurePublicService } from "@module/sustainability-disclosure-public-view/services/sustainability-disclosure-public.service";
import { sustainabilityEmail } from "app/app.const";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-disclosure-comparison",
  templateUrl: "./disclosure-comparison.component.html",
  styleUrls: ["./disclosure-comparison.component.scss"],
})
export class DisclosureComparisonComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<any>();
  metals: any;
  selectedMetal: string;
  chartData: any;
  projectBarDataOptions: any;
  disclosureData: any;
  filteredDisclosures: any;
  selectedDisclosure: any;
  disclosure: any;
  pagingState = {
    start: 0,
    size: 7,
  };
  dataToFilter: any;
  dataToDisplay: { labelData: any[]; values: any[]; backgroundColors: any[] };
  sustainabilityEmail: string;
  sustainabilityEmailWithoutMailTo: string;
  disclosureList: any;

  constructor(
    private lookupService: LookupService,
    private sustainabilityDisclosureLiveService: SustainabilityDisclosurePublicService,
    private _decimalPipe: DecimalPipe,
    private titleService: Title
  ) {
    titleService.setTitle("Portal - LMEpassport - Metric Comparison");
  }

  ngOnInit(): void {
    this.sustainabilityEmail = `mailto: ${sustainabilityEmail}`;
    this.sustainabilityEmailWithoutMailTo = sustainabilityEmail;
    this.lookupService
      .getMetalsLivesustainability()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.metals = res;
        this.metals.sort();
        this.sustainabilityDisclosureLiveService
          .getDisclosureData()
          .pipe(takeUntil(this._destroy$))
          .subscribe((res) => {
            this.disclosureData = res;
            this.selectMetal(this.metals[0]);
          });
      });
  }

  selectMetal(metal) {
    this.selectedDisclosure = null;
    this.disclosure = null;
    this.selectedMetal = metal;
    this.filteredDisclosures = this.disclosureData.filter(
      (row) =>
        row.id &&
        row.disclosureId.type.toLowerCase() === "metric" &&
        row.metals.includes(metal) &&
        row.disclosureId.status === "ACTIVE"
    );

    let nonAppendedData = this.disclosureData.filter((row) => !row.id);

    this.filteredDisclosures = [
      ...this.filteredDisclosures,
      ...nonAppendedData,
    ];

    this.disclosureList = _.uniqBy(this.filteredDisclosures, "disclosureId.id");

    this.disclosureChange(this.disclosureList[0]);
  }

  disclosureChange({ id, disclosureId }) {
    this.pagingState.start = 0;
    this.disclosure = disclosureId.id;
    this.selectedDisclosure = disclosureId;
    if (id) {
      this.generateBarChart();
    }
  }

  generateBarChart() {
    let colors = [
      "#FFADAD",
      "#FFD6A5",
      "#FDFFB6",
      "#CAFFBF",
      "#9BF6FF",
      "#AOC4FF",
      "#BDB2FF",
      "#FFC6FF",
      "#C1EFFF",
      "#F7DAD9",
    ];
    let labelData = [],
      values = [],
      backgroundColors = [];
    let entityMap = {};
    this.dataToFilter = _.cloneDeep(this.filteredDisclosures);
    this.dataToFilter = this.dataToFilter.filter(
      (row) => row.disclosureId.id === this.selectedDisclosure.id
    );

    this.dataToFilter.sort((a, b) => a.metricPercentage - b.metricPercentage);

    let counter = 0;
    this.dataToFilter.forEach((element) => {
      let brandName = element.brand ? element.brand : "";
      let asset = element.asset ? element.asset : "";
      if (brandName && asset) {
        labelData.push(`Brand: ${brandName}, Asset: ${asset}`);
      } else if (brandName && !asset) {
        labelData.push(`Brand: ${brandName}`);
      } else {
        labelData.push(`Producer: ${element.entity.entityName}`);
      }
      values.push(parseFloat(element.metricPercentage));
      if (entityMap.hasOwnProperty(element.entity.entityName)) {
        backgroundColors.push(entityMap[element.entity.entityName]);
      } else {
        let color = colors[counter];
        backgroundColors.push(color);
        entityMap[element.entity.entityName] = color;

        counter++;
      }
    });

    this.dataToDisplay = {
      labelData,
      values,
      backgroundColors,
    };

    this.sliceBarData();

    this.projectBarDataOptions = {
      plugins: {
        hover: {
          mode: null,
        },
        legend: {
          display: false,
          labels: {
            fontSize: 14,
            font: {
              color: "black",
              family: "Arial",
            },
          },
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: (tooltipItem) => {
              return "";
            },
            label: (tooltipItem, data) => {
              let index = tooltipItem["dataIndex"] + this.pagingState.start;

              let brandName = `Brand - ${this.dataToFilter[index].brand}`;
              let metric = `Metric - ${this._decimalPipe.transform(
                this.dataToFilter[index].metricPercentage,
                "1.2-2"
              )} ${this.dataToFilter[index].disclosureId["metricLevel"]}`;
              let producerName = `Producer - ${this.dataToFilter[index].entity.entityName}`;

              let asset;
              if (this.dataToFilter[index].asset) {
                asset = `Asset - ${this.dataToFilter[index].asset}`;
              }
              if (
                !this.dataToFilter[index].brand &&
                !this.dataToFilter[index].asset
              ) {
                return [producerName, metric];
              }

              if (
                this.dataToFilter[index].brand &&
                !this.dataToFilter[index].asset
              ) {
                return [producerName, metric, brandName];
              }
              return [producerName, metric, brandName, asset];
            },
          },
        },
      },
      scales: {
        y: {
          ticks: {
            fontSize: 15,
            beginAtZero: true,
            callback: (value) => {
              if (value % 1 === 0) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            },
          },
          title: {
            text: this.dataToFilter[0].disclosureId.metricLevel,
            font: {
              size: 20,
            },
            display: true,
          },
        },
        x: {
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            fontSize: 13,
            autoSkip: false,
            callback: (val, index) => {
              let element = this.dataToFilter[index + this.pagingState.start];
              let brandName = element.brand ? element.brand : "";
              let asset = element.asset ? element.asset : "";
              if (brandName && asset) {
                return [`Brand: ${brandName}`, `Asset: ${asset}`];
              } else if (brandName && !asset) {
                return `Brand: ${brandName}`;
              } else {
                return `Producer: ${element.entity.entityName}`;
              }
            },
          },
        },
      },
    };
  }

  sliceBarData() {
    let start = this.pagingState.start,
      size = this.pagingState.start + this.pagingState.size;

    this.chartData = {
      labels: this.dataToDisplay.labelData.slice(start, size),
      datasets: [
        {
          label: "",
          backgroundColor: this.dataToDisplay.backgroundColors.slice(
            start,
            size
          ),
          data: this.dataToDisplay.values.slice(start, size),
          barThickness: 80,
          minBarLength: 20,
        },
      ],
    };

    console.log(this.chartData);
  }

  paginateBarChart(event) {
    this.pagingState.start = event.first;
    this.pagingState.size = event.rows;
    this.sliceBarData();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
