import { DatePipe } from "@angular/common";
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { IPublicDisclosures } from "@core/models/api.model";
import { sustainabilityPublicViewListingFilters } from "@core/models/filter-types.model";
import { CoreService } from "@core/services/core.service";
import { environment } from "@env/environment";
import { SustainabilityDisclosurePublicService } from "@module/sustainability-disclosure-public-view/services/sustainability-disclosure-public.service";
import { liveDisclosuresListTableConfig } from "@module/sustainability-disclosure-public-view/sustainability-disclosure-public-view.const";
import { ITableViewConfig } from "@shared/models/table-view.model";
import { SharedService } from "@shared/services/shared.service";
import { DELIVERABLE_VALUES } from "app/app.const";
import * as _ from "lodash";
import { filter } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-live-sustainability-disclosures",
  templateUrl: "./live-sustainability-disclosures.component.html",
  styleUrls: ["./live-sustainability-disclosures.component.scss"],
})
export class LiveSustainabilityDisclosuresComponent
  implements OnInit, OnDestroy
{
  tableConfig: ITableViewConfig;
  sustainabilityFilters = sustainabilityPublicViewListingFilters;
  disclosures: IPublicDisclosures[];
  filterDisclosures: IPublicDisclosures[];

  private _destroy$ = new Subject<any>();

  @ViewChild("table", { static: true }) table: any;
  disclosuresData: any;
  @ViewChild("themeSubThemeTemplate", { static: true })
  public themeSubThemeTemplate: TemplateRef<any>;
  @ViewChild("proofPointTemplate", { static: true })
  proofPointTemplate: TemplateRef<any>;
  @ViewChild("companyTemplate", { static: true })
  public companyTemplate: TemplateRef<any>;
  @ViewChild("brandTemplate", { static: true })
  public brandTemplate: TemplateRef<any>;

  lastUpdated: any;

  constructor(
    private sustainabilityPublicDisclosureService: SustainabilityDisclosurePublicService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private coreService: CoreService,
    private titleService: Title,
    private router: Router
  ) {
    titleService.setTitle(
      "Portal - LMEpassport - Live Sustainability Disclosures"
    );
  }

  ngOnInit() {
    const { columns, ...config } = liveDisclosuresListTableConfig;
    const _columns = [...liveDisclosuresListTableConfig.columns];
    _columns.splice(0, 0, {
      header: "Company",
      width: "180px",
      templateRef: this.companyTemplate,
      field: "entityName",
      sortField: "entityName",
    });
    _columns.splice(3, 0, {
      header: "Brand",
      width: "140px",
      templateRef: this.brandTemplate,
      field: "brand",
      sortField: "brand",
    });
    _columns.splice(4, 0, {
      header: "Theme",
      width: "240px",
      templateRef: this.themeSubThemeTemplate,
      field: "themeSubTheme",
      sortField: "themeSubTheme",
    });
    _columns.splice(12, 0, {
      header: "Proof Point",
      width: "240px",
      templateRef: this.proofPointTemplate,
    });
    this.tableConfig = {
      ...config,
      ...{ columns: _columns },
    };

    this.initTable();
    this.getDisclosures();
    this.getLastUpdated();
  }

  initTable() {
    this.sustainabilityPublicDisclosureService
      .getPublicDisclosures()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        if (res) {
          res.forEach((row, i) => {
            row["asset"] = row["asset"] ? row["asset"] : "N/A";
            row["brand"] = row["brand"] ? row["brand"] : "N/A";
            row.entityName = row.entity ? row.entity.entityName : "N/A";
            row.disclosureName = row.disclosureId.name;
            row.themeSubTheme = row.themeId["subThemeName"]
              ? `${row.themeId["themeName"]} - ${row.themeId["subThemeName"]}`
              : row.themeId["themeName"];
            row["themeType"] = row.disclosureId.type;
            row["metricLabel"] = row.disclosureId.metricLevel
              ? row.disclosureId.metricLevel
              : "N/A";
            row["frameworkEmployed"] = row.disclosureId.fraeworkEmployed;
            row["proofPoints"] = row.disclosureId.proofPoint
              ? row.disclosureId.proofPoint
              : "N/A";
            row.metals = row.metals ? row.metals.join(", ") : row.metals;
            if (
              row["themeType"] === "Membership" ||
              row["themeType"] === "Certification"
            ) {
              row["value"] = "";
              row["metricPercentage"] = "N/A";
            } else {
              row["value"] = row["metricPercentage"];
            }

            row["validityDateFrom"] &&
              (row["validityDateFrom"] = new Date(row["validityDateFrom"]));

            row["validityDateTo"] &&
              (row["validityDateTo"] = new Date(row["validityDateTo"]));

            if (row["level"] === "Brand") {
              row["brandStatusDb"] = row["brandStatus"];
              let brandStatusId = DELIVERABLE_VALUES.findIndex(
                (brandStatus) => brandStatus.id === row["brandStatus"]
              );
              row["brandStatus"] =
                brandStatusId !== -1
                  ? DELIVERABLE_VALUES[brandStatusId].value
                  : "N/A";
            } else {
              row["brandStatus"] = "N/A";
            }
          });
        }
        this.disclosures = res;
        this.filterDisclosures = _.clone(this.disclosures);
      });
  }

  getLastUpdated() {
    this.sustainabilityPublicDisclosureService
      .generateKey()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.lastUpdated = res.dateTime;
      });
  }

  getDisclosures() {
    this.sustainabilityPublicDisclosureService
      .getDisclosures()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        res.forEach((disclosure) => {
          disclosure["themeSubTheme"] = disclosure["subThemeName"]
            ? `${disclosure["themeName"]} - ${disclosure["subThemeName"]}`
            : disclosure["themeName"];
        });
        this.disclosuresData = res.filter((disc) => disc.status !== "DELETED");
      });
  }

  onFilterChanged(filters) {
    this.table.reset();
    let filteredData: any;
    if (filters.BRAND && filters.BRAND.length) {
      const dataToFilter = filteredData || this.disclosures;
      filteredData = dataToFilter.filter((value) =>
        filters.BRAND.includes(value.brand)
      );
    }

    if (filters.DELIVERABLE) {
      const dataToFilter = filteredData || this.disclosures;
      filteredData = dataToFilter.filter(
        (value) => filters.DELIVERABLE === value.brandStatusDb
      );
    }

    if (filters.ASSETS && filters.ASSETS.length) {
      const dataToFilter = filteredData || this.disclosures;
      filteredData = dataToFilter.filter((value) =>
        filters.ASSETS.includes(value.asset)
      );
    }

    if (filters.MASTER_PRODUCER && filters.MASTER_PRODUCER.length) {
      const dataToFilter = filteredData || this.disclosures;
      filteredData = dataToFilter.filter((value) => {
        if (value.entityName) {
          let masterProducer = filters.MASTER_PRODUCER.map((row) =>
            row.toLowerCase()
          );
          return masterProducer.includes(value.entityName.toLowerCase());
        }
      });
    }

    if (filters.disclosure && filters.disclosure.length) {
      let disclosures = this.setDisclosureFilter(filters);
      const dataToFilter = filteredData || this.disclosures;
      filteredData = dataToFilter.filter((value) =>
        disclosures.includes(value.disclosureId.id)
      );
    }

    if (filters.METAL && filters.METAL.length) {
      const dataToFilter = filteredData || this.disclosures;
      filteredData = dataToFilter.filter((value) => {
        let metals = value.metals.split(",").map((ele) => ele.trim());
        const intersection = _.intersection(metals, filters.METAL);
        return intersection.length ? true : false;
      });
    }

    if (filters.freeText) {
      const dataToFilter = filteredData || this.disclosures;
      filteredData = dataToFilter.filter((row) => {
        return (
          row.themeSubTheme
            .toLowerCase()
            .includes(filters.freeText.toLowerCase()) ||
          (row.entityName &&
            row.entityName
              .toLowerCase()
              .includes(filters.freeText.toLowerCase())) ||
          (row.asset &&
            row.asset.toLowerCase().includes(filters.freeText.toLowerCase())) ||
          (row.brand &&
            row.brand.toLowerCase().includes(filters.freeText.toLowerCase())) ||
          (row.metal &&
            row.metal.toLowerCase().includes(filters.freeText.toLowerCase())) ||
          (row.disclosureName &&
            row.disclosureName
              .toLowerCase()
              .includes(filters.freeText.toLowerCase())) ||
          (row.level &&
            row.level.toLowerCase().includes(filters.freeText.toLowerCase())) ||
          (row.metricLabel &&
            row.metricLabel
              .toLowerCase()
              .includes(filters.freeText.toLowerCase()))
        );
      });
    }

    this.filterDisclosures = filteredData || this.disclosures;
  }

  setDisclosureFilter(filters) {
    let disclosures = [];
    filters["disclosure"].forEach((elem) => {
      if (elem.hasOwnProperty("disclosureId")) {
        disclosures.push(elem.disclosureId);
      } else {
        let filteredDisc = this.disclosuresData
          .filter(
            (disclosure) => disclosure.themeSubTheme === elem.themeSubTheme
          )
          .map((disclosure) => disclosure.disclosureId);

        disclosures.push(filteredDisc);
      }
    });

    let disclosureIds = _.flatten(disclosures);

    if (typeof filters["disclosure"][0] === "string") {
      filters["disclosure"] = this.disclosuresData.filter((elem) => {
        return _.flatten(disclosures).includes(elem.disclosureId.toString());
      });
    } else {
      filters["disclosure"] = this.disclosuresData.filter((elem) => {
        return _.flatten(disclosures).includes(elem.disclosureId);
      });
    }
    return disclosureIds;
  }

  downloadDocument(rowData, type) {
    window.open(
      `${environment.apiUrl}/home-service/publicView/document/download?uuid=${rowData.proofDocument.uuid}&name=${rowData.proofDocument.fileName}`
    );
  }

  redirectToUrl(event, url) {
    event.preventDefault();
    if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url;
    }
    return window.open(url);
  }

  exportExcel() {
    window.open(
      `${environment.apiUrl}/home-service/publicView/export/sustainability`
    );
  }

  brandProducerSelectionNavigation(rowData) {
    let name = rowData.entity.entityName
      .trim()
      .replace(/\s+/g, "-")
      .toLowerCase();
    this.router.navigate([
      `/public/producer-profile/${name}/${rowData.entity.id}`,
    ]);
  }

  navigateToTaxonomyWheel(rowData?: any) {
    localStorage.setItem(
      "themeSelection",
      btoa(JSON.stringify(rowData.themeId))
    );
    this.router.navigate([`public/disclosures-detail`]);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
    this.coreService.countDown.next(null);
  }
}
