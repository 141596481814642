import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FilterFieldsLists,
  ITableFilterSortedField,
} from "@shared/models/table-filter.model";
import { ROWS_PER_PAGE } from "@shared/models/table-view.model";
import { Subject } from "rxjs";

@Component({
  selector: "app-table-simple",
  templateUrl: "./table-simple.component.html",
  styleUrls: ["./table-simple.component.scss"],
})
export class TableSimpleComponent implements OnInit {
  @Input() config: any;
  @Input() tableData: any[];
  @Input() selections: any[];
  @Input() from?: string;
  @Input() virtualScroll: boolean;
  @Input() noRecordMessage: string;

  @Output() checkboxSelected = new EventEmitter<any>();

  first: number = 0;
  private _destroy$ = new Subject<any>();
  filterFields: FilterFieldsLists;
  sortedFieldsList: ITableFilterSortedField[] = [];
  rows = ROWS_PER_PAGE;
  allRowsSelected: boolean = false;
  headercheckBox: boolean = false;

  constructor() {}

  ngOnInit() {
    this.rows = this.config.rowsPerPage ? this.config.rowsPerPage : this.rows;
    console.log(this.tableData);
  }

  reset() {
    this.first = 0;
  }

  selected() {
    if (this.from === "review") {
      if (this.selections.length === this.tableData.length) {
        this.headercheckBox = true;
        this.allRowsSelected = true;
      } else {
        this.headercheckBox = false;
        this.allRowsSelected = false;
      }
    }

    if (this.from === "searchResults") {
      this.selections = this.selections.filter(
        (row) =>
          !(
            row.status == "APPROVED" ||
            row.status == "REVIEW1" ||
            row.status == "REVIEW2"
          )
      );
    }

    this.checkboxSelected.emit(this.selections);
  }

  selectAllVirtualScrollTable() {
    if (this.allRowsSelected) {
      this.selections = [];
      this.allRowsSelected = false;
    } else {
      this.allRowsSelected = true;
      this.selections = this.tableData;
    }
    this.checkboxSelected.emit(this.selections);
  }
}
