<div [class.calendar-div]="calendarOptionsOpened" class="date-range">
  <div class="hotspot-area" tabindex="0" (keydown)="
      keyPressed($event, 'toggleCalendar', ['Tab', 'Shift', 'Control', 'Alt'])
    " (click)="toggleCalendar()" id="toggleCalendar"></div>

  <div class="flex" role="button" aria-label="Toggle" (click)="toggleCalendar()" id="dateRangeLabel">
    <div class="date-range-label" [class.placeholder]="!dateFrom && !dateTo">
      <span>{{ dateRangeLabel }}</span>
    </div>
  </div>

  <div class="cursor-pointer" role="button" aria-label="Toggle" [class.opened]="calendarOptionsOpened"
    (click)="toggleCalendar()">
    <div class="arrow"></div>
  </div>

  <div *ngIf="calendarOptionsOpened" class="calendars" cdkTrapFocus>
    <div class="months-pickers">
      <p-calendar (onSelect)="customCalendarDatesChanged('start')" [(ngModel)]="dateFromUTC.boundDate" [inline]="true"
        [showTime]="false" [yearNavigator]="true" wcag-label view="date" yearRange="2000:2030"
        [maxDate]="maxDateStartDate" dateFormat="dd MM yy">
      </p-calendar>
      <p-calendar (onSelect)="customCalendarDatesChanged('end')" [(ngModel)]="dateToUTC.boundDate" [inline]="true"
        [showTime]="false" [yearNavigator]="true" wcag-label view="date" yearRange="2000:2030"
        [minDate]="minDateEndDate" dateFormat="dd MM yy">
      </p-calendar>
    </div>
  </div>
</div>