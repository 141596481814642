import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CoreService } from "@core/services/core.service";
import { SustainabilityDisclosurePublicService } from "@module/sustainability-disclosure-public-view/services/sustainability-disclosure-public.service";
import { disclosuresListTableConfig } from "@module/sustainability-disclosure-public-view/sustainability-disclosure-public-view.const";
import { ITableViewConfig } from "@shared/models/table-view.model";
import {
  TAXONOMY_WHEELE_COLORS,
  TAXONOMY_WHEELE_FONTCOLOR,
  sustainabilityEmail,
} from "app/app.const";
import * as _ from "lodash";
import { forEach } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

declare let anychart;
@Component({
  selector: "app-disclosures-detail",
  templateUrl: "./disclosures-detail.component.html",
  styleUrls: ["./disclosures-detail.component.scss"],
})
export class DisclosuresDetailComponent implements OnInit, OnDestroy {
  @ViewChild("disclosureTemplate", { static: true })
  public disclosureTemplate: TemplateRef<any>;

  private _destroy$ = new Subject<any>();
  themes: any;
  themeMap: any;
  tableConfig: ITableViewConfig;
  subThemeClick: boolean;
  selectedThemes: any;
  disclosures = [];

  @ViewChild("chartContainer", { static: true }) container;
  @ViewChild("line", { static: true }) line;

  chart = null;
  themeSelected: boolean;
  initialLoad = true;
  selectedThemeSubThemeName: any;
  disclosureAboutDialog: boolean;
  selectedColor: any;
  urlOpened: boolean;
  additionalInfoDialog: boolean;
  sustainabilityEmail: string;
  sustainabilityEmailLink: string;
  selectedBackgroundColor: any;
  taxonomyWheel: any = null;
  storeSelection: any;

  constructor(
    private sustainabilityDisclosurePublicService: SustainabilityDisclosurePublicService,
    private titleService: Title,
    private elementRef: ElementRef,
    private coreService: CoreService
  ) {
    titleService.setTitle(
      "Portal - LMEpassport - LME’S Sustainability Taxonomy"
    );
  }

  ngOnInit() {
    this.sustainabilityEmail = sustainabilityEmail;
    this.sustainabilityEmailLink = `mailto: ${sustainabilityEmail}`;

    this.generateChart();
  }

  generateChart() {
    this.sustainabilityDisclosurePublicService
      .getPieDisclosures()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.themeMap = {};
        this.themes = res;

        setTimeout(() => {
          this.liveSustainabilitySelection();
        }, 100);

        this.generateThemeMap(res);

        let colors = [
          "#9966ff",
          "#ff00ff",
          "#0066ff",
          "#cc3300",
          "#ff5050",
          "#0099cc",
          "#993333",
          "#ff99ff",
        ];

        let i = 0;
        let data = [
          {
            name: "Sustainability at the LME",
            normal: { fill: "#fff" },
            selected: { fill: "#fff" },
            children: [],
          },
        ];
        for (const key in this.themeMap) {
          let [color, backgroundColor, fontColor] = this.generateColors(
            key,
            colors,
            i
          );

          this.setThemeData(key, data, color, fontColor, backgroundColor);
          this.setSubThemeData(key, data, color, fontColor, backgroundColor, i);
          i++;
        }

        this.initializeChart(data);
      });
  }

  generateColors(key, colors, i) {
    let color = "";
    let backgroundColor = "";
    let fontColor = "";
    switch (key.toLowerCase()) {
      case "environmental":
        color = TAXONOMY_WHEELE_COLORS.ENVIRONMENTAL.COLOR;
        fontColor = TAXONOMY_WHEELE_FONTCOLOR;
        backgroundColor = TAXONOMY_WHEELE_COLORS.ENVIRONMENTAL.BACKGROUNDCOLOR;
        break;
      case "multi-dimensional":
      case "multi - dimensional":
      case "multi- dimensional":
      case "multi -dimensional":
        color = TAXONOMY_WHEELE_COLORS.MULTIDIMENSIONAL.COLOR;
        fontColor = TAXONOMY_WHEELE_FONTCOLOR;
        backgroundColor =
          TAXONOMY_WHEELE_COLORS.MULTIDIMENSIONAL.BACKGROUNDCOLOR;
        break;
      case "social":
        color = TAXONOMY_WHEELE_COLORS.SOCIAL.COLOR;
        fontColor = TAXONOMY_WHEELE_FONTCOLOR;
        backgroundColor = TAXONOMY_WHEELE_COLORS.SOCIAL.BACKGROUNDCOLOR;
        break;
      case "governance":
        color = TAXONOMY_WHEELE_COLORS.GOVERNANCE.COLOR;
        fontColor = TAXONOMY_WHEELE_FONTCOLOR;
        backgroundColor = TAXONOMY_WHEELE_COLORS.GOVERNANCE.BACKGROUNDCOLOR;
        break;
      default:
        color = colors[i];
        break;
    }

    if (
      this.taxonomyWheel &&
      this.taxonomyWheel.themeName.toLowerCase() === key.toLowerCase()
    ) {
      this.storeSelection = {
        color: color,
        backgroundColor: backgroundColor,
        fontColor: fontColor,
      };
    }
    return [color, backgroundColor, fontColor];
  }

  setThemeData(key, data, color, fontColor, backgroundColor) {
    if (
      key.toLowerCase() === "multi-dimensional" ||
      key.toLowerCase() === "multi - dimensional" ||
      key.toLowerCase() === "multi- dimensional" ||
      key.toLowerCase() === "multi -dimensional" ||
      key.toLowerCase() === "social"
    ) {
      data[0]["children"].push({
        name: key,
        type: "theme",
        fill: color,
        normal: { fill: color },
        selected: { fill: color },
        fontColor: "#13426b",
        backgroundColor,
        label: {
          fontColor: "#13426b",
          fontWeight: "500",
        },
        stroke: { color: "#fff", thickness: 2 },
        hovered: { color, thickness: 3 },
      });
    } else {
      data[0]["children"].push({
        name: key,
        type: "theme",
        fill: color,
        normal: { fill: color },
        selected: { fill: color },
        fontColor: "#13426b",
        backgroundColor,
        label: {
          fontColor: "#13426b",
          fontWeight: "500",
        },
        stroke: { color: "#fff", thickness: 2 },
      });
    }
  }

  setSubThemeData(key, data, color, fontColor, backgroundColor, i) {
    data[0]["children"][i]["children"] = [];
    this.themeMap[key].forEach((ele) => {
      if (ele) {
        /* if (
          key.toLowerCase() === "multi-dimensional" ||
          key.toLowerCase() === "multi - dimensional" ||
          key.toLowerCase() === "multi- dimensional" ||
          key.toLowerCase() === "multi -dimensional" ||
          key.toLowerCase() === "social"
        ) { */
        data[0]["children"][i]["children"].push({
          name: ele,
          type: "subtheme",
          fill: color,
          normal: { fill: color },
          selected: { fill: color },
          fontColor: "#13426b",
          backgroundColor,
          theme: key,
          label: {
            fontColor: "#13426b",
          },
          stroke: { color: "#fff", thickness: 2 },
        });
        /* } else {
          data[0]["children"][i]["children"].push({
            name: ele,
            type: "subtheme",
            fill: color,
            normal: { fill: color },
            selected: { fill: color },
            fontColor: "#13426b",
            backgroundColor,
            theme: key,
            label: {
              fontColor: "#13426b",
            },
            stroke: { color: "#fff", thickness: 2 },
          });
        } */
      }
    });

    data[0]["children"][i]["children"] = _.orderBy(
      data[0]["children"][i]["children"],
      ["name"],
      ["asc"]
    );
  }

  initializeChart(data) {
    this.chart = anychart.sunburst(data, "as-tree");

    this.chart.container(this.container.nativeElement);

    this.chartDraw();

    this.pointSelectChart();

    this.pointHoverChart();

    this.mouseDownChart();

    this.chart.level(2).labels().position("circular");
    this.chart.labels().hAlign("center");
    this.chart.labels().vAlign("middle");
    this.chart.labels().useHtml(true);
    this.chart
      .level(2)
      .labels()
      .format("<span style='font-size:11px;'>{%name}</span>");
    this.chart
      .level(1)
      .labels()
      .format("<span style='font-size:20px'>{%name}</span>");
    /* this.chart
      .level(0)
      .labels()
      .format(
        "<span style='font-size:18px; text-transform: uppercase;'>SUSTAINABILITY</span><br><span style='font-size:18px; ; text-transform: uppercase;'>AT THE LME</span>"
      ); */
    //this.chart.labels().wordWrap("break-word");
    this.chart.leaves().labels().width("100%");
    this.chart.leaves().labels().height(100);
    this.chart.interactivity().selectionMode("single-select");
    this.chart.innerRadius(20);
    this.chart.normal();
    this.chart.startAngle(315);
    this.chart.contextMenu(false);
    // this.chart.labels().wordWrap("break-word");
    this.chart.draw();
    this.generateDisclosureTable(
      data[0].children[0].name,
      "theme",
      data[0].children[0].fill,
      data[0].children[0].backgroundColor,
      data[0].children[0].fontColor
    );
  }

  generateThemeMap(res) {
    res.forEach((element) => {
      if (this.themeMap.hasOwnProperty(element.themeName)) {
        this.themeMap[element.themeName].push(element.subThemeName);
      } else {
        this.themeMap[element.themeName] = [element.subThemeName];
      }
    });
  }

  pointSelectChart() {
    /* this.chart.listen("pointsSelect", (e) => {
      if (e.point) {
        this.pointClicked(e);
      }
    }); */

    this.chart.listen("pointMouseDown", this.registerClick.bind(this));
  }

  registerClick(e) {
    var element = document.getElementById(
      e.originalEvent.originalEvent.ZD
        ? e.originalEvent.originalEvent.ZD.Be.target.id
        : e.originalEvent.ZD.Be.target.id
    );

    let mainParent = document.getElementById(
      e.originalEvent.originalEvent.ZD
        ? e.originalEvent.originalEvent.ZD.currentTarget.Sd.id
        : e.originalEvent.ZD.currentTarget.Sd.id
    );
    mainParent.setAttribute("class", "");
    mainParent.classList.add(`rotate-${e.originalEvent.pointIndex}`);
    let type = e.point.getStat("type");
    let pathsArr = document.querySelectorAll("g > path");

    pathsArr.forEach((path) => {
      path.classList.remove("active");
      path.classList.remove("child-active");
    });

    element && element.classList.add("active");
    if (type === "theme") {
      let childArr = e.point.node.Ai.pathFromRoot[1].b;
      childArr.forEach((item) => {
        let id = item.Ai.path.Sd.id;
        let d = document.getElementById(id);
        d.classList.add("child-active");
      });
    }

    this.themeSelected = true;
    let name = e.point.getStat("name");
    let fill = e.point.getStat("fill");
    let theme = e.point.getStat("theme");
    let backgroundColor = e.point.getStat("backgroundColor");
    let fontColor = e.point.getStat("fontColor");
    if (name && type) {
      this.generateDisclosureTable(
        name,
        type,
        fill,
        backgroundColor,
        fontColor,
        theme
      );
    }

    this.chart.unlisten("pointMouseDown", this.registerClick);

    //get a child nodes of a clicked element
    const child = e.point.node.getChildren();
    //get a parent node of a clicked leaf
    const parent = e.point.node.getParent();
    //get a clicked element node
    const ele = e.point.node;

    //amount of angles to rotate
    let rotateAngles = 0;

    //rotate a chart, depending on an clicked element level
    if (child.length == 0) {
      //level 1
      rotateAngles = 225 - parent.meta("start");
    } else if (parent == null) {
      //level 0
      this.chart.listen("pointMouseDown", this.registerClick);
    } else {
      //level 2
      rotateAngles = 225 - ele.meta("start");
    }

    //call an angle update function
    this.updateStartAngle(rotateAngles);
  }

  chartDraw() {
    this.chart.listen("chartDraw", (e) => {
      if (!this.initialLoad) {
        this.themeSelected = true;
        this.initialLoad = false;
      }
      this.printPath(this.chart.getDrilldownPath());
    });
  }

  mouseDownChart() {
    this.chart.listen("mouseDown", (e) => {
      if (e.pointIndex === 0) {
        window.open(
          "https://www.lme.com/en/about/responsibility/sustainability/sustainability-disclosures-on-lmepassport"
        );
      }
    });
  }

  pointHoverChart() {
    this.chart.listen("pointsHover", (e) => {
      if (e.point && !this.themeSelected) {
        let name = e.point.getStat("name");
        let type = e.point.getStat("type");
        let backgroundColor = e.point.getStat("backgroundColor");
        let fill = e.point.getStat("fill");
        let theme = e.point.getStat("theme");
        let fontColor = e.point.getStat("fontColor");
        if (name && type) {
          this.generateDisclosureTable(
            name,
            type,
            fill,
            backgroundColor,
            fontColor,
            theme
          );
        }
      }
    });
  }

  /* pointClicked(e) {
    var element = document.getElementById(
      e.originalEvent.originalEvent.ZD
        ? e.originalEvent.originalEvent.ZD.Be.target.id
        : e.originalEvent.ZD.Be.target.id
    );

    let mainParent = document.getElementById(
      e.originalEvent.originalEvent.ZD
        ? e.originalEvent.originalEvent.ZD.currentTarget.Sd.id
        : e.originalEvent.ZD.currentTarget.Sd.id
    );
    mainParent.setAttribute("class", "");
    mainParent.classList.add(`rotate-${e.originalEvent.pointIndex}`);
    let type = e.point.getStat("type");
    if (type === "subtheme") {
      const parent = e.point.node.getParent();
      //get number of angles to rotate a chart
      const rotateAngles = 225 - parent.meta("start");
      //call an angle update function
      this.updateStartAngle(rotateAngles);
    }
    //this.animateStartAngle(150);
    let pathsArr = document.querySelectorAll("g > path");

    pathsArr.forEach((path) => {
      path.classList.remove("active");
      path.classList.remove("child-active");
    });

    element && element.classList.add("active");
    if (type === "theme") {
      let childArr = e.point.node.Ai.pathFromRoot[1].b;
      childArr.forEach((item) => {
        let id = item.Ai.path.Sd.id;
        let d = document.getElementById(id);
        d.classList.add("child-active");
      });
    }

    this.themeSelected = true;
    let name = e.point.getStat("name");
    let fill = e.point.getStat("fill");
    let backgroundColor = e.point.getStat("backgroundColor");
    let fontColor = e.point.getStat("fontColor");
    if (name && type) {
      this.generateDisclosureTable(
        name,
        type,
        fill,
        backgroundColor,
        fontColor
      );
    }
  } */

  updateStartAngle(rotateAngles) {
    const goalAngle = rotateAngles;
    //request animation frame for updating a start angle
    window.requestAnimationFrame(() => {
      //check if there is a need to update an angle
      if (rotateAngles != 0) {
        //update the chart by 5 angles
        this.chart.startAngle(this.chart.startAngle() + 5) % 360;
        //subtract rotated angles
        rotateAngles = (rotateAngles - 5) % 360;
        //recurcive call of an update function
        this.updateStartAngle(rotateAngles);
      } else {
        //listen for click again
        this.chart.listen("pointMouseDown", this.registerClick);
      }
    });
  }

  generateDisclosureTable(
    name,
    type,
    fill,
    backgroundColor,
    fontColor,
    themeName?
  ) {
    this.selectedColor = fontColor;
    this.selectedBackgroundColor = fill;
    this.selectedThemeSubThemeName = name;
    this.subThemeClick = true;
    const { columns, ...config } = disclosuresListTableConfig;
    const _columns = [...disclosuresListTableConfig.columns];
    this.tableConfig = {
      ...config,
      ...{ columns: _columns },
    };

    _columns.push({
      header: `Disclosures (${name})`,
      width: "60px",
      visible: true,
      templateRef: this.disclosureTemplate,
    });

    this.disclosures = [];
    if (type === "subtheme") {
      this.subThemeDisclosures(
        themeName,
        name,
        fill,
        backgroundColor,
        fontColor
      );
    } else {
      let selectedThemes = this.themes.filter((row) => row.themeName === name);
      selectedThemes.forEach((theme) => {
        theme.disclosures.map((disclosure) => {
          this.disclosures.push({
            disclosureName: disclosure.name,
            url: disclosure.url,
            description: disclosure.description,
            fill,
            backgroundColor,
            fontColor,
          });
        });
      });
    }
    this.disclosures = _.orderBy(
      this.disclosures,
      [(disclosure) => disclosure.disclosureName.trim().toLowerCase()],
      ["asc"]
    );
  }

  subThemeDisclosures(themeName, name, fill, backgroundColor, fontColor) {
    let subThemeIdx = this.themes.findIndex(
      (theme) => theme.subThemeName === name && theme.themeName === themeName
    );
    let discs = this.themes[subThemeIdx].disclosures;
    discs.map((disclosure) => {
      this.disclosures.push({
        disclosureName: disclosure.name,
        description: disclosure.description,
        url: disclosure.url,
        fill,
        backgroundColor,
        fontColor,
      });
    });
  }

  liveSustainabilitySelection() {
    if (localStorage.getItem("themeSelection")) {
      this.taxonomyWheel = JSON.parse(
        atob(localStorage.getItem("themeSelection"))
      );
    }
    if (this.taxonomyWheel) {
      const [color, backgroundColor, fontColor] = this.generateColors(
        this.taxonomyWheel.themeName,
        ["#9966ff"],
        0
      );
      this.selectedBackgroundColor = color;
      this.selectedColor = fontColor;
      this.selectedThemeSubThemeName = this.taxonomyWheel.subThemeName;
      this.disclosures = [];
      this.subThemeDisclosures(
        this.taxonomyWheel.themeName,
        this.taxonomyWheel.subThemeName,
        fontColor,
        backgroundColor,
        color
      );
      this.disclosures = _.orderBy(
        this.disclosures,
        ["disclosureName"],
        ["asc"]
      );
    }
  }

  printPath(path) {
    for (const item of path) {
      let pathName = item.get("name");
      let type = item.get("type");
      let fill = item.get("fill");
      let theme = item.get("theme");
      let backgroundColor = item.get("backgroundColor");
      let fontColor = item.get("fontColor");
      if (pathName && type) {
        this.generateDisclosureTable(
          pathName,
          type,
          fill,
          backgroundColor,
          fontColor,
          theme
        );
      }
    }
  }

  redirectToUrl(event, url) {
    event.preventDefault();
    if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url;
    }
    return window.open(url);
  }

  viewDisclosureAbout() {
    this.disclosureAboutDialog = true;
  }

  additionalInfo() {
    this.additionalInfoDialog = true;
  }

  ngOnDestroy(): void {
    this.chart.removeAllListeners();
    this.coreService.countDown.next(null);
    localStorage.removeItem("themeSelection");
  }
}
