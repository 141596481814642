import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metric-comparison-container',
  templateUrl: './metric-comparison-container.component.html',
  styleUrls: ['./metric-comparison-container.component.scss']
})
export class MetricComparisonContainerComponent implements OnInit {
  menuItems = [
    {
      moduleLabel: "Producer / brand side-by-side",
      path: "brand-comparison",
    },
    // {
    //   moduleLabel: "Metric side-by-side",
    //   path: "metric-comparison-producer",
    // }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
