<p-dialog header="Redact File" [closeOnEscape]="false" [modal]="true" [style]="{ width: '100vw' }"
    [(visible)]="redactDialog" (onHide)="onRedactHide($event)" appendTo="body">
    <div *ngIf="fileToRedact && fileToRedact?.type === 'application/pdf'"
        class="ml-5 text-center mt-2 mb-2 col-md-6 note">
        Please select any confidential information (if needed), then click "Redact"
        and "Submit" to proceed.
    </div>
    <lib-redact [file]="fileToRedact" (redactResult)="redactResultHandler($event)"></lib-redact>
    <p-footer>
        <button (click)="onRedact($event)" class="btn btn-primary">
            Submit
        </button>
    </p-footer>
</p-dialog>