import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "formly-field-dictionary-select",
  template: `
    <ng-container *ngIf="!to?.fieldName; else FIELD_NAME">
      <ng-select
        [items]="items"
        [placeholder]="placeholder"
        [formControl]="formControl"
        [bindLabel]="to.bindLabel"
        [loading]="isLoading"
        [clearable]="false"
        [attr.disabled]="to.disabled"
        [class]="to.class"
        [appendTo]="to.appendTo ? 'body' : null"
        [multiple]="to.multiple"
        (change)="to.change && to.change(field, $event)"
        [notFoundText]="to.notFoundText || 'No items found'"
        [inputAttrs]="{ 'aria-label': to.label, id: to.id, 'cy-id': to.id }"
      >
      </ng-select>
    </ng-container>
    <ng-template #FIELD_NAME>
      <ng-select
        [items]="items"
        [clearable]="false"
        [placeholder]="placeholder"
        [formControl]="formControl"
        [loading]="isLoading"
        [attr.disabled]="to.disabled"
        [class]="to.class"
        [multiple]="to.multiple"
        (change)="to.change && to.change(field, $event)"
        [notFoundText]="to.notFoundText || 'No items found'"
        [inputAttrs]="{ 'aria-label': to.label, id: to.id, 'cy-id': to.id }"
      >
      </ng-select>
    </ng-template>
  `,
})
export class FormlyFieldDictionarySelect
  extends FieldType
  implements OnInit, OnChanges, OnDestroy
{
  onDestroy$ = new Subject<void>();

  isLoading = false;
  items = [];
  source: any;
  dataProvided: boolean;
  public placeholder: string;

  public translatePrefix;
  constructor() {
    super();
  }

  ngOnInit() {
    this.source = this.to.source;
    this.dataProvided = this.to.dataProvided;
    if (this.dataProvided) {
      this.loadProvidedData();
    } else {
      this.loadData();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  get disabled() {
    return this.to.disabled;
  }

  private loadData() {
    this.isLoading = true;
    this.source
      .pipe(
        map((data: any) => {
          if (this.to.label === "Break Reason") {
            data = data.filter((br) => {
              return br.value !== "MATCHED";
            });
          }
          return data;
        })
      )
      .subscribe((data) => {
        this.items = data;
        this.isLoading = false;
      });
  }

  private loadProvidedData() {
    this.items = this.to.items;
  }

  ngOnDestroy() {
    this.onDestroy$.complete();
  }
}
