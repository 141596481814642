import { Component } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "formly-horizontal-wrapper",
  styles: [
    `
      ::ng-deep .custom-checkbox {
        display: inline-block;
        margin-right: 2px;
      }
    `,
  ],
  template: `
    <div class="form-group" [class.has-error]="showError">
      <ng-container *ngIf="field.type !== 'checkbox'">
        <label [attr.for]="id" *ngIf="to.label">
          {{ to.label }}
          <ng-container *ngIf="to.info"
            ><i
              class="fa fa-info-circle"
              tooltipPosition="top"
              [pTooltip]="to.info"
              aria-hidden="true"
            ></i
          ></ng-container>
          <ng-container *ngIf="to.required && to.hideRequiredMarker !== true"
            >*</ng-container
          >
        </label>
      </ng-container>
      <div>
        <ng-template #fieldComponent></ng-template>
        <ng-container *ngIf="field.type === 'checkbox'"
          ><i
            class="fa fa-info-circle"
            tooltipPosition="top"
            [pTooltip]="to.info"
            aria-hidden="true"
          ></i
        ></ng-container>
      </div>
      <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-custom-validation-message
          [field]="field"
        ></formly-custom-validation-message>
      </div>
    </div>
  `,
})
export class FormlyHorizontalWrapper extends FieldWrapper {}
