import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ISearchPresetsModel } from "@shared/components/search-presets/search-presets.model";
import { Observable } from "rxjs/internal/Observable";

export const DFAULT_VIEW = "/inventory-service/myrecords/getDefaultView";
export const ADD_MY_RECORDS = "/inventory-service/records/addToMyRecord";
export const MY_RECORDS = "/inventory-service/myrecords/search";
export const SAVE_DEFAULT_VIEW =
  "/inventory-service/myrecords/storeDefaultView";
export const SAVE_PREFERENCE =
  "/inventory-service/myrecords/storeSearchPreference";
export const PRESETS = "/inventory-service/myrecords/getSavedSearchPrefs";
export const SAVED_FILTER =
  "/inventory-service/myrecords/getSavedSearchFilterByPref";
export const DELETE_FILTER =
  "/inventory-service/myrecords/deleteSavedSearchFilterByPref";
export const EXPORT = "/inventory-service/myrecords/export";
export const APPENDED_THEME = "/inventory-service/themes/records/";
export const DELETE_APPENDED = "/inventory-service/themes/records/detach";
export const SEARCH_COA_DOCS = "/report-service/records/searchCOADocuments";
export const GENERATE_ARCHIVE_ID =
  "/report-service/records/prepareCOADocuments";
export const DELETE_MY_RECORDS = "/inventory-service/myrecords/delete";
@Injectable({
  providedIn: "root",
})
export class MyRecordsService {
  constructor(private http: HttpClient) {}

  getPresets(): Observable<ISearchPresetsModel[]> {
    return this.http.get<ISearchPresetsModel[]>(
      `${environment.apiUrl}${PRESETS}`
    );
  }

  getDefaultView(): any {
    return this.http.get(`${environment.apiUrl}${DFAULT_VIEW}`);
  }

  addToMyRecords(payload, coaReference?): any {
    if (coaReference) {
      let params = new HttpParams();
      params = params.append("coaRef", coaReference);

      return this.http.post(`${environment.apiUrl}${ADD_MY_RECORDS}`, payload, {
        params,
      });
    } else {
      return this.http.post(`${environment.apiUrl}${ADD_MY_RECORDS}`, payload);
    }
  }

  getMyRecords(filters, pagination): any {
    let params = new HttpParams();
    params = params.append("fromPage", pagination.page);
    params = params.append("pageSize", pagination.size);

    return this.http.post(`${environment.apiUrl}${MY_RECORDS}`, filters, {
      params,
    });
  }

  saveDefaultView(payload) {
    return this.http.post(`${environment.apiUrl}${SAVE_DEFAULT_VIEW}`, payload);
  }

  storePreference(name, payload) {
    let params = new HttpParams();
    params = params.append("searchPrefName", name);
    return this.http.post(`${environment.apiUrl}${SAVE_PREFERENCE}`, payload, {
      params,
    });
  }

  getSavedFilter(id) {
    let params = new HttpParams();
    params = params.append("id", id);
    return this.http.get(`${environment.apiUrl}${SAVED_FILTER}`, { params });
  }

  deletePreference(id) {
    let params = new HttpParams();
    params = params.append("id", id);
    return this.http.delete(`${environment.apiUrl}${DELETE_FILTER}`, {
      params,
    });
  }

  export(payload): any {
    return this.http.post(`${environment.apiUrl}${EXPORT}`, payload);
  }

  getValueAddThemesDisc(appendId): any {
    return this.http.get(`${environment.apiUrl}${APPENDED_THEME}${appendId}`);
  }

  getValueAddThemes(): any {
    return this.http.get(`${environment.apiUrl}${APPENDED_THEME}`);
  }

  deleteAppended(payload) {
    return this.http.post(`${environment.apiUrl}${DELETE_APPENDED}`, payload);
  }

  searchCOADocs(filters): any {
    return this.http.post(`${environment.apiUrl}${SEARCH_COA_DOCS}`, filters);
  }

  generateArchiveId(filters): any {
    return this.http.post(
      `${environment.apiUrl}${GENERATE_ARCHIVE_ID}`,
      filters
    );
  }

  deleteMyRecords(payload: any) {
    const options = {
      body: payload,
    };
    return this.http.delete(
      `${environment.apiUrl}${DELETE_MY_RECORDS}`,
      options
    );
  }
}
