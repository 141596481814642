import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { TableSimpleComponent } from "./components/table-simple/table-simple.component";
import { FormlyFieldDictionarySelect } from "./formly/formly-custom-types/dictionary-select.type.component";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarModule } from "primeng/sidebar";
import { DialogModule } from "primeng/dialog";
import { TooltipModule } from "primeng/tooltip";
import { CheckboxModule } from "primeng/checkbox";
import { FieldsetModule } from "primeng/fieldset";
import { FormlyFieldsetComponent } from "./formly/formly-wrappers/formly-fieldset.component";
import { HeaderComponent } from "./components/header/header.component";
import { RouterModule } from "@angular/router";
import { HeaderLoginComponent } from "./components/header-login/header-login.component";
import { FormlyHorizontalWrapper } from "./formly/formly-wrappers/horizontal-wrapper";
import { SplitButtonModule } from "primeng/splitbutton";
import { FormlyFieldDictionarySelectStatic } from "./formly/formly-custom-types/dictionary-select-static.component";
import { TableFilterComponent } from "./components/table-filter/table-filter.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { NeedMoreInfoComponent } from "./components/need-more-info/need-more-info.component";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ActiveSuspendedComponent } from "./components/active-suspended/active-suspended.component";
import { FormlyFieldDictionarySelectTemplate } from "./formly/formly-custom-types/dictionary-select-type-template";
import { TableViewComponent } from "./components/table-view/table-view.component";
import { FormlyCalendarInput } from "./formly/formly-custom-types/calendar-type.component";
import { CalendarModule } from "primeng/calendar";
import { FooterComponent } from "./components/footer/footer.component";
import { SearchPresetsComponent } from "./components/search-presets/search-presets.component";
import { DateRangeComponent } from "./components/date-range/date-range.component";
import { ChemicalCompositionComponent } from "./components/chemical-composition/chemical-composition.component";
import { RepeatTypeComponent } from "./formly/formly-custom-types/repeat-section.type";
import { UploadInput } from "./formly/formly-custom-types/upload-type";
import { AppendComponent } from "./components/append/append.component";
import { WCAGLabelDirective } from "./directives/wcag-label.directive";
import { TableModule } from "primeng/table";
import { SustainabilityProfileComponent } from "./components/sustainability-profile/sustainability-profile.component";
import { ApprovedRecordsComponent } from "@shared/components/approved-records/approved-records.component";
import { ReviewComponent } from "./components/review/review.component";
import { AdditionalDocumentsComponent } from "./components/additional-documents/additional-documents.component";
import { ValueAddThemesComponent } from "./components/value-add-themes/value-add-themes.component";
import { EditCoaRecordComponent } from "./components/edit-coa-record/edit-coa-record.component";
import { AssignCoaComponent } from "./components/assign-coa/assign-coa.component";
import { UploadAdditionaDocsComponent } from "./components/upload-additiona-docs/upload-additiona-docs.component";
import { FormlyFieldReadOnlyInput } from "./formly/formly-custom-types/read-only-input.type.component";
import { RecordViewComponent } from "./components/record-view/record-view.component";
import { FormlyCustomValidationMessage } from "./formly/formly-custom-types/formly-custom-validation-message.component";
import { FormlyFieldDictionarySelectAll } from "./formly/formly-custom-types/dictionary-select-select-all.type.component";
import { MultiSelectModule } from "primeng/multiselect";
import { PreviewOldNewDocComponent } from "./components/preview-old-new-doc/preview-old-new-doc.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { DisclosureConfigComponent } from "./components/disclosure-config/disclosure-config.component";
import { EditDisclosureComponent } from "./components/edit-disclosure/edit-disclosure.component";
import { RedactFileComponent } from "./components/redact-file/redact-file.component";
import { RazorRedactLibModule } from "razor-redact-lib";
import { TargetSettingComponent } from "./components/target-setting/target-setting.component";
import { InputTextModule } from "primeng/inputtext";
import { SliderModule } from "primeng/slider";

import { FusionChartsModule } from "angular-fusioncharts";

// Load FusionCharts
import * as FusionCharts from "fusioncharts";

// Load Widgets
import * as Widgets from "fusioncharts/fusioncharts.widgets";

// Load FusionTheme Theme
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { TargetSettingListingComponent } from "./components/target-setting-listing/target-setting-listing.component";
import { RegisterUserComponent } from "./components/register-user/register-user.component";
import { FormlyAutoCompleteInput } from "./formly/formly-custom-types/autocomplete-type.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { FormlyFieldCustomTextareaComponent } from "./formly/formly-custom-types/formly-field-custom-textarea/formly-field-custom-textarea.component";
import { CustomLinkTypeComponent } from "./formly/formly-custom-types/link.type";
import { InputSwitchTypeComponent } from "./formly/formly-custom-types/input-switch.type";
import { InputSwitchModule } from "primeng/inputswitch";
import { CustomFieldComponent } from "./formly/formly-custom-types/custom-field.component";
import { GdlDataComponent } from "./components/gdl-data/gdl-data.component";
import { CustomFormatPipe } from "./pipe/custom-format.pipe";

// Add dependencies to FusionChartsModule
FusionChartsModule.fcRoot(FusionCharts, Widgets, FusionTheme);

export function PcrValidatorMessage(err, field: FormlyFieldConfig) {
  return `"${field.templateOptions.label}" cannot cannot start with -,=,@,+ characters`;
}

@NgModule({
  declarations: [
    TableSimpleComponent,
    TableViewComponent,
    TableFilterComponent,
    FormlyFieldDictionarySelect,
    FormlyFieldDictionarySelectStatic,
    FormlyFieldDictionarySelectTemplate,
    FormlyCalendarInput,
    LoaderComponent,
    FormlyFieldsetComponent,
    UploadInput,
    HeaderComponent,
    HeaderLoginComponent,
    FormlyHorizontalWrapper,
    RegistrationComponent,
    NeedMoreInfoComponent,
    ActiveSuspendedComponent,
    FooterComponent,
    SearchPresetsComponent,
    DateRangeComponent,
    ChemicalCompositionComponent,
    RepeatTypeComponent,
    AppendComponent,
    WCAGLabelDirective,
    SustainabilityProfileComponent,
    ApprovedRecordsComponent,
    ReviewComponent,
    AdditionalDocumentsComponent,
    ValueAddThemesComponent,
    EditCoaRecordComponent,
    AssignCoaComponent,
    UploadAdditionaDocsComponent,
    FormlyFieldReadOnlyInput,
    RecordViewComponent,
    FormlyCustomValidationMessage,
    FormlyFieldDictionarySelectAll,
    FormlyAutoCompleteInput,
    PreviewOldNewDocComponent,
    DisclosureConfigComponent,
    EditDisclosureComponent,
    RedactFileComponent,
    TargetSettingComponent,
    TargetSettingListingComponent,
    RegisterUserComponent,
    FormlyFieldCustomTextareaComponent,
    CustomLinkTypeComponent,
    InputSwitchTypeComponent,
    CustomFieldComponent,
    GdlDataComponent,
    CustomFormatPipe,
  ],
  imports: [
    CommonModule,
    TableModule,
    SidebarModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    DialogModule,
    TooltipModule,
    CheckboxModule,
    FieldsetModule,
    RouterModule,
    SplitButtonModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    ConfirmDialogModule,
    CalendarModule,
    MultiSelectModule,
    AutoCompleteModule,
    PdfViewerModule,
    RazorRedactLibModule,
    InputTextModule,
    FusionChartsModule,
    NgxSliderModule,
    InputSwitchModule,
    FormlyModule.forRoot({
      validationMessages: [
        { name: "required", message: "This field is required" },
        { name: "cmd", message: PcrValidatorMessage },
      ],
      types: [
        {
          name: "dict-select",
          component: FormlyFieldDictionarySelect,
          wrappers: ["form-field"],
        },
        {
          name: "dict-select-all",
          component: FormlyFieldDictionarySelectAll,
          wrappers: ["form-field"],
        },
        {
          name: "dict-select-static",
          component: FormlyFieldDictionarySelectStatic,
          wrappers: ["form-field"],
        },
        {
          name: "dict-select-template",
          component: FormlyFieldDictionarySelectTemplate,
          wrappers: ["form-field"],
        },
        {
          name: "calendar-input",
          component: FormlyCalendarInput,
          wrappers: ["form-field"],
        },
        { name: "repeat", component: RepeatTypeComponent },
        { name: "upload", component: UploadInput, wrappers: ["form-field"] },
        {
          name: "readonly-input",
          component: FormlyFieldReadOnlyInput,
          wrappers: ["form-field"],
        },
        {
          name: "autocomplete-input",
          component: FormlyAutoCompleteInput,
          wrappers: ["form-field"],
        },
        {
          name: "custom-textarea",
          component: FormlyFieldCustomTextareaComponent,
          wrappers: ["form-field"],
        },
        {
          name: "custom-link",
          component: CustomLinkTypeComponent,
          wrappers: ["form-field"],
        },
        {
          name: "input-switch",
          component: InputSwitchTypeComponent,
          wrappers: ["form-field"],
        },
        { name: "custom", component: CustomFieldComponent },
      ],
      wrappers: [
        { name: "app-formly-fieldset", component: FormlyFieldsetComponent },
        { name: "form-field-horizontal", component: FormlyHorizontalWrapper },
      ],
    }),
  ],
  exports: [
    TableSimpleComponent,
    TableViewComponent,
    LoaderComponent,
    HeaderComponent,
    HeaderLoginComponent,
    TableFilterComponent,
    RegistrationComponent,
    NeedMoreInfoComponent,
    ActiveSuspendedComponent,
    FooterComponent,
    SearchPresetsComponent,
    ChemicalCompositionComponent,
    AppendComponent,
    WCAGLabelDirective,
    SustainabilityProfileComponent,
    ApprovedRecordsComponent,
    ReviewComponent,
    AdditionalDocumentsComponent,
    ValueAddThemesComponent,
    EditCoaRecordComponent,
    AssignCoaComponent,
    UploadAdditionaDocsComponent,
    DisclosureConfigComponent,
    EditDisclosureComponent,
    RedactFileComponent,
    TargetSettingComponent,
    TargetSettingListingComponent,
    CustomFormatPipe,
    GdlDataComponent,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
