import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[wcag-label]',
})
export class WCAGLabelDirective implements OnInit, OnDestroy {

  observer: any;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    const el = this.elRef.nativeElement;
    this.createLabel(el);

    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        this.update();
      });
    });
    const config = { attributes: true, childList: true, characterData: true };
    this.observer.observe(this.elRef.nativeElement, config);
  }

  private createLabel(el) {
    const elParent = el.querySelector('.ng-input,.ui-chkbox,.ui-datepicker-header,.ui-radiobutton');
    if (elParent) {
      const input = el.querySelector('.ng-input input,.ui-chkbox input,.ui-datepicker-header select,.ui-radiobutton input');
      const id = this.getRandomID();

      const label = document.createElement('label');
      label.innerHTML = 'Select...';
      label.className = 'wcag-visuallyhidden';
      label.setAttribute('for', id);
      this.renderer.setAttribute(input, 'id', id);
      this.renderer.appendChild(elParent, label);
    }
  }

  private update() {
    const el = this.elRef.nativeElement;
    const label = el.querySelector('.ng-input label,.ui-chkbox label,.ui-datepicker-header label,.ui-radiobutton label');
    if (label) {
      const labelId = label.getAttribute('for');
      const input = el.querySelector('.ng-input input,.ui-chkbox input,.ui-datepicker-header select,.ui-radiobutton input');
      this.renderer.setAttribute(input, 'id', labelId);
    } else {
      this.createLabel(el);
    }
  }

  private getRandomID() {
    return `wcag-${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`;
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
