import { IUser } from "@core/models/api.model";
import { BehaviorSubject, of } from "rxjs";

export const email = "lmepassport.support@lme.com";
export const helpDeskEmail = "posttradeoperations@lme.com";
export const sustainabilityEmail = "sustainability@lme.com";
export const owsrSupportEmail = "offwarrantstockreporting@lme.com";
export const physicalOperationsEmail = "physicaloperations@lme.com";
export const HOME = "/private/dashboard";
export const STORE_COA_LIMIT = 100;
export const STORE_LME_PASSPORT_LIMIT = 1000;
export const DOWNLOAD_LIMIT_COUNT = 500000;
export const LME_PASSPORT_ID_SEARCH_LIMIT = 100000;
export const COA_REFERENCE_SEARCH_LIMIT = 10000;
export const TAXONOMY_WHEELE_FONTCOLOR = "#136569";
export const SIDE_BY_SIDE_TEXT = "What is side-by-side?";
export const NO_CERTIFICATION_METRICS_AVAILABLE =
  "There are currently no certification or metrics available under this sub-theme";
export const CUSTOM_TARGET = "custom target";
export const NET_ZERO = "net zero";
export const DELIVERABLE_VALUES = [
  { id: "DELISTED", value: "Delisted" },
  { id: "DELISTING_PERIOD", value: "Delisting Period (Deliverable)" },
  { id: "SUSPENDED", value: "Suspended" },
  { id: "SUSPENSION_PERIOD", value: "Suspension Period (Deliverable)" },
  { id: "DELIVERABLE", value: "Deliverable" },
  { id: "NON_LME_BRAND", value: "Non-LME brand (Non deliverable)" },
];

export const TAXONOMY_WHEELE_COLORS = {
  ENVIRONMENTAL: {
    COLOR: "#B8E389",
    BACKGROUNDCOLOR: "#F1F8EC",
  },
  MULTIDIMENSIONAL: {
    COLOR: "#DDEFEC",
    BACKGROUNDCOLOR: "#E9FAFB",
  },
  SOCIAL: {
    COLOR: "#FCDA96",
    BACKGROUNDCOLOR: "#FFF8E8",
  },
  GOVERNANCE: {
    COLOR: "#F187B4",
    BACKGROUNDCOLOR: "#f9d2e2",
  },
};

export const meterColors = [
  "#092135",
  "#0e3150",
  "#185286",
  "#206fb6",
  "#7ea0c4",
  "#abc1d9",
  "#c5d4e5",
  "#e8eef4",
];

export const targetYear = [
  2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036,
  2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049,
  2050,
];

export const USER_REGISTRATION_FIELDS = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  USERNAME: "userName",
  PHONE: "phone",
};

export const REGISTER_ENTITY_FORM_FIELDS = {
  ENTITY_NAME: "entityName",
  REGISTRATION_NO: "registrationNo",
  ADDRESS: "address1",
  COUNTRY: "country",
  WEBSITE: "website",
  TAX_ID: "taxId",
  LOGO: "logo",
  COMMENT: "comment",
  SUSTAINABILITY_TYPE: "sustainabilityType",
  SUSTAINABILITY_URL: "sustainabilityUrl",
  REPORTHEADING: "reportHeading",
  SUSTAINABILITY_REPORT: "sustainabilityReport",
  USER_REGISTRATION: USER_REGISTRATION_FIELDS,
};

export const CHEMICAL_COMPOSITION_PATTERN = /^([<>]=?)?\d+(\.\d+)?$/;

export class MockMyService {
  public data: IUser;
  refreshListing = new BehaviorSubject(null);
  menuItemsSub = new BehaviorSubject(null);
  public getLoggedInuserDetails() {
    this.data = {
      id: 3029803,
      firstName: "Platform Admin",
      lastName: "User 001",
      city: null,
      country: null,
      userName: "cmtkmarkit.manager2@gmail.com",
      entityId: null,
      entityName: null,
      status: "ACTIVE",
      platformAdmin: true,
      entityAdmin: false,
      write: true,
      overwrite: true,
      read: true,
      append: true,
      export: true,
      stakeholderId: null,
      stakeHolder: "PRODUCER",
      stakeHolderName: null,
      lastLogin: null,
      termsAccepted: true,
      reviewRequired: false,
    };
    return this.data;
  }

  public getUserDetails() {
    let userDetails = {
      id: 19624723,
      firstName: "Copper Producer",
      lastName: "Limited",
      city: null,
      country: null,
      userName: "lmeplatform58+qa.producer.copper@gmail.com",
      partyId: 5,
      entityId: 5012,
      entityName: "Copper Limited",
      status: "ACTIVE",
      platformAdmin: false,
      entityAdmin: true,
      write: true,
      overwrite: true,
      read: true,
      append: true,
      export: true,
      stakeholderId: 1,
      stakeHolder: "PRODUCER",
      stakeHolderName: "Producer",
      lastLogin: null,
      termsAccepted: true,
      reviewRequired: false,
      redactAppend: false,
    };
    return of(userDetails);
  }

  public getStats() {
    return of({
      entityStatistics: null,
      userStatistics: {
        entityAdminCount: 7,
        individualUserCount: 3,
        suspendUserCount: 0,
      },
    });
  }
  showMessage(data) {
    console.log(data);
  }
}

export class MockCreateImportService {
  public data: IUser;
  refresh = new BehaviorSubject("refresh");
  refreshListing = new BehaviorSubject("refresh");
  hideUploadDialog = new BehaviorSubject(true);
  unlock = new BehaviorSubject(true);

  showMessage(data) {
    console.log(data);
  }

  getTableConfig(type) {
    let data = null;
    console.log(" ");
    if (type === "ACTIVITY_LOGS") {
      data = {
        config: {
          rowsPerPage: 10,
          pagination: true,
          columns: [
            {
              order: 1,
              field: "actionDate",
              header: "Date/TimeStamp",
              width: "160px",
              sortField: "",
              dateTime: true,
            },
            {
              order: 2,
              field: "stakeHolder",
              header: "Stakeholder Type",
              width: "120px",
              sortField: null,
              dateTime: null,
            },
            {
              order: 3,
              field: "entityName",
              header: "Company Legal Name",
              width: "160px",
              sortField: null,
              dateTime: null,
            },
            {
              order: 4,
              field: "actionFor",
              header: "User",
              width: "180px",
              sortField: null,
              dateTime: null,
            },
            {
              order: 5,
              field: "action",
              header: "Action",
              width: "150px",
              sortField: null,
              dateTime: null,
            },
          ],
        },
        filterConfig: {
          searchBox: true,
          coaBulkSearch: null,
          coaBulkSearchPlaceHolder: null,
          passportIdFilter: null,
          passportIdSearchPlaceHolder: null,
          passportIdSearchToolTipText: null,
          searchButtons: true,
          disclosures: null,
          dateRange: true,
          dateRangeLabel: null,
          searchBoxPlaceholder: null,
          filters: [
            {
              order: 4,
              placeholder: "Users",
              itemsType: "users",
              selectableGroup: true,
              multiple: false,
              closeOnSelect: true,
              ngModel: "user",
              notFoundText: null,
            },
            {
              order: 1,
              placeholder: "Action",
              itemsType: "actions",
              selectableGroup: true,
              multiple: false,
              closeOnSelect: true,
              ngModel: "action",
              notFoundText: null,
            },
            {
              order: 2,
              placeholder: "Company",
              itemsType: "companyTypeActiveSuspended",
              selectableGroup: true,
              multiple: false,
              closeOnSelect: true,
              ngModel: "entityId",
              notFoundText: null,
            },
            {
              order: 3,
              placeholder: "Stakeholder Type",
              itemsType: "stakeHolderType",
              selectableGroup: true,
              multiple: false,
              closeOnSelect: true,
              ngModel: "stakeHolder",
              notFoundText: null,
            },
          ],
        },
        export: true,
      };
    }
    return data;
  }
}

export class MockDashboardService {
  public getsuspendedStats() {
    return [
      {
        id: 945,
        entityName: "JBM International Ltd -rename 3",
        stakeHolderName: "Producer",
        username: "sahilgrwl15@gmail.cjuyt",
        updatedBy: "purnima.burnwal@ihsmarkit.com",
      },
    ];
  }

  public getsuspendedUsers() {
    return [];
  }
}

export class Routerstub {
  public getcurrentnavigation() {
    return {
      extras: {
        state: {
          locationid: "12",
          locationname: "home-page",
        },
      },
    };
  }
}
