import { HttpClient, HttpRequest } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CoreService } from "@core/services/core.service";
import { LookupService } from "@core/services/lookup.service";
import { environment } from "@env/environment";
import { CreateImportService } from "@module/create-import/services/create-import.service";
import { EntityService } from "@module/platform-admin/services/entity.service";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { SharedService } from "@shared/services/shared.service";
import { email } from "app/app.const";
import { MessageService } from "primeng/api";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
  providers: [MessageService],
})
export class RegistrationOutsideComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[];
  entitiesSub = new BehaviorSubject<any>(null);

  higherPermissionForm = new UntypedFormGroup({});
  higherPermissionModel: any = {};
  higherPermissionFields: FormlyFieldConfig[];

  private _destroy$ = new Subject<any>();
  stakeHolder: any;
  entities: any;
  email: string;
  fileLogo: any;
  sustainabilityReport: any;
  entityStatus: string;
  userTypeDialog: boolean;
  selectedUserType: string;
  emailLink: string;
  entityId: any;
  constructor(
    private lookUpService: LookupService,
    private sharedService: SharedService,
    private router: Router,
    private entityService: EntityService,
    private coreService: CoreService,
    private http: HttpClient,
    private messageService: MessageService,
    private createImportService: CreateImportService
  ) {}

  ngOnInit() {
    this.initForm();
    this.email = email;
    this.emailLink = `mailto: ${email}`;
  }

  downloadTerms(event) {
    event.preventDefault();
    window.location.href = `https://${window.location.hostname}/api/doc-upload-service/resources/download?type=TOU`;
  }

  downloadEula(event) {
    event.preventDefault();
    window.location.href = `https://${window.location.hostname}/api/doc-upload-service/resources/download?type=EULA`;
  }

  submit() {
    if (this.form.valid) {
      if (this.fileLogo && !this.isFileImage(this.fileLogo)) {
        return this.invalidLogoFile();
      }

      if (this.sustainabilityReport) {
        let ext = this.sustainabilityReport.name.match(/\.([^\.]+)$/)[1];

        if (ext.toLowerCase() !== "pdf") {
          this.messageService.clear();
          this.messageService.add({
            key: "reg",
            severity: "error",
            summary: "Error",
            detail:
              "Please upload pdf for sustainability report, file type not allowed.",
            sticky: true,
          });

          this.resetSustainabilityField();
          return false;
        }
      }

      let postData = this.model;
      postData.stakeHolderId = this.form.get("stakeHolderId").value.id;
      postData.declaration = true;
      postData.status = "PENDING_APPROVAL";
      if (this.entityStatus === "ACTIVE") {
        postData.country = "";
      } else {
        postData.country =
          this.stakeHolder === "METAL_OWNER"
            ? this.model.country.name
            : this.model.country;
      }
      postData.entityName =
        typeof this.model.entityName === "object"
          ? this.model.entityName.entityName
          : this.model.entityName;
      postData.userRegistration.country =
        typeof postData.userRegistration.country === "string"
          ? postData.userRegistration.country
          : this.model.userRegistration.country.name;
      if (this.entityStatus) {
        postData.isUserRegistrationOnly =
          this.entityStatus === "PRE_REGISTER" ? false : true;
      } else {
        postData.isUserRegistrationOnly = false;
      }

      if (this.entityStatus !== "PRE_REGISTER") {
        postData.id = this.entityId;
        delete postData.status;
      }
      this.sharedService
        .createEntity(postData)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (res) => {
            this.checkIfFileLogo(res);
          },
          (err) => {
            this.handleError(err);
          }
        );
    }
  }

  initHigherPermissionFields() {
    this.higherPermissionFields = [
      {
        fieldGroupClassName: "",
        fieldGroup: [
          {
            type: "textarea",
            key: "higherPermissionReason",
            templateOptions: {
              label:
                "Please indicate your reasoning for needing a higher permission account",
              rows: 5,
              maxLength: 500,
              required: true,
            },
          },
        ],
      },
    ];
  }

  registerUser() {
    if (this.higherPermissionForm.valid) {
      console.log(this.model);
      console.log(this.higherPermissionModel);
    }
  }

  invalidLogoFile() {
    this.messageService.clear();
    this.messageService.add({
      key: "reg",
      severity: "error",
      summary: "Error",
      detail: `Selected FileType is not supported. Upload jpg, jpeg or png.`,
      sticky: true,
    });
    (document.getElementById("fileName") as HTMLInputElement).value = null;
    this.fileLogo = null;
    this.form.get("logo").setValue(null);
    return false;
  }

  checkIfFileLogo(res) {
    if (this.fileLogo || this.sustainabilityReport) {
      this.fileLogo && this.uploadLogo(res);
      if (this.sustainabilityReport && !this.fileLogo) {
        this.uploadSustainabilityReport(res);
      }
    } else {
      return this.router.navigate(["/"], {
        state: { register: true },
      });
    }
  }

  handleError(err) {
    if (
      err.status === 400 &&
      err.error.message.includes("This application has already been received")
    ) {
      this.form.get("userRegistration.userName").setErrors({
        serverError: { message: "Email address already exists" },
      });
    } else if (
      err.status === 400 &&
      err.error.message.includes("Entity with provided name already exist")
    ) {
      this.form.get("entityName").setErrors({
        serverError: {
          message: `Company already registered, please contact us at ${email}`,
        },
      });
    } else {
      this.messageService.clear();
      this.messageService.add({
        key: "reg",
        severity: "error",
        summary: "Error",
        detail: err.error.message,
        sticky: true,
      });
    }
  }

  uploadLogo(res) {
    const formData = new FormData();
    formData.append("file", this.fileLogo, this.fileLogo.name);

    const uploadReq = new HttpRequest(
      "POST",
      `${environment.apiUrl}/doc-upload-service/documentfiles/public/uploadLogo?entityId=${res.id}&user=${this.model.userRegistration.userName}`,
      formData
    );

    this.http.request(uploadReq).subscribe(
      (event) => {
        if (event.type === 4 && !this.sustainabilityReport) {
          return this.router.navigate(["/"], {
            state: { register: true },
          });
        } else if (event.type === 4 && this.sustainabilityReport) {
          this.uploadSustainabilityReport(res);
        }
      },
      (err) => {
        let errorMessage = err.error.message.replace(
          "com.markit.cmtk.exception.ServiceException:",
          ""
        );
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail: errorMessage,
        });
      }
    );
  }

  uploadSustainabilityReport(res) {
    const formData = new FormData();
    formData.append(
      "file",
      this.sustainabilityReport,
      this.sustainabilityReport.name
    );

    const uploadReq = new HttpRequest(
      "POST",
      `${environment.apiUrl}/doc-upload-service/documentfiles/public/uploadLogo?entityId=${res.id}&user=${this.model.userRegistration.userName}&docType=SUSTAINABILITY_REPORT`,
      formData
    );

    this.http.request(uploadReq).subscribe(
      (event) => {
        if (event.type === 4) {
          return this.router.navigate(["/"], {
            state: { register: true },
          });
        }
      },
      (err) => {
        let errorMessage = err.error.message.replace(
          "com.markit.cmtk.exception.ServiceException:",
          ""
        );
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail: errorMessage,
        });
      }
    );
  }

  onStakeHolderChange() {
    this.resetContactInfo();
    this.resetContactSection();
    getField("taxId", this.fields).hideExpression = false;
    getField("website", this.fields).hideExpression = false;
    getField("registrationNo", this.fields).hideExpression = false;
    getField("address1", this.fields).hideExpression = false;
    getField("country", this.fields).hideExpression = false;
    getField("logo", this.fields).hideExpression = false;
    this.form.get("address1") && this.form.get("address1").setValue("");
    this.form.get("country") && this.form.get("country").setValue("");
    this.form.get("entityName") && this.form.get("entityName").setValue(null);
    this.stakeHolder = this.model.stakeHolderId.stakeholderId;
    this.hideShowsustainabilityType();
    if (
      this.stakeHolder === "PRODUCER" ||
      this.stakeHolder === "LME_MEMBER" ||
      this.stakeHolder === "WAREHOUSE_OPERATOR"
    ) {
      this.fields[0].fieldGroup[1].hideExpression = true;
      this.fields[0].fieldGroup[2].hideExpression = false;
      this.fields[0].fieldGroup[5].hideExpression = false;
      this.fields[0].fieldGroup[6].hideExpression = true;
      this.fields[0].fieldGroup[4].templateOptions.disabled = true;
      this.entityService
        .getCompaniesPublic(this.model.stakeHolderId.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          this.entities = res;
          this.entitiesSub.next(res);
          //this.fields[0].fieldGroup[2].templateOptions.options = [...res];
        });
    } else {
      this.fields[0].fieldGroup[2].hideExpression = true;
      this.fields[0].fieldGroup[1].hideExpression = false;
      this.fields[0].fieldGroup[5].hideExpression = true;
      this.fields[0].fieldGroup[6].hideExpression = false;
      this.fields[0].fieldGroup[4].templateOptions.disabled = false;
      this.entityService
        .getCompaniesPublic(this.model.stakeHolderId.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          this.entities = res;
          this.entitiesSub.next(res);
        });
    }
  }

  resetContactInfo() {
    this.form.get("userRegistration.firstName").setValue(null);
    this.form.get("userRegistration.lastName").setValue(null);
    this.form.get("userRegistration.userName").setValue(null);
    this.form.get("userRegistration.phone").setValue(null);
    this.form.get("userRegistration.country").setValue(null);
    this.form.get("userRegistration.city").setValue(null);
    this.form.get("userRegistration.comment").setValue(null);
  }

  hideShowsustainabilityType() {
    if (
      this.stakeHolder === "METAL_OWNER" ||
      this.stakeHolder === "LME_MEMBER" ||
      this.stakeHolder === "WAREHOUSE_OPERATOR"
    ) {
      getField("sustainabilityType", this.fields).hideExpression = true;
      getField("sustainabilityUrl", this.fields).hideExpression = true;
      getField("sustainabilityReport", this.fields).hideExpression = true;
      getField("reportHeading", this.fields).hideExpression = true;
      (
        document.getElementById("sustainabilityReport") as HTMLInputElement
      ).value = null;
      this.sustainabilityReport = null;
      this.form.get("sustainabilityReport") &&
        this.form.get("sustainabilityReport").setValue(null);
      this.form.get("sustainabilityUrl") &&
        this.form.get("sustainabilityUrl").setValue(null);
    } else {
      getField("sustainabilityType", this.fields).hideExpression = false;
      getField("reportHeading", this.fields).hideExpression = false;
    }
  }

  addLogo(event) {
    event.stopImmediatePropagation();
    this.fileLogo = event.target.files[0];
    const fsize = this.fileLogo.size;
    const file = Math.round(fsize / 1024);

    if (file > 30000) {
      this.messageService.clear();
      this.messageService.add({
        key: "reg",
        severity: "error",
        summary: "Error",
        detail: `${this.fileLogo.name} cannot be greater than 30MB in size.`,
        sticky: true,
      });
      setTimeout(() => {
        this.resetLogoField();
      }, 0);
      return false;
    }
    this.createImportService
      .validateDocument(this.fileLogo)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          if (!res) {
            this.messageService.clear();
            this.messageService.add({
              key: "reg",
              severity: "error",
              summary: "Error",
              detail: `${this.fileLogo.name} is password protected / encrypted, please upload a different file`,
              sticky: true,
            });

            return this.resetLogoField();
          }
        },
        (err) => {
          this.messageService.clear();
          this.messageService.add({
            key: "reg",
            severity: "error",
            summary: "Error",
            detail: err.error.message,
            sticky: true,
          });

          this.resetLogoField();
        }
      );
  }

  resetLogoField() {
    (document.getElementById("fileName") as HTMLInputElement).value = null;
    this.fileLogo = null;
    this.form.get("logo").setValue(null);
    return false;
  }

  addSustainabilityDocument(event) {
    event.stopImmediatePropagation();
    this.sustainabilityReport = event.target.files[0];
    const fsize = this.sustainabilityReport.size;
    const file = Math.round(fsize / 1024);

    if (file > 30000) {
      this.messageService.clear();
      this.messageService.add({
        key: "reg",
        severity: "error",
        summary: "Error",
        detail: `${this.sustainabilityReport.name} cannot be greater than 30MB in size.`,
        sticky: true,
      });
      setTimeout(() => {
        this.resetSustainabilityField();
      }, 0);
      return false;
    }
    this.createImportService
      .validateDocument(this.sustainabilityReport, "SUSTAINABILITY_REPORT")
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          if (!res) {
            this.messageService.clear();
            this.messageService.add({
              key: "reg",
              severity: "error",
              summary: "Error",
              detail: `${this.sustainabilityReport.name} is password protected / encrypted, please upload a different file`,
              sticky: true,
            });
            return this.resetSustainabilityField();
          }
        },
        (err) => {
          this.messageService.clear();
          this.messageService.add({
            key: "reg",
            severity: "error",
            summary: "Error",
            detail: err.error.message,
            sticky: true,
          });

          this.resetSustainabilityField();
        }
      );
  }

  resetSustainabilityField() {
    (
      document.getElementById("sustainabilityReport") as HTMLInputElement
    ).value = null;
    this.sustainabilityReport = null;
    this.form.get("sustainabilityReport").setValue(null);
    return false;
  }

  isFileImage(file) {
    const filename = file.name;
    const extension = filename.substr(filename.lastIndexOf("."));

    const allowedExtensionsRegx = /(\.jpg|\.jpeg|\.png)$/i;

    const isAllowed = allowedExtensionsRegx.test(extension);

    if (isAllowed) {
      return true;
    } else {
      return false;
    }
  }

  companyChange() {
    this.resetContactInfo();
    this.entityStatus = this.model.entityName.status;
    let status = this.model.entityName.entityRegistrationStatus;
    if (
      status === "NEED_MORE_INFO" ||
      status === "PENDING_APPROVAL" ||
      status === "REJECTED" ||
      this.entityStatus === "SUSPENDED"
    ) {
      setTimeout(() => {
        this.form.get("entityName") &&
          this.form.get("entityName").setValue(null);
        this.form.get("address1") && this.form.get("address1").setValue(null);
        this.form.get("country") && this.form.get("country").setValue(null);
        this.messageService.clear();
        this.messageService.add({
          key: "reg",
          severity: "error",
          summary: "Error",
          detail: `Entity registration is currently 'Under Review', for any queries, please contact LME Platform Administrator at ${email}`,
          sticky: true,
        });
      });

      return false;
    }
    this.entityId = this.model.entityName.id;
    if (this.entityStatus === "PRE_REGISTER") {
      getField("taxId", this.fields).hideExpression = false;
      getField("website", this.fields).hideExpression = false;
      getField("registrationNo", this.fields).hideExpression = false;
      getField("address1", this.fields).hideExpression = false;
      getField("country", this.fields).hideExpression = false;
      getField("logo", this.fields).hideExpression = false;
      setTimeout(() => {
        this.form.get("address1").setValue(this.model.entityName.address1);
        this.form.get("country").setValue(this.model.entityName.country);
      });
      this.resetContactSection();
    } else {
      getField("taxId", this.fields).hideExpression = true;
      getField("website", this.fields).hideExpression = true;
      getField("registrationNo", this.fields).hideExpression = true;
      getField("address1", this.fields).hideExpression = true;
      getField("country", this.fields).hideExpression = true;
      getField("logo", this.fields).hideExpression = true;
      this.fields[2].templateOptions.label = "User Details";
      this.fields[2].fieldGroup[6].templateOptions.label =
        "Please include your job title and describe the reason for access so your company admin can determine the permission type(s) required for your access. Please also include  if your main use case is for sustainability or certificates of analysis.";
      if (this.stakeHolder === "PRODUCER") {
        getField("sustainabilityType", this.fields).hideExpression = true;
        getField("reportHeading", this.fields).hideExpression = true;
      }
      this.fields[1].template =
        '<h4 class="col-12 badge-company mt-3 mb-3">Individual User registration – Contact Information </h4>';
    }
  }

  resetContactSection() {
    this.fields[2].templateOptions.label = "Main Contact";
    this.fields[1].template =
      '<h4 class="col-12 badge-company mt-3 mb-3">Main Contact – entity registration</h4>';
    this.fields[2].fieldGroup[6].templateOptions.label =
      "Please include your job title and reason for registering as the main contact for the company.";
    if (this.stakeHolder === "PRODUCER") {
      getField("sustainabilityType", this.fields).hideExpression = false;
      getField("reportHeading", this.fields).hideExpression = false;
    }
  }

  metalOwnerSelect(field, event, value) {
    this.entityStatus = this.model.entityName.status;
    getField("taxId", this.fields).hideExpression = true;
    getField("website", this.fields).hideExpression = true;
    getField("registrationNo", this.fields).hideExpression = true;
    getField("address1", this.fields).hideExpression = true;
    this.fields[0].fieldGroup[5].hideExpression = true;
    this.fields[0].fieldGroup[6].hideExpression = true;
    getField("country", this.fields).hideExpression = true;
    getField("logo", this.fields).hideExpression = true;
    this.fields[2].templateOptions.label = "User Details";
    this.fields[2].fieldGroup[6].templateOptions.label =
      "Please include your job title and describe the reason for access so your company admin can determine the permission type(s) required for your access. Please also include  if your main use case is for sustainability or certificates of analysis.";
    this.fields[1].template =
      '<h4 class="col-12 badge-company mt-3 mb-3">Individual User registration – Contact Information </h4>';
    /* this.form.get("address1").setValue(this.model.entityName.address1);
      this.form.get("country").setValue(this.model.entityName.country); */
  }

  entityChangeMetalOwner() {
    if (typeof this.model.entityName !== "object") {
      getField("taxId", this.fields).hideExpression = false;
      getField("website", this.fields).hideExpression = false;
      getField("registrationNo", this.fields).hideExpression = false;
      getField("address1", this.fields).hideExpression = false;
      this.fields[0].fieldGroup[2].hideExpression = true;
      this.fields[0].fieldGroup[1].hideExpression = false;
      this.fields[0].fieldGroup[5].hideExpression = true;
      this.fields[0].fieldGroup[6].hideExpression = false;
      getField("logo", this.fields).hideExpression = false;
      this.fields[2].templateOptions.label = "User Details";
      this.fields[2].fieldGroup[6].templateOptions.label =
        "Please include your job title and reason for registering as the main contact for the company.";
      this.fields[1].template =
        '<h4 class="col-12 badge-company mt-3 mb-3">Main Contact – entity registration</h4>';
    }
  }

  filterMetalOwner(field, event) {
    const value = field.formControl.value;
    let filteredEntity = this.entities.filter((element) => {
      return (
        element.entityName.toLowerCase() === value.toLowerCase() &&
        element.status === "ACTIVE"
      );
    });

    if (filteredEntity.length) {
      this.entityStatus = filteredEntity[0] ? filteredEntity[0].status : "";
      this.entityId = filteredEntity[0].id;
      getField("taxId", this.fields).hideExpression = true;
      getField("website", this.fields).hideExpression = true;
      getField("registrationNo", this.fields).hideExpression = true;
      getField("address1", this.fields).hideExpression = true;
      this.fields[0].fieldGroup[5].hideExpression = true;
      this.fields[0].fieldGroup[6].hideExpression = true;
      getField("country", this.fields).hideExpression = true;
      getField("logo", this.fields).hideExpression = true;
      this.fields[2].templateOptions.label = "User Details";
      this.fields[2].fieldGroup[6].templateOptions.label =
        "Please include your job title and describe the reason for access so your company admin can determine the permission type(s) required for your access. Please also include  if your main use case is for sustainability or certificates of analysis.";
      this.fields[1].template =
        '<h4 class="col-12 badge-company mt-3 mb-3">Individual User registration – Contact Information </h4>';
    } else {
      getField("taxId", this.fields).hideExpression = false;
      getField("website", this.fields).hideExpression = false;
      getField("registrationNo", this.fields).hideExpression = false;
      getField("address1", this.fields).hideExpression = false;
      this.fields[0].fieldGroup[2].hideExpression = true;
      this.fields[0].fieldGroup[1].hideExpression = false;
      this.fields[0].fieldGroup[5].hideExpression = true;
      this.fields[0].fieldGroup[6].hideExpression = false;
      getField("logo", this.fields).hideExpression = false;
      this.fields[2].templateOptions.label = "User Details";
      this.fields[2].fieldGroup[6].templateOptions.label =
        "Please include your job title and reason for registering as the main contact for the company.";
      this.fields[1].template =
        '<h4 class="col-12 badge-company mt-3 mb-3">Main Contact – entity registration</h4>';
    }
  }

  updateSustainabilityType() {
    if (this.model.sustainabilityType === "url") {
      getField("sustainabilityUrl", this.fields).hideExpression = false;
      getField("sustainabilityReport", this.fields).hideExpression = true;
      (
        document.getElementById("sustainabilityReport") as HTMLInputElement
      ).value = null;
      this.sustainabilityReport = null;
      this.form.get("sustainabilityReport") &&
        this.form.get("sustainabilityReport").setValue(null);
    } else {
      getField("sustainabilityUrl", this.fields).hideExpression = true;
      getField("sustainabilityReport", this.fields).hideExpression = false;
      this.form.get("sustainabilityUrl") &&
        this.form.get("sustainabilityUrl").setValue(null);
    }
  }

  initForm() {
    this.fields = [
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            className: "col-md-6 col-sm-12",
            type: "dict-select-template",
            key: "stakeHolderId",
            wrappers: ["form-field"],
            templateOptions: {
              label: "Stakeholder Type",
              required: true,
              multiple: false,
              source: this.lookUpService.getStakeHoldersPublic(),
              bindLabel: "name",
              bindValue: "id",
              change: (event) => this.onStakeHolderChange(),
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "entityName",
            hideExpression: true,
            templateOptions: {
              label: "Company Legal Name (Maximum 1000 characters allowed)",
              maxLength: 1000,
              required: true,
              blur: (field, $event) => this.filterMetalOwner(field, $event),
            },
          },
          /*{
            className: "col-md-6 col-sm-12",
            type: "autocomplete-input",
            key: "entityName",
            hideExpression: true,
            templateOptions: {
              label: "Company Legal Name",
              required: true,
              list: this.entitiesSub,
              select: (field, $event, value) =>
                this.metalOwnerSelect(field, $event, value),
              onblur: (field, $event, value) => this.entityChangeMetalOwner(),
            },
          },*/
          {
            className: "col-md-6 col-sm-12",
            type: "dict-select",
            key: "entityName",
            wrappers: ["form-field"],
            templateOptions: {
              label: "Company Legal Name",
              required: true,
              source: this.entitiesSub,
              bindLabel: "entityName",
              //bindValue: "id",
              change: (field, $event) => this.companyChange(),
              expressionProperties: {
                "templateOptions.disabled": "!model.stakeHolderId",
              },
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "registrationNo",
            wrappers: ["form-field-horizontal"],
            templateOptions: {
              maxLength: 100,
              label: "LEI/Business Identifier (100 characters allowed)",
              info: "Please provide your company registration number",
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "address1",
            templateOptions: {
              maxLength: 1000,
              label: "Address (Maximum 1000 characters allowed)",
              required: true,
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (
                  model.stakeHolderId &&
                  model.stakeHolderId.stakeholderId !== "METAL_OWNER"
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "country",
            templateOptions: {
              label: "Country/Territory",
              required: true,
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (
                  model.stakeHolderId &&
                  model.stakeHolderId.stakeholderId !== "METAL_OWNER"
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "dict-select",
            key: "country",
            hideExpression: true,
            templateOptions: {
              label: "Country/Territory",
              required: true,
              source: this.lookUpService.getCountries(),
              bindLabel: "name",
              multiple: false,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "website",
            templateOptions: {
              label: "Website",
              required: false,
              pattern:
                /[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid url. Please make sure your url starts with "http://" or "https://".`,
              },
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "taxId",
            templateOptions: {
              label: "Tax Identification Number/Tax ID",
              required: false,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "upload",
            key: "logo",
            templateOptions: {
              label: "Logo",
              accept: "image/png, image/jpg, image/jpeg",
              preview: this.entityStatus,
              documentId: this.model.documentIdLogo,
              change: (field, event) => this.addLogo(event),
            },
            expressionProperties: {
              "templateOptions.documentId": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (this.model.documentIdLogo) {
                  return this.model.documentIdLogo;
                }
              },
              "templateOptions.preview": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (this.model.documentIdLogo) {
                  return this.entityStatus;
                }
              },
            },
          },
        ],
      },
      {
        className: "",
        template: `<h4 class="col-12 badge-company mt-3 mb-3">Contact Information</h4>`,
      },
      {
        fieldGroupClassName: "row",
        key: "userRegistration",
        wrappers: ["app-formly-fieldset"],
        templateOptions: {
          label: "Main Contact",
        },
        fieldGroup: [
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "firstName",
            templateOptions: {
              maxLength: 100,
              label: "First Name",
              required: true,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "lastName",
            templateOptions: {
              label: "Last Name",
              maxLength: 100,
              required: true,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "userName",
            templateOptions: {
              label: "Email/Username",
              required: true,
              pattern:
                /^(([^!#^<>()[\]\\.,;:\s@\"]+(\.[^!#^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid email address`,
              },
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "phone",
            templateOptions: {
              type: "text",
              min: 0,
              label: "Phone",
              pattern: /^\d{10}$/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid phone number`,
              },
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "dict-select",
            key: "country",
            templateOptions: {
              label: "Country",
              required: true,
              source: this.lookUpService.getCountries(),
              bindLabel: "name",
              multiple: false,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "city",
            templateOptions: {
              label: "City (Maximum 255 characters allowed)",
              maxLength: 255,
              required: true,
            },
          },
          {
            className: "col-md-12 col-sm-12",
            type: "custom-textarea",
            key: "comment",
            templateOptions: {
              label:
                "Please include your job title and reason for registering as the main contact for the company.",
              rows: 5,
              maxLength: 500,
              required: true,
            },
          },
        ],
      },
      {
        className: "",
        template: `<h4 class="col-12 badge-company mt-3 mb-3">Sustainability Report</h4>`,
        hideExpression: true,
        key: "reportHeading",
      },
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            className: "col-sm-12 col-md-6 sustainability-report",
            key: "sustainabilityType",
            type: "radio",
            hideExpression: true,
            templateOptions: {
              type: "radio",
              label: "",
              required: false,
              name: "sustainabilityType",
              options: [
                { value: "URL", key: "url" },
                { value: "Attachment", key: "report" },
              ],
              change: (field, event) => this.updateSustainabilityType(),
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "sustainabilityUrl",
            hideExpression: true,
            templateOptions: {
              label: "",
              placeholder: "Url",
              required: false,
              pattern:
                /[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `${field.formControl.value} is not a valid url. Please make sure your url starts with "http://" or "https://".`,
              },
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "upload",
            key: "sustainabilityReport",
            hideExpression: true,
            templateOptions: {
              label: "",
              accept: "application/pdf",
              id: "sustainabilityReport",
              change: (field, event) => this.addSustainabilityDocument(event),
            },
          },
        ],
      },
    ];
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}

function getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
  for (let i = 0, len = fields.length; i < len; i++) {
    const f = fields[i];
    if (f.key === key) {
      return f;
    }

    if (f.fieldGroup && !f.key) {
      const cf = getField(key, f.fieldGroup);
      if (cf) {
        return cf;
      }
    }
  }
}
