import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { CoreService } from "@core/services/core.service";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DisclosureDetailViewGuard implements CanActivate {
  constructor(private coreService: CoreService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const stats = this.coreService.getProducerProfile();
    if (stats) {
      const publicViewEnabledSplit = stats.publicViewTabsEnabled.split(",");
      if (publicViewEnabledSplit[2] === "true") {
        return of(true);
      } else {
        this.router.navigate(["/"]);
        return of(false);
      }
    } else {
      return this.coreService.getStats().pipe(
        map((user) => {
          this.coreService.producerProfile = user;
          const publicViewEnabledSplit =
            user["publicViewTabsEnabled"].split(",");
          if (publicViewEnabledSplit[2] === "true") {
            return of(true);
          } else {
            this.router.navigate(["/"]);
            return of(false);
          }
        })
      );
    }
  }
}
