import { NgSelectModule } from "@ng-select/ng-select";
import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AppWrapperComponent } from "./components/app-wrapper/app-wrapper.component";
import { SharedModule } from "@shared/shared.module";
import { ToastModule } from "primeng/toast";
import { DialogModule } from "primeng/dialog";
import { TermsAndConditionsComponent } from "./components/terms-and-conditions/terms-and-conditions.component";
import { SustainabilityDisclosuresPublicWrapperComponent } from "./components/sustainability-disclosures-public-wrapper/sustainability-disclosures-public-wrapper.component";
import { UserPublicViewComponent } from "./components/user-public-view/user-public-view.component";
@NgModule({
  declarations: [
    AppWrapperComponent,
    TermsAndConditionsComponent,
    SustainabilityDisclosuresPublicWrapperComponent,
    UserPublicViewComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,
    ToastModule,
    DialogModule,
  ],
  providers: [DatePipe],
})
export class CoreModule {}
