import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { map } from "rxjs";

@Component({
  selector: "autocomplete-input",
  template: `
    <p-autoComplete
      [showEmptyMessage]="false"
      (completeMethod)="filterEntity($event)"
      [suggestions]="filteredEntities"
      field="entityName"
      [minLength]="1"
      [formControl]="formControl"
      (onSelect)="to.select()"
      (onBlur)="to.onblur()"
    ></p-autoComplete>
  `,
})
export class FormlyAutoCompleteInput extends FieldType implements OnInit {
  filteredEntities: any[];
  entities: any;
  constructor() {
    super();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.to.list
      .pipe(
        map((data: any) => {
          return data;
        })
      )
      .subscribe((data) => {
        if (data) {
          this.entities = data;
        }
      });
  }

  get currValue() {
    return this.model && this.model[this.field.key as string];
  }

  filterEntity(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.entities.length; i++) {
      let entity = this.entities[i];
      if (entity.entityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(entity);
      }
    }

    this.filteredEntities = filtered;
  }
}
