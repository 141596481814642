import { ITableViewConfig } from "@shared/models/table-view.model";

export const notifListTableConfig: ITableViewConfig = {
  rowsPerPage: 10,
  columns: [
    {
      field: "message",
      header: "Message",
      width: "200px",
    },
    {
      field: "requestedBy",
      header: "From",
      width: "180px",
    },
    {
      field: "requestedOn",
      header: "Date/Timestamp",
      width: "160px",
      dateTimeStamp: true,
    },
  ],
};

export const suspisiousNotifListTableConfig: ITableViewConfig = {
  rowsPerPage: 10,
  columns: [
    {
      field: "companyName",
      header: "Company Name",
      width: "200px",
    },
    {
      field: "pcrs",
      header: "Pcr(s)",
      width: "180px",
    },
  ],
};
