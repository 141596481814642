export const ROWS_PER_PAGE = 20;
export interface ITableViewConfig {
  dataKey?: any;
  uri?: string;
  translationPrefix?: string;
  paginator?: boolean;
  rowsPerPage?: number;
  className?: string;
  editService?: string;
  validateService?: string;
  updatePage?: string;
  width?: number;
  columns: ITableViewColumn[];
  fileName?: string;
  filter?: boolean;
  headerCheckboxDisabled?: boolean;
  filterListsModel?: IFilterModel[];
}

export interface IFilterModel {
  placeholder?: string;
  itemsType?: string;
  multiple?: boolean;
  selectableGroup?: boolean;
  closeOnSelect?: boolean;
  ngModel?: string;
}

export interface ITableViewColumn {
  expandIcon?: boolean;
  checkboxSelect?: boolean;
  expandCollapse?: boolean;
  expandSectionDataRoot?: any;
  expandSectionData?: any;
  expandSectionTableHeader?: any;
  required?: boolean;
  field?: string;
  header?: string;
  width?: string;
  sortField?: string;
  id?: string;
  maxWidth?: string;
  templateRef?: any;
  translate?: boolean;
  translationPrefix?: string;
  dateTime?: boolean;
  dateTimeStamp?: boolean;
  editable?: boolean;
  key?: string;
  className?: string;
  defaultValue?: any;
  fieldType?: string;
  inputType?: any;
  editRowField?: boolean;
  newRowField?: boolean;
  source?: string;
  placeholder?: string;
  items?: any;
  bindLabel?: string;
  bindValue?: string;
  dataProvided?: boolean;
  defaultDropDownValue?: string;
  formField?: string;
  numeric?: boolean;
  arg0?: string;
  visible?: boolean;
  type?: string;
  largeText?: boolean;
  borderRight?: boolean;
  filter?: boolean;
  filterPlaceHolder?: string;
  itemsType?: string;
  docsType?: any;
}
