import {
  ActionOptionType,
  ITableFilterActionModel,
} from "@shared/models/table-filter.model";

export const newEntity = {
  id: "newEntity",
  label: "Create New Entity",
  uri: "/platform-admin/create-entity",
  type: ActionOptionType.URL,
  permission: "ACCOUNT_WRITE",
};

export const newUser = {
  id: "newUser",
  label: "Create New User",
  uri: "/platform-admin/create-user",
  type: ActionOptionType.URL,
  permission: "ACCOUNT_WRITE",
};

export const toDoList = {
  id: "toDoList",
  label: "To Do List",
  uri: "/account-management/mop-register-account/new-account-mop",
  type: ActionOptionType.URL,
  permission: "ACCOUNT_WRITE",
};

export const newRecord = {
  id: "newRecord",
  label: "Create Record",
  uri: "/account-management/mop-register-account/new-sub-account-mop",
  type: ActionOptionType.URL,
  permission: "ACCOUNT_WRITE",
};

export const approve = {
  id: "approve",
  label: "Active",
  uri: "/account-management/mop-register-account/new-sub-account-mop",
  type: ActionOptionType.BULK_APPROVE,
  permission: "ACCOUNT_WRITE",
};

export const suspend = {
  id: "suspend",
  label: "Suspend",
  uri: "/account-management/mop-register-account/new-sub-account-mop",
  type: ActionOptionType.BULK_SUSPEND,
  permission: "ACCOUNT_WRITE",
};

export const terminate = {
  id: "terminate",
  label: "Terminate",
  uri: "/account-management/mop-register-account/new-sub-account-mop",
  type: ActionOptionType.BULK_TERMINATE,
  permission: "ACCOUNT_WRITE",
};

export const activate = {
  id: "activate",
  label: "Active",
  uri: "/account-management/mop-register-account/new-sub-account-mop",
  type: ActionOptionType.BULK_ACTIVATE,
  permission: "ACCOUNT_WRITE",
};

export const recordListTableFilters: ITableFilterActionModel = {
  /* searchBox: true,
  searchButtons: true,
  searchBoxPlaceholder:
    "Search a Certificate of Analysis Reference Number or Submitted By", */
  filterListsModel: [
    /* {
      placeholder: "Stakeholder Type",
      itemsType: "stakeHolderType",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "stakeHolderTypeList",
    },
    {
      placeholder: "Metal",
      itemsType: "metal",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "METAL",
    },
    {
      placeholder: "Brand",
      itemsType: "brand",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "BRAND",
      notFoundText: "Please select a Metal to continue",
    },
    {
      placeholder: "Status",
      itemsType: "recordStatus",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "recordStatus",
    }, */
  ],
};

export const entitiesListTableFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  filterListsModel: [
    {
      placeholder: "Entity Registration Status",
      itemsType: "registrationStatus",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "registrationStatusList",
    },
    {
      placeholder: "Entity Status",
      itemsType: "status",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "statusList",
    },
  ],
  actionModel: [],
};

export const brandListTableFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  filterListsModel: [
    {
      placeholder: "Metal",
      itemsType: "metal",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "METAL",
    },
    {
      placeholder: "Brand",
      itemsType: "brand",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "BRAND",
      notFoundText: "Please select a Metal to continue",
    },
    {
      placeholder: "Active",
      itemsType: "brandStatus",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "STATUS",
    },
    {
      placeholder: "Brand Status",
      itemsType: "deliverable",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "DELIVERABLE",
    },
  ],
  actionModel: [],
};

export const activeSuspendedUserListTableFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  filterListsModel: [
    {
      placeholder: "Company",
      itemsType: "companyTypeActiveSuspended",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "companyTypeList",
    },
    {
      placeholder: "Stakeholder Type",
      itemsType: "stakeHolderType",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "stakeHolderTypeList",
    },
    {
      placeholder: "Status",
      itemsType: "status",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "statusList",
    },
  ],

  actionModel: [],
};

export const managePermissionsListTableFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  filterListsModel: [
    {
      placeholder: "Company",
      itemsType: "companyTypeActiveSuspended",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "companyTypeList",
    },
    {
      placeholder: "Stakeholder Type",
      itemsType: "stakeHolderType",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "stakeHolderTypeList",
    },
    {
      placeholder: "Status",
      itemsType: "status",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "statusList",
    },
  ],

  actionModel: [],
};

export const terminatedUserListTableFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  filterListsModel: [
    {
      placeholder: "Company",
      itemsType: "companyTypeTerminated",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "companyTypeList",
    },
    {
      placeholder: "Stakeholder Type",
      itemsType: "stakeHolderType",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "stakeHolderTypeList",
    },
  ],

  actionModel: [],
};

export const approvedRecordListingFilters: ITableFilterActionModel = {
  // searchBox: true,
  /* coaBulkSearch: true,
  coaBulkSearchPlaceHolder: "Enter COA Reference No.",
  passportIdFilter: true,
  passportIdSearchPlaceHolder: "Enter LMEpassport ID",
  passportIdSearchToolTipText: "Example: Brand|PCR, Brand|PCR, Brand|PCR",
  searchButtons: true,
  disclosures: true,
  dateRange: true,
  dateRangeLabel: "Approved Date",
  searchBoxPlaceholder:
    "Search a Certificate of Analysis Reference Number or LMEpassport ID", */
  filterListsModel: [
    /* {
      placeholder: "Company",
      itemsType: "companyTypeActiveSuspended",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "ENTITY_ID",
    },
    {
      placeholder: "Stakeholder Type",
      itemsType: "stakeHolderType",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "STAKEHOLDER_TYPE",
    },
    {
      placeholder: "Record Type",
      itemsType: "recordType",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "RECORD_TYPE",
    },
    {
      placeholder: "Metal",
      itemsType: "metal",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "METAL",
    },
    {
      placeholder: "Brand",
      itemsType: "brand",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "BRAND",
      notFoundText: "Please select a Metal to continue",
    },
    {
      placeholder: "SCR Compliant",
      itemsType: "scrCompliant",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "SCR_COMPLAINTS",
    }, */
  ],

  actionModel: [],
};

export const updateRequestsListTableFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  filterListsModel: [],

  actionModel: [],
};

export const searchRecordsListTableFilters: ITableFilterActionModel = {
  /* searchBox: false,
  searchButtons: true,
  passportIdFilter: true,
  coaBulkSearch: true, */
  // brandLoadNotRequired: true,
  filterListsModel: [
    /* {
      placeholder: "Certificate of Analysis Reference Number",
      itemsType: "coaReference",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "COA_REFERENCE",
      notFoundText: "Please search on LMEpassport ID to continue",
    },
    {
      placeholder: "Metal",
      itemsType: "metal",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "METAL",
      notFoundText: "Please search on LMEpassport ID to continue",
    },
    {
      placeholder: "Brand",
      itemsType: "brand",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "BRAND",
      notFoundText: "Please select metal to continue",
    }, */
  ],
  actionModel: [],
};

export const bulkDownloadListTableFilters: ITableFilterActionModel = {
  searchBox: false,
  coaBulkSearch: true,
  searchButtons: true,
  filterListsModel: [
    {
      placeholder: "Metal",
      itemsType: "metal",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "METAL",
    },
    {
      placeholder: "Brand",
      itemsType: "brand",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "BRAND",
      notFoundText: "Please select a Metal to continue",
    },
  ],
  actionModel: [],
};

export const recordReviewApprovalListTableFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  filterListsModel: [],

  actionModel: [],
};

export const myRecordsListTableFilters: ITableFilterActionModel = {
  /* coaBulkSearchPlaceHolder: "Enter COA Reference No.",
  passportIdSearchPlaceHolder: "Enter LMEpassport ID",
  passportIdSearchToolTipText: "Example: Brand|PCR, Brand|PCR, Brand|PCR",
  coaBulkSearch: true,
  searchButtons: true,
  disclosures: true,
  passportIdFilter: true,
  searchBoxPlaceholder:
    "Search a Certificate of Analysis Reference Number or Smelter or Country of Production", */
  filterListsModel: [
    /* {
      placeholder: "Record Type",
      itemsType: "recordType",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "recordTypes",
    },
    {
      placeholder: "Producer",
      itemsType: "producer",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "producers",
    },
    {
      placeholder: "Metal",
      itemsType: "metal",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "metals",
    },
    {
      placeholder: "Brand",
      itemsType: "brand",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "brands",
      notFoundText: "Please select a Metal to continue",
    },
    {
      placeholder: "Shape",
      itemsType: "shape",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "shapes",
    },
    {
      placeholder: "Industry Standards Met",
      itemsType: "standardMet",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "industryStandards",
      notFoundText: "Please select a Metal to continue",
    },
    {
      placeholder: "SCR Compliant",
      itemsType: "scrCompliant",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "scrCompliants",
    }, */
  ],

  actionModel: [],
};

export const activityLogListTableFilters: ITableFilterActionModel = {
  /* dateRange: true,
  searchBox: true,
  searchButtons: true, */
  filterListsModel: [
    /* {
      placeholder: "Action",
      itemsType: "actions",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "action",
    },
    {
      placeholder: "Company",
      itemsType: "companyTypeActiveSuspended",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "entityId",
    },
    {
      placeholder: "Stakeholder Type",
      itemsType: "stakeHolderType",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "stakeHolder",
    },
    {
      placeholder: "Users",
      itemsType: "users",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "user",
    }, */
  ],

  actionModel: [],
};

export const monitorRecordListTableFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  dateRange: true,
  filterListsModel: [
    {
      placeholder: "Company",
      itemsType: "companyTypeActiveSuspended",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "entityId",
    },
    {
      placeholder: "Stakeholder Type",
      itemsType: "stakeHolderType",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "stakeHolder",
    },
  ],
};

export const toDoListTableFilters: ITableFilterActionModel = {
  filterListsModel: [
    {
      placeholder: "Brand",
      itemsType: "brandToDoList",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "BRAND",
    },
    {
      placeholder: "Status",
      itemsType: "recordStatus",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "STATUS",
    },
  ],
};

export const activeUserListTableFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  filterListsModel: [
    {
      placeholder: "Company",
      itemsType: "companyTypeActiveSuspended",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "companyTypeList",
    },
    {
      placeholder: "Stakeholder Type",
      itemsType: "stakeHolderType",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "stakeHolderTypeList",
    },
  ],

  actionModel: [],
};

export const appendedListingFilters: ITableFilterActionModel = {
  /* searchBox: false,
  searchButtons: true,
  disclosures: true,
  dateRange: true, */
  filterListsModel: [
    /* {
      placeholder: "Producer",
      itemsType: "masterProducer",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "MASTER_PRODUCER",
    },
    {
      placeholder: "Disclosure Level",
      itemsType: "level",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "LEVEL",
    },
    {
      placeholder: "Metal",
      itemsType: "metal",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "METAL",
    },
    {
      placeholder: "Brand",
      itemsType: "brand",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "BRAND",
      notFoundText: "Please select a Metal to continue",
    }, */
  ],

  actionModel: [],
};

export const sustainabilityPublicViewListingFilters: ITableFilterActionModel = {
  searchBox: true,
  searchButtons: true,
  disclosures: true,
  brandLoadNotRequired: false,
  filterListsModel: [
    {
      placeholder: "Company",
      itemsType: "masterProducer",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "MASTER_PRODUCER",
    },
    {
      placeholder: "Metal",
      itemsType: "metal",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "METAL",
    },
    {
      placeholder: "Brand",
      itemsType: "brand",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "BRAND",
      notFoundText: "Please select a Metal to continue",
    },
    {
      placeholder: "Brand Status",
      itemsType: "deliverable",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "DELIVERABLE",
    },
    {
      placeholder: "Assets",
      itemsType: "assets",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "ASSETS",
    },
  ],

  actionModel: [],
};

export const producerProfileBrandPublicViewListingFilters: ITableFilterActionModel =
  {
    searchBox: true,
    searchButtons: true,
    filterListsModel: [],

    actionModel: [],
  };

export const producerProfileDisclosurePublicViewListingFilters: ITableFilterActionModel =
  {
    searchBox: true,
    searchButtons: true,
    filterListsModel: [],

    actionModel: [],
  };

export const producerProfileTargetPublicViewListingFilters: ITableFilterActionModel =
  {
    searchBox: true,
    searchButtons: true,
    filterListsModel: [],

    actionModel: [],
  };

export const disclosureConfigFilters: ITableFilterActionModel = {
  searchBox: false,
  searchButtons: true,
  disclosures: true,
  filterListsModel: [
    {
      placeholder: "Metal",
      itemsType: "metal",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "METAL",
    },
  ],

  actionModel: [],
};

export const targetSettingsTableFilters: ITableFilterActionModel = {
  dateRange: false,
  searchBox: true,
  searchButtons: true,
  filterListsModel: [
    {
      placeholder: "Target year",
      itemsType: "targetYear",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "targetToYear",
    },
    {
      placeholder: "Company",
      itemsType: "masterProducer",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "MASTER_PRODUCER",
    },
    {
      placeholder: "Theme",
      itemsType: "themeSubTheme",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "themeSubThemeTargets",
    },
    {
      placeholder: "Target",
      itemsType: "target",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "target",
      notFoundText: "Please select a theme to continue",
    },
  ],

  actionModel: [],
};

export const offWarrantDashboardListTableFilters: ITableFilterActionModel = {
  searchBox: false,
  searchButtons: true,
  filterListsModel: [
    {
      placeholder: "Company",
      itemsType: "warehouse",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "warehouse",
    },
    {
      placeholder: "Country",
      itemsType: "country",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "country",
    },
    {
      placeholder: "DP",
      itemsType: "gdl",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "gdl",
    },
    {
      placeholder: "Metal",
      itemsType: "metalsGDL",
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "metalsGDL",
    },
    {
      placeholder: "Significant Stock Change",
      itemsType: "significantValueChange",
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: "significantValueChange",
    },
  ],
  actionModel: [],
};
