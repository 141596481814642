import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

  @Component({
    selector: "app-side-by-side-info",
    templateUrl: "./side-by-side-info.component.html",
    styleUrls: ["./side-by-side-info.component.scss"],
  })
  export class SideBySideInfoComponent implements OnInit  {
    @Input() infoTriggers:boolean;
    @Input() sideBySideText:string;
    @Output() dailogClose = new EventEmitter<any>();

    ngOnInit(): void {}
    
    cancel(){
        this.dailogClose.emit();
    }
  }
  