<p-dialog header="{{ title }}" [modal]="true" [style]="{ width: '90vw' }" [(visible)]="reviewRecordDialog"
  (onHide)="onReviewRecordHide($event)">
  <div class="mt-2">
    <div class="lme review-table">
      <div class="p-datatable">
        <p style="display:none" id="mydesc">review meta data</p>
        <table aria-describedby="mydesc" aria-label="review meta data" role="table" class="review-header"
          id="review-header">
          <thead class="p-datatable-thead">
            <tr>
              <th scope="col">Company Name</th>
              <th colspan="7" scope="col">
                {{ data.companyName }}
              </th>
            </tr>
          </thead>
          <tbody class="p-datatable-tbody">
            <tr>
              <td>
                <strong>Producer Name<span class="mandatory">*</span></strong>
              </td>
              <td>
                <ng-container *ngIf="!canEdit || type === 'approved' || !data.editable">
                  {{ data.producerName }}
                </ng-container>
                <ng-select [inputAttrs]="{
                  'aria-label': 'producerName', 'cy-id': 'producerName', 'id': 'producerName'
                }" *ngIf="canEdit && type !== 'approved' && data.editable" [items]="producers" bindLabel="name"
                  [(ngModel)]="data.producerName"></ng-select>
              </td>
              <td>
                <strong>Certificate of Analysis Reference Number<span class="mandatory">*</span></strong>
              </td>
              <td>
                <ng-container *ngIf="!canEdit || type === 'approved' || !data.editable">
                  {{ data.coaReference }}
                </ng-container>
                <input id="coaReferenceNo" *ngIf="canEdit && type !== 'approved' && data.editable" type="text"
                  [(ngModel)]="data.coaReference" class="form-control" />
              </td>
              <td>
                <strong>Certificate of Analysis Type<span class="mandatory">*</span></strong>
              </td>
              <td>
                <ng-container *ngIf="
                    !canEdit ||
                    type === 'approved' ||
                    !data.editable ||
                    !data.coaTypeEditable
                  ">
                  {{ data.coaType }}
                </ng-container>
                <ng-select *ngIf="
                    canEdit &&
                    type !== 'approved' &&
                    data.editable &&
                    data.coaTypeEditable
                  " [items]="coaTypes" [(ngModel)]="data.coaType" [inputAttrs]="{
                    'aria-label': 'coaType', 'cy-id': 'coaType', 'id': 'coaType'
                  }"></ng-select>
              </td>
              <td>
                <strong>Metal<span class="mandatory">*</span></strong>
              </td>
              <td>
                <ng-container *ngIf="!canEdit || type === 'approved' || !data.editable">
                  {{ data.commodity }}
                </ng-container>
                <ng-select [inputAttrs]="{
                  'aria-label': 'metal', 'cy-id': 'metal', 'id': 'metal'
                }" *ngIf="canEdit && type !== 'approved' && data.editable" [items]="metals" bindLabel="name"
                  [(ngModel)]="data.commodity"></ng-select>
              </td>
            </tr>
            <tr>
              <td><strong>Smelter</strong></td>
              <td>
                <ng-container *ngIf="!canEdit || type === 'approved' || !data.editable">
                  {{ data.smelter }}
                </ng-container>
                <input id="smelter" *ngIf="canEdit && type !== 'approved' && data.editable" type="text"
                  [(ngModel)]="data.smelter" class="form-control" />
              </td>
              <td>
                <strong>Certificate of Analysis Date<span class="mandatory">*</span></strong>
              </td>
              <td>
                <ng-container *ngIf="!canEdit || type === 'approved' || !data.editable">
                  {{ data.coaDateStr | date: "dd MMMM, yyyy" }}
                </ng-container>
                <p-calendar inputId="coaDate" *ngIf="canEdit && type !== 'approved' && data.editable"
                  [(ngModel)]="data.coaDateStr" appendTo="body" dateFormat="dd M yy"></p-calendar>
              </td>
              <td>
                <strong>LME Brand<span class="mandatory">*</span></strong>
              </td>
              <td>
                <ng-container *ngIf="!canEdit || type === 'approved' || !data.editable">
                  {{ data.brand }}
                </ng-container>
                <ng-select [inputAttrs]="{
                  'aria-label': 'brand', 'cy-id': 'brand', 'id': 'brand'
                }" *ngIf="canEdit && type !== 'approved' && data.editable" [items]="brands" bindLabel="name"
                  [(ngModel)]="data.brand"></ng-select>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-5">
          <button id="assignRecord" *ngIf="
              reviewRecords &&
              (reviewRecords[0]?.status === 'REVIEW1' ||
                !reviewRecords[0]?.status) &&
              type !== 'approved'
            " class="btn btn-primary my-2 mr-2" (click)="assignCoa()">
            Assign
          </button>
          <button id="approveRecord" *ngIf="
              reviewRecords?.length &&
              type !== 'approved' &&
              !user.reviewRequired
            " class="btn btn-primary my-2" (click)="bulkApprove()">
            Approve
          </button>
        </div>
        <div class="col-md-7">
          <button id="saveRecord" *ngIf="canEdit && type !== 'approved'" type="button"
            class="pull-right mr-2 my-2 btn btn-primary" (click)="save()">
            Save
          </button>
          <button id="approveRecord" *ngIf="type !== 'approved'" class="pull-right mr-2 my-2 btn btn-primary"
            (click)="showDialogToAdd()">
            Add Row
          </button>
          <button id="exportRecords" *ngIf="user.export && type !== 'approved'"
            class="pull-right mr-2 my-2 btn btn-primary" (click)="exportExcel()">
            Export
          </button>
          <button id="uploadAdditionalDocs" *ngIf="type !== 'approved'" class="pull-right mr-2 my-2 btn btn-primary"
            (click)="uploadAdditionalDocs()">
            Upload Additional Documents
          </button>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <app-table-simple from="review" [selections]="selectedReviewRecords" (checkboxSelected)="onRowSelect($event)"
        [config]="tableConfig" [tableData]="gridData" [virtualScroll]="type !== 'approved' ? true : null">
      </app-table-simple>
    </div>
  </div>
  <p-footer>
    <div *ngIf="canEdit" class="mr-2 col-md-5 note">
      Click on Save button to save any changes performed on the screen
    </div>
    <button id="saveRecord" *ngIf="canEdit && type === 'approved'" type="button" class="mr-2 my-2 btn btn-primary"
      (click)="save()">
      Save
    </button>
    <button id="exportRecord" *ngIf="user.export && type === 'approved'" class="mr-2 my-2 btn btn-primary"
      (click)="exportExcel()">
      Export
    </button>
  </p-footer>
</p-dialog>
<ng-template #additionalDocTemplate let-rowData>
  <span *ngIf="rowData.status !== 'DUPLICATE_REVIEW'" class="link" tabindex="0" role="button"
    (click)="viewAddiotnalDocs($event, rowData)" (keyup)="viewAddiotnalDocs($event, rowData)">View Additional
    Documents</span>
</ng-template>
<ng-template #actionTemplate let-rowData>
  <div>
    <ng-select wcag-label [items]="rowData.actionItems" placeholder="Actions" bindValue="id" bindLabel="name"
      (change)="triggerActionController($event, rowData)" [clearable]="false" appendTo="body"
      [(ngModel)]="actionModel[rowData.id]" *ngIf="rowData.status !== 'DUPLICATE_REVIEW'"
      [inputAttrs]="{ 'aria-label': 'Actions' }"></ng-select>

    <!-- <div class="dropdown">
      <button
        class="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Actions
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a
          *ngFor="let actionItem of rowData.actionItems"
          (click)="triggerActionController($event, rowData, actionItem)"
          class="dropdown-item"
          href="#"
          >{{ actionItem.name }}</a
        >
      </div>
    </div> -->
  </div>
</ng-template>
<ng-template #chemicalCompositionTemplate let-rowData>
  <span *ngIf="rowData.type === 'ENHANCED'; else BASIC" class="link" tabindex="0" role="button"
    (click)="viewComposition($event, rowData)" (keyup)="viewComposition($event, rowData)">View Chemical
    Composition</span>
</ng-template>

<ng-template #BASIC> N/A </ng-template>

<app-edit-coa-record *ngIf="editDialog" [editDialog]="editDialog" [selectedRecord]="selectedRecord"
  [newRecord]="newRecord" [recordType]="recordType" [standards]="standards"
  [industryStandardGrades]="industryStandardGrades" (saveCoa)="onSaveCoa($event)" [type]="type"
  (hideEditDialog)="onHideEditDialog()"></app-edit-coa-record>

<app-chemical-composition *ngIf="reviewChemicalCompDialog" [reviewChemicalCompDialog]="reviewChemicalCompDialog"
  [chemicalComposition]="chemicalComposition" [castReference]="castReference"
  (onChemicalCompHide)="hideChemicalComp()"></app-chemical-composition>
<p-confirmDialog [style]="{ width: '27vw' }"></p-confirmDialog>

<app-assign-coa *ngIf="assignDialog" [assignDialog]="assignDialog" [selectedReviewRecords]="selectedReviewRecords"
  [data]="data" (hideAssignDialog)="onHideAssignDialog()" (assigned)="onAssigned()"></app-assign-coa>

<app-upload-additiona-docs *ngIf="addtionalDocsDialog" [addtionalDocsDialog]="addtionalDocsDialog" [docId]="data.id"
  [coaReference]="data.coaReference" [coaDocument]="data.coaDocument"
  (additionalDocsDialogHide)="onAdditonalDocsHide()"></app-upload-additiona-docs>

<app-additional-documents *ngIf="viewAdditionalDocsDialog" [viewAdditionalDocsDialog]="viewAdditionalDocsDialog"
  [coaReference]="coaReference" [documentId]="documentId" from="review"
  (additionalDocsDialogHide)="hideAdditionalDocsDialog()">
</app-additional-documents>

<p-dialog header="Details" [modal]="true" [style]="{ width: '50vw' }" [(visible)]="requestUpdateCommentDialog">
  <form [formGroup]="form" #myForm="ngForm">
    <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
  </form>
  <p-footer>
    <button class="btn btn-primary" type="button" (click)="requestUpdate()">
      Submit
    </button>
    <button type="button" (click)="requestUpdateCommentDialog = false" class="btn btn-secondary">
      Cancel
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="You have attempted to create duplicate record(s)." [modal]="true" [style]="{ width: '50vw' }"
  [(visible)]="duplicateRecordsDialog" *ngIf="duplicateRecordsDialog">
  <ul *ngIf="successPcrs?.length" class="failed-imports">
    The following PCR(s) have been added to your My Records List
    <li *ngFor="let success of successPcrs">
      {{ success }}
    </li>
  </ul>

  <ul *ngIf="failedPcrs?.length" class="failed-imports">
    The following PCR(s) already exist in your My Records List
    <li *ngFor="let failed of failedPcrs">
      {{ failed }}
    </li>
  </ul>
  <p class="mt-2" *ngIf="duplicateRecords?.length">
    Click on the continue button to approve remaining PCR(s), if selected.
  </p>
  <p-footer>
    <button *ngIf="duplicateRecords?.length" class="btn btn-primary" type="button" (click)="continueApprove()">
      Continue
    </button>
    <button *ngIf="duplicateRecords?.length" type="button" (click)="cancelDuplicate()" class="btn btn-secondary">
      Cancel
    </button>
    <button *ngIf="!duplicateRecords?.length" class="btn btn-primary" type="button" (click)="completeApprove()">
      Ok
    </button>
  </p-footer>
</p-dialog>
<app-preview-old-new-doc [oldDocId]="oldDocId" [newDocId]="newDocId" [previewOldNewDoc]="previewOldNewDoc"
  [oldCoaDateStr]="oldCoaDateStr" [coaRef]="reviewData.coaReference" [errorMessage]="errorMessage"
  *ngIf="previewOldNewDoc" (newCoaSelected)="onNewCoaSelected()" (hideOldNewDialog)="onHideOldNewDialog()"
  (sameDoc)="onSameDocSelected()" (sameDate)="onSameDateSelected()"></app-preview-old-new-doc>