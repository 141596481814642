import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IRequests } from "@core/models/api.model";
import { environment } from "@env/environment";
import { Observable } from "rxjs-compat/Observable";

export const PENDING_REQUESTS = "/inventory-service/recordAmend/get";
export const APPROVE = "/inventory-service/recordAmend/update?status=APPROVED";
export const REJECT = "/inventory-service/recordAmend/update?status=REJECTED";
export const COMPLETE =
  "/inventory-service/recordAmend/update?status=COMPLETED";

@Injectable({
  providedIn: "root",
})
export class UserAdminService {
  constructor(private http: HttpClient) {}

  getUpdateRequests(requests): Observable<IRequests[]> {
    return this.http.post<IRequests[]>(
      `${environment.apiUrl}${PENDING_REQUESTS}`,
      requests
    );
  }

  approveRequests(payload) {
    return this.http.put(`${environment.apiUrl}${APPROVE}`, payload);
  }

  rejectRequests(payload) {
    return this.http.put(`${environment.apiUrl}${REJECT}`, payload);
  }

  updateCompleted(id) {
    return this.http.put(`${environment.apiUrl}${COMPLETE}`, [id]);
  }
}
