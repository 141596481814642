import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { IPagination } from "@core/models/app.interface";
import { Table } from "primeng/table";
import { ITableViewConfig } from "../../models/table-view.model";

export const itemsPerPage = [10, 20, 50, 100, 200];

@Component({
  selector: "app-table-view",
  templateUrl: "./table-view.component.html",
  styleUrls: ["./table-view.component.scss"],
})
export class TableViewComponent implements OnInit {
  @Input("data") data: any;
  @Input("config") config: ITableViewConfig;
  @Input() firstItemIndex = 0;
  @Input() clearSelection;
  @Input() paginationDisabled = false;
  @Input() footerTemplate: TemplateRef<any>;
  @Input() relative = false;
  @Input() selections: any[];
  @Input() expandFirstRow = false;
  @Input() selectionMode?: string;
  @Input() scrollHeight?: string;
  @Input() parent?: string;
  @Output() paginationChanged = new EventEmitter<IPagination>();
  @Output() checkboxSelected = new EventEmitter<any>();
  @Output() rowClickEmit = new EventEmitter<any>();

  @ViewChild("tableContainer", { static: true }) tableContainer: ElementRef;
  @ViewChild("dT", { static: false }) table: Table;

  itemsPerPage = itemsPerPage;
  rows = 10;
  expandedRows = {};

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      if (this.config) {
        this.rows = this.config.rowsPerPage ? this.config.rowsPerPage : 20;
      }
    }, 0);
  }

  reset() {
    this.firstItemIndex = 0;
  }

  rowClick(event, data) {
    if (this.selectionMode) {
      if (this.parent === "recordListing") {
        this.rowClickEmit.emit({ row: data, event });
      } else {
        this.rowClickEmit.emit(data);
      }
    }
  }

  isThereSubTable(rowData, col) {
    return (
      rowData[col.expandSectionDataRoot] !== null &&
      rowData[col.expandSectionData] !== null
    );
  }

  get tableStyles(): any {
    const styles: any = {};
    if (this.config && this.config.width) {
      styles.width = `${this.config.width}px`;
    }
    return styles;
  }

  onPaginationChanged($event: any) {
    if (!this.paginationDisabled) {
      let page;
      if (this.rows !== $event.rows) {
        this.rows = $event.rows;
        page = 0;
      } else {
        page =
          $event.first /
          ($event.rows || this.config.rowsPerPage || this.itemsPerPage[0]);
      }
      const size = $event.rows || this.config.rowsPerPage;
      let sort;
      let dir;
      if ($event.sortField) {
        sort = $event.sortField;
        dir = $event.sortOrder < 0 ? "DESC" : "ASC";
      }
      // this.scrollToTop();
      this.paginationChanged.emit({
        sort,
        dir,
        page,
        size,
      } as IPagination);
    }
  }

  selected(data = null) {
    this.checkboxSelected.emit(this.selections);
  }

  innerTableData: any[];
  innerTableHeaders: any[];
  expandDataField: string;

  getInnerTableData(root?, child?, rowData?) {
    if (root !== undefined && root !== null) {
      if (!rowData || typeof rowData !== "object") {
        return child[root];
      } else {
        return rowData[root][child];
      }
    } else {
      if (!child) {
        child = this.expandDataField;
      }
      return rowData[child];
    }
  }

  getInnerTableHeaders(field) {
    let innerTableHeaders = this.config.columns.find((element) => {
      return field == element.field;
    });

    return innerTableHeaders.expandSectionTableHeader.columns;
  }

  getExpandDataField(field) {
    const innerTableHeaders = this.config.columns.find((element) => {
      return field === element.field;
    });
    return innerTableHeaders.expandSectionDataRoot;
  }

  scrollToTop() {
    if (this.table) {
      setTimeout(() => {
        const tableEl = this.table.getBlockableElement();
        const tableWrapper: Partial<HTMLElement> =
          tableEl.getElementsByClassName("ui-table-wrapper")[0];
        tableWrapper.scrollTo(0, 0);
      });
    }
  }
}
