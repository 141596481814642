<header class="masthead">
  <div class="container-fluid">
    <div class="row">
      <div class="col-8">
        <a href="https://www.lme.com" target="_blank">
          <img alt="LME logo" class="logo" src="assets/img/LME_POS.svg" />
        </a>
      </div>
      <div class="col-4 mobile-padding" style="margin-top: 20px">
        <div class="pull-right desktop-login">
          <p-splitButton [label]="fullName" icon="pi pi-file" [model]="items"
            styleClass="ui-button-secondary"></p-splitButton>
          <div *ngIf="lastLogin" class="last-login">
            Last successful Login:
            <span>{{ lastLogin | date: "dd MMMM, yyyy h:mm:ss a" }}</span>
          </div>
        </div>
        <div class="mobile-login">
          <p-splitButton [label]="mobileName" icon="pi pi-file" [model]="items"
            styleClass="ui-button-secondary"></p-splitButton>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light">
    <a class="skip-main" href="#" (keydown)="skipToMain($event)">
      Skip to main content
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav" *ngFor="let menuItem of menuItems">
        <a [id]="menuItem.moduleLabel" [attr.cy-test]="menuItem.moduleLabel" class="nav-link" wcag-dummy-link-target
          [routerLink]="menuItem.path" routerLinkActive="active">{{ menuItem.moduleLabel }}
        </a>
      </div>
    </div>
  </nav>
</header>