import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { IUser } from "@core/models/api.model";
import { LmeCurrentUser } from "@core/models/current-user.model";
import { environment } from "@env/environment";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter } from "rxjs/operators";

declare const window;

export const USER_DEATILS = "/user-service/users/user";
export const ACCEPT_TC = "/user-service/users/acceptTerms/";
export const NOTIF_CHANNEL = "/common-service/notifications/channels";
export const ACKNOWLEDGE = "/user-service/notifications/acknowledge";
export const ACTIVE_NOTIF = "/user-service/notifications/fetch";
export const PARTY = "/admin-service/settings/party";
export const LAST_LOGIN = "/user-service/users/lastLogin";
export const FOOTER_LINKS = "/admin-service/settings/link";
export const DELETE_NOTIF = "/report-service/reports/aknlowdgement/delete/";
export const SETTNGS = "/admin-service/settings/tabs";
export const STATISTICS = "/common-service/publicView/statistics";
export const SETTNGS_OWSR = "/common-service/off-warrant/configuration";
@Injectable({
  providedIn: "root",
})
export class CoreService {
  userDetails: any;
  notifMessage = new BehaviorSubject(null);
  countDown = new BehaviorSubject(null);
  idleEnded = new BehaviorSubject(null);
  refreshListing = new BehaviorSubject(null);
  menuItemsSub = new BehaviorSubject(null);
  publicRoutePath = new BehaviorSubject(null);
  dataRefreshGdl = new BehaviorSubject(null);
  timedOut = false;
  lastPing?: Date = null;
  socket: any;
  request: any;
  idleTime: number;
  producerProfile: any;
  previousUrl: any;
  currentUrl: any;
  owsrSettings: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  getUserDetails(autoRefresh?): Observable<LmeCurrentUser> {
    if (autoRefresh) {
      let params = new HttpParams();
      params = params.append("autoRefresh", autoRefresh);
      return this.http.get<LmeCurrentUser>(
        `${environment.apiUrl}${USER_DEATILS}`,
        { params }
      );
    } else {
      return this.http.get<LmeCurrentUser>(
        `${environment.apiUrl}${USER_DEATILS}`
      );
    }
  }

  setUserDetails(data) {
    this.userDetails = data;
  }

  getLoggedInuserDetails(): IUser {
    return this.userDetails;
  }

  setProducerProfile(data) {
    this.producerProfile = data;
  }

  getProducerProfile() {
    return this.producerProfile;
  }

  showMessage(data) {
    this.notifMessage.next(data);
  }

  checkIfUserNeedsToBeRedirected(from) {
    let user = this.getLoggedInuserDetails();
    if (from === "user" && user.entityAdmin) {
      return true;
    } else if (from === "platform" && user.platformAdmin) {
      return true;
    } else if (
      from === "METAL_OWNER" &&
      user.stakeHolder !== "METAL_OWNER" &&
      user.coa
    ) {
      return true;
    } else if (
      from === "sustainability" &&
      user.stakeHolder === "PRODUCER" &&
      user.sustainability
    ) {
      return true;
    } else if (from === "manageRecords" && user.coa) {
      return true;
    } else {
      this.router.navigate(["/private/dashboard"]);
      return false;
    }
  }

  checkProducerProfile() {
    let publicViewEnabledSplit =
      this.producerProfile.publicViewTabsEnabled.split(",");
    this.navigateToActiveTab(publicViewEnabledSplit);
  }

  navigateToActiveTab(publicViewEnabledSplit) {
    if (publicViewEnabledSplit[0] === "true") {
      return of(true);
    } else if (publicViewEnabledSplit[1] === "true") {
      this.router.navigate(["/public/live-sustainability-disclosures"]);
      return of(false);
    } else if (publicViewEnabledSplit[2] === "true") {
      this.router.navigate(["/public/disclosures-detail"]);
      return of(false);
    } else if (publicViewEnabledSplit[3] === "true") {
      this.router.navigate(["/public/metric-comparison"]);
      return of(false);
    } else {
      this.router.navigate(["/"]);
      return of(false);
    }
  }

  acceptTerms() {
    let user = this.getLoggedInuserDetails();
    return this.http.put(`${environment.apiUrl}${ACCEPT_TC}${user.id}`, {});
  }

  getNotificationsChannels() {
    return this.http.get(`${environment.apiUrl}${NOTIF_CHANNEL}`);
  }

  initChannel(url, ctrlObj) {
    this.socket = window["atmosphere"];
    this.request = new window["atmosphere"].AtmosphereRequest();
    this.request.url = url;
    this.request.contentType = "application/json";
    this.request.transport = "sse";
    this.request.fallbackTransport = "long-polling";
    this.request.logLevel = "debug";
    this.request.enableXDR = true;

    console.log(this.request);
    this.request.onOpen = (response) => {
      console.log("Channel Opened");
    };

    this.request.onMessage = (response) => {
      console.log("Channel Message Recvd");
      const message = response.responseBody;
      try {
        const json = JSON.parse(message);
        ctrlObj.onChannelMessage(json);
      } catch (e) {
        console.log("This doesn't look like a valid JSON: ", message.data);
        return;
      }
    };

    this.request.onError = (response) => {
      console.log("On Error");
      console.log(response);
      this.dispose();
    };
  }

  subscribeChannel() {
    this.socket.subscribe(this.request);
  }

  dispose() {
    this.unSubscribeChannel();
  }

  unSubscribeChannel() {
    this.socket.unsubscribe(this.request);
  }

  refreshCreateImportList() {
    this.refreshListing.next("refresh");
  }

  acknowledgeNotif(payload) {
    return this.http.put(`${environment.apiUrl}${ACKNOWLEDGE}`, payload);
  }

  getActiveNotifications(): any {
    return this.http.get(`${environment.apiUrl}${ACTIVE_NOTIF}`);
  }

  getPartyDetails() {
    return this.http.get(`${environment.apiUrl}${PARTY}`);
  }

  getLastLogin(): any {
    return this.http.get(`${environment.apiUrl}${LAST_LOGIN}`);
  }

  getFooterLinks() {
    return this.http.get(`${environment.apiUrl}${FOOTER_LINKS}`);
  }

  setTimer() {
    let currentRoute = "";
    let previousRoute = "";
    if (this.currentUrl) {
      const currentUrlArr = this.currentUrl?.split("/");
      if (currentUrlArr?.length >= 1) {
        currentRoute = currentUrlArr[1];
      }
    } else {
      currentRoute = this.currentUrl;
    }
    if (this.previousUrl !== "/" && this.previousUrl) {
      const previousUrlArr = this.previousUrl?.split("/");
      if (previousUrlArr?.length >= 1) {
        previousRoute = previousUrlArr[1];
      }
    } else {
      previousRoute = this.previousUrl;
    }

    const privateToPublicNavigation = !(
      previousRoute &&
      previousRoute.includes("private") &&
      currentRoute.includes("public")
    );
    const publicToprivateNavigation = !(
      previousRoute &&
      previousRoute.includes("/") &&
      currentRoute.includes("private")
    );

    if (privateToPublicNavigation && publicToprivateNavigation) {
      this.idle.setIdle(this.idleTime);
      this.idle.setTimeout(60);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this.idle.onIdleEnd.subscribe(() => {
        this.idleEnded.next(true);
      });

      this.idle.onIdleStart.subscribe(() => {
        console.log("You've gone idle!");
        this.countDown.next(true);
      });

      this.keepalive.interval(15);

      this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    }

    this.reset();
  }

  acknowledgeReportNotif(reportId) {
    return this.http.delete(`${environment.apiUrl}${DELETE_NOTIF}${reportId}`);
  }

  getTabs(): any {
    return this.http.get(`${environment.apiUrl}${SETTNGS}?moduleType=TAB`);
  }

  getStats(): any {
    return this.http.get(`${environment.apiUrl}${STATISTICS}`);
  }

  menuItemReceived(menuItems) {
    this.menuItemsSub.next(menuItems);
  }

  getOWSRConfig(): any {
    return this.http.get(`${environment.apiUrl}${SETTNGS_OWSR}`);
  }

  getOwsrSettings() {
    return this.owsrSettings;
  }

  refreshGdlData() {
    this.dataRefreshGdl.next(true);
  }
}
