import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FilterModel } from "@core/models/filter.model";
import { LookupService } from "@core/services/lookup.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ITableViewConfig } from "@shared/models/table-view.model";
import { Subject } from "rxjs";
import {
  FilterFieldsLists,
  ITableFilterSortedField,
} from "@shared/models/table-filter.model";
import * as _ from "lodash";
import { takeUntil } from "rxjs/operators";
import { CoreService } from "@core/services/core.service";
import { IDisclosure, IUser } from "@core/models/api.model";
import { IDateRange } from "../date-range/date-range.component";
import { CreateImportService } from "@module/create-import/services/create-import.service";
import { TABLE_FILTER_SETTINGS_KEY } from "./table-filter.const";
import { ILookup } from "@core/models/app.interface";
import { SustainabilityDisclosurePublicService } from "@module/sustainability-disclosure-public-view/services/sustainability-disclosure-public.service";
import { targetYear } from "app/app.const";
import { SharedService } from "@shared/services/shared.service";
import { EntityService } from "@module/platform-admin/services/entity.service";

@Component({
  selector: "app-table-filter",
  templateUrl: "./table-filter.component.html",
  styleUrls: ["./table-filter.component.scss"],
})
export class TableFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fields;
  @Input() tableData;
  @Input("config") config: ITableViewConfig;
  @Input("fallback") fallback?: boolean;
  @Input() parent?: string;
  @Input() appliedFilters?: any;
  @Input() disclosures?: IDisclosure[];
  @Input() metalFilter?: any;
  @Input() title?: string;

  @Output() actionSelected = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<FilterModel>();
  @Output() filterInit = new EventEmitter<FilterModel>();
  @Output() requestExport = new EventEmitter<string>();
  @Output() newEntryAdded = new EventEmitter<any>();
  @Output() newPopUpCreated = new EventEmitter<any>();
  @Output() adminActions = new EventEmitter<any>();
  @Output() customButtonAction = new EventEmitter<any>();
  @Output() filterCleared = new EventEmitter<any>();

  @ViewChildren(NgSelectComponent) ngSelectList!: QueryList<NgSelectComponent>;
  @ViewChild("searchTextBox", { static: false }) searchTextBox: ElementRef;

  private _destroy$ = new Subject<any>();
  filterFields: FilterFieldsLists;

  filterRequest: FilterModel;

  selectedActionOption: any;
  actionSelectedDummyModel: any = {};
  sortedFieldsList: ITableFilterSortedField[] = [];

  downloadOptions = [];
  downloads = ["Excel", "PDF"];
  statuses = [];
  recordTypes = [];
  recordStatus: { id: string; name: string }[];
  user: IUser;
  producerData: any;
  private filterParent: string;
  public facilityNamesModel: ILookup[];
  searchBoxPlaceHolder: any;
  ngSelectGroupBy: any;
  ngSelectBindLabel: any;
  filterTitle: string;
  allThemes: any;

  constructor(
    private lookupService: LookupService,
    private coreService: CoreService,
    private createImportService: CreateImportService,
    private sustainabilityDisclosureLiveService: SustainabilityDisclosurePublicService,
    private sharedService: SharedService,
    private entityService: EntityService
  ) {}

  ngOnInit() {
    this.filterTitle = this.title ? this.title : "Filters & Search";
    this.sustainabilityDisclosureLiveService.updateFilters.subscribe((data) => {
      if (data) {
        this.clearSearch();
      }
    });
    this.ngSelectGroupBy = "themeSubTheme";
    this.ngSelectBindLabel = "disclosureName";
    this.user = this.coreService.getLoggedInuserDetails();
    this.downloads.forEach((c, i) => {
      this.downloadOptions.push({ id: i, name: c });
    });
    this.filterRequest = new FilterModel();
    this.filterFields = new FilterFieldsLists();
    this.buildFilterFieldsList();
    this.createStatus();
    this.recordStatuses();
    this.createRecordTypes();
    this.getLookUpData();
    this.setDefaultFilter();
    this.searchBoxPlaceHolder = this.fields.searchBoxPlaceholder;
  }

  setDefaultFilter() {
    if (this.parent === "entityListing") {
      this.filterInit.emit(this.filterRequest);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields && changes.fields.previousValue !== undefined) {
      this._destroy$.next(true);
      setTimeout(() => this.ngOnInit());
    }

    if (
      changes["appliedFilters"] &&
      changes["appliedFilters"].currentValue !==
        changes["appliedFilters"].previousValue
    ) {
      if (this.parent === "myRecords") {
        if (
          changes["appliedFilters"].currentValue.metals &&
          changes["appliedFilters"].currentValue.metals.length
        ) {
          this.getBrand(changes["appliedFilters"].currentValue.metals);
          this.getIndustryStandards(
            changes["appliedFilters"].currentValue.metals
          );
        }

        this.filterRequest = changes["appliedFilters"].currentValue;
        changes["appliedFilters"].currentValue["keyword"] &&
          (this.filterRequest["freeText"] =
            changes["appliedFilters"].currentValue["keyword"]);
        delete this.filterRequest["keyword"];
        this.filterRequest["disclosureIds"] && this.setDisclosures();
        if (this.filterRequest["passportIds"]) {
          this.filterRequest.LME_PASSPORT_ID =
            this.filterRequest["passportIds"];
        }
        if (this.filterRequest["coaReferencies"]) {
          this.filterRequest.COA_REFERENCE_NUMBER =
            this.filterRequest["coaReferencies"];
        }
      }
    }
  }

  setDisclosures() {
    if (typeof this.filterRequest["disclosureIds"][0] === "string") {
      this.filterRequest["disclosure"] = this.disclosures.filter((elem) => {
        return this.filterRequest["disclosureIds"].includes(
          elem.disclosureId.toString()
        );
      });
    } else {
      this.filterRequest["disclosure"] = this.disclosures.filter((elem) => {
        return this.filterRequest["disclosureIds"].includes(elem.disclosureId);
      });
    }
  }

  recordStatuses() {
    if (this.parent !== "toDoList") {
      this.recordStatus = [
        {
          id: "DIGITISED",
          name: "Digitised",
        },
        {
          id: "DIGITISING",
          name: "Digitising",
        },
        {
          id: "REVIEW1",
          name: "Review 1",
        },
        {
          id: "REVIEW2",
          name: "Review 2",
        },
      ];
    } else {
      this.recordStatus = [
        {
          id: "POTENTIAL",
          name: "POTENTIAL",
        },
        {
          id: "NOT FOUND",
          name: "NOT FOUND",
        },
      ];
    }
  }

  createRecordTypes() {
    this.recordTypes = [
      {
        id: "BASIC",
        name: "Basic",
      },
      {
        id: "ENHANCED",
        name: "Enhanced",
      },
    ];
  }

  createStatus() {
    this.statuses = [
      {
        id: "ACTIVE",
        name: "Active",
      },
      {
        id: "SUSPENDED",
        name: "Suspended",
      },
    ];

    /* if (this.parent === "activeSuspended" && this.user.entityAdmin) {
      this.statuses.push({
        id: "TERMINATED",
        name: "Terminated",
      });
    } */

    if (
      this.parent === "pending" ||
      (this.parent === "activeSuspended" && this.user.entityAdmin) ||
      (this.parent === "managePermissions" && this.user.entityAdmin)
    ) {
      this.statuses.push({
        id: "PENDING APPROVAL",
        name: "Pending Approval",
      });
    }
  }

  getScrCompliants() {
    this.filterFields.scrCompliant = [
      {
        id: "Compliant",
        name: "Compliant",
      },
      {
        id: "Non-Compliant",
        name: "Non-Compliant",
      },
      {
        id: "Check COA - Missing Elements",
        name: "Check COA - Missing Elements",
      },
      {
        id: "Check COA - Non-Numerics",
        name: "Check COA - Non-Numerics",
      },
    ];
  }

  getBrandStatus() {
    this.filterFields.brandStatus = [
      {
        id: "Y",
        name: "Y",
      },
      {
        id: "N",
        name: "N",
      },
    ];
  }

  getBrandDeliverable() {
    this.filterFields.deliverable = [
      { id: "DELISTING_PERIOD", name: "Delisting Period (deliverable)" },
      { id: "SUSPENDED", name: "Suspended" },
      { id: "SUSPENSION_PERIOD", name: "Suspension Period (deliverable)" },
      { id: "DELIVERABLE", name: "Deliverable" },
      { id: "NON_LME_BRAND", name: "Non-LME brand (non deliverable)" },
    ];
  }

  getLookUpData() {
    this.fields.filterListsModel.forEach((actionModel) => {
      switch (actionModel.itemsType) {
        case "country":
          this.lookupService
            .getCountriesBasedOnGDL()
            .pipe(takeUntil(this._destroy$))
            .subscribe((countries) => {
              this.filterFields.country = countries.sort();
            });
          break;
        case "gdl":
          this.sharedService
            .getGDLLocations()
            .pipe(takeUntil(this._destroy$))
            .subscribe((res) => {
              const uniqueGdls = [...new Set(res)];
              this.filterFields.gdl = uniqueGdls.sort();
            });
          break;
        case "warehouse":
          this.getActiveWarehouses();
          break;
        case "significantValueChange":
          this.filterFields.significantValueChange = ["Yes", "No"];
          break;
        case "metalsGDL":
          this.sharedService
            .getGDLColumns()
            .pipe(takeUntil(this._destroy$))
            .subscribe((res) => {
              const uniqueMetals = [...new Set(res)];
              this.filterFields.metalsGDL = uniqueMetals.sort();
            });
          break;
        case "stakeHolderType":
          this.getStakeHolderTypes();
          break;
        case "recordType":
          this.getRecordTypes();
          break;
        case "metal":
          if (this.parent === "liveSustainability") {
            this.getMetalsLiveSustainability();
          } else {
            this.getMetal();
          }
          break;
        case "brandToDoList":
          this.getBrandToDoList();
          break;
        case "companyType":
          this.getCompanies();
          break;
        case "status":
          this.getStatuses();
          break;
        case "brandStatus":
          this.getBrandStatus();
          break;
        case "deliverable":
          this.getBrandDeliverable();
          break;
        case "registrationStatus":
          this.getEntityRegisterStatus();
          break;
        case "recordStatus":
          this.filterFields.recordStatus = this.recordStatus;
          break;
        case "companyTypeActiveSuspended":
          this.getActiveSuspendedCompanies();
          break;
        case "companyTypeTerminated":
          this.getTerminatedCompanies();
          break;
        case "producer":
          this.getProducers();
          break;
        case "shape":
          this.getShapes();
          break;
        case "users":
          this.getUsers();
          break;
        case "scrCompliant":
          this.getScrCompliants();
          break;
        case "actions":
          this.getActions();
          break;
        case "level":
          this.getLevels();
          break;
        case "assets":
          this.getAssets();
          break;
        case "masterProducer":
          if (this.parent === "liveSustainability") {
            this.getMasterProducerLiveSustainability();
          } else {
            this.getMasterProducer();
          }
          break;
        case "targetYear":
          this.getTargetYears();
          break;
        case "themeSubTheme":
          this.getThemeData();
          break;
        case "target":
          this.filterFields.target = [];
          break;
        case "brand":
          if (this.parent === "append") {
            this.lookupService
              .getBrandMultiMetal(this.metalFilter, "deliverable")
              .pipe(takeUntil(this._destroy$))
              .subscribe((value) => {
                let brands = [..._.flattenDeep(value)];

                let brand = [];
                brands.forEach((element) => {
                  brand.push(element);
                });
                let sortedBrand = _.orderBy(brand, ["brand"], ["asc"]);
                this.filterFields.brand = sortedBrand;
              });
          }
      }
    });
  }

  getActiveWarehouses() {
    this.lookupService
      .getStakeHolders()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        let warehouseOperatorId = res.filter(
          (x) => x.stakeholderId === "WAREHOUSE_OPERATOR"
        )[0].id;

        this.entityService
          .getCompanies(warehouseOperatorId)
          .pipe(takeUntil(this._destroy$))
          .subscribe((res) => {
            this.filterFields.warehouse = res
              .filter((x) => x.status === "ACTIVE" && x?.locations?.length)
              .map((x) => {
                x.name = x.entityName;
                return x;
              });

            this.filterFields.warehouse = _.orderBy(
              this.filterFields.warehouse,
              "name",
              "asc"
            );
          });
      });
  }

  getAssets() {
    this.lookupService
      .getAssets()
      .pipe(takeUntil(this._destroy$))
      .subscribe((assets) => (this.filterFields.assets = assets));
  }

  getTargetYears() {
    this.filterFields.targetYear = targetYear;
  }

  getThemeData() {
    this.sharedService
      .getTargetsList("ACTIVE")
      .pipe(takeUntil(this._destroy$))
      .subscribe((res: any) => {
        /* res.forEach((row) => {
          row.target = row.name;
          row.name = `${row.theme} - ${row.subTheme}`;
        }); */
        let themeSubThemes = res.map((row) => `${row.theme} - ${row.subTheme}`);
        this.allThemes = res;
        this.filterFields.themeSubTheme = [...new Set(themeSubThemes)];
      });
  }

  getTargets(themeSubtheme) {
    let [theme, subtheme] = themeSubtheme.split("-");
    this.filterFields.target = this.allThemes
      .filter(
        (target) =>
          target.theme === theme.trim() &&
          target.subTheme === subtheme.trim() &&
          target.status === "ACTIVE"
      )
      .map((target) => target.name);
  }

  getMasterProducer() {
    this.lookupService
      .getMasterProducers()
      .pipe(takeUntil(this._destroy$))
      .subscribe((producers) => (this.filterFields.masterProducer = producers));
  }

  getMasterProducerLiveSustainability() {
    this.lookupService
      .getMasterProducersLiveSustainability()
      .pipe(takeUntil(this._destroy$))
      .subscribe((producers) => {
        this.filterFields.masterProducer = producers.sort();
      });
  }

  getLevels() {
    this.filterFields.level = [
      { name: "Entity", id: "Entity" },
      { name: "Brand", id: "Brand" },
      { name: "Record", id: "Record" },
    ];
  }

  getShapes() {
    this.lookupService
      .getShapes()
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.filterFields.shape = value.map((shape) => shape.name);
      });
  }

  getProducers() {
    this.lookupService
      .getProducers()
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.filterFields.producer = value;
      });
  }

  getActions() {
    this.lookupService
      .getActions()
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.filterFields.actions = value;
      });
  }

  getUsers() {
    this.lookupService
      .getUsers()
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.filterFields.users = value;
      });
  }

  getRecordTypes() {
    this.filterFields.recordType = this.recordTypes;
  }

  getActiveSuspendedCompanies() {
    this.lookupService
      .getActiveSuspendedCompanies()
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        value.forEach((company) => {
          company.name = company.entityName;
        });
        this.filterFields.companyTypeActiveSuspended = value;
      });
  }

  getTerminatedCompanies() {
    this.lookupService
      .getTerminatedCompanies()
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        value.forEach((company) => {
          company.name = company.entityName;
        });
        this.filterFields.companyTypeTerminated = value;
      });
  }

  getStakeHolderTypes() {
    this.filterFields.stakeHolderType = [];
    this.lookupService
      .getStakeHolders()
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        value.forEach((row) => {
          row.id = row.name;
        });

        if (
          this.parent === "recordListing" ||
          this.parent === "append" ||
          this.parent === "approvedRecord"
        ) {
          this.filterFields.stakeHolderType = value.filter(
            (stakeholer) => stakeholer.stakeholderId !== "METAL_OWNER"
          );
        } else {
          this.filterFields.stakeHolderType = value;
          value.push({
            name: "Platform Admin",
            id: "Platform Admin",
          });

          this.filterFields.stakeHolderType = _.orderBy(
            this.filterFields.stakeHolderType,
            ["name"],
            ["asc"]
          );
        }
      });
  }

  getMetal() {
    if (this.user.stakeHolder === "PRODUCER") {
      this.lookupService
        .getBrandsByProducer(this.user.entityName, this.parent)
        .pipe(takeUntil(this._destroy$))
        .subscribe((data) => {
          this.producerData = data;
          this.filterFields.metal = [...new Set(data.map((row) => row.metal))];
        });
    } else {
      this.lookupService
        .getMetals()
        .pipe(takeUntil(this._destroy$))
        .subscribe((value) => {
          this.filterFields.metal = value;
        });
    }
  }

  getMetalsLiveSustainability() {
    this.lookupService
      .getMetalsLivesustainability()
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.filterFields.metal = value;
      });
  }

  getBrandToDoList() {
    if (this.user.stakeHolder === "PRODUCER") {
      this.lookupService
        .getBrandsByProducer(this.user.entityName)
        .pipe(takeUntil(this._destroy$))
        .subscribe((data) => {
          this.producerData = data;
          this.filterFields.brandToDoList = [
            ...new Set(data.map((row) => row.brand)),
          ];

          this.filterFields.brandToDoList =
            this.filterFields.brandToDoList.sort();
        });
    } else {
      this.lookupService
        .getBrands()
        .pipe(takeUntil(this._destroy$))
        .subscribe((value) => {
          this.filterFields.brandToDoList = value;
        });
    }
  }

  getIndustryStandards(metal) {
    this.createImportService
      .getIndustryGrade(metal)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        let standards = [...new Set(res.map((row) => row.industryStd))];
        standards = standards.filter((standard) => standard !== "NA");
        this.filterFields.standardMet = standards;
      });
  }

  getBrand(metal) {
    if (
      this.user &&
      this.user.stakeHolder === "PRODUCER" &&
      this.parent !== "liveSustainability"
    ) {
      let sortableBrand: any;
      if (Array.isArray(metal)) {
        sortableBrand = [
          ...new Set(
            this.producerData
              .filter((row) => metal.includes(row.metal))
              .map((row) => row.brand)
          ),
        ];
      } else {
        sortableBrand = [
          ...new Set(
            this.producerData
              .filter((row) => row.metal === metal)
              .map((row) => row.brand)
          ),
        ];
      }

      this.filterFields.brand = sortableBrand.sort();
    } else if (this.parent === "liveSustainability") {
      if (metal.length) {
        this.getBrandsLiveSustainability(metal.join(","));
      } else {
        this.filterFields.brand = [];
      }
    } else {
      this.lookupService
        .getBrands(metal, this.parent)
        .pipe(takeUntil(this._destroy$))
        .subscribe((value) => {
          this.filterFields.brand = value;
        });
    }
  }

  getBrandsLiveSustainability(metals) {
    this.lookupService
      .getBrandsLiveSustainability(metals)
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.filterFields.brand = value;
      });
  }

  getCompanies() {
    this.lookupService
      .getCompanies()
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        value.forEach((company) => {
          company.name = company.entityName;
        });
        this.filterFields.companyType = value;
      });
  }

  getStatuses() {
    this.filterFields.status = this.statuses;
  }

  getEntityRegisterStatus() {
    this.filterFields.registrationStatus = [
      {
        id: "APPROVED",
        name: "Approved",
      },
      {
        id: "NEED_MORE_INFO",
        name: "Need More Information",
      },
      {
        id: "PENDING_APPROVAL",
        name: "Pending Approval",
      },
      {
        id: "REJECTED",
        name: "Rejected",
      },
      {
        id: "PRE_REGISTER",
        name: "Pending Registration",
      },
    ];
  }

  private buildFilterFieldsList() {
    this.sortedFieldsList = [];
    this.fields.dateRange &&
      this.sortedFieldsList.push({ fieldType: "dateRange" });
    this.fields.filterListsModel.forEach((f) =>
      this.sortedFieldsList.push({ fieldType: "filterListModel", data: f })
    );

    this.fields.disclosures &&
      this.sortedFieldsList.push({ fieldType: "disclosuresFilter" });

    this.fields.searchBox &&
      this.sortedFieldsList.push({ fieldType: "searchBox" });
    this.fields.passportIdFilter &&
      this.sortedFieldsList.push({ fieldType: "passportIdFilter" });
    this.fields.coaBulkSearch &&
      this.sortedFieldsList.push({ fieldType: "coaBulkSearch" });
    this.fields.searchButtons &&
      this.sortedFieldsList.push({
        fieldType: "searchButtons",
        options: this.fields.options && this.fields.options.searchButtons,
      });

    this.fields.customButton &&
      this.sortedFieldsList.push({
        fieldType: "customButton",
        data: this.fields.customButton,
      });
    this.sortedFieldsList.length &&
      (this.sortedFieldsList[this.sortedFieldsList.length - 1].last = true);
  }

  updateCoaReferenceComboBox(data) {
    this.filterFields.coaReference = data;
  }

  searchServiceManager(type?, from?) {
    if (type === "METAL" && !this.fields.brandLoadNotRequired) {
      this.filterRequest["BRAND"] = null;
      if (
        this.filterRequest["METAL"] &&
        this.filterRequest["METAL"].length > 0
      ) {
        this.getBrand(this.filterRequest["METAL"]);
      } else {
        this.filterFields.brand = [];
      }
    }

    if (type === "themeSubThemeTargets") {
      this.filterRequest["target"] = null;
      this.filterRequest["themeSubThemeTargets"] &&
        this.getTargets(this.filterRequest["themeSubThemeTargets"]);
      !this.filterRequest["themeSubThemeTargets"] &&
        (this.filterFields.target = []);
    }

    if (type === "metals") {
      this.filterRequest["brands"] = null;
      if (this.filterRequest["metals"].length > 0) {
        this.getBrand(this.filterRequest["metals"]);
      } else {
        this.filterFields.brand = [];
      }
      this.getIndustryStandards(this.filterRequest["metals"]);
    }

    this.ngSelectList.forEach((element) => {
      element.searchTerm = "";
    });
    if (this.parent === "entityListing") {
      this.storeFilterSettings();
    }

    if (this.fields.searchBox) {
      this.filterRequest.freeText = this.filterRequest.freeText
        ? this.filterRequest.freeText.trim()
        : null;
    }

    if (
      from === "dropdown" &&
      (this.parent === "bulkSearchCoa" || this.parent === "search")
    ) {
      console.log("do not emit");
    } else {
      this.filterChanged.emit(this.filterRequest);
    }
  }

  storeFilterSettings() {
    if (this.filterParent) {
      let filterSettings =
        sessionStorage.getItem(TABLE_FILTER_SETTINGS_KEY) || "{}";
      filterSettings = JSON.parse(filterSettings);
      filterSettings[this.filterParent] = this.filterRequest;
      if (filterSettings[this.filterParent]["facilityNameList"]) {
        filterSettings[this.filterParent]["facilityNameList"] =
          this.facilityNamesModel;
      }
      sessionStorage.setItem(
        TABLE_FILTER_SETTINGS_KEY,
        JSON.stringify(filterSettings)
      );
    }
  }

  getStoredFilterSettings() {
    if (this.filterParent) {
      let filterSettings =
        sessionStorage.getItem(TABLE_FILTER_SETTINGS_KEY) || "{}";
      filterSettings = JSON.parse(filterSettings);
      this.filterRequest = filterSettings[this.filterParent] || {};
      !this.filterRequest.dateFrom && (this.filterRequest.dateFrom = null);
      !this.filterRequest.dateTo && (this.filterRequest.dateTo = null);
      this.filterRequest["facilityNameList"] &&
        (this.facilityNamesModel = this.filterRequest["facilityNameList"]);
    }
  }

  customButton() {
    this.customButtonAction.emit();
  }

  onSearchFieldKeyUp(event) {
    if (event.key === "Enter") {
      this.searchServiceManager();
    }
  }

  onChangeAction(event) {
    this.actionSelected.emit(event);
    setTimeout(() => {
      Object.keys(this.actionSelectedDummyModel).forEach(
        (key) => (this.actionSelectedDummyModel[key] = null)
      );
      (document.activeElement as HTMLElement).blur();
    }, 0);
  }

  bulkSearchCOA() {
    this.filterRequest["METAL"] = null;
    this.filterRequest["BRAND"] = null;
    !this.filterRequest["METAL"] && (this.filterFields.brand = []);
    this.filterRequest.COA_REFERENCE_NUMBER = "";
    this.parent === "search" && (this.filterRequest.LME_PASSPORT_ID = "");
    this.filterCleared.emit();
  }

  clearSearch(event?) {
    event && event.stopPropagation();
    if (this.parent === "bulkSearchCoa" || this.parent === "search") {
      this.bulkSearchCOA();
      return false;
    }
    if (this.filterRequest.LME_PASSPORT_ID) {
      this.filterRequest.LME_PASSPORT_ID = "";
    }
    if (this.filterRequest.COA_REFERENCE_NUMBER) {
      this.filterRequest.COA_REFERENCE_NUMBER = "";
    }

    if (this.filterRequest.COA_REFERENCE) {
      this.filterRequest.COA_REFERENCE = "";
    }

    if (this.filterRequest["passportIds"]) {
      this.filterRequest["passportIds"] = "";
    }

    if (this.filterRequest["coaReferencies"]) {
      this.filterRequest["coaReferencies"] = "";
    }
    if (this.parent !== "search" && this.parent !== "bulkSearchCoa") {
      this.ngSelectList.toArray().forEach((ngSelect) => {
        ngSelect.handleClearClick();
      });
    } else {
      this.filterRequest["METAL"] = null;
      this.parent === "search" && (this.filterRequest["BRAND"] = null);
    }
    this.searchTextBox && (this.searchTextBox.nativeElement.value = "");
    this.filterRequest.freeText && (this.filterRequest.freeText = "");
    this.filterRequest.dateFrom = undefined;
    this.filterRequest.dateTo = undefined;
    this.storeFilterSettings();
    this.setDefaultFilter();
    this.filterCleared.emit();
    this.filterChanged.emit(this.filterRequest);
    (document.activeElement as HTMLElement).blur();
    this.parent !== "producerProfile" &&
      document.getElementById("filter-clear-btn")?.focus();
  }

  onDateRangeChanged(newDateRange: IDateRange) {
    this.filterRequest.dateFrom = newDateRange.dateFrom;
    this.filterRequest.dateTo = newDateRange.dateTo;
  }

  public ngSearchFn = (searchTerm: string, item: any) => {
    if (this.ngSelectGroupBy) {
      return (
        item[this.ngSelectGroupBy]
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) > -1 ||
        item[this.ngSelectBindLabel]
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) > -1
      );
    } else {
      return (
        item[this.ngSelectBindLabel]
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) > -1
      );
    }
  };

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
