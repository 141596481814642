<div class="container-fluid">
    <p class="mt-3 mb-3">
        The LME producer profile tab enables users to understand which metals, assets and brands sit beneath the
        overarching company. If you would like to update or add asset information, please reach out to
        <a [attr.href]="physicalOperationsEmailLink" class="contact-us">{{physicalOperationsEmail}}</a>.

        With this profile tab, users can clearly see which sustainability credentials LME producers have uploaded to
        LMEpassport.<br>

        <span class="italics">Please click or search to see more on your preferred producer.</span>
    </p>
    <p class="mt-2">
        <a (click)="howToaAddYourCompany()" class="link">
            Want to add your company logo and / or an updated sustainability report?
            <i class="ml-2 fa fa-info-circle" aria-hidden="true"></i>
        </a>
    </p>
    <!-- <div *ngIf="!selectedProducer" class="mt-3 mb-3 row">
        <div class="col-md-12">
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">
                <div class="custom-pagination float-right">
                    <a class="mr-3 prev btn btn-primary" [ngClass]="{'disabled': p.isFirstPage()}"
                        (click)="p.previous()">
                        Prev
                    </a>
                    <a class="mr-3 prev btn btn-primary" [ngClass]="{'disabled': p.isLastPage()}" (click)="p.next()">
                        Next
                    </a>
                </div>
            </pagination-template>
        </div>
    </div> -->
    <ng-select wcag-label placeholder="Select producer" [clearable]="false" [clearOnBackspace]="false"
        (change)="producerChange($event)" (search)="producerSearch($event)" [items]="producers" bindLabel="entityName"
        bindValue="id" [(ngModel)]="producer" appendTo="body"></ng-select>
    <div *ngIf="!selectedProducer" class="row mt-3 mb-3">
        <!-- <div *ngFor="let producer of filteredProducers | paginate: config" class="mt-3 col-xxl-4 col-md-6"> -->
        <div *ngFor="let producer of filteredProducers" class="mt-3 col-xxl-4 col-md-6">
            <div class="producer-box" (click)="producerChange(producer)">
                <div class="logo-container">
                    <img class="producer-logo" *ngIf="producer?.uuid; else ORG_DEFAULT_LOGO" loading="lazy" src="{{
                            env.apiUrl +
                              '/home-service/publicView/document/download?uuid=' +
                              producer?.uuid +
                              '&name=logo.png'
                          }}" alt="company logo" />
                    <ng-template #ORG_DEFAULT_LOGO>
                        <img class="producer-logo" src="assets/img/org-logo.png" alt="company logo" />
                    </ng-template>
                </div> 
                <div class="text-container">
                    <div class="producer-name">{{producer?.entityName}}</div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="selectedProducer">
        <div class="mt-3 account-title">
            <a href="#" class="arrow-back" wcag-dummy-link-target role="button" aria-label="Back"
                (click)="resetSelectedProducer($event)" (keypress)="resetSelectedProducer($event)">
                <i class="fa fa-chevron-left float-left back-icon"></i>
                <span style="display: none">back</span>
            </a>
            <h2 class="float-left">
                Back
            </h2>
            <div class="text-right">
                <div class="d-inline-block"></div>
            </div>
        </div>
        <div class="producer-section mt-3 selected-producer">
            <div class="selected-producer-logo-container">
                <img class="producer-logo" *ngIf="selectedProducer?.uuid; else ORG_DEFAULT_LOGO" loading="lazy" src="{{
                                env.apiUrl +
                                  '/home-service/publicView/document/download?uuid=' +
                                  selectedProducer?.uuid +
                                  '&name=logo.png'
                              }}" alt="company logo" />
                <ng-template #ORG_DEFAULT_LOGO>
                    <img class="producer-logo" src="assets/img/org-logo.png" alt="company logo" />
                </ng-template>
            </div>
            <div class="producer-name-box">
                <div class="producer-name">{{selectedProducer?.entityName}}</div>
            </div>
            <a class="selected-producer-link"
                *ngIf="selectedProducer?.sustainabilityDocument || selectedProducer?.sustainabilityUrl; else NO_REPORT"
                href="#" (click)="redirectToUrlOrDoc($event)">Latest sustainability report <i
                    class="pi pi-external-link"></i></a>
            <ng-template #NO_REPORT>
                <span class="selected-producer-link disabled"
                    pTooltip="Producer has not uploaded any sustainability report" tooltipPosition="top">Latest
                    sustainability report</span>
            </ng-template>
        </div>
        <div>
            <nav #stickyMenu [ngClass]="{'sticky': sticky === true}"
                class="navbar navbar-toggleable-md navbar-main navbar-expand-md navbar-light">
                <!-- <span class="navbar-text">
                    JUMP TO
                </span> -->
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                    data-target="#producerProfileNavAltMarkup" aria-controls="producerProfileNavAltMarkup"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="producerProfileNavAltMarkup">
                    <ul class="navbar-nav">
                        <li class="nav-item" *ngFor="let menuItem of menuItems; let i = index">
                            <a [ngClass]="{'active': menuItem.active}" id="{{ menuItem.moduleLabel }}"
                                [attr.cy-test]="menuItem.moduleLabel" class="nav-link" wcag-dummy-link-target
                                (click)="scrollToElement($event, menuItem.scrollTo, i)" href="#">{{
                                menuItem.moduleLabel }} <i class="pi pi-angle-down"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
            <div class="mt-3" #brandData>
                <h3 class="section-heading">Brand information</h3>
                <div class="mb-3">
                    <app-table-filter (filterChanged)="onFilterChangedBrand($event)" [fields]="brandFilters"
                        parent="producerProfile" title="Search"></app-table-filter>
                </div>
                <app-table-simple [config]="brandTableConfig" [tableData]="filteredBrandData" #table>
                </app-table-simple>
            </div>
            <div #sustainabilityData class="mt-2 mb-5">
                <h3 class="section-heading">Sustainability credentials</h3>
                <button class="btn btn-primary producer-bg-color mb-2" (click)="redirectToComparison()">
                    View side-by-side
                </button> &nbsp;
                <p (click)="infoSideBySide()" style="display: inline-block;">
                    <a class="mt-5 link side-by-side-text-container"> What is side-by-side?
                        <i aria-hidden="true" class="ml-2 fa fa-info-circle"></i></a>
                </p>
                <app-table-filter (filterCleared)="disclosureFilterCleared()"
                    (filterChanged)="onFilterChangedDisclosure($event)" [fields]="disclosureFilters"
                    parent="producerProfile" title="Search"></app-table-filter>

                <div class="cards_wrap">
                    <div (click)="selectTheme('multi-dimensional')"
                        [ngClass]="{'selected': selectedThemeFilterName === 'multi-dimensional'}"
                        class="card_item multi-dimesional-bg taxonomy-color">
                        Multi-dimensional
                    </div>
                    <div (click)="selectTheme('environmental')"
                        [ngClass]="{'selected': selectedThemeFilterName === 'environmental'}"
                        class="card_item environmental-bg taxonomy-color">
                        Environmental
                    </div>
                    <div (click)="selectTheme('social')" [ngClass]="{'selected': selectedThemeFilterName === 'social'}"
                        class="card_item social-bg taxonomy-color">
                        Social
                    </div>
                    <div (click)="selectTheme('governance')"
                        [ngClass]="{'selected': selectedThemeFilterName === 'governance'}"
                        class="card_item governance-bg taxonomy-color">
                        Governance
                    </div>
                </div>

                <app-table-simple from="producerProfileSustainability"
                    noRecordMessage="The producer has not uploaded any sustainability data."
                    [config]="disclosureTableConfig" [tableData]="filteredDisclosureData" #table>
                </app-table-simple>
            </div>
            <div class="mt-3" #sustainabilityTargets>
                <h3 class="section-heading">Targets, goals and commitments</h3>
                <div class="mb-2">
                    <app-table-filter (filterChanged)="onFilterChangedTargets($event)"
                        (filterCleared)="targetFilterCleared()" [fields]="targetsFilters" parent="producerProfile"
                        title="Search"></app-table-filter>
                </div>

                <p>
                    <a (click)="howDialCalculated()" class="link">
                        How is the "on track?" dial calculated?
                        <i class="ml-2 fa fa-info-circle" aria-hidden="true"></i>
                    </a>
                </p>
                <div class="cards_wrap">
                    <div (click)="selectThemeTarget('environmental')"
                        [ngClass]="{'selected': selectedTargetThemeFilterName === 'environmental'}"
                        class="card_item environmental-bg taxonomy-color">
                        Environmental
                    </div>
                    <div (click)="selectThemeTarget('social')"
                        [ngClass]="{'selected': selectedTargetThemeFilterName === 'social'}"
                        class="card_item social-bg taxonomy-color">
                        Social
                    </div>
                    <div (click)="selectThemeTarget('governance')"
                        [ngClass]="{'selected': selectedTargetThemeFilterName === 'governance'}"
                        class="card_item governance-bg taxonomy-color">
                        Governance
                    </div>
                </div>
                <app-table-simple
                    noRecordMessage="The producer has not uploaded any targets, goals or commitments data."
                    from="producerProfileSustainability" [config]="targetTableConfig" [tableData]="filteredTargetsData"
                    #table>
                </app-table-simple>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #proofPointTemplate let-rowData>
    <ng-container *ngIf="rowData.proofText || rowData.proofUrl || rowData.proofDocument;else NA_TEMP">
        {{ rowData.proofText === "null" ? "" : rowData.proofText }}
        <a class="proof-url" (click)="redirectToUrl($event, rowData.proofUrl)" href="#">{{ rowData.proofUrl === "null" ?
            "" : rowData.proofUrl }}</a>

        <i *ngIf="rowData.proofDocument" (click)="downloadDocument(rowData, 'PROOF_POINT')" class="fa fa-file"
            aria-hidden="true" title="Download" style="cursor: pointer"></i>
    </ng-container>
    <ng-template #NA_TEMP>N/A</ng-template>
</ng-template>

<ng-template #metricTemplate let-rowData>
    <span class="cursor-pointer" (click)="onRowSelect(rowData)">{{rowData.metric}}</span>
</ng-template>

<ng-template #targetMetricTemplate let-rowData>
    <span>{{rowData?.targetName ? rowData.targetName : rowData.name}}</span>
</ng-template>

<ng-template #proofPointTargetsTemplate let-rowData>
    <ng-container *ngIf="rowData?.documents?.length">
        <span class="link" tabindex="0" role="button" (click)="viewProofDocs($event, rowData)"
            (keyup)="viewProofDocs($event, rowData)">View proof
            documents</span><br>
    </ng-container>
    {{rowData.targetComment}}
    <ng-container *ngIf="!(rowData.documents.length || rowData.targetComment)">
        N/A
    </ng-container>
</ng-template>

<ng-template #progressTemplate let-rowData>
    <div class="text-center">
        <fusioncharts *ngIf="targetsData?.length" type="angulargauge" width="130" height="130" dataFormat="json"
            [dataSource]="rowData.progress">
        </fusioncharts>
    </div>  
</ng-template>

<p-dialog [modal]="true" header="Want to add your company logo and / or an updated sustainability report?"
    [(visible)]="howToaAddYourCompanyDialog" [style]="{ width: '60vw' }">
    <p class="mt-3">As a User Admin (producers only), you can add a company logo and / or update your sustainability
        report. Please login to LMEpassport and click on the "Admin Tasks" tab and ensure you're on the "Entity
        Registration" tab to then upload or add your logo (in PNG, JPEG or JPG format) and / or your sustainability
        report (as a web-link or a PDF attachment).
    </p>
</p-dialog>

<p-dialog *ngIf="viewAProofDocsDialog" header="Additional Documents" [modal]="true" [style]="{ width: '30vw' }"
    [(visible)]="viewAProofDocsDialog">
    <app-table-simple [config]="proofDocsConfig" [tableData]="proofDocsData">
    </app-table-simple>

    <p-footer>
        <button class="btn btn-primary" (click)="viewAProofDocsDialog = false">
            Ok
        </button>
    </p-footer>
</p-dialog>

<ng-template #proofDocsActionTemplate let-rowData>
    <i *ngIf="rowData?.documentId" (click)="downloadProofDoc(rowData)" class="fa fa-download" aria-hidden="true" title="Download"
        style="cursor: pointer"></i>
</ng-template>

<p-dialog header='How is the "on track?" dial calculated?' appendTo="body" [modal]="true" [style]="{ width: '50vw' }"
    [(visible)]="howDialCalculatedDialog">
    <p>The "on track?" dial is automatically calculated when the producer inputs their progress against a target, taking
        into account their target year, baseline year, target percentage change, and progress. The dial determines if a
        producer is on schedule or not in achieving their target/goal. Dark blue denotes being slightly behind, while a
        lighter blue shade indicates exceeding expectations. If you have any questions regarding the targets, goals and
        commitments section, please don't hesitate to reach out to us at <a [attr.href]="sustainabilitySupportEmail"
            class="contact-us">{{sustainabilityEmail}}</a>
    </p>
</p-dialog>
<app-side-by-side-info [infoTriggers]="infoTriggers" [sideBySideText]="sideBySideText"
    (dailogClose)="dailogClose()"></app-side-by-side-info>