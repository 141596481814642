export class FilterModel {
  freeText?: string;
  LME_PASSPORT_ID?: any;
  COA_REFERENCE_NUMBER?: string;
  dateFrom?: any;
  dateTo?: any;
  COA_REFERENCE: any;
}

export interface ProjectsFilterModel {
  search?: string;
  program?: string;
  country?: string;
}

export interface UnitsFilterModel {
  program?: string;
  status?: string;
}
