import { CoreModule } from "./core/core.module";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  CommonModule,
  DecimalPipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { LoaderInterceptorService } from "@core/interceptors/loader-interceptor.service";
import { SharedModule } from "@shared/shared.module";
import { RegistrationOutsideComponent } from "./registration/registration.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyFieldDictionarySelect } from "@shared/formly/formly-custom-types/dictionary-select.type.component";
import { FormlyFieldsetComponent } from "@shared/formly/formly-wrappers/formly-fieldset.component";
import { LandingPageComponentOutside } from "./landing-page/landing-page.component";
import { ToastModule } from "primeng/toast";
import { FormlyHorizontalWrapper } from "@shared/formly/formly-wrappers/horizontal-wrapper";
import { TooltipModule } from "primeng/tooltip";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { FormlyFieldDictionarySelectTemplate } from "@shared/formly/formly-custom-types/dictionary-select-type-template";
import { OcrTagggingComponent } from "@module/create-import/components/ocr-taggging/ocr-taggging.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { AuthInterceptor } from "@core/interceptors/auth-interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LiveSustainabilityDisclosuresComponent } from "@shared/components/live-sustainability-disclosures/live-sustainability-disclosures.component";
import { DisclosuresDetailComponent } from "@module/sustainability-disclosure-public-view/components/disclosures-detail/disclosures-detail.component";
import { ChartModule } from "primeng/chart";
import { DialogModule } from "primeng/dialog";
import { AutoCompleteModule } from "primeng/autocomplete";
import { PaginatorModule } from "primeng/paginator";
import { NgSelectModule } from "@ng-select/ng-select";
import { DisclosureComparisonComponent } from "@module/sustainability-disclosure-public-view/components/disclosure-comparison/disclosure-comparison.component";
import { RazorClientTaggingLibModule } from "razor-client-tagging-lib";
import { NgxPaginationModule } from "ngx-pagination";
import { TabViewModule } from "primeng/tabview";
import { FusionChartsModule } from "angular-fusioncharts";
import { CheckboxModule } from "primeng/checkbox";
@NgModule({
  declarations: [
    AppComponent,
    RegistrationOutsideComponent,
    LandingPageComponentOutside,
    OcrTagggingComponent,
    LiveSustainabilityDisclosuresComponent,
    DisclosuresDetailComponent,
    DisclosureComparisonComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    ToastModule,
    TooltipModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxExtendedPdfViewerModule,
    BrowserAnimationsModule,
    ChartModule,
    DialogModule,
    NgSelectModule,
    PaginatorModule,
    FormsModule,
    RazorClientTaggingLibModule,
    NgxPaginationModule,
    TabViewModule,
    FusionChartsModule,
    CheckboxModule,
    FormlyModule.forRoot({
      validationMessages: [
        { name: "required", message: "This field is required" },
      ],
      types: [
        {
          name: "dict-select",
          component: FormlyFieldDictionarySelect,
          wrappers: ["form-field"],
        },
        {
          name: "dict-select-template",
          component: FormlyFieldDictionarySelectTemplate,
          wrappers: ["form-field"],
        },
      ],
      wrappers: [
        { name: "app-formly-fieldset", component: FormlyFieldsetComponent },
        { name: "form-field-horizontal", component: FormlyHorizontalWrapper },
      ],
    }),
  ],
  providers: [
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
