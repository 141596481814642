import {
  ITableViewConfig,
  ROWS_PER_PAGE,
} from "@shared/models/table-view.model";

export const liveDisclosuresListTableConfig: ITableViewConfig = {
  rowsPerPage: 10,
  paginator: true,
  columns: [
    {
      field: "masterProducer",
      header: "Producer Name",
      width: "180px",
      sortField: "masterProducer",
    },
    {
      field: "asset",
      header: "Asset",
      width: "140px",
      sortField: "asset",
    },
    {
      field: "brandStatus",
      header: "Brand Status",
      width: "160px",
      sortField: "brandStatus",
    },
    {
      field: "metals",
      header: "Metal",
      width: "200px",
      sortField: "metals",
    },
    {
      field: "disclosureName",
      header: "Disclosure",
      width: "180px",
      sortField: "disclosureName",
    },
    {
      field: "level",
      header: "Disclosure Level",
      width: "120px",
      sortField: "level",
    },
    {
      field: "validityDateFrom",
      header: "Valid From",
      width: "180px",
      dateTime: true,
      sortField: "validityDateFrom",
    },
    {
      field: "validityDateTo",
      header: "Valid To",
      width: "180px",
      dateTime: true,
      sortField: "validityDateTo",
    },
    {
      field: "metricPercentage",
      header: "Metric",
      width: "120px",
    },
    {
      field: "metricLabel",
      header: "Metric label / unit",
      width: "180px",
    },
    /* {
      field: "proofPoints",
      header: "Proof Point",
      width: "180px",
    }, */
  ],
};

export const producerProfilePublicListTableConfig: ITableViewConfig = {
  rowsPerPage: ROWS_PER_PAGE,
  paginator: true,
  columns: [
    {
      field: "asset",
      header: "Assets",
      width: "180px",
      sortField: "asset",
    },
    {
      field: "country",
      header: "asset location",
      width: "140px",
      sortField: "country",
    },
    {
      field: "producerName",
      header: "Producer name",
      width: "150px",
      sortField: "producerName",
    },
    {
      field: "brand",
      header: "Brand",
      width: "140px",
      sortField: "brand",
    },
    {
      field: "brandStatus",
      header: "Brand Status",
      width: "140px",
      sortField: "brandStatus",
    },
    {
      field: "metal",
      header: "Metal",
      width: "200px",
      sortField: "metal",
    },
  ],
};

export const disclosurePublicListTableConfig: ITableViewConfig = {
  rowsPerPage: ROWS_PER_PAGE,
  paginator: true,
  columns: [
    {
      field: "disclosure",
      header: "Disclosures",
      width: "450px",
      sortField: "disclosure",
    },
    {
      field: "level",
      header: "Disclosure Level",
      width: "150px",
      sortField: "level",
    },
    {
      field: "brand",
      header: "Brand",
      width: "200px",
      sortField: "brand",
    },
    {
      field: "asset",
      header: "Assets Captured",
      width: "220px",
      sortField: "asset",
    },
    // {
    //   field: "metric",
    //   header: "Metric",
    //   width: "200px",
    // },
    {
      field: "validityDateFromRaw",
      header: "Validity From",
      width: "150px",
      dateTime: true,
    },
    {
      field: "validityDateToRaw",
      header: "Validity To",
      width: "150px",
      dateTime: true,
    },
  ],
};

export const targetsPublicListTableConfig: ITableViewConfig = {
  rowsPerPage: ROWS_PER_PAGE,
  paginator: true,
  columns: [
    {
      field: "targets",
      header: "Targets",
      width: "200px",
      sortField: "targets",
    },
    // {
    //   field: "targetName",
    //   header: "Metric",
    //   width: "120px",
    //   sortField: "targetName",
    // },
  ],
};

export const disclosuresListTableConfig: ITableViewConfig = {
  rowsPerPage: ROWS_PER_PAGE,
  columns: [],
};
