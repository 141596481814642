import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IUsers } from "@core/models/api.model";
import { IStakeHolderLookUp } from "@core/models/app.interface";
import { environment } from "@env/environment";
import { USERS_ACTIVE } from "@module/platform-admin/services/entity.service";
import { forkJoin, Observable } from "rxjs";

export const STAKEHOLDERS = "/warehouse-service/stakeholders";
export const ENTITIES = "/warehouse-service/entities";
export const METALS = "/common-service/producers/metals";
export const BRANDS = "/common-service/producers/brands";
export const PRODUCERS = "/common-service/producers/fetchByProducerName";
export const PRODUCERS_GET = "/common-service/producers";
export const ACTIVE_SUSPENDED = "/warehouse-service/entities/userStatus";
export const COUNTRIES = "/common-service/producers/countries";
export const COUNTRIES_BASED_GDL = "/common-service/gdls/countries";
export const SHAPES =
  "/common-service/producers/getProductShapes?type=PRODUCT_SHAPE";
export const THEMES = "/admin-service/themes/fetch?status=ACTIVE";
export const DISCLOSURES = "/admin-service/disclosures/get?status=ACTIVE";
export const USERS = "/common-service/activityLogs/users";
export const ACTIONS = "/common-service/activityLogs/actions";
export const MASTER_PRODUCERS = "/common-service/producers/masters";
export const MASTER_PRODUCERBY_METAL_BRAND =
  "/common-service/producers/getByMetalAndBrand";
export const SUSTAINABLE_BRANDS =
  "/common-service/producers/brands/sustainable";
export const BRAND_DETAILS = "/common-service/producers/masters/brandDetails";
export const SUSTAINABILITY_METALS =
  "/home-service/publicView/sustainability/metals";
export const SUSTAINABILITY_BRANDS =
  "/home-service/publicView/sustainability/brands";
export const MASTER_PRODUCERS_LIVE_SUSTAINABILITY =
  "/home-service/publicView/masterProducers";
export const ASSETS = "/home-service/publicView/sustainability/assets";
export const STAKEHOLDERS_PUBLIC = "/warehouse-service/stakeholders/all";

@Injectable({
  providedIn: "root",
})
export class LookupService {
  constructor(private http: HttpClient) {}

  getStakeHolders(): Observable<IStakeHolderLookUp[]> {
    return this.http.get<IStakeHolderLookUp[]>(
      `${environment.apiUrl}${STAKEHOLDERS}`
    );
  }

  getStakeHolderById(stakeholder): any {
    return this.http.get<IStakeHolderLookUp[]>(
      `${environment.apiUrl}${STAKEHOLDERS}/${stakeholder}`
    );
  }

  getStakeHoldersPublic(): any {
    return this.http.get(`${environment.apiUrl}${STAKEHOLDERS_PUBLIC}`);
  }

  getCompanies(): any {
    return this.http.get(`${environment.apiUrl}${ENTITIES}`);
  }

  getMetals(): any {
    return this.http.get(`${environment.apiUrl}${METALS}`);
  }

  getMetalsLivesustainability(): any {
    return this.http.get(`${environment.apiUrl}${SUSTAINABILITY_METALS}`);
  }

  getBrands(metal?, parent?): any {
    if (metal) {
      let params = new HttpParams();
      params = params.append("metal", metal);
      if (parent === "manage-brands" || parent === "sustainability") {
        params = params.append("nonDeliverable", "true");
      }
      return this.http.get(`${environment.apiUrl}${BRANDS}`, { params });
    } else {
      return this.http.get(`${environment.apiUrl}${BRANDS}`);
    }
  }

  getBrandsLiveSustainability(metals): any {
    let params = new HttpParams();
    params = params.append("metal", metals);
    return this.http.get(`${environment.apiUrl}${SUSTAINABILITY_BRANDS}`, {
      params,
    });
  }

  getBrandMultiMetal(metals, type, isAllStatus?) {
    const multiPuts = [];
    metals.forEach((item) => {
      multiPuts.push(this.addLineItem(item, type, isAllStatus));
    });
    return forkJoin(multiPuts);
  }

  addLineItem(metal, type, isAllStatus?) {
    let params = new HttpParams();
    params = params.append("metal", metal);
    isAllStatus && (params = params.append("isAllStatus", "true"));
    if (type === "nonDeliverable") {
      params = params.append("nonDeliverable", "true");
      return this.http.get(`${environment.apiUrl}${SUSTAINABLE_BRANDS}`, {
        params,
      });
    } else {
      return this.http.get(`${environment.apiUrl}${BRANDS}`, { params });
    }
  }

  getProducers(): any {
    return this.http.get(`${environment.apiUrl}${PRODUCERS_GET}`);
  }

  getActiveSuspendedCompanies(pending?): any {
    let statuses = ["ACTIVE", "SUSPENDED"];
    if (pending) {
      statuses.push("PENDING");
    }
    return this.http.post(`${environment.apiUrl}${ACTIVE_SUSPENDED}`, statuses);
  }

  getActiveCompanies(): any {
    return this.http.get(`${environment.apiUrl}${ENTITIES}?active=true`);
  }

  getAssets(): any {
    return this.http.get(`${environment.apiUrl}${ASSETS}`);
  }

  getTerminatedCompanies(): any {
    return this.http.post(`${environment.apiUrl}${ACTIVE_SUSPENDED}`, [
      "TERMINATED",
    ]);
  }

  getBrandsByProducer(producer, parent?, isActive?): any {
    let params = new HttpParams();
    if (parent === "sustainability" || parent === "append") {
      params = params.append("nonDeliverable", "true");
    }

    let data = isActive
      ? { name: producer, isActive: true }
      : { name: producer };

    return this.http.post(`${environment.apiUrl}${PRODUCERS}`, data, {
      params,
    });
  }

  getBrandDetails(payload): any {
    let params = new HttpParams();
    params = params.append("nonDeliverable", "true");
    params = params.append("isAllStatus", "true");
    return this.http.post(`${environment.apiUrl}${BRAND_DETAILS}`, payload, {
      params,
    });
  }

  getCountries(): any {
    return this.http.get(`${environment.apiUrl}${COUNTRIES}`);
  }

  getCountriesBasedOnGDL(): any {
    return this.http.get(`${environment.apiUrl}${COUNTRIES_BASED_GDL}`);
  }

  getShapes(): any {
    return this.http.get(`${environment.apiUrl}${SHAPES}`);
  }

  getThemes(): any {
    return this.http.get(`${environment.apiUrl}${THEMES}`);
  }

  getDisclosures(): any {
    return this.http.get(`${environment.apiUrl}${DISCLOSURES}`);
  }

  getUsers(): any {
    return this.http.get(`${environment.apiUrl}${USERS}`);
  }

  getActions(): any {
    return this.http.get(`${environment.apiUrl}${ACTIONS}`);
  }

  getUsersLisiting(entityId): any {
    if (entityId) {
      return this.http.get(
        `${environment.apiUrl}${USERS_ACTIVE}&entityId=${entityId}`
      );
    } else {
      return this.http.get(`${environment.apiUrl}${USERS_ACTIVE}`);
    }
  }

  getMasterProducers(): any {
    return this.http.get(`${environment.apiUrl}${MASTER_PRODUCERS}`);
  }

  getMasterProducersLiveSustainability(): any {
    return this.http.get(
      `${environment.apiUrl}${MASTER_PRODUCERS_LIVE_SUSTAINABILITY}`
    );
  }

  getMasterProducersByMetalBrand(metal, brand): any {
    let params = new HttpParams();
    params = params.append("metal", metal);
    params = params.append("brand", brand);

    return this.http.get(
      `${environment.apiUrl}${MASTER_PRODUCERBY_METAL_BRAND}`,
      { params }
    );
  }

  getMasterProducersMultiMetal(metals, brand, producerName) {
    const multiPuts = [];
    if (Array.isArray(metals)) {
      metals.forEach((item) => {
        multiPuts.push(
          this.addLineItemMasterProducer(item, brand, producerName)
        );
      });
    } else {
      multiPuts.push(
        this.addLineItemMasterProducer(metals, brand, producerName)
      );
    }
    return forkJoin(multiPuts);
  }

  addLineItemMasterProducer(metal, brand, producerName) {
    let params = new HttpParams();
    params = params.append("metal", metal);
    params = params.append("brand", brand);
    params = params.append("producerName", producerName);
    params = params.append("nonDeliverable", "true");
    return this.http.get(
      `${environment.apiUrl}${MASTER_PRODUCERBY_METAL_BRAND}`,
      { params }
    );
  }

  getEntitiesEntityLevel(metals) {
    const multiPuts = [];
    metals.forEach((item) => {
      multiPuts.push(this.addLineItemEntity(item));
    });
    return forkJoin(multiPuts);
  }

  addLineItemEntity(metal) {
    let params = new HttpParams();
    params = params.append("metal", metal);
    params = params.append("nonDeliverable", "true");
    params = params.append("isAppendScreen", "true");
    return this.http.get(`${environment.apiUrl}${MASTER_PRODUCERS}`, {
      params,
    });
  }
}
