import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";

@Pipe({
  name: "customFormat",
})
export class CustomFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any): string | number {
    if (typeof value === "number") {
      // Use the Angular DecimalPipe to format the number
      return this.decimalPipe.transform(value, "1.0-0", "en-US");
    } else {
      return value;
    }
  }
}
