import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { enLocales, frLocales } from "./date-range.locales";
import { Moment } from "moment";

export interface IDateRange {
  dateFrom: Date;
  dateTo: Date;
}

@Component({
  selector: "app-date-range",
  templateUrl: "./date-range.component.html",
  styleUrls: ["./date-range.component.scss"],
  providers: [DatePipe],
})
export class DateRangeComponent implements OnInit, OnDestroy, OnChanges {
  public locales = {
    en: enLocales,
    fr: frLocales,
  };

  @Output() datesChanged = new EventEmitter<IDateRange>();

  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() lang;
  @Input() dateRangeLabelInput?: string;

  dateFromUTC: UTCDate;
  dateToUTC: UTCDate;

  private _destroy$ = new Subject<any>();
  private wasInside = false;

  dateRangeLabel: string;
  calendarOptionsOpened = false;
  isDateChanged = false;
  selectedDateOption: any;
  maxDateStartDate: Date;
  maxDateEndDate: Date;
  minDateEndDate: Date;

  constructor(public datePipe: DatePipe, private eRef: ElementRef) {
    this.dateFromUTC = new UTCDate(new Date());
    this.dateToUTC = new UTCDate(new Date());
  }

  /* @HostListener("document:click", ["$event"])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.calendarOptionsOpened = false;
    } 
    if (this.eRef.nativeElement.contains(event.target)) {
      console.log("clicked inside");
    } else {
      console.log("clicked outside");
    }
  } */

  @HostListener("click")
  clickInside() {
    this.wasInside = true;
  }

  @HostListener("document:click")
  clickout() {
    if (!this.wasInside) {
      this.calendarOptionsOpened = false;
    }
    this.wasInside = false;
  }

  ngOnInit() {
    this.maxDateStartDate = new Date();
    this.maxDateEndDate = new Date();
    this.setDateRangeLabel();
  }

  toggleCalendar() {
    this.calendarOptionsOpened = !this.calendarOptionsOpened;
    if (this.isDateChanged) {
      this.updateDatesInDashboardContext();
    }
  }

  setDateRangeLabel() {
    if (this.dateFrom && this.dateTo) {
      this.dateRangeLabel = `${moment(this.dateFrom).format("DD MMMM YYYY")}
      - ${moment(this.dateTo).format("DD MMMM YYYY")}`;
    } else {
      this.dateRangeLabel = this.dateRangeLabelInput
        ? this.dateRangeLabelInput
        : "Date range";
    }
  }

  customCalendarDatesChanged(from?) {
    /* this.dateFrom = new Date(
      moment(this.dateFrom).format("YYYY-MM-DDT00:00:00")
    );
    this.dateTo = new Date(moment(this.dateTo).format("YYYY-MM-DDT23:59:59")); */
    this.dateFrom = new Date(
      moment(this.dateFromUTC.boundDate).format("YYYY-MM-DDT00:00:00")
    );
    this.dateTo = new Date(
      moment(this.dateToUTC.boundDate).format("YYYY-MM-DDT23:59:59")
    );

    if (from === "start") {
      this.minDateEndDate = this.dateFrom;
    } else if (from === "end") {
      this.maxDateStartDate = this.dateTo;
    }

    this.setDateRangeLabel();
    this.isDateChanged = true;

    this.updateDatesInDashboardContext();
  }

  updateDatesInDashboardContext(): void {
    if (this.dateFrom && this.dateTo) {
      this.datesChanged.emit({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      });
      this.isDateChanged = false;
    }
  }

  private toUTC(date: Date): Date {
    return moment.utc(moment(date).format("YYYY-MM-DD")).toDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setDateRangeLabel();
  }

  keyPressed(event, method: string, ignoredKeys: string[] = []): void {
    if (!this.calendarOptionsOpened) {
      if (!(event.key && ignoredKeys.includes(event.key))) {
        this[method]();
      }
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (this.calendarOptionsOpened) {
      if (event.key === "Escape") {
        this.toggleCalendar();
        (
          document.getElementsByClassName("hotspot-area")[0] as HTMLElement
        ).focus();
      }
    }
  }

  private toDate(d: Moment): Date {
    return new Date(
      d.year(),
      d.month(),
      d.date(),
      d.hours(),
      d.minutes(),
      d.seconds()
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}

enum DateOption {
  THIS_MONTH,
  THIS_YEAR,
  LAST_MONTH,
  LAST_YEAR,
}

class UTCDate {
  private boundDateBacker: Date;
  constructor(private dateInstance: Date) {
    this.boundDateBacker = this.utcToLocal(dateInstance);
  }

  public get boundDate(): Date {
    return this.boundDateBacker;
  }
  public set boundDate(value: Date) {
    if (value) {
      this.dateInstance.setTime(this.localToUtc(value).getTime());
      const newTime = value.getTime();
      if (newTime !== this.boundDateBacker.getTime()) {
        this.boundDateBacker.setTime(newTime);
      }
    }
  }

  private localToUtc(date: Date): Date {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  private utcToLocal(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }
}
