<div class="table-container" [class.relative]="relative" #tableContainer>
  <ng-container *ngIf="!config?.paginator; else WITH_PAGINATOR_ROW_EXPANSION">
    <p-table [ngClass]="config?.className || 'lme'" [class.relative]="relative" [value]="data" [resizableColumns]="true"
      [scrollable]="!relative" scrollHeight="100%" *ngIf="config?.columns" [tableStyle]="tableStyles"
      [dataKey]="config?.dataKey || 'id'" [responsive]="true" #dT>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of config?.columns" [class.checkbox-col]="col.checkboxSelect"
            [style.width]="col.expandIcon ? '40px' : 'auto'" />
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pResizableColumn *ngFor="let col of config.columns; let i = index" [pSortableColumn]="col.sortField"
            [style.width]="col.expandIcon ? '40px' : 'auto'">
            <ng-container *ngIf="col.checkboxSelect; else HEADER_FIELD">
              <p-tableHeaderCheckbox wcag-label></p-tableHeaderCheckbox>
            </ng-container>

            <ng-template #HEADER_FIELD>
              <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                <span [innerHTML]="config.translationPrefix + '.' + col.header"></span>
              </ng-container>

              <ng-template #NO_TRANSLATION>
                <span [innerHTML]="col.header"></span>
              </ng-template>

              <p-sortIcon id="{{ col.field }}" [field]="col.field" *ngIf="col.sortField"></p-sortIcon>
            </ng-template>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="tableColumns" let-rowIndex="rowIndex"
        let-expanded="expanded">
        <tr class="wcag-visuallyhidden">
          <td *ngFor="let col of config.columns; let i = index" class="wcag-visuallyhidden">
            <span class="ui-column-title">{{col.header}}</span>
            <ng-container *ngIf="col.checkboxSelect; else HEADER_FIELD">&nbsp;</ng-container>
            <ng-template #HEADER_FIELD>
              <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                <span [innerHTML]="config.translationPrefix + '.' + col.header"></span>
              </ng-container>
              <ng-template #NO_TRANSLATION>
                <span>&nbsp;</span>
              </ng-template>
            </ng-template>
          </td>
        </tr>
        <tr #rE [pSelectableRow]="rowData">
          <td *ngFor="let col of config.columns; let i = index" [ngClass]="col.className">
            <ng-container *ngIf="col.checkboxSelect; else ROW_FIELD">
              <p-tableCheckbox wcag-label [value]="rowData"></p-tableCheckbox>
            </ng-container>

            <ng-template #ROW_FIELD>
              <ng-container *ngIf="!col.templateRef; else CUSTOM_CONTENT">
                <ng-container *ngIf="col.translate; else DO_NOT_TRANSLATE">
                  <!-- FIELD WITH EXPAND COLLAPSE -->
                  <ng-container *ngIf="col.expandCollapse; else REGULAR_DISPLAY">
                    <ng-container *ngIf="
                        col.expandIcon && rowData[col.expandSectionData];
                        else DATA_EXPANDER
                      ">
                      <a href="#" [pRowToggler]="rowData" *ngIf="
                          !col.expandSectionData ||
                          (col.expandSectionData &&
                            rowData[col.expandSectionData] &&
                            rowData[col.expandSectionData].length > 1)
                        ">
                      </a>
                    </ng-container>
                    <ng-template #DATA_EXPANDER>
                      <a href="#" style="color: #012640" *ngIf="rowData[col.expandSectionData]" [pRowToggler]="rowData">
                        {{
                        (col.translationPrefix || config.translationPrefix) +
                        "." +
                        rowData[col.field]
                        }}
                      </a>
                    </ng-template>
                  </ng-container>

                  <!-- FIELD PURELY REGULAR DISPLAY -->
                  <ng-template #REGULAR_DISPLAY>
                    <ng-container *ngIf="
                        rowData[col.field] != null &&
                          rowData[col.field].length > 0;
                        else IS_NULL
                      ">
                      {{
                      (col.translationPrefix || config.translationPrefix) +
                      "." +
                      rowData[col.field]
                      }}
                    </ng-container>
                    <ng-template #IS_NULL></ng-template>
                  </ng-template>
                </ng-container>

                <ng-template #DO_NOT_TRANSLATE>
                  <!-- FIELD WITH EXPAND COLLAPSE -->
                  <ng-container *ngIf="col.expandCollapse; else REGULAR_DISPLAY">
                    <ng-container *ngIf="
                        col.expandIcon && rowData[col.expandSectionData];
                        else DATA_EXPANDER
                      ">
                      <a href="#" [pRowToggler]="rowData" *ngIf="
                          !col.expandSectionData ||
                          (col.expandSectionData &&
                            rowData[col.expandSectionData] &&
                            rowData[col.expandSectionData].length > 1)
                        ">
                      </a>
                    </ng-container>
                    <ng-template #DATA_EXPANDER>
                      <a href="#" style="color: #012640" *ngIf="rowData[col.expandSectionData]" [pRowToggler]="rowData">
                        <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">
                          <ng-container *ngIf="!col.numeric; else NUMERIC">
                            <ng-container *ngIf="
                                rowData[col.field] !== null &&
                                  rowData[col.field].length > 0;
                                else IS_NULL
                              ">
                              <span [innerHTML]="rowData[col.field]"></span>
                            </ng-container>
                            <ng-template #IS_NULL></ng-template>
                          </ng-container>
                        </ng-container>
                      </a>
                    </ng-template>
                  </ng-container>

                  <!-- FIELD PURELY REGULAR DISPLAY -->
                  <ng-template #REGULAR_DISPLAY>
                    <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">
                      <ng-container *ngIf="!col.numeric; else NUMERIC">
                        <ng-container *ngIf="rowData[col.field] !== null; else IS_NULL">
                          <span [innerHTML]="rowData[col.field]"></span>
                        </ng-container>
                        <ng-template #IS_NULL></ng-template>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </ng-template>

                <ng-template #NUMERIC>
                  {{ rowData[col.field] | number: "1." }}
                </ng-template>

                <ng-template #DATETIME_CONTENT>
                  {{ rowData[col.field] | date: "yyyy-MM-dd" }}
                </ng-template>
              </ng-container>

              <ng-template #CUSTOM_CONTENT>
                <ng-container *ngTemplateOutlet="
                    col.templateRef;
                    context: { $implicit: rowData }
                  "></ng-container>
              </ng-template>
            </ng-template>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer" *ngIf="footerTemplate">
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-template #WITH_PAGINATOR_ROW_EXPANSION>
    <p-table [ngClass]="config.className || 'lme'" [class.pagebale]="config.paginator"
      (onLazyLoad)="onPaginationChanged($event)" [lazy]="true" [paginator]="true" [value]="data?.content"
      [totalRecords]="data?.metadata?.totalElements" [rows]="rows" [first]="firstItemIndex" [resizableColumns]="true"
      [(selection)]="selections" [selectionMode]="selectionMode ? selectionMode : null"
      (onRowSelect)="rowClick($event.originalEvent, $event.data)" [tableStyle]="tableStyles" dataKey="id"
      [scrollable]="true" [scrollHeight]="scrollHeight ? scrollHeight : null" [style]="{ width: '100%' }"
      [rowsPerPageOptions]="[10, 20, 50, 100]" [resetPageOnSort]="false" paginatorDropdownAppendTo="body"
      [responsive]="true" #dT>

      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of config.columns; let i = index" [pSortableColumn]="col.sortField"
            [ngStyle]="{'min-width': col.width}">
            <ng-container *ngIf="col.checkboxSelect; else HEADER_FIELD">
              <p-tableHeaderCheckbox (click)="selected()" wcag-label></p-tableHeaderCheckbox>
            </ng-container>

            <ng-template #HEADER_FIELD>
              <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                <span [innerHTML]="config.translationPrefix + '.' + col.header"></span>
              </ng-container>

              <ng-template #NO_TRANSLATION>
                <span [attr.cy-test]="col.header" id="{{col.header}}" [innerHTML]="col.header"></span>
              </ng-template>

              <p-sortIcon id="{{ col.field }}" [field]="col.field" *ngIf="col.sortField"></p-sortIcon>
            </ng-template>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="tableColumns" let-expanded="expanded"
        let-rowIndex="rowIndex">
        <tr [attr.id]="rowData?.id" [ngClass]="{
            'disabled-row': rowData.status === 'DUPLICATE_APPROVED'
          }" #rE [pSelectableRow]="rowData">
          <td [attr.cy-test]="col.field + '_cell'" *ngFor="let col of config.columns; let i = index"
            [ngClass]="col.className">
            <!-- <span class="ui-column-title">{{col.header}}</span> -->
            <!-- CHECKBOX ROW SELECTION -->
            <ng-container *ngIf="col.checkboxSelect; else ROW_FIELD">
              <p-tableCheckbox [value]="rowData" (click)="selected(rowData)" wcag-label></p-tableCheckbox>
            </ng-container>

            <!-- TABLE FIELDS -->
            <ng-template #ROW_FIELD>
              <!-- REGULAR FIELD -->
              <ng-container *ngIf="!col.templateRef; else CUSTOM_CONTENT">
                <!-- FIELD WITH TRANSLATION -->
                <ng-container *ngIf="col.translate; else DO_NOT_TRANSLATE">
                  <!-- FIELD WITH EXPAND COLLAPSE -->
                  <ng-container *ngIf="col.expandCollapse; else REGULAR_DISPLAY">
                    <ng-container *ngIf="col.expandIcon; else EXPANDED_DATA">
                      <a href="#" [pRowToggler]="rowData" *ngIf="
                          !col.expandSectionData ||
                          (col.expandSectionData &&
                            rowData[col.expandSectionData] &&
                            rowData[col.expandSectionData].length > 1)
                        ">
                      </a>
                    </ng-container>

                    <ng-template #EXPANDED_DATA>
                      <a href="#" style="color: #012640" *ngIf="rowData[col.expandSectionData]" [pRowToggler]="rowData">
                        {{
                        (col.translationPrefix || config.translationPrefix) +
                        "." +
                        rowData[col.field]
                        }}
                      </a>
                    </ng-template>
                  </ng-container>

                  <!-- FIELD PURELY REGULAR DISPLAY -->
                  <ng-template #REGULAR_DISPLAY>
                    <ng-container *ngIf="
                        rowData[col.field] !== null &&
                        rowData[col.field].length > 0
                      ">
                      {{
                      (col.translationPrefix || config.translationPrefix) +
                      "." +
                      rowData[col.field]
                      }}
                    </ng-container>
                  </ng-template>
                </ng-container>

                <!-- FIELD WITH-OUT TRANSLATION -->
                <ng-template #DO_NOT_TRANSLATE>
                  <!-- FIELD STRING TYPE -->
                  <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">
                    <!-- FIELD WITH EXPAND COLLAPSE -->

                    <ng-container *ngIf="col.expandIcon; else EXPANDED_DATA">
                      <a href="#" [pRowToggler]="rowData" *ngIf="
                          !col.expandSectionData ||
                          (col.expandSectionData &&
                            rowData[col.expandSectionData] &&
                            rowData[col.expandSectionData].length > 1)
                        ">
                      </a>
                    </ng-container>

                    <ng-template #EXPANDED_DATA>
                      <ng-container *ngIf="
                          col.expandCollapse && col.field;
                          else REGULAR_DISPLAY
                        ">
                        <a href="#" style="color: #012640" *ngIf="
                            isThereSubTable(rowData, col) && rowData[col.field];
                            else NO_EXPAND_COLLAPSE
                          " [pRowToggler]="rowData">
                          {{ rowData[col.field] }}
                        </a>

                        <ng-template #NO_EXPAND_COLLAPSE>
                          {{ rowData[col.field] }}
                        </ng-template>
                      </ng-container>

                      <!-- FIELD PURELY REGULAR DISPLAY -->
                      <ng-template #REGULAR_DISPLAY>
                        <ng-container *ngIf="!col.numeric; else NUMERIC">
                          <ng-container *ngIf="!col.dateTimeStamp; else DATE_TIMESTAMP">
                            {{ rowData[col.field] }}
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </ng-template>
                  </ng-container>

                  <ng-template #NUMERIC>
                    {{ rowData[col.field] | number: "1." }}
                  </ng-template>

                  <!-- FIELD DATETIME TYPE -->
                  <ng-template #DATETIME_CONTENT>
                    <ng-container *ngIf="rowData[col.field]; else NO_DATE">
                      {{ rowData[col.field] | date: "dd MMMM yyyy"}}
                    </ng-container>
                  </ng-template>
                  <ng-template #NO_DATE>
                    <span *ngIf="parent === 'platformAdminLiveView'">N/A</span>
                  </ng-template>
                  <ng-template #DATE_TIMESTAMP>
                    {{ rowData[col.field] | date: "dd MMMM, yyyy h:mm:ss a" }}
                  </ng-template>
                </ng-template>
              </ng-container>

              <!-- CUSTOM FIELD -->
              <ng-template #CUSTOM_CONTENT>
                <ng-container *ngTemplateOutlet="
                    col.templateRef;
                    context: { $implicit: rowData }
                  "></ng-container>
              </ng-template>
            </ng-template>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="config.columns.length">No records found</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorright">
        Total Records:
        {{ data?.metadata?.totalElements | number: "1.0":"en-US" }}
      </ng-template>
    </p-table>
  </ng-template>
</div>

<ng-template #TABLE_CONTENT> </ng-template>