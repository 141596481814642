import { IEntityList } from "@core/models/api.model";
import { ILookup } from "@core/models/app.interface";

export enum ActionOptionType {
  APPROVE,
  REJECT,
  URL,
  FILEUPLOAD,
  DOWNLOAD,
  SERVICE,
  EXPORT,
  BULK_APPROVE,
  BULK_REJECT,
  BULK_SUSPEND,
  BULK_TERMINATE,
  BULK_ACTIVATE,
}

export class FilterFieldsLists {
  stakeHolderType?: ILookup[];
  metal?: ILookup[];
  brand?: ILookup[];
  companyType?: IEntityList[];
  status?: ILookup[];
  recordType: ILookup[];
  recordStatus: any;
  companyTypeActiveSuspended: Object;
  companyTypeTerminated: any;
  producer?: any;
  shape?: any;
  standardMet: any;
  brandToDoList: any;
  users: any;
  actions: any;
  registrationStatus: any;
  scrCompliant: any[];
  level?: any;
  coaReference?: any;
  masterProducer?: any;
  brandStatus: { id: string; name: string }[];
  deliverable: { id: string; name: string }[];
  assets?: any;
  targetYear?: number[];
  themeSubTheme?: any;
  target?: any;
  brands?: any[];
  country?: any[];
  gdl?: any[];
  warehouse?: any[];
  metalsGDL?: any[];
  significantValueChange: any;
}

export interface ITableFilterActionModel {
  filterListsModel?: IFilterModel[];
  actionModel?: IActionModel[];
  searchBox?: boolean;
  dateRange?: boolean;
  dateRangeLabel?: string;
  adminAction?: boolean;
  from?: string;
  searchButtons?: boolean;
  customButton?: ICustomButton;
  passportIdFilter?: boolean;
  disclosures?: boolean;
  searchBoxPlaceholder?: string;
  coaBulkSearch?: boolean;
  brandLoadNotRequired?: boolean;
  passportIdSearchPlaceHolder?: string;
  passportIdSearchToolTipText?: string;
  coaBulkSearchPlaceHolder?: string;
}

export interface ICustomButton {
  label?: string;
  disabled?: boolean;
}

export interface IFilterModel {
  placeholder?: string;
  itemsType?: string;
  multiple?: boolean;
  selectableGroup?: boolean;
  closeOnSelect?: boolean;
  ngModel?: string;
  notFoundText?: string;
}

export interface IActionModel {
  data_id?: string;
  id?: string;
  placeholder?: string;
  options?: IActionOptionsModel[];
}

export interface IActionOptionsModel {
  id?: any;
  labelFormatted?: string;
  label?: string;
  uri?: string;
  confirmationRequired?: boolean;
  default?: boolean;
}

export interface ITableFilterSortedField {
  fieldType: string;
  index?: number;
  last?: boolean;
  data?: any;
  options?: any;
}
