<app-header-login></app-header-login>
<div class="container-fluid mt-3 mb-3">
  <div class="row mb-3">
    <div class="col-12 note">
      Note: If your company has already been registered, you will have the option to request individual user access
      after
      selecting your company name. If your company is not registered, you will need to complete the company
      information fields and main contact details in order to proceed. If you have any support queries, please contact
      LME Platform Administrator at <a [attr.href]="emailLink"> {{ email }}</a>.
    </div>
  </div>
  <h4 class="badge-company mb-3">Company Information</h4>
  <div class="panel">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
      <button type="submit" class="btn btn-primary submit-button mt-3 mb-3">
        Submit
      </button>
    </form>
    <div class="mt-2 mb-2 col-md-12">
      Note: I understand all Users are required to read and accept LMEpassport
      <a (click)="downloadTerms($event)" wcag-dummy-link-target href="#">TERMS OF USE</a>
      and the LMEpassport
      <a (click)="downloadEula($event)" wcag-dummy-link-target href="#">END USER LICENSE AGREEMENT</a>.
    </div>
  </div>
</div>
<app-loader></app-loader>
<app-footer from="home"></app-footer>
<p-toast life="300000" sticky="true" position="top-center" key="reg"></p-toast>

<p-dialog [style]="{ width: '80vw' }" header="User Type" [modal]="true" [(visible)]="userTypeDialog">
  <div class="mb-4 field-checkbox">
    <p-checkbox class="user-type-checkbox" name="group1" value="San Francisco" [(ngModel)]="selectedUserType"
      inputId="sf"></p-checkbox>
    <label class="user-type-label ml-2" for="sf">By default, you will be registered as an individual user. If you
      would
      like to increase
      permissions on your account, please tick this box to indicate your request.<br><br>

      Once your individual user account has been approved, your request to increase access on LMEpassport will be
      sent to any user admins registered at the same company name to provide approval. You will still have
      access to login to LMEpassport as an individual user. <br><br>

      <span class="bold">Individual user:</span> This will provide you with the ability to access the platform,
      view Certificates of
      Analysis and disclose sustainability credentials (if you’re an LME-listed producer)<br><br>

      <span class="bold">User admin:</span> This will enable you to be able to create Certificates of Analysis
      (producers and warehouses
      only), as well as the ability to create or delete additional accounts for colleagues </label>
  </div>
  <form [formGroup]="form" (ngSubmit)="registerUser()" #myForm="ngForm" class="ml-2">
    <formly-form [model]="higherPermissionModel" [fields]="higherPermissionFields"
      [form]="higherPermissionForm"></formly-form>
    <button type="submit" class="btn btn-primary submit-button mt-3 mb-3">
      Submit
    </button>
  </form>

  <div class="mt-2 mb-2 col-md-12">
    Note: I understand all Users are required to read and accept LMEpassport
    <a (click)="downloadTerms($event)" wcag-dummy-link-target href="#">TERMS OF USE</a>
    and the LMEpassport
    <a (click)="downloadEula($event)" wcag-dummy-link-target href="#">END USER LICENSE AGREEMENT</a>.
  </div>
</p-dialog>