import { HttpClient, HttpEventType, HttpRequest } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { CoreService } from "@core/services/core.service";
import { environment } from "@env/environment";

@Component({
  selector: "app-upload-additiona-docs",
  templateUrl: "./upload-additiona-docs.component.html",
  styleUrls: ["./upload-additiona-docs.component.scss"],
})
export class UploadAdditionaDocsComponent implements OnInit {
  @Input() addtionalDocsDialog: boolean;
  @Input() docId: number;
  @Input() coaReference: string;
  @Input() coaDocument: number;

  @Output() additionalDocsDialogHide = new EventEmitter<any>();

  additionalFiles = [];
  docs: any[];

  @ViewChildren("cmp") components: QueryList<any>;
  queueInProgress: boolean;

  constructor(private coreService: CoreService, private http: HttpClient) {}

  ngOnInit() {
    this.additionalFiles.push({
      progress: 0,
    });
  }

  get progress(): number {
    let psum = 0;

    for (let entry of this.additionalFiles) {
      psum += entry.progress;
    }

    if (psum == 0) return 0;

    return Math.round(psum / this.additionalFiles.length);
  }

  addRow() {
    this.additionalFiles.push({
      progress: 0,
    });
  }

  selectAdditionalFile(event, index) {
    event && event.preventDefault();
    this.docs = this.components.toArray();
    this.docs[index].nativeElement.click();
  }

  addAdditionalFile(event, index) {
    this.additionalFiles[index] = {
      file: event.target.files[0],
      progress: 0,
    };
  }

  removeAdditonalDoc(index) {
    this.additionalFiles.splice(index, 1);
  }

  upload() {
    let filesNotAdded = 0;
    for (let index = 0; index < this.additionalFiles.length; index++) {
      if (!this.additionalFiles[index].hasOwnProperty("file")) {
        filesNotAdded++;
        break;
      }
    }

    if (filesNotAdded) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please attach all files to upload.",
      });

      return false;
    }

    this.queueInProgress = true;
    let fileUploadCounter = 0;
    for (let item of this.additionalFiles) {
      const formData = new FormData();
      formData.append("file", item.file, item.file.name);
      fileUploadCounter++;

      const apiUrl = `${environment.apiUrl}/doc-upload-service/documentfiles/upload`;
      const doctype = "ATTACHMENT";
      const source = "PORTAL";
      const parentDocument = encodeURIComponent(this.docId);
      const coaDocument = encodeURIComponent(this.coaDocument);
      const coaReference = encodeURIComponent(this.coaReference);

      const url = `${apiUrl}?doctype=${doctype}&source=${source}&parentDocument=${parentDocument}&coaDocument=${coaDocument}&coaReference=${coaReference}`;

      const uploadReq = new HttpRequest("POST", url, formData, {
        reportProgress: true,
      });

      this.http.request(uploadReq).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            item.progress = Math.round((100 * event.loaded) / event.total);
          } else if (event.type === HttpEventType.Response) {
            item.message = event.body.toString();
            if (fileUploadCounter === this.additionalFiles.length) {
              this.coreService.showMessage({
                key: "tc",
                severity: "success",
                summary: "Success",
                detail: `File(s) uploaded successfully.`,
              });
            }
          }
        },
        (err) => {
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: `${err.error.message.split(":")[1]}`,
          });
        }
      );
    }
  }

  onAdditionalDocsDialogHide(event) {
    this.additionalDocsDialogHide.emit();
  }
}
