import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { environment } from "@env/environment";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-field-upload",
  styleUrls: ["./upload-type.scss"],
  template: `
    <div *ngIf="!to.onlyDownload" class="form-group">
      <div class="input-group input-file">
        <ng-container *ngIf="!to.hideField">
          <span class="input-group-btn">
            <button
              class="btn btn-primary btn-upload"
              (click)="selectFilesType($event)"
              (keypress)="selectFilesType($event)"
            >
              Browse
            </button>
            <label class="wcag-visuallyhidden">Choose a File</label>
            <input
              #fileInputType
              data-cy="file-input"
              (change)="addFileType($event) && to.change(field, $event)"
              class="hidden"
              type="file"
              accept="{{ to?.accept }}"
              placeholder="Choose a File"
              [formControl]="formControl"
              [formlyAttributes]="field"
            />
          </span>
          <input
            tabindex="-1"
            type="text"
            class="form-control"
            value="{{ fileType?.name || to.defaultValue }}"
            readonly
            placeholder="Choose a File"
            accept="{{ to?.accept }}"
            [attr.id]="to.id || 'fileName'"
          />
        </ng-container>
        <img
          *ngIf="to?.preview && to?.documentId"
          style="height: auto; max-width: 200px; object-fit: cover; margin-left: 20px; cursor: pointer"
          src="{{
            env.apiUrl + '/doc-upload-service/documentfiles/' + to?.documentId
          }}"
          (click)="showPreview = true"
        />
        <i
          *ngIf="to.allowDelete"
          (click)="to.onDelete($event)"
          class="fa fa-trash trash action text-center mt-2 ml-2"
          aria-hidden="true"
          title="Delete"
        ></i>
      </div>
    </div>

    <div *ngIf="to.onlyDownload" class="form-group">
      <span class="file-name">
        <a
          [href]="
            env.apiUrl + '/doc-upload-service/documentfiles/' + to?.documentId
          "
          target="_blank"
        >
          {{ to?.documentName }}
        </a>
      </span>
    </div>

    <p-dialog
      header="Logo"
      [modal]="true"
      [(visible)]="showPreview"
      [style]="{ width: '40vw' }"
    >
      <img
        *ngIf="to?.preview && to?.documentId"
        style="max-width: 100%;
        height: auto; object-fit: cover"
        src="{{
          env.apiUrl + '/doc-upload-service/documentfiles/' + to?.documentId
        }}"
      />
    </p-dialog>
  `,
})
export class UploadInput extends FieldType implements OnInit {
  fileType: any;
  showPreview: false;
  @ViewChild("fileInputType", { static: false }) fileInputType: ElementRef;
  env: { production: boolean; apiUrl: string; serviceUrl: string };

  ngOnInit() {
    this.env = environment;
  }

  selectFilesType(event) {
    event && event.preventDefault();
    this.fileInputType.nativeElement.click();
    return true;
  }

  addFileType(event) {
    this.fileType = event.target.files[0];
    return true;
  }
}
