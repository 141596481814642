<p-dialog header="Accept Terms & Conditions" [modal]="true" [(visible)]="showTandCDialog" [closeOnEscape]="false"
  [closable]="false" [style]="{ width: '25vw' }">
  <p>Please click on the links below and read before proceeding</p>
  <br />
  <a id="downloadTerms" (click)="downloadTerms($event)" wcag-dummy-link-target>TERMS OF USE</a>
  <br />
  <a id="downloadEula" (click)="downloadEula($event)" wcag-dummy-link-target>END USER LICENSE AGREEMENT</a>
  <p-footer>
    <button class="btn btn-primary" (click)="acceptTC()">Accept</button>
  </p-footer>
</p-dialog>