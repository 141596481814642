import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "formly-field-dictionary-select",
  template: `
    <p-multiSelect
      [options]="items"
      [formControl]="formControl"
      (onChange)="to.change && to.change(field, $event)"
      (onPanelShow)="changeDropDownIcon('up')"
      (onPanelHide)="changeDropDownIcon('down')"
      [maxSelectedLabels]="20"
      [tooltip]="toolTip"
      [defaultLabel]="to.defaultLabel"
      [dropdownIcon]="dropDownIcon"
      [inputId]="to.id"
    ></p-multiSelect>
  `,
})
export class FormlyFieldDictionarySelectAll
  extends FieldType
  implements OnInit, OnDestroy
{
  onDestroy$ = new Subject<void>();

  isLoading = false;
  items = [];
  source: any;
  dataProvided: boolean;
  public placeholder: string;
  toolTip: string;
  dropDownIcon: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.dropDownIcon = "fa fa-caret-down";
    this.source = this.to.source;
    this.dataProvided = this.to.dataProvided;
    this.loadData();
  }

  changeDropDownIcon(direction) {
    this.dropDownIcon = `fa fa-caret-${direction}`;
  }

  get disabled() {
    return this.to.disabled;
  }

  private loadData() {
    this.isLoading = true;
    this.source
      .pipe(
        map((data: any) => {
          let iterableData = [];
          if (data) {
            data.forEach((element) => {
              iterableData.push({
                label: element,
                value: element,
              });
            });
          }
          return iterableData;
        })
      )
      .subscribe((data) => {
        this.items = data;
        console.log(this.items);
        this.isLoading = false;
        if (this.model.metals && this.model.metals.length > 1) {
          !this.to.editable && (this.toolTip = this.model.metals.join(",\n"));
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.complete();
  }
}
