import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-user-public-view",
  templateUrl: "./user-public-view.component.html",
})
export class UserPublicViewComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.navigate(["/public/producer-profile"]);
  }
}
