import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-field-input-switch",
  template: `
    <div
      class="custom-input-switch"
      [ngStyle]="{ display: to.display === 'block' ? 'block' : 'inline-block' }"
    >
      <p-inputSwitch
        [formControl]="formControl"
        [formlyAttributes]="field"
        (onChange)="to.change && to.change(field, $event)"
      ></p-inputSwitch>
      <div
        class="switch-labels"
        [ngClass]="{
          'on-label': formControl.value,
          'off-label': !formControl.value
        }"
      >
        <ng-container>{{ formControl.value ? "ON" : "OFF" }}</ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .custom-input-switch {
        position: relative;
        vertical-align: middle;
        margin-left: 0.5rem;
        width: 15%;
      }
      .switch-labels {
        position: absolute;
        top: 50%;
        width: 100%;
        pointer-events: none;
        font-weight: bold;
        font-size: 0.65em;
        text-align: center;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
      }
      .on-label {
        left: 5px;
        color: white;
        bottom: -4px;
      }
      .off-label {
        left: 30px;
        color: white;
        bottom: -4px;
      }
      .p-inputswitch-checked .on-label {
        color: white;
      }
      .p-inputswitch:not(.p-inputswitch-checked) .off-label {
        color: white;
      }
      ::ng-deep .p-inputswitch {
        width: 3.5rem;
      }

      ::ng-deep
        .p-inputswitch.p-inputswitch-checked
        .p-inputswitch-slider:before {
        transform: translateX(1.65rem);
      }
    `,
  ],
})
export class InputSwitchTypeComponent extends FieldType {
  isChecked: boolean;

  ngOnInit() {}
}
