import { Injectable } from "@angular/core";
import {
  HttpResponse,
  HttpEvent,
  HttpRequest,
  HttpHandler,
} from "@angular/common/http";
import { LoaderService } from "@core/services/loader.service";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderInterceptorService {
  constructor(private loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const requestedUrlSubStr = "/admin-service/settings/party";
    if (req.url.indexOf(requestedUrlSubStr) === -1) {
      this.showLoader();
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (
            event instanceof HttpResponse &&
            event.type === 4 &&
            req.url.indexOf(requestedUrlSubStr) === -1
          ) {
            this.onEnd();
          }
        },
        (err: any) => {
          this.onEnd();
        }
      )
    );
  }

  public onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
