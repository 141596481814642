import { HttpParams } from '@angular/common/http';

export abstract class AbstractPaginationService {
  protected addPagination(params: HttpParams, pagination): HttpParams {
    if (pagination) {
      if (pagination.page !== '') {
        params = params.append('page', String(pagination.page));
      }
      if (pagination.sort) {
        params = params.append('sort', String(pagination.sort));
      }
      if (pagination.dir) {
        params = params.append('dir', String(pagination.dir));
      }
      if (pagination.size) {
        params = params.append('size', String(pagination.size));
      }
    }
    return params;
  }
}
