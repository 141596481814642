<p-dialog
  [(visible)]="editDialog"
  [focusOnShow]="false"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [style]="{ width: '55%' }"
  (onHide)="hideEditCoa()"
>
  <p-header>
    {{ newRecord ? "Add Row" : "Edit Row" }}
  </p-header>
  <form [formGroup]="form" #myForm="ngForm">
    <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
  </form>
  <p-footer>
    <button class="btn btn-primary" (click)="save()">
      {{ newRecord ? "Add Row" : "Edit Row" }}
    </button>
  </p-footer>
</p-dialog>
