<p-dialog [(visible)]="reviewChemicalCompDialog" [modal]="true" [style]="{ width: '40vw' }" [draggable]="false"
  [resizable]="false" (onHide)="hideChemicalComp($event)">
  <ng-template pTemplate="header">
    Chemical Composition <ng-container *ngIf="castReference">({{castReference}})</ng-container>
  </ng-template>
  <app-table-simple from="chemicalComposition" [config]="tableConfig" [tableData]="tableData">
  </app-table-simple>
  <p-footer>
    <button type="button" (click)="hideChemicalComp($event)" class="btn btn-primary" label="Yes">
      Ok
    </button>
  </p-footer>
</p-dialog>