import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CoreService } from "@core/services/core.service";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
})
export class TermsAndConditionsComponent {
  @Input() showTandCDialog: boolean;

  @Output() hideTCDialog = new EventEmitter<any>();

  readTerms = false;
  readEula = false;

  constructor(private coreService: CoreService) {}

  acceptTC() {
    if (!this.readTerms) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail:
          "Please read TERMS OF USE and END USER LICENSE AGREEMENT before proceeding.",
      });
      return false;
    }

    if (!this.readEula) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please read END USER LICENSE AGREEMENT before proceeding.",
      });
      return false;
    }
    this.hideTCDialog.emit();
  }

  downloadTerms(event) {
    event.preventDefault();
    this.readTerms = true;
    window
      .open(
        `https://${window.location.hostname}/api/doc-upload-service/resources/download?type=TOU`,
        "_blank"
      )
      .focus();
  }

  downloadEula(event) {
    event.preventDefault();
    this.readEula = true;
    window
      .open(
        `https://${window.location.hostname}/api//doc-upload-service/resources/download?type=EULA`,
        "_blank"
      )
      .focus();
  }
}
