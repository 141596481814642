import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CoreService } from "@core/services/core.service";
import { email, helpDeskEmail, HOME, sustainabilityEmail } from "app/app.const";
import { MessageService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
  providers: [MessageService],
})
export class LandingPageComponentOutside implements OnInit, OnDestroy {
  param1: string;
  param2: string;
  supportEmail: string;

  private _destroy$ = new Subject<any>();
  userLoaded: boolean;
  statistics: any;
  sustainabilitySupportEmail: string;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private coreService: CoreService
  ) {
    this.getRouteChange();
  }

  getRouteChange() {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.register
    ) {
      setTimeout(() => {
        this.messageService.add({
          key: "tc",
          severity: "success",
          summary: "Success",
          detail:
            "Your registration has been submitted successfully. You will receive an email once this is approved.",
          sticky: true,
        });
      }, 0);
    }
  }

  ngOnInit() {
    this.supportEmail = `mailto: ${helpDeskEmail}`;
    this.sustainabilitySupportEmail = `mailto: ${sustainabilityEmail}`;
    this.coreService
      .getUserDetails()
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (user) => {
          if (user) {
            localStorage.removeItem("idle");
            localStorage.removeItem("inactive");
            localStorage.removeItem("suspended");
            localStorage.removeItem("terminated");
            return this.router.navigate([HOME]);
          }
        },
        (err) => {
          this.userLoaded = true;
          if (
            err.status === 400 &&
            err.error.message &&
            err.error.message.includes("Your account is not active")
          ) {
            localStorage.setItem("inactive", "true");
          } else if (err.status === 500) {
            localStorage.setItem("suspended", "true");
          }

          this.checkStatuses();
        }
      );

    this.getStatistics();
  }

  getStatistics() {
    this.coreService
      .getStats()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.statistics = res;
      });
  }

  logOut() {
    window.location.href = `https://${window.location.hostname}/redirect_uri?logout=https://${window.location.hostname}/`;
  }

  checkStatuses() {
    let inactive = localStorage.getItem("inactive");
    if (inactive === "true") {
      setTimeout(() => {
        this.messageService.add({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail: `Your do not currently have an account registered on LMEpassport. Please return to the homepage and click "register now" to create an account. If you need assistance, please contact ${email}.`,
          sticky: true,
        });
      }, 0);

      setTimeout(() => {
        localStorage.removeItem("inactive");
      }, 2000);
    }

    let idle = localStorage.getItem("idle");
    if (idle === "true") {
      setTimeout(() => {
        this.messageService.add({
          key: "tc",
          severity: "error",
          detail: `Your session has ended. Please logon in order to access your account. If you need assistance, please contact ${email}`,
          sticky: true,
        });
      }, 0);

      setTimeout(() => {
        localStorage.removeItem("idle");
      }, 2000);
    }

    let suspended = localStorage.getItem("suspended");
    if (suspended === "true") {
      setTimeout(() => {
        this.messageService.add({
          key: "tc",
          severity: "error",
          detail: `Your account has been suspended. If you need assistance, please contact ${email}`,
          sticky: true,
        });
      }, 0);

      setTimeout(() => {
        localStorage.removeItem("suspended");
      }, 2000);
    }
    let terminated = localStorage.getItem("terminated");
    if (terminated === "true") {
      setTimeout(() => {
        this.messageService.add({
          key: "tc",
          severity: "error",
          detail: `Your account is not registered. If you need assistance, please contact ${email}`,
          sticky: true,
        });
      }, 0);

      setTimeout(() => {
        localStorage.removeItem("terminated");
      }, 2000);
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
