import { Component, OnDestroy, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "formly-field-dictionary-select",
  template: `
    <ng-select
      [items]="items"
      [formControl]="formControl"
      [bindLabel]="to.bindLabel"
      [loading]="isLoading"
      (change)="to.change && to.change(field, $event)"
      [inputAttrs]="{ 'aria-label': to.label }"
      [notFoundText]="to.notFoundText || 'No items found'"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm"
      >
        <div *ngIf="!item.inactive" title="{{ item?.title }}" [id]="item?.name">
          {{ item.name }}
        </div>
        <div
          *ngIf="item.inactive"
          title="{{ item?.title }}"
          class="disabled italics"
          [id]="item?.name"
        >
          {{ item.name }}
        </div>
      </ng-template>
    </ng-select>
  `,
})
export class FormlyFieldDictionarySelectTemplate
  extends FieldType
  implements OnInit, OnDestroy
{
  onDestroy$ = new Subject<void>();

  isLoading = false;
  items = [];
  source: any;
  dataProvided: boolean;
  public placeholder: string;

  public translatePrefix;
  constructor() {
    super();
  }

  ngOnInit() {
    this.source = this.to.source;
    this.dataProvided = this.to.dataProvided;
    if (this.dataProvided) {
      this.loadProvidedData();
    } else {
      this.loadData();
    }
  }

  get disabled() {
    return this.to.disabled;
  }

  private loadData() {
    this.isLoading = true;
    this.source
      .pipe(
        map((data: any) => {
          return data;
        })
      )
      .subscribe((data) => {
        if (data) {
          data.forEach((stakeholder) => {
            if (stakeholder.stakeholderId === "WAREHOUSE_OPERATOR") {
              stakeholder.name = "Warehouse Company";
            }
            switch (stakeholder.stakeholderId) {
              case "PRODUCER":
                stakeholder.title =
                  "Producers are corporate entities whose principle business activity is that of smelting, casting or producing metal products. This may include the producers of LME approved brands. All brands approved for good delivery against LME contracts must conform to strict requirements on quality, shape and weight, as outlined by the Exchange.";
                break;
              case "WAREHOUSE_OPERATOR":
                stakeholder.title =
                  "Warehouse companies are corporate entities responsible for the storage of metal.    Warehouse companies include LME approved warehouses. To support the mechanism of physical delivery the LME approves and licenses a network of warehouses and storage facilities around the world. Warehouse companies must meet strict criteria before they are approved for the handling of warranted LME metals.";
                break;
              case "LME_MEMBER":
                stakeholder.title =
                  "For the purposes of this definition LME members are defined as Category 1, 2, 3 or 4 members eligible to trade on the LME on behalf of clients or on a proprietary basis. LME members comprise of trading houses, brokers, banks and proprietary traders.";
                break;
              case "METAL_OWNER":
                stakeholder.title =
                  "Metal owners are the title owners of a given parcel of metal. Metal owners will frequently employ other Stakeholder Types as their agent. Where a metal owner is otherwise categorised as a producer, warehouse company or LME member this categorisation will take precedence.";
                break;
              default:
                break;
            }
          });

          this.items = data;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
  }

  private loadProvidedData() {
    this.to.items.forEach((stakeholder) => {
      if (stakeholder.stakeholderId === "WAREHOUSE_OPERATOR") {
        stakeholder.name = "Warehouse Company";
      }
      switch (stakeholder.stakeholderId) {
        case "PRODUCER":
          stakeholder.title =
            "Producers are corporate entities whose principle business activity is that of smelting, casting or producing metal products. This may include the producers of LME approved brands. All brands approved for good delivery against LME contracts must conform to strict requirements on quality, shape and weight, as outlined by the Exchange.";
          break;
        case "WAREHOUSE_OPERATOR":
          stakeholder.title =
            "Warehouse companies are corporate entities responsible for the storage of metal.    Warehouse companies include LME approved warehouses. To support the mechanism of physical delivery the LME approves and licenses a network of warehouses and storage facilities around the world. Warehouse companies must meet strict criteria before they are approved for the handling of warranted LME metals.";
          break;
        case "LME_MEMBER":
          stakeholder.title =
            "For the purposes of this definition LME members are defined as Category 1, 2, 3 or 4 members eligible to trade on the LME on behalf of clients or on a proprietary basis. LME members comprise of trading houses, brokers, banks and proprietary traders.";
          break;
        case "METAL_OWNER":
          stakeholder.title =
            "Metal owners are the title owners of a given parcel of metal. Metal owners will frequently employ other Stakeholder Types as their agent. Where a metal owner is otherwise categorised as a producer, warehouse company or LME member this categorisation will take precedence.";
          break;
        default:
          break;
      }
    });
    this.items = this.to.items;
  }

  ngOnDestroy() {
    this.onDestroy$.complete();
  }
}
