<p-toast position="top-center"></p-toast>
<p (click)="infoSideBySide()" style="display: inline-block" class="mb-3">
  <a class="mt-5 link side-by-side-text-container">
    What is side-by-side?
    <i aria-hidden="true" class="ml-2 fa fa-info-circle"></i></a>
</p>

<div class="card-wrapper" id="card-wrapper">
  <div class="table-wrapper" id="table-wrapper">
    <p-table [value]="themesList" [scrollable]="true" groupRowsByOrder="0" [scrollHeight]="'calc(100vh - 88px)'"
      rowGroupMode="subheader" groupRowsBy="themeName" dataKey="themeName" [expandedRowKeys]="expandedThemeRows"
      #themesTable [tableStyle]="{ width: '100%' }">
      <ng-template pTemplate="header">
        <tr class="header-h" id="producers-header">
          <th class="cell-p header-w" style="min-width:200px"></th>
          <th class="cell-p mw-20 p-relative producer-column-width"
            *ngFor="let header of selectedHeaders; let i = index">
            <div class="producer-header-wrapper">
              <div class="logo-header-container">
                <img class="producer-header-logo" *ngIf="header?.uuid; else ORG_DEFAULT_LOGO" loading="lazy" src="{{
                    env.apiUrl +
                      '/home-service/publicView/document/download?uuid=' +
                      header?.uuid +
                      '&name=logo.png'
                  }}" alt="company logo" />
                <ng-template #ORG_DEFAULT_LOGO>
                  <img class="producer-header-logo" src="assets/img/org-logo.png" alt="company logo"
                    class="producer-logo" />
                </ng-template>
              </div>
              <div class="header-entity">
                {{ header.entityName }}
              </div>
            </div>
            <div class="producer-dropdown">
              <!-- <ng-select [clearable]="false" [items]="header.brands" bindLabel="dispayText" 
                appendTo="body"
                [placeholder]="'Select comparison level...'" (change)="changeProducerAndBrand($event, header, i)"
                [(ngModel)]="producerBrand[header.uid]" [searchFn]="brandSearchFn">
              </ng-select> -->

              <ng-select [clearable]="false" [items]="header.brands" bindLabel="dispayText" appendTo="body"
                [placeholder]="'Select comparison level...'" (change)="changeProducerAndBrand($event, header, i)"
                [(ngModel)]="producerBrand[header.uid]" [searchFn]="brandSearchFn">
                <ng-template ng-label-tmp let-item="item">
                  <span [title]="item.label">{{item.label}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <span [innerHTML]="item.dispayText"></span>
                </ng-template>
              </ng-select>
            </div>
            <span class="icon-wrapper">
              <button type="button" pButton pRipple class="remove-icon p-remove-icon" [icon]="'pi pi-times'" (click)="
                  unSelectProducer(header, false, i); $event.stopPropagation()
                "></button>
            </span>
          </th>
          <th *ngIf="selectedHeaders.length <= 4 && producerList" class="cell-p mw-20"
            [ngClass]="selectedHeaders.length >= 1 ? 'text-center' : ''">
            <button pButton pRipple class="addProductBtn" (click)="showProducerDialog()">
              <span class="pi pi-plus add-icon"></span> <span>Add a producer to <br />side-by-side</span>
            </button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="groupheader" let-standard let-rowIndex="rowIndex" let-expanded="expanded">
        <tr pRowGroupHeader class="theme-group">
          <td class="themeName bgColor cell-p header-w side-by-side-text-color" [ngClass]="{
              envBgColor: standard.themeName === ThemeNameEnm.ENVIRONMENTAL,
              govBgColor: standard.themeName === ThemeNameEnm.GOVERNANCE,
              multiBgColor:
                standard.themeName === ThemeNameEnm.MULTIDIMENSIONAL,
              socialBgColor: standard.themeName === ThemeNameEnm.SOCIAL
            }" style="width: 210px">
            <span class="p-text-bold theme-subtheme-float-left" *ngIf="standard.themeName">{{ standard.themeName
              }}</span>
            <div class="icon-wrapper theme-subtheme-float-right">
              <button type="button" pButton pRipple [pRowToggler]="standard"
                class="p-button-text p-button-rounded p-button-plain mr-2" style="color: #fff"
                [icon]="expanded ? 'pi pi-minus' : 'pi pi-plus'"></button>
            </div>
          </td>
          <td colspan="5" class="b-none"></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-standard let-rowIndex="rowIndex" let-standard.subThemeName="false"
        let-expanded="expanded">
        <tr class="sub-theme-header" *ngIf="standard.subThemeName">
          <td class="themeName bgColor cell-p sub-theme-group side-by-side-text-color" [ngClass]="{
              envBgColor: standard.themeName === ThemeNameEnm.ENVIRONMENTAL,
              govBgColor: standard.themeName === ThemeNameEnm.GOVERNANCE,
              multiBgColor:
                standard.themeName === ThemeNameEnm.MULTIDIMENSIONAL,
              socialBgColor: standard.themeName === ThemeNameEnm.SOCIAL
            }">
            <span class="theme-subtheme-float-left">{{
              standard.subThemeName
              }}</span>
            <div class="icon-wrapper theme-subtheme-float-right">
              <button type="button" (click)="standard.viewDetails = !standard.viewDetails" pButton pRipple
                class="p-button-text p-button-rounded p-button-plain mr-2" style="color: #fff"
                [icon]="standard.viewDetails ? 'pi pi-minus' : 'pi pi-plus'"></button>
            </div>
          </td>
        </tr>
        <ng-container *ngTemplateOutlet="extensiontemplate; context: standard"></ng-container>
        <ng-template #extensiontemplate>
          <ng-container *ngIf="standard.viewDetails">
            <tr *ngFor="let header of selectedHeader">
              <td style="background-color: #13426b; color: #fff">
                {{ header.entityName }}
              </td>
            </tr>
            <tr *ngFor="let rowData of standard.disclosures" class="sub-header-content">
              <td class="cell-p side-by-side-text-color">
                <span [ngClass]="{ 'disclosure-data': rowData.description }"
                  (click)="rowData.description && subGroupText(rowData)">
                  <i
                    *ngIf="noCertificationMetricsAvailable == rowData.name; else certificateExists">{{rowData.name}}</i>
                  <ng-template #certificateExists>
                    {{ rowData.name ? rowData.name : "N/A"}}
                  </ng-template>
                </span>
              </td>
              <ng-container *ngFor="let header of selectedHeaders; let i = index">
                <ng-container
                  *ngIf="header.brandLength?.length == 0 && noCertificationMetricsAvailable != rowData.name; else brandData">
                  <td class="producer-column-width"></td>
                </ng-container>
                <ng-template #brandData>
                  <ng-container *ngFor="let entity of rowData.entities">
                    <ng-container *ngIf="
                        header.entityName.toLowerCase() ===
                          entity.entity.entityName.toLowerCase() &&
                        header.brandLength?.length >= 1 &&
                        header.uid === entity.entity.uid
                      ">
                      <td class="producer-column-width">
                        <div *ngIf="
                            entity.type === 'metric' ||
                            entity.proofText ||
                            entity.proofUrl ||
                            entity.proofDocument
                          ">
                          <button type="button" pButton pRipple
                            class="p-button-text p-button-rounded p-button-plain mr-2" style="
                              color: #13426b !important;
                              display: block !important;
                            " [icon]="'pi pi-check'"></button>
                        </div>

                        <div *ngIf="entity.type === 'metric'">
                          <div>
                            {{ entity.metricPercentage }}
                            {{ entity.metricLevel ? entity.metricLevel : "" }}
                          </div>
                        </div>

                        <div *ngIf="
                            entity.proofText ||
                            entity.proofUrl ||
                            entity.proofDocument
                          ">
                          <div class="proof-url">
                            Proof point:
                            {{
                            entity.proofText ? entity.proofText : ""
                            }}
                            &nbsp;
                            <a *ngIf="entity.proofUrl" class="proof-url"
                              (click)="redirectToUrl($event, entity.proofUrl)" href="#">{{
                              entity.proofUrl ? entity.proofUrl : "" }}</a>
                            &nbsp;
                            <i *ngIf="entity.proofDocument" (click)="downloadDocument(entity)" class="fa fa-file"
                              aria-hidden="true" title="Download" style="cursor: pointer"></i>
                          </div>
                        </div>

                        <div *ngIf="
                            entity.type === 'metric' ||
                            entity.proofText ||
                            entity.proofUrl ||
                            entity.proofDocument
                          ">
                          <div *ngIf="entity.validityDateFrom">
                            Valid from:
                            {{
                            entity.validityDateFrom | date : "dd MMMM YYYY"
                            }}
                          </div>
                          <div *ngIf="entity.validityDateTo">
                            Valid to:
                            {{ entity.validityDateTo | date : "dd MMMM YYYY" }}
                          </div>
                        </div>

                        <div *ngIf="!entity.brandId">
                          <span class="cross-icon-wrapper">
                            <button type="button" pButton pRipple
                              class="p-button-text p-button-rounded p-button-plain mr-2" style="
                                color: #ff0000 !important;
                                display: block !important;
                                cursor: default;
                              " [icon]="'pi pi-times'"></button>
                          </span>
                          {{entity.metal}}
                        </div>
                      </td>
                    </ng-container>
                  </ng-container>
                </ng-template>
                <ng-container *ngIf="noCertificationMetricsAvailable == rowData.name && brandExists">
                  <td class="producer-column-width"></td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
        </ng-template>
      </ng-template>
    </p-table>
    <div class="centered-element">
      <p class="centered-text">LME Sustainablitity Taxonomy</p>
    </div>
  </div>
</div>
<div class="footer-wrapper">
  <p class="footer-text-wrapper" (click)="scrollDownThemesList()">
    Continue taxonomy in list
    <span class="pi pi-arrow-down"></span>
  </p>
</div>
<div class="hide-footer-bg"></div>

<p-dialog [(visible)]="producerDisplayModal" [style]="{ width: '70vw' }" [baseZIndex]="10000" class="producers-modal"
  [modal]="true">
  <ng-template pTemplate="header">
    <div class="header-wrapper">
      <p class="producer-header-text">
        Select a producer(s) - you can choose up to five
      </p>
      <p class="producer-header-sub-text">
        Once you've selected a producer, you'll be able to choose a brand or if
        you'd like to complete a side-by-side at the entity level
      </p>
    </div>
  </ng-template>
  <div>
    <div class="row my-3">
      <div class="col-md-12">
        <ng-select [placeholder]="'Select producer'" [clearable]="false" [clearOnBackspace]="false"
          (change)="producerSearch($event)" (search)="producerSearch($event)" [items]="producerList"
          bindLabel="entityName" bindValue="id" [(ngModel)]="modalProducer"></ng-select>
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="mt-3 col-md-4" *ngFor="let producer of filteredProducers;let producerIndex=index">
        <div class="producer-box" (click)="producerSelection(producer)"
          [ngClass]="{ active: validateProdcuerActiveState(producer) }">
          <div class="logo-container">
            <img class="producer-logo" *ngIf="producer?.uuid; else ORG_DEFAULT_LOGO" loading="lazy" src="{{
                env.apiUrl +
                  '/home-service/publicView/document/download?uuid=' +
                  producer?.uuid +
                  '&name=logo.png'
              }}" alt="company logo" />
            <ng-template #ORG_DEFAULT_LOGO>
              <img class="producer-logo" src="assets/img/org-logo.png" alt="company logo" class="producer-logo" />
            </ng-template>
          </div>
          <div class="text-container">
            <div class="producer-name">
              {{ producer.entityName }}
            </div>
          </div>
          <span class="icon-wrapper">
            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain mr-2"
              style="color: #000; position: absolute; top: 0; right: -6px" [icon]="'pi pi-times'" (click)="
                unSelectProducer(producer, true,producerIndex); $event.stopPropagation()
              " *ngIf="validateProdcuerActiveState(producer)"></button>
          </span>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="producerDisplayModal = false; confirmToDisplay()" label="Confirm"
      class="p-button-text modal-actions"></p-button>
    <p-button icon="pi pi-times" (click)="producerDisplayModal = false; cancelToUnSelect()" label="Cancel"
      class="modal-actions"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [header]="subGroupdescription?.name" [(visible)]="displaySubGroupDescription" [style]="{ width: '50vw' }"
  [baseZIndex]="10000" class="producers-modal" [modal]="true">
  <div>
    <div class="row mt-3 mb-3">
      <div class="col-md-12">
        <div class="text-justify">{{ subGroupdescription?.description }}</div>
      </div>
    </div>
  </div>
</p-dialog>

<app-side-by-side-info [infoTriggers]="infoTriggers" [sideBySideText]="sideBySideText"
  (dailogClose)="dailogClose()"></app-side-by-side-info>