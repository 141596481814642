import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CoreService } from "@core/services/core.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {
  menuItems: { name: string; id: string }[];
  currentDate = new Date();

  @Input() from?: string;

  private _destroy$ = new Subject<any>();
  footerLinks: any;

  constructor(private coreService: CoreService) {}

  ngOnInit() {
    this.coreService
      .getFooterLinks()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.footerLinks = res;
      });
    this.menuItems = [
      {
        name: "About",
        id: "about",
      },
      {
        name: "END USER LICENSE AGREEMENT",
        id: "end-user-license agreement",
      },
      {
        name: "TERMS OF USE",
        id: "terms-of-use",
      },
      {
        name: "Legal",
        id: "legal",
      },
      {
        name: "Privacy Statement",
        id: "privacy-statement",
      },
      {
        name: "Modern Slavery Statement",
        id: "modern-slavery-statement",
      },
      {
        name: "Cyber Security Awareness",
        id: "cyber-security-awareness",
      },
    ];
  }

  navigateFooter(event, item) {
    event.preventDefault();
    let link = "";
    switch (item) {
      case "END USER LICENSE AGREEMENT":
        window.open(
          `https://${window.location.hostname}/api/doc-upload-service/resources/download?type=EULA`,
          "_blank"
        );
        break;
      case "TERMS OF USE":
        window.open(
          `https://${window.location.hostname}/api/doc-upload-service/resources/download?type=TOU`,
          "_blank"
        );
        break;
      case "Legal":
        link = this.getLink("LEGAL");
        window.open(link);
        break;
      case "Privacy Statement":
        link = this.getLink("PRIVACY");
        window.open(link);
        break;
      case "Modern Slavery Statement":
        link = this.getLink("MODERN");
        window.open(link);
        break;
      case "Cyber Security Awareness":
        link = this.getLink("CYBER");
        window.open(link);
        break;
      case "About":
        window.open("https://www.lme.com");
        break;
      default:
        break;
    }
  }

  getLink(name) {
    let idx = this.footerLinks.findIndex((links) => links.name === name);

    return this.footerLinks[idx].link;
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
