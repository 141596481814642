<ng-container *ngIf="userLoaded">
  <app-header-login></app-header-login>
  <div class="content" id="main-content" tabindex="-1">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-3">
          <div class="card master">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-12 mr-5">
                  <div class="lme-title mb-5">LMEpassport</div>
                  <a id="register" href="#/register" wcag-dummy-link-target class="btn btn-primary btn-lg register"
                    pTooltip="Register now for Entity and User Registration">Register Now</a>
                  <!-- <a id="register" href="#/register-user" wcag-dummy-link-target
                    class="ml-2 btn btn-primary btn-lg register"
                    pTooltip="Register your Company with LMEpassport">REGISTER USER</a> -->
                </div>
                <div class="landing-img col-5">
                  <img alt="system image" class="pull-right" src="/assets/img/Approved_cert_white.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="card setting-card">
            <div class="card-body">
              <h1 style="display: none" class="card-title setting">
                Lme Passport
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container about">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <p class="mb-3">
          LMEpassport provides a single-point utility to all metal market participants. This platform stores and
          maintains essential provenance documents and information for traded metal, as well as showcasing a broad range
          of sustainability credentials. It acts as a centralised library available to all metal owners and their
          agents.
        </p>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="contact-container">
          <p>Need to contact us?</p>
          <h1 class="phone"><i class="fa fa-phone"></i>+44 20 7113 8555</h1>
          <p>
            <span class="mr-2" style="font-weight: bold">Help desk support</span>
            <i class="fa fa-envelope-square"></i>
            <a [attr.href]="supportEmail" class="contact-us">Email us</a>
          </p>
          <p>
            <span class="mr-2" style="font-weight: bold">Sustainability queries</span>
            <i class="fa fa-envelope-square"></i>
            <a [attr.href]="sustainabilitySupportEmail" class="contact-us">Email us</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="statistics && statistics?.publicViewEnabled" class="container text-center">
    <a id="disclosures" [routerLink]="['/public/producer-profile']" routerLinkActive="router-link-active"
      wcag-dummy-link-target class="btn btn-primary btn-lg sustainability-disc">Click here
      to access: LMEpassport sustainability disclosures</a>
  </div>
  <div class="container">
    <div class="cards_wrap">
      <div class="card_item">
        <div class="card_inner">
          <i style="font-size: 4rem" class="fa fa-database" aria-hidden="true"></i>
          <div class="role_name">Total number of Certificate of Analysis records</div>
          <div class="film">
            {{ statistics?.recordCount | number: "1.0":"en-US" }}
          </div>
        </div>
      </div>
      <div class="card_item">
        <div class="card_inner">
          <i style="font-size: 4rem" class="fa fa-user" aria-hidden="true"></i>
          <!-- <div class="role_name">Registered Producers</div>
          <div class="film">
            {{ statistics?.producerRegistered | number: "1.0":"en-US" }}
          </div> -->
          <div class="role_name">Number of LME brands sharing sustainability credentials</div>
          <div class="film">
            {{ statistics?.sustainableBrand | number: "1.0":"en-US" }}
          </div>
        </div>
      </div>

      <div class="card_item">
        <div class="card_inner">
          <i style="font-size: 4rem" class="fa fa-envira" aria-hidden="true"></i>
          <div class="role_name">Number of LME approved sustainability disclosures supported</div>
          <div class="film">
            {{ statistics?.disclosureCount | number: "1.0":"en-US" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer from="home"></app-footer>
</ng-container>
<p-toast life="300000" sticky="true" position="top-center" key="tc"></p-toast>