<app-header-login></app-header-login>
<div class="container-fluid mt-3 mb-3">
    <div class="row mb-3">
        <div class="col-12 note">
            Note: Once you have submitted your registration, you will require approval before being able to login into
            LMEpassport. Once access is provided, an email will be sent. If you need assistance, please contact LME
            Platform Administrator at {{ email }}.
        </div>
    </div>
    <h4 class="badge-company mb-3">Company Information</h4>
    <div class="panel">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
            <button type="submit" class="btn btn-primary submit-button mt-3 mb-3">
                Next
            </button>
        </form>
    </div>
</div>
<app-loader></app-loader>
<app-footer from="home"></app-footer>
<p-dialog [style]="{ width: '60vw' }" header="User Type" [modal]="true" [(visible)]="userTypeDialog">
    <div class="field-checkbox">
        <p-checkbox class="user-type-checkbox" name="group1" value="San Francisco" [(ngModel)]="selectedUserType"
            inputId="sf"></p-checkbox>
        <label class="user-type-label ml-2" for="sf">By default, you will be registered as an individual user. If you
            would
            like to increase
            permissions on your account, please tick this box to indicate your request.<br><br>

            Once your individual user account has been approved, your request to increase access on LMEpassport will be
            sent to any user admins registered at the same company name to provide approval. You will still have
            access to login to LMEpassport as an individual user. <br><br>

            <span class="bold">Individual user:</span> This will provide you with the ability to access the platform,
            view Certificates of
            Analysis and disclose sustainability credentials (if you’re an LME-listed producer)<br><br>

            <span class="bold">User admin:</span> This will enable you to be able to create Certificates of Analysis
            (producers and warehouses
            only), as well as the ability to create or delete additional accounts for colleagues </label>
    </div>

    <button (click)="registerUser()" class="mt-4 btn btn-primary">Submit</button>
</p-dialog>