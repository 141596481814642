import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, NgForm } from "@angular/forms";
import { IAdditionalDocs, IEntityList, IUser } from "@core/models/api.model";
import { IPagination } from "@core/models/app.interface";
import { CoreService } from "@core/services/core.service";
import { LookupService } from "@core/services/lookup.service";
import { environment } from "@env/environment";
import {
  addiotnalDocsTableConfig,
  reviewListTableConfigNonProducer,
  reviewListTableConfigProducer,
} from "@module/create-import/create-import-config";
import { CreateImportService } from "@module/create-import/services/create-import.service";
import { UserAdminService } from "@module/user-admin/services/user-admin.service";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { ITableViewConfig } from "@shared/models/table-view.model";
import * as _ from "lodash";
import * as moment from "moment";
import { ConfirmationService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-review",
  templateUrl: "./review.component.html",
  styleUrls: ["./review.component.scss"],
  providers: [ConfirmationService],
})
export class ReviewComponent implements OnInit, OnDestroy {
  @Input() reviewRecordDialog: boolean;
  @Input() reviewId: string;
  @Input() reviewData: any;
  @Input() type?: string;
  @Input() reviewStatus?: string;
  @Input() entityId?: number;

  @Output() hideReviewRecord = new EventEmitter<any>();
  @Output() reviewSaved = new EventEmitter<any>();
  @Output() assigned = new EventEmitter<any>();
  @Output() recordsApproved = new EventEmitter<any>();
  @Output() editComplete = new EventEmitter<any>();

  tableConfig: ITableViewConfig;
  private _destroy$ = new Subject<any>();
  pagination = {
    page: 0,
    size: 20,
  } as IPagination;
  public firstItemIndex = 0;
  reviewRecords: any;
  actionItems: any;
  editDialog: boolean;
  selectedRecord: any;
  actionModel: any = [];
  data: any;

  form = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[];

  @ViewChild("actionTemplate", { static: true })
  public actionTemplate: TemplateRef<any>;
  @ViewChild("additionalDocTemplate", { static: true })
  public additionalDocTemplate: TemplateRef<any>;
  @ViewChild("chemicalCompositionTemplate", { static: true })
  public chemicalCompositionTemplate: TemplateRef<any>;
  @ViewChild("myForm", { static: true }) myForm: NgForm;

  chemicalComposition: any;
  reviewChemicalCompDialog: boolean;
  newRecord: boolean;
  companies: IEntityList[];
  metals: any;
  coaTypes: string[] = ["Producer COA", "Assayer COA"];
  brands: any;
  analysisDate: Date;
  producers: any;
  gridData = [];
  selectedReviewRecords = [];
  assignDialog: boolean;
  docId: any;
  user: IUser;
  canEdit: boolean;
  editId: number;
  recordType: string;
  producerData: any;
  standards: unknown[];
  permissibleGrades: unknown[];
  addtionalDocsDialog: boolean;
  saveCols: any;
  viewAdditionalDocsDialog: boolean;
  industryStandardGrades: any;
  addtionalDocsRowData: any;
  documentId: any;
  title: string = "Review Record";
  coaReference: any;
  requestUpdateCommentDialog: boolean;
  requestUpdateRow: any;
  duplicateRecordsDialog: boolean;
  successPcrs: any;
  duplicateRecords: any;
  failedPcrs: any;
  previewOldNewDoc: boolean;
  oldDocId: any;
  newDocId: any;
  oldCoaDateStr: any;
  newCoaDateStr: any;
  errorMessage: boolean;
  originalcoaDateStr: any;
  oldOcrCustodyId: any;
  castReference: any;

  constructor(
    private coreService: CoreService,
    private createImportService: CreateImportService,
    private lookupService: LookupService,
    private confirmationService: ConfirmationService,
    private userAdminService: UserAdminService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.user = this.coreService.getLoggedInuserDetails();
    this.initData();
    // this.getCompanies();
    this.getProducers();
    if (this.user.stakeHolder !== "PRODUCER") {
      this.getMetals();
      this.getBrand();
    }
    this.analysisDate = new Date();

    this.generateActionItems();
    if (this.reviewRecords[0].status === "APPROVED") {
      this.title = "Approved Record";
    }
    let user: IUser = this.coreService.getLoggedInuserDetails();
    let tableView: ITableViewConfig;
    if (this.reviewRecords[0].type === "BASIC") {
      tableView = reviewListTableConfigNonProducer;
    } else {
      tableView = reviewListTableConfigProducer;
    }

    this.saveCols = [...tableView.columns];

    tableView = this.removeCols(tableView);

    const { columns, ...config } = tableView;
    const _columns = [...tableView.columns];
    this.tableConfig = {
      ...config,
      ...{ columns: _columns },
    };

    if (this.reviewRecords[0].type !== "BASIC") {
      _columns.splice(11, 0, {
        header: "Chemical Composition",
        width: "200px",
        templateRef: this.chemicalCompositionTemplate,
      });
    }

    _columns.push({
      header: "Additional Documents",
      width: "160px",
      visible: true,
      templateRef: this.additionalDocTemplate,
    });
    _columns.push({
      header: "Action",
      width: "120px",
      visible: true,
      templateRef: this.actionTemplate,
    });

    if (this.type !== "approved") {
      _columns.splice(0, 0, {
        checkboxSelect: true,
        width: "3em",
      });
    }
  }

  generateActionItems() {
    this.reviewRecords.forEach((record) => {
      if (this.type === "approved") {
        record["pagePCR"] = record["pagePcr"];
        record["packingFrom"] = record["packingForm"];
        record["industryStandards"] = record["industryStd"];
        record.actionItems = [];

        if (
          (this.user.entityAdmin || this.user.platformAdmin) &&
          this.reviewStatus !== "OTHERS"
        ) {
          record.actionItems.push({
            id: "edit",
            name: "Edit",
          });
          this.canEdit = true;
        } else if (this.reviewStatus === "OTHERS") {
          this.canEdit = false;
        } else {
          this.userAdminService
            .getUpdateRequests(["APPROVED", "PENDING"])
            .pipe(takeUntil(this._destroy$))
            .subscribe((res) => {
              let index = res.findIndex(
                (row) => row.recordId === this.reviewRecords[0].id
              );
              if (!res.length || index === -1) {
                record.actionItems = [
                  ...record.actionItems,
                  {
                    id: "requestUpdate",
                    name: "Request Update",
                  },
                ];
                this.canEdit = false;
              } else {
                if (index !== -1) {
                  let status = res[index].status;
                  if (status === "APPROVED") {
                    record.actionItems = [
                      ...record.actionItems,
                      {
                        id: "edit",
                        name: "EDIT",
                      },
                    ];

                    this.canEdit = true;
                    this.editId = res[index].id;
                  } else {
                    this.canEdit = false;
                  }
                } else {
                  this.canEdit = false;
                }
              }
            });
        }
      } else {
        this.canEdit = true;
        record.actionItems = [
          {
            id: "delete",
            name: "Delete",
          },
        ];

        if (record.status !== "DUPLICATE_REVIEW") {
          record.actionItems.push({
            id: "edit",
            name: "Edit",
          });
        }

        if (!this.user.reviewRequired) {
          record.actionItems.unshift({
            id: "approve",
            name: "Approve",
          });
        }
      }
    });
  }

  removeCols(tableView) {
    if (this.type === "approved") {
      let statusIdx = tableView.columns.findIndex(
        (col) => col.field === "status"
      );
      statusIdx !== -1 && tableView.columns.splice(statusIdx, 1);
    } else {
      let lmePassportIdx = tableView.columns.findIndex(
        (col) => col.field === "passportId"
      );
      lmePassportIdx !== -1 && tableView.columns.splice(lmePassportIdx, 1);

      let scrCompliantIdx = tableView.columns.findIndex(
        (col) => col.field === "scrCompliantStr"
      );
      scrCompliantIdx !== -1 && tableView.columns.splice(scrCompliantIdx, 1);

      if (this.reviewRecords[0].type === "BASIC") {
        let packingFromIdx = tableView.columns.findIndex(
          (col) => col.field === "packingFrom"
        );
        packingFromIdx !== -1 && tableView.columns.splice(2, 9);
      } else {
        let packingFromIdx = tableView.columns.findIndex(
          (col) => col.field === "packingFrom"
        );
        packingFromIdx !== -1 && tableView.columns.splice(1, 9);
      }
    }

    return tableView;
  }

  initData() {
    this.data = _.clone(this.reviewData);
    this.originalcoaDateStr = this.data.coaDateStr;
    this.data.coaDateStr = this.setCoaDateStr(this.data.coaDateStr);

    this.reviewRecords = this.data.ocrRecords;
    this.reviewRecords.forEach((row) => {
      if (row.status === "REVIEW1") {
        row["displayableStatus"] = "Review 1";
      } else if (row.status === "REVIEW2") {
        row["displayableStatus"] = "Review 2";
      } else {
        row["displayableStatus"] =
          row["status"][0].toUpperCase() + row["status"].slice(1).toLowerCase();
      }
    });
    this.gridData = [...this.reviewRecords];

    this.fields = [
      {
        fieldGroupClassName: "",
        fieldGroup: [
          {
            type: "textarea",
            key: "comment",
            templateOptions: {
              label: "Details for requesting update",
              rows: 5,
              required: true,
            },
          },
        ],
      },
    ];
  }

  setCoaDateStr(coaDateStr) {
    if (coaDateStr) {
      coaDateStr = moment(coaDateStr, "DD-MM-YYYY").toDate();

      if (isNaN(coaDateStr)) {
        coaDateStr = moment(this.originalcoaDateStr, "DD-MMM-YYYY").toDate();
      }

      if (isNaN(coaDateStr)) {
        coaDateStr = moment(this.originalcoaDateStr, "DD-MM-YYYY").toDate();
      }

      if (isNaN(coaDateStr)) {
        coaDateStr = moment(this.originalcoaDateStr, "DD-M-YYYY").toDate();
      }
    }

    return coaDateStr;
  }

  getAllActiveRecords() {
    return this.reviewRecords.filter((row) => row.uiStatus !== "DELETED");
  }

  getCompanies() {
    this.lookupService
      .getCompanies()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => (this.companies = res));
  }

  getMetals() {
    if (this.user.stakeHolder === "PRODUCER") {
      this.metals = [...new Set(this.producerData.map((row) => row.metal))];
      this.getBrand();
    } else {
      this.lookupService
        .getMetals()
        .pipe(takeUntil(this._destroy$))
        .subscribe((value) => {
          this.metals = value;
        });
    }
  }

  getProducers() {
    if (this.user.stakeHolder === "PRODUCER") {
      this.lookupService
        .getBrandsByProducer(this.user.entityName)
        .pipe(takeUntil(this._destroy$))
        .subscribe((data) => {
          this.producerData = data;
          this.producers = [...new Set(data.map((row) => row.producerName))];
          this.getMetals();
        });
    } else {
      this.lookupService
        .getProducers()
        .pipe(takeUntil(this._destroy$))
        .subscribe((value) => {
          this.producers = value;
        });
    }
  }

  getBrand() {
    if (this.user.stakeHolder === "PRODUCER") {
      this.brands = [...new Set(this.producerData.map((row) => row.brand))];
    } else {
      this.lookupService
        .getBrands()
        .pipe(takeUntil(this._destroy$))
        .subscribe((value) => {
          this.brands = value;
        });
    }
  }

  onEdit() {
    this.newRecord = false;
    this.recordType = this.selectedRecord.type;
    if (
      !(
        this.selectedRecord.type === "BASIC" ||
        this.selectedRecord.uiStatus === "ADD"
      )
    ) {
      this.createImportService
        .getChemicalComposition(this.selectedRecord.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          this.selectedRecord.chemicalCompositions = res.chemicalCompositions;
          this.getGradeStandard();
        });
    } else {
      this.getGradeStandard();
    }

    this.resetSelection(this.selectedRecord.id);
  }

  getGradeStandard() {
    this.createImportService
      .getIndustryGrade(this.data.commodity)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.standards = [...new Set(res.map((row) => row.industryStd))];
        this.standards = this.standards.filter((standard) => standard !== "NA");
        this.permissibleGrades = [
          ...new Set(res.map((row) => row.permissibleGrade)),
        ];
        this.permissibleGrades = this.permissibleGrades.filter(
          (grade) => grade !== "NA"
        );

        this.industryStandardGrades = res;

        this.editDialog = true;
      });
  }

  onSaveCoa(data) {
    this.editDialog = false;
    let reviewRecords = [...this.reviewRecords];

    data.type = this.reviewRecords[0].type;

    if (data.shape) {
      data.shape =
        typeof data.shape === "string" ? data.shape : data.shape.name;
    }

    data.productionCountry &&
      (data.productionCountry =
        typeof data.productionCountry === "string"
          ? data.productionCountry
          : data.productionCountry.name);

    data.productionDateStr = data.productionDateStr
      ? moment(data.productionDateStr).format("DD-MM-YYYY")
      : null;

    data.chemicalCompositions = !_.isEmpty(data.chemicalCompositions)
      ? this.getchemicalComposition(data.chemicalCompositions)
      : null;

    if (this.newRecord) {
      data.uiStatus = "ADD";
      data.actionItems = [
        {
          id: "delete",
          name: "Delete",
        },
        {
          id: "edit",
          name: "Edit",
        },
      ];

      if (!this.user.reviewRequired) {
        data.actionItems.unshift({
          id: "approve",
          name: "Approve",
        });
      }

      reviewRecords.push(data);
    } else {
      if (!data.hasOwnProperty("uiStatus")) {
        data.uiStatus = "EDIT";
      }
      let index = this.reviewRecords.findIndex(
        (record) => record.id === this.selectedRecord.id
      );
      reviewRecords[index] = data;
    }

    this.reviewRecords = reviewRecords;
    this.gridData = this.getAllActiveRecords();
  }

  getchemicalComposition(chemicalComposition) {
    let composition = {
      Ag: chemicalComposition.hasOwnProperty("Ag")
        ? chemicalComposition.Ag
        : null,
      Al: chemicalComposition.hasOwnProperty("Al")
        ? chemicalComposition.Al
        : null,
      As: chemicalComposition.hasOwnProperty("As")
        ? chemicalComposition.As
        : null,
      B: chemicalComposition.hasOwnProperty("B") ? chemicalComposition.B : null,
      Bi: chemicalComposition.hasOwnProperty("Bi")
        ? chemicalComposition.Bi
        : null,
      C: chemicalComposition.hasOwnProperty("C") ? chemicalComposition.C : null,
      Ca: chemicalComposition.hasOwnProperty("Ca")
        ? chemicalComposition.Ca
        : null,
      Cd: chemicalComposition.hasOwnProperty("Cd")
        ? chemicalComposition.Cd
        : null,
      Co: chemicalComposition.hasOwnProperty("Co")
        ? chemicalComposition.Co
        : null,
      Cr: chemicalComposition.hasOwnProperty("Cr")
        ? chemicalComposition.Cr
        : null,
      Cu: chemicalComposition.hasOwnProperty("Cu")
        ? chemicalComposition.Cu
        : null,
      Fe: chemicalComposition.hasOwnProperty("Fe")
        ? chemicalComposition.Fe
        : null,
      Ga: chemicalComposition.hasOwnProperty("Ga")
        ? chemicalComposition.Ga
        : null,
      Li: chemicalComposition.hasOwnProperty("Li")
        ? chemicalComposition.Li
        : null,
      Mg: chemicalComposition.hasOwnProperty("Mg")
        ? chemicalComposition.Mg
        : null,
      Mn: chemicalComposition.hasOwnProperty("Mn")
        ? chemicalComposition.Mn
        : null,
      Na: chemicalComposition.hasOwnProperty("Na")
        ? chemicalComposition.Na
        : null,
      Ni: chemicalComposition.hasOwnProperty("Ni")
        ? chemicalComposition.Ni
        : null,
      P: chemicalComposition.hasOwnProperty("P") ? chemicalComposition.P : null,
      Pb: chemicalComposition.hasOwnProperty("Pb")
        ? chemicalComposition.Pb
        : null,
      S: chemicalComposition.hasOwnProperty("S") ? chemicalComposition.S : null,
      Sb: chemicalComposition.hasOwnProperty("Sb")
        ? chemicalComposition.Sb
        : null,
      Se: chemicalComposition.hasOwnProperty("Se")
        ? chemicalComposition.Se
        : null,
      Si: chemicalComposition.hasOwnProperty("Si")
        ? chemicalComposition.Si
        : null,
      Sn: chemicalComposition.hasOwnProperty("Sn")
        ? chemicalComposition.Sn
        : null,
      Te: chemicalComposition.hasOwnProperty("Te")
        ? chemicalComposition.Te
        : null,
      Ti: chemicalComposition.hasOwnProperty("Ti")
        ? chemicalComposition.Ti
        : null,
      V: chemicalComposition.hasOwnProperty("V") ? chemicalComposition.V : null,
      Zn: chemicalComposition.hasOwnProperty("Zn")
        ? chemicalComposition.Zn
        : null,
      "Ag+As+Bi+Fe+Pb+S+Sb+Se+Te": chemicalComposition.hasOwnProperty(
        "Ag+As+Bi+Fe+Pb+S+Sb+Se+Te"
      )
        ? chemicalComposition["Ag+As+Bi+Fe+Pb+S+Sb+Se+Te"]
        : null,
      "As+Cd+Cr+Mn+P+Sb": chemicalComposition.hasOwnProperty("As+Cd+Cr+Mn+P+Sb")
        ? chemicalComposition["As+Cd+Cr+Mn+P+Sb"]
        : null,
      "Bi+Se+Te": chemicalComposition.hasOwnProperty("Bi+Se+Te")
        ? chemicalComposition["Bi+Se+Te"]
        : null,
      "Se+Te": chemicalComposition.hasOwnProperty("Se+Te")
        ? chemicalComposition["Se+Te"]
        : null,
      "Co+Fe+Ni": chemicalComposition.hasOwnProperty("Co+Fe+Ni")
        ? chemicalComposition["Co+Fe+Ni"]
        : null,
      "Co+Fe+Ni+Si+Sn+Zn": chemicalComposition.hasOwnProperty(
        "Co+Fe+Ni+Si+Sn+Zn"
      )
        ? chemicalComposition["Co+Fe+Ni+Si+Sn+Zn"]
        : null,
      "Ag+As+Bi+Fe+Pb+Ni+S+Sb+Se+Sn+Te": chemicalComposition.hasOwnProperty(
        "Ag+As+Bi+Fe+Pb+Ni+S+Sb+Se+Sn+Te"
      )
        ? chemicalComposition["Ag+As+Bi+Fe+Pb+Ni+S+Sb+Se+Sn+Te"]
        : null,
      "Fe+Mn+Cr": chemicalComposition.hasOwnProperty("Fe+Mn+Cr")
        ? chemicalComposition["Fe+Mn+Cr"]
        : null,
      "Pb+Cd+Fe+Sn+Cu+Al": chemicalComposition.hasOwnProperty(
        "Pb+Cd+Fe+Sn+Cu+Al"
      )
        ? chemicalComposition["Pb+Cd+Fe+Sn+Cu+Al"]
        : null,
      others_each: chemicalComposition.hasOwnProperty("others_each")
        ? chemicalComposition["others_each"]
        : null,
      others_total: chemicalComposition.hasOwnProperty("others_total")
        ? chemicalComposition["others_total"]
        : null,
      total_all_impurities: chemicalComposition.hasOwnProperty(
        "total_all_impurities"
      )
        ? chemicalComposition["total_all_impurities"]
        : null,
    };

    return Object.fromEntries(
      Object.entries(composition).filter(([_, v]) => v != null)
    );
  }

  showDialogToAdd() {
    this.recordType = this.reviewRecords[0].type;
    this.newRecord = true;
    this.selectedRecord = {};
    this.getGradeStandard();
  }

  onHideEditDialog() {
    this.editDialog = false;
    this.selectedRecord = null;
  }

  cloneRecord(selectedRecord) {
    let record = {};
    for (let prop in selectedRecord) {
      record[prop] = selectedRecord[prop];
    }
    return record;
  }

  validateCoaRef() {
    if (
      this.data.coaReference &&
      (this.data.coaReference.startsWith("=") ||
        this.data.coaReference.startsWith("+") ||
        this.data.coaReference.startsWith("-") ||
        this.data.coaReference.startsWith("@") ||
        this.data.coaReference.startsWith("0x09") ||
        this.data.coaReference.startsWith("0x0D"))
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateSmelter() {
    if (
      this.data.smelter &&
      (this.data.smelter.startsWith("=") ||
        this.data.smelter.startsWith("+") ||
        this.data.smelter.startsWith("-") ||
        this.data.smelter.startsWith("@") ||
        this.data.smelter.startsWith("0x09") ||
        this.data.smelter.startsWith("0x0D"))
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkMandatoryFields() {
    if (!this.data.producerName) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Producer name cannot be empty.",
      });
      return false;
    } else if (!this.data.coaReference) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "COA Reference Number cannot be empty.",
      });
      return false;
    } else if (!this.data.commodity) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Metal cannot be empty.",
      });
      return false;
    } else if (!this.data.coaDateStr) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "COA date cannot be empty.",
      });
      return false;
    } else if (!this.data.brand) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Brand cannot be empty.",
      });
      return false;
    }

    return true;
  }

  save() {
    if (!this.checkMandatoryFields()) {
      return;
    }

    if (this.type !== "approved" && this.validateCoaRef()) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "COA Ref value cannot start with -,=,@,+ characters",
      });
      return;
    }

    if (this.type !== "approved" && this.validateSmelter()) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Smelter value cannot start with -,=,@,+ characters",
      });
      return;
    }

    let updatedRecords = [];
    let newRecords = [];
    let deletedRecords = [];
    this.reviewRecords.forEach((record) => {
      if (record.pagePCR) {
        record.pagePCR = parseInt(record.pagePCR, 10);
      }
      if (record.grade) {
        record.grade =
          typeof record.grade === "string" ? record.grade : record.grade.name;
      }

      switch (record.uiStatus) {
        case "EDIT":
          updatedRecords.push(record);
          break;
        case "ADD":
          newRecords.push(record);
          break;
        case "DELETED":
          if (record.id) {
            deletedRecords.push(record.id);
          }
        default:
          break;
      }
    });

    if (this.type === "approved") {
      let payload = {
        id: this.reviewData.id,
        ocrRecord: {
          id: this.reviewRecords[0].id,
          documentId: this.reviewData.documentId,
          producerName: this.reviewData.producerName,
          smelter: this.reviewData.smelter,
          commodity: this.reviewData.commodity,
          brand: this.reviewData.brand,
          coaDateStr: this.reviewData.coaDateStr
            ? moment(this.data.coaDateStr).format("DD-MMM-YYYY")
            : null,
          coaDate: this.data.coaDateStr ? this.data.coaDateStr.getTime() : null,
          coaReference: this.reviewData.coaReference,
          coaType: this.reviewData.coaType,
          pcr: this.reviewRecords[0].pcr,
          pagePCR: this.reviewRecords[0].pagePCR,
          pagePcr: this.reviewRecords[0].pagePCR,
          type: this.reviewRecords[0].type,
          status: this.reviewRecords[0].status,
          grade: this.reviewRecords[0].grade,
          shape: this.reviewRecords[0].shape,
          packingForm: this.reviewRecords[0].packingFrom,
          industryStd: this.reviewRecords[0].industryStandards,
          units: this.reviewRecords[0].units,
          grossWeight: this.reviewRecords[0].grossWeight,
          netWeight: this.reviewRecords[0].netWeight,
          // productionDate: this.reviewRecords[0].productionDate,
          productionDateStr: this.reviewRecords[0].productionDateStr,
          productionCountry: this.reviewRecords[0].productionCountry,
          passportId: this.reviewRecords[0].passportId,
          chemicalCompositions: this.reviewRecords[0].chemicalCompositions,
        },
      };
      this.createImportService
        .updateApproved(payload)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (res) => {
            if (res) {
              this.reviewSaved.emit(true);
              if (!this.user.entityAdmin) {
                this.editComplete.emit(this.editId);
              }
            }
          },
          (err) => {
            this.coreService.showMessage({
              key: "tc",
              severity: "error",
              summary: "Error",
              detail: err.error.message,
            });
          }
        );
    } else {
      let updated = false;
      let coaDateTime = this.data.coaDateStr;
      if (
        !(
          coaDateTime === this.reviewData.coaDateStr &&
          this.data.coaReference === this.reviewData.coaReference &&
          this.data.commodity === this.reviewData.commodity &&
          this.data.brand === this.reviewData.brand &&
          this.data.producerName === this.reviewData.producerName &&
          this.data.smelter === this.reviewData.smelter
        )
      ) {
        updated = true;
      }
      let payload = {
        coaDocument: this.data.coaDocument,
        documentId: this.data.id,
        uuid: this.data.uuid,
        companyName: this.data.companyName,
        producerName: this.data.producerName,
        smelter: this.data.smelter,
        commodity: this.data.commodity,
        brand: this.data.brand,
        coaDateStr:
          this.data.coaDateStr && coaDateTime !== this.reviewData.coaDateStr
            ? moment(this.data.coaDateStr).format("DD-MMM-YYYY")
            : null,
        coaDate: this.data.coaDateStr ? this.data.coaDateStr.getTime() : null,
        coaReference: this.data.coaReference,
        coaType: this.data.coaType,
        updated,
        entityId: this.entityId ? this.entityId : null,
        updatedRecords,
        newRecords,
        deletedRecords,
      };
      this.createImportService
        .updateReview1(payload)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (res) => {
            if (res) {
              // this.reviewSaved.emit(true);
              this.coreService.showMessage({
                key: "tc",
                severity: "success",
                summary: "Success",
                detail: "Record updated successfully.",
              });

              this.reviewData = _.cloneDeep(this.data);
              this.refreshReviewData();
            }
          },
          (err) => {
            if (
              err.status === 400 &&
              err.error.message.includes("Invalid Brand, Metal and Producer")
            ) {
              this.coreService.showMessage({
                key: "tc",
                severity: "error",
                summary: "Error",
                detail:
                  "Please provide a valid combination of Metal, LME Brand and Producer Name.",
              });
            } else {
              let splitArray = err.error.message.split(":");
              let message = splitArray[splitArray.length - 1];
              this.coreService.showMessage({
                key: "tc",
                severity: "error",
                summary: "Error",
                detail: splitArray.length > 1 ? message : err.error.message,
              });
            }
          }
        );
    }
  }

  completeApprove() {
    this.reviewSaved.emit(true);
  }

  triggerActionController(event, rowData) {
    switch (event.id) {
      case "edit":
        this.selectedRecord = this.cloneRecord(rowData);
        this.onEdit();
        break;
      case "approve":
        this.selectedRecord = this.cloneRecord(rowData);
        this.resetSelection(rowData.id);
        this.approveRecord(rowData);
        break;
      case "delete":
        this.resetSelection(rowData.id);
        this.confirmDelete(rowData);
        break;
      case "requestUpdate":
        this.resetSelection(rowData.id);
        this.requestUpdateCommentDialog = true;
        this.requestUpdateRow = rowData;
        // this.requestUpdate(rowData);
        break;
      default:
        break;
    }
  }

  requestUpdate() {
    this.form.markAsTouched();
    (this.myForm as any).submitted = true;
    if (this.form.valid) {
      let payload = {
        recordId: this.requestUpdateRow.id,
        passportId: this.requestUpdateRow.passportId,
        comment: this.model.comment,
      };
      this.createImportService
        .requestUpdate(payload)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          if (res) {
            this.coreService.showMessage({
              key: "tc",
              severity: "success",
              summary: "Success",
              detail:
                "The record update request was sent successfully to admin. You will receive an email once request is approved.",
            });
            this.requestUpdateRow.actionItems = [];
            this.requestUpdateCommentDialog = false;
            this.cd.detectChanges();
          }
        });
    }
  }

  confirmDelete(rowData) {
    this.confirmationService.confirm({
      message: `
      Are you sure you want to delete this records?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        rowData.uiStatus = "DELETED";
        this.gridData = this.getAllActiveRecords();
      },
    });
  }

  approveRecord(record) {
    if (this.checkUnsaved()) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please save the updates before taking an action.",
      });

      return;
    }

    if (!this.data.coaAvailable && this.data.documentType !== "COA") {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail:
          "The record(s) cannot be approved. COA document is missing, please upload an associated COA document.",
      });

      return;
    }

    let allRecords = null;
    let payload = {
      documentId: this.data.id,
      uuid: this.data.uuid,
      coaReference: this.data.coaReference,
      records: [record.id],
      allRecords,
      coaDocument: this.data.coaDocument,
      merged: false,
    };
    this.bulkApproveRecords(payload);
  }

  resetSelection(id) {
    setTimeout(() => {
      this.actionModel[id] = null;
      (document.activeElement as HTMLElement).blur();
    }, 0);
  }

  viewComposition(event, row) {
    if (event.key && event.key !== "Enter") {
      return;
    }
    if (!row.chemicalCompositions) {
      this.createImportService
        .getChemicalComposition(row.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          row.chemicalCompositions = res.chemicalCompositions;
          this.chemicalComposition = row.chemicalCompositions;
          row.castReference = res.castReference;
          this.castReference = res.castReference || row.passportId || row.pcr;
          this.reviewChemicalCompDialog = true;
        });
    } else {
      this.chemicalComposition = row.chemicalCompositions;
      this.castReference = row.castReference || row.passportId || row.pcr;
      this.reviewChemicalCompDialog = true;
    }
  }

  hideChemicalComp() {
    this.reviewChemicalCompDialog = false;
  }

  onReviewRecordHide(event) {
    this.hideReviewRecord.emit();
  }

  assignCoa() {
    if (this.checkUnsaved()) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please save the updates before taking an action.",
      });

      return;
    }
    if (this.selectedReviewRecords.length === this.gridData.length) {
      this.assignDialog = true;
      this.docId = this.data.id;
    } else {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please select all the records to assign for review.",
      });
    }
  }

  onHideAssignDialog() {
    this.assignDialog = false;
  }

  onAssigned() {
    this.onHideAssignDialog();
    this.assigned.emit();
  }

  checkUnsaved() {
    let unsavedData = false;

    if (this.data.editable) {
      let coaDateMatch = false;
      if (typeof this.reviewData.coaDateStr === "string") {
        coaDateMatch =
          this.data.coaDateStr.getTime() ===
          this.setCoaDateStr(this.reviewData.coaDateStr).getTime();
      } else {
        coaDateMatch =
          this.data.coaDateStr.getTime() ===
          this.reviewData.coaDateStr.getTime();
      }

      if (
        !(
          coaDateMatch &&
          this.data.coaType === this.reviewData.coaType &&
          this.data.coaReference === this.reviewData.coaReference &&
          this.data.commodity === this.reviewData.commodity &&
          this.data.brand === this.reviewData.brand &&
          this.data.producerName === this.reviewData.producerName &&
          this.data.smelter === this.reviewData.smelter
        )
      ) {
        unsavedData = true;
      }
    }

    this.reviewRecords.forEach((record) => {
      switch (record.uiStatus) {
        case "EDIT":
          unsavedData = true;
          break;
        case "ADD":
          unsavedData = true;
          break;
        case "DELETED":
          if (record.id) {
            unsavedData = true;
          }
        default:
          break;
      }
    });

    return unsavedData;
  }

  bulkApprove() {
    if (this.checkUnsaved()) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please save the updates before taking an action.",
      });

      return;
    }

    if (!this.data.coaAvailable && this.data.documentType !== "COA") {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail:
          "The record(s) cannot be approved. COA document is missing, please upload an associated COA document.",
      });

      return;
    }

    if (!this.selectedReviewRecords.length) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please select at least one record to approve.",
      });
    } else {
      let records = this.selectedReviewRecords.map((record) => record.id);
      let allRecords = null;
      if (this.selectedReviewRecords.length === this.reviewRecords.length) {
        allRecords = true;
        records = [];
      }

      let payload = {
        documentId: this.data.id,
        uuid: this.data.uuid,
        coaReference: this.data.coaReference,
        records,
        coaDocument: this.data.coaDocument,
        allRecords,
        coaType: this.data.coaType,
        merged: false,
      };

      this.bulkApproveRecords(payload);
    }
  }

  bulkApproveRecords(payload) {
    this.createImportService
      .approveBulk(payload)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          if (!res.approved && res.asyncMode) {
            this.coreService.showMessage({
              key: "tc",
              severity: "info",
              summary: "Info",
              detail: res.asyncModeMessage,
            });

            this.hideReviewRecord.emit();
            return false;
          }

          if (res.approved) {
            this.coreService.showMessage({
              key: "tc",
              severity: "success",
              summary: "Success",
              detail: "Record(s) approved successfully.",
            });

            this.refreshReviewData();
          } else if (
            !res.approved &&
            res.myrecordAddResult &&
            res.myrecordAddResult.hasOwnProperty("custodyIds")
          ) {
            this.duplicateRecordsDialog = true;
            this.successPcrs = res.myrecordAddResult.successPcrs;
            this.duplicateRecords = res.myrecordAddResult.custodyIds;
            this.failedPcrs = res.myrecordAddResult.failedPcrs;
          } else if (res.oldDocumentId && res.newDocumentId) {
            this.oldDocId = res.oldDocumentId;
            this.newDocId = res.newDocumentId;
            this.oldCoaDateStr = res.oldCoaDateStr;
            this.newCoaDateStr = res.newCoaDateStr;
            this.oldOcrCustodyId = res.oldOcrCustodyId;
            this.errorMessage = false;
            this.confirmationService.confirm({
              message: res.errorMessage,
              acceptLabel: "Confirm",
              rejectLabel: "Cancel",
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                this.previewOldNewDoc = true;
              },
            });
          } else {
            this.coreService.showMessage({
              key: "tc",
              severity: "error",
              summary: "Error",
              detail: res.myrecordAddResult.message,
            });
          }
        },
        (err) => {
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: err.error.message,
          });
        }
      );
  }

  onHideOldNewDialog() {
    this.previewOldNewDoc = false;
    this.selectedRecord = null;
  }

  onNewCoaSelected() {
    this.coreService.showMessage({
      key: "tc",
      severity: "info",
      detail: "Please revise you COA Reference and click Save to proceed",
    });

    this.onHideOldNewDialog();
  }

  checkCoaDateStr(coaDateStr) {
    let originalCoaDate = coaDateStr;
    if (coaDateStr) {
      coaDateStr = moment(coaDateStr, "DD-MM-YYYY").toDate();

      if (isNaN(coaDateStr)) {
        coaDateStr = moment(originalCoaDate, "DD-MMM-YYYY").toDate();
      }

      if (isNaN(coaDateStr)) {
        coaDateStr = moment(originalCoaDate, "DD-MM-YYYY").toDate();
      }

      if (isNaN(coaDateStr)) {
        coaDateStr = moment(originalCoaDate, "DD-M-YYYY").toDate();
      }
    }

    return coaDateStr;
  }

  onSameDocSelected() {
    this.previewOldNewDoc = false;
    let oldDate = this.checkCoaDateStr(this.oldCoaDateStr).getTime();
    let newDate = this.checkCoaDateStr(this.newCoaDateStr).getTime();
    if (oldDate === newDate) {
      this.generateApprovePayload();
    } else {
      setTimeout(() => {
        this.errorMessage = true;
        this.previewOldNewDoc = true;
      }, 500);
    }
  }

  generateApprovePayload(oldCustodyId?) {
    let records = this.selectedRecord
      ? [this.selectedRecord.id]
      : this.selectedReviewRecords.map((record) => record.id);
    let allRecords = null;
    if (this.selectedReviewRecords.length === this.reviewRecords.length) {
      allRecords = true;
      records = [];
    }
    let payload = {
      documentId: this.data.id,
      uuid: this.data.uuid,
      coaReference: this.data.coaReference,
      records,
      coaDocument: this.data.coaDocument,
      allRecords,
      duplicateRecords: this.duplicateRecords,
      merged: true,
      oldDocumentId: this.oldDocId,
    };

    oldCustodyId && (payload["oldOcrCustodyId"] = this.oldOcrCustodyId);

    this.bulkApproveRecords(payload);
  }

  onSameDateSelected() {
    this.previewOldNewDoc = false;
    this.generateApprovePayload(this.oldOcrCustodyId);
  }

  continueApprove() {
    this.duplicateRecordsDialog = false;
    let records = this.selectedReviewRecords.map((record) => record.id);
    let allRecords = null;
    if (this.selectedReviewRecords.length === this.reviewRecords.length) {
      allRecords = true;
      records = [];
    }
    let payload = {
      documentId: this.data.id,
      uuid: this.data.uuid,
      coaReference: this.data.coaReference,
      records,
      coaDocument: this.data.coaDocument,
      allRecords,
      oldDocumentId: this.oldDocId,
      oldOcrCustodyId: this.oldOcrCustodyId,
      duplicateRecords: this.duplicateRecords,
      merged: false,
    };

    this.bulkApproveRecords(payload);
  }

  cancelDuplicate() {
    this.duplicateRecordsDialog = false;
    this.completeApprove();
  }

  refreshReviewData() {
    this.createImportService
      .getReviewRecords(
        this.reviewData.uuid,
        this.reviewData.coaReference,
        this.reviewData.coaDocument
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          this.reviewData = res;
          if (!res.ocrRecords.length) {
            this.reviewSaved.emit(false);
          } else {
            this.cd.detectChanges();
            this.initData();
            this.generateActionItems();
            this.selectedReviewRecords = [];
          }
        },
        (err) => {
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: err.error.message,
          });
        }
      );
  }

  onRowSelect(selectedRecords) {
    this.selectedReviewRecords = selectedRecords;
  }

  exportExcel() {
    if (this.type === "approved") {
      this.createImportService
        .exportApprovedRecords(this.data.documentId, this.data.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          if (res) {
            window.open(
              `${environment.apiUrl}/dashboard-service/record/download?reportId=${res.uuid}`
            );
          }
        });
    } else {
      this.createImportService
        .exportReviewRecords(this.reviewData.uuid, this.reviewData.coaDocument)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          if (res) {
            window.open(
              `${environment.apiUrl}/dashboard-service/record/download?reportId=${res.uuid}`
            );
          }
        });
    }
  }

  uploadAdditionalDocs() {
    this.addtionalDocsDialog = true;
  }

  onAdditonalDocsHide() {
    this.addtionalDocsDialog = false;
  }

  viewAddiotnalDocs(event, rowData) {
    if (event.key && event.key !== "Enter") {
      return;
    }
    this.viewAdditionalDocsDialog = true;
    this.coaReference = this.data.coaReference;
    if (this.type === "approved") {
      this.documentId = this.data.documentId;
    } else {
      this.documentId = this.data.id;
    }
  }

  hideAdditionalDocsDialog() {
    this.viewAdditionalDocsDialog = false;
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();

    if (
      this.user.stakeHolder === "WAREHOUSE_OPERATOR" ||
      this.user.stakeHolder === "LME_MEMBER" ||
      this.reviewRecords[0].type === "BASIC"
    ) {
      reviewListTableConfigNonProducer.columns = this.saveCols;
    } else if (
      (this.user.stakeHolder === "PRODUCER" || this.user.platformAdmin) &&
      this.reviewRecords[0].type !== "BASIC"
    ) {
      reviewListTableConfigProducer.columns = this.saveCols;
    }
  }
}
