<p-dialog
  header="Assign for review"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [(visible)]="assignDialog"
  (onHide)="onAssignDialogHide($event)"
>
  <ng-select
    [items]="users"
    bindLabel="name"
    [(ngModel)]="assignedUser"
    appendTo="body"
    placeholder="Select User"
  ></ng-select>
  <p-footer>
    <button *ngIf="assignedUser" class="btn btn-primary" (click)="assign()">
      Assign
    </button>
    <button *ngIf="!assignedUser" class="btn btn-secondary" disabled>
      Assign
    </button>
    <button class="btn btn-secondary" (click)="onAssignDialogHide(true)">
      Cancel
    </button>
  </p-footer>
</p-dialog>
