import { HttpClient, HttpEventType, HttpRequest } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { CanDeactivateGuard } from "@core/guards/allow-user-navigation.guard";
import { IUser } from "@core/models/api.model";
import { CoreService } from "@core/services/core.service";
import { LookupService } from "@core/services/lookup.service";
import { environment } from "@env/environment";
import { CreateImportService } from "@module/create-import/services/create-import.service";
import { EntityService } from "@module/platform-admin/services/entity.service";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DELIVERABLE_VALUES } from "app/app.const";
import * as _ from "lodash";
import * as moment from "moment";
import { ConfirmationService } from "primeng/api";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-append",
  templateUrl: "./append.component.html",
  styleUrls: ["./append.component.scss"],
  providers: [ConfirmationService],
})
export class AppendComponent implements OnInit, OnDestroy {
  @Input() appendDialog: boolean;
  @Input() selectedApprovedRecords?: any;
  @Input() editAppended?: boolean;
  @Input() appendedRowData?: any;
  @Input() allRecords?: any;
  @Input() filters?: any;

  @Output() hideAppendDialog = new EventEmitter<any>();

  @ViewChild("myForm", { static: false }) myForm: NgForm;
  @ViewChild("approvedRecords", { static: false }) approvedRecords;

  levels = new BehaviorSubject<any>(null);
  metals = new BehaviorSubject<any>(null);
  brands = new BehaviorSubject<any>(null);
  validities = new BehaviorSubject<any>(null);
  entities = new BehaviorSubject<any>(null);

  selectTheme: boolean;
  theme: any;
  private _destroy$ = new Subject<any>();
  themes: any;
  form = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[];
  disclosures: any;
  fileTypeFramework: any;
  fileTypeProofPoint: any;
  metricLabel: any;
  inheritance = ["Y", "N"];
  showApproved: boolean;
  user: IUser;
  responseValidities: any;
  disclosureData: any;
  selectedRecords: any;
  allRecordsApprovedSelected: any;
  approvedRecordsFilter: boolean;
  sustainableBrands: any[];
  masterProducers: any[];
  allMasterProducers: any;
  redactDialog: boolean;
  fileToRedact: any;
  fileTypeToRedact: string;
  singleMetal = [];
  selectedMasterProducerId: any;
  canLeavePage = true;
  showExpiryNotifField: any;
  addStatus: boolean = false;

  constructor(
    private createImportService: CreateImportService,
    private coreService: CoreService,
    private entityService: EntityService,
    private lookupService: LookupService,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.user = this.coreService.getLoggedInuserDetails();
    this.selectTheme = this.editAppended ? false : true;
    this.createImportService
      .getThemes()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        res.forEach((theme) => {
          theme["themeSubTheme"] = theme.subThemeName
            ? `${theme.themeName} - ${theme.subThemeName}`
            : theme.themeName;
          theme.disclosures = theme.disclosures.filter(
            (disc) => disc.active === "Y"
          );
        });
        this.themes = res.filter((ele) => ele.disclosures.length);
        if (this.editAppended) {
          let themeIdx = this.themes.findIndex(
            (theme) =>
              theme.themeSubTheme === this.appendedRowData.themeSubTheme
          );
          themeIdx !== -1 && (this.theme = this.themes[themeIdx].id);
          this.model.proofPoint = {};
          this.onThemeSelect();
          this.model.type = this.appendedRowData.themeType;
          this.disclosureChange();
        }
      });
  }

  hideApproved() {
    this.showApproved = false;
    this.changeDetectorRef.detectChanges();
    this.form.get("attachLevels").setValue(null);
  }

  appendRecords() {
    if (!this.approvedRecords.selectedApprovedRecords.length) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please select at least one record to append.",
      });
      return false;
    }
    this.selectedRecords = this.approvedRecords.selectedApprovedRecords;
    this.allRecordsApprovedSelected = this.approvedRecords.allRecords;
    this.approvedRecordsFilter = this.approvedRecords.appliedFilters;
    this.showApproved = false;
    getField("records", this.fields).hideExpression = false;
    let approvedRecords = this.approvedRecords.selectedApprovedRecords.map(
      (record) => record.passportId
    );
    setTimeout(() => {
      this.form.get("records").setValue(approvedRecords);
    }, 0);
  }

  disclosureChange() {
    let discId = this.editAppended
      ? this.appendedRowData.disclosureId.id
      : this.model.disclosure.id;
    this.singleMetal = [];
    this.entityService
      .getdisclosureData(discId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.fields[1].hideExpression = false;
        this.fields[2].hideExpression = false;
        this.showExpiryNotifField = res.expiryNotification;
        getField("attachLevels", this.fields).hideExpression = false;
        getField("brand", this.fields).hideExpression = true;
        getField("brandType", this.fields).hideExpression = true;
        getField("asset", this.fields).hideExpression = true;
        this.user.platformAdmin &&
          (getField("entities", this.fields).hideExpression = false);
        if (res.inheritance === "Y") {
          getField("inheritance", this.fields).hideExpression = false;
          setTimeout(
            () =>
              this.form.get("inheritance") &&
              this.form.get("inheritance").setValue(null),
            0
          );
        } else {
          this.form.get("inheritance") &&
            this.form.get("inheritance").setValue("N");
          getField("inheritance", this.fields).hideExpression = false;
        }
        this.disclosureData = _.cloneDeep(res);
        const type = res.type;
        if (this.editAppended) {
          this.model.type = res.type;
        } else {
          this.model.disclosure.type = res.type;
        }
        this.metricLabel = res.metricLevel;
        getField("type", this.fields).hideExpression = false;
        setTimeout(() => {
          this.form.get("type") && this.form.get("type").setValue(type);
        }, 0);
        if (type === "Metric") {
          getField("certificateConfirm", this.fields).hideExpression = true;
          getField("membershipConfirm", this.fields).hideExpression = true;
          getField("metricPercentage", this.fields).hideExpression = false;
          getField(
            "metricPercentage",
            this.fields
          ).templateOptions.addonRight.text = this.metricLabel;

          this.editAppended &&
            (this.model.metricPercentage =
              this.appendedRowData.metricPercentage);
        } else if (type === "Certification") {
          getField("certificateConfirm", this.fields).hideExpression = false;
          getField("membershipConfirm", this.fields).hideExpression = true;
          getField("metricPercentage", this.fields).hideExpression = true;
          this.editAppended &&
            (this.model.certificateConfirm =
              this.appendedRowData.certificationConfirm);
        } else {
          getField("certificateConfirm", this.fields).hideExpression = true;
          getField("membershipConfirm", this.fields).hideExpression = false;
          getField("metricPercentage", this.fields).hideExpression = true;
          this.editAppended &&
            (this.model.membershipConfirm =
              this.appendedRowData.membershipConfirm);
        }

        if (res.metals.length > 1) {
          if (!this.editAppended) {
            this.model.metals = [];
            setTimeout(
              () =>
                this.form.get("metals") && this.form.get("metals").setValue([]),
              0
            );
          }

          if (this.user.stakeHolder === "PRODUCER") {
            this.lookupService
              .getBrandsByProducer(this.user.entityName, "append")
              .pipe(takeUntil(this._destroy$))
              .subscribe((data) => {
                let metals = [...new Set(data.map((row) => row.metal))];
                let finalMetals = metals.filter((value) =>
                  res.metals.includes(value)
                );

                if (this.editAppended) {
                  let metals = this.appendedRowData.metals.split(",");
                  setTimeout(() => {
                    this.form.get("metals") &&
                      this.form.get("metals").setValue(metals);
                    this.metals.next(metals);
                    this.getBrands(metals);
                  }, 200);
                } else {
                  this.metals.next(finalMetals);
                }
              });
          } else {
            this.lookupService
              .getMetals()
              .pipe(takeUntil(this._destroy$))
              .subscribe((value) => {
                let metals = value;
                let finalMetals = metals.filter((value) =>
                  res.metals.includes(value)
                );
                if (this.editAppended) {
                  let metals = this.appendedRowData.metals.split(",");
                  setTimeout(() => {
                    this.form.get("metals") &&
                      this.form.get("metals").setValue(metals);
                    this.metals.next(metals);
                    this.getBrands(metals);
                  }, 200);
                } else {
                  this.metals.next(finalMetals);
                }
              });
          }
        } else {
          this.singleMetal = res.metals;
          if (this.editAppended) {
            res.metals = this.appendedRowData.metals.split(",");
            setTimeout(() => {
              this.form.get("metals") &&
                this.form.get("metals").setValue(res.metals);
              this.metals.next(res.metals);
              this.getBrands(res.metals);
            }, 200);
          } else {
            setTimeout(() => {
              this.form.get("metals") &&
                this.form.get("metals").setValue(res.metals);
              this.metals.next(res.metals);
              this.getBrands(res.metals);
            }, 200);
          }
        }

        setTimeout(() => {
          this.fields[0].fieldGroup[2].hideExpression = false;
          this.fields[0].fieldGroup[3].hideExpression = true;
        }, 100);

        this.levels.next(res.attachLevels);
        setTimeout(
          () =>
            this.form.get("attachLevels") &&
            this.form.get("attachLevels").setValue(null),
          0
        );
        res.validities && (this.responseValidities = [...res.validities]);
        if (!res.validities.includes("Perpetuity")) {
          getField("validity", this.fields).hideExpression = false;
          getField("onAfter", this.fields).hideExpression = true;
          getField("before", this.fields).hideExpression = true;
          getField("dateRange", this.fields).hideExpression = true;
          this.validities.next(res.validities);
        } else {
          getField("validity", this.fields).hideExpression = true;
          getField("onAfter", this.fields).hideExpression = true;
          getField("before", this.fields).hideExpression = true;
          getField("dateRange", this.fields).hideExpression = true;
        }

        this.hideExpiryNotifFields();

        if (this.user.platformAdmin) {
          this.lookupService
            .getMasterProducers()
            .pipe(takeUntil(this._destroy$))
            .subscribe((res) => {
              this.masterProducers = res;
              this.entities.next(res);
            });
        }

        this.configureProofPoinFields(res);
        this.configureFramworkEmployed(res);

        this.editAppended && this.appendDefault();
        !this.editAppended && this.resetFields();
      });
  }

  hideExpiryNotifFields() {
    getField("expiryNotification", this.fields).hideExpression = true;
    getField("frequency", this.fields).hideExpression = true;
    getField("threeMonths", this.fields).hideExpression = true;
    getField("oneMonths", this.fields).hideExpression = true;
    getField("twoWeeks", this.fields).hideExpression = true;
  }

  resetFields() {
    setTimeout(() => {
      this.form.get("validity") && this.form.get("validity").setValue(null);
      this.form.get("onAfter") && this.form.get("onAfter").setValue(null);
      this.form.get("before") && this.form.get("before").setValue(null);
      this.form.get("dateRange") && this.form.get("dateRange").setValue(null);
      this.model.dateRange = null;
      this.model.before = null;
      this.model.onAfter = null;
      this.model.validity && (this.model.validity = null);
      this.form.get("brandType") && this.form.get("brandType").setValue(null);
      this.form.get("asset") && this.form.get("asset").setValue(null);
      this.form.get("entities") && this.form.get("entities").setValue(null);

      this.form.get("frameworkEmployed.sameProofPoint") &&
        this.form.get("frameworkEmployed.sameProofPoint").setValue(null);
      this.form.get("frameworkEmployed.textBox") &&
        this.form.get("frameworkEmployed.textBox").setValue(null);
      this.form.get("frameworkEmployed.url") &&
        this.form.get("frameworkEmployed.url").setValue(null);

      if (this.form.get("proofPoint.uploadFile")) {
        this.form.get("proofPoint.uploadFile").setValue(null);
        this.fileTypeProofPoint = null;
        (document.getElementById("proofPointFile") as HTMLInputElement).value =
          null;
      }

      if (this.form.get("frameworkEmployed.uploadFile")) {
        this.form.get("frameworkEmployed.uploadFile").setValue(null);
        this.fileTypeFramework = null;
        (
          document.getElementById("frameworkEmployedFile") as HTMLInputElement
        ).value = null;
      }

      this.form.get("proofPoint.textBox") &&
        this.form.get("proofPoint.textBox").setValue(null);
      this.form.get("proofPoint.url") &&
        this.form.get("proofPoint.url").setValue(null);

      this.form.get("metricPercentage") &&
        this.form.get("metricPercentage").setValue(null);

      this.form.get("inheritance") &&
        this.form.get("inheritance").setValue(null);
    }, 0);
  }

  getBrands(metal?) {
    this.form.get("asset") && this.form.get("brand").setValue(null);
    this.form.get("brandType") && this.form.get("brandType").setValue(null);
    if (!(metal || this.model.metals)) {
      this.brands.next([]);
      this.form.get("asset") && this.form.get("asset").setValue(null);
      getField("brandType", this.fields).hideExpression = true;
      getField("asset", this.fields).hideExpression = true;
      return;
    }
    if (this.user.platformAdmin) {
      if (metal && typeof metal === "string") {
        metal = [metal];
      }
      if (this.model.metals && typeof this.model.metals === "string") {
        this.model.metals = [this.model.metals];
      }
      if (metal || this.model.metals.length) {
        this.lookupService
          .getBrandMultiMetal(
            metal || this.model.metals,
            "nonDeliverable",
            true
          )
          .pipe(takeUntil(this._destroy$))
          .subscribe((value) => {
            if (!metal) {
              this.form.get("brand") && this.form.get("brand").setValue(null);
              this.form.get("entities") &&
                this.form.get("entities").setValue(null);
            }
            getField("entities", this.fields).hideExpression = false;
            let brands = [..._.flattenDeep(value)];
            this.sustainableBrands = brands;
            let brand = [];
            brands.forEach((element) => {
              brand.push({
                name: `${element["brand"]} | ${element.producerName}`,
                inactive:
                  element.brandStatus === "DELISTED" ||
                  element.brandStatus === "DELISTING_PERIOD" ||
                  element.brandStatus === "SUSPENDED" ||
                  element.status === false,
                status: element.brandStatus,
              });
            });

            let sortedBrand = _.orderBy(brand, ["name"], ["asc"]);
            this.brands.next(sortedBrand);
            if (this.editAppended && this.appendedRowData.level === "Brand") {
              let brandIdx = brands.findIndex(
                (row) => row["brand"] === this.appendedRowData.brand
              );
              let brandStatusId = DELIVERABLE_VALUES.findIndex(
                (brandStatus) =>
                  brandStatus.id === this.appendedRowData.brandStatusDb
              );
              let brandStatus =
                brandStatusId !== -1
                  ? DELIVERABLE_VALUES[brandStatusId].value
                  : "N/A";
              this.form
                .get("brand")
                .setValue(
                  `${this.appendedRowData.brand} | ${this.appendedRowData.producerName}`
                );
              this.form.get("brandType").setValue(brandStatus);
              this.form.get("asset").setValue(brands[brandIdx]["asset"]);
            }
          });

        if (
          this.model.attachLevels &&
          this.model.attachLevels.toLowerCase() === "entity"
        ) {
          this.getCompaniesEntityLevel();
        }
      } else {
        this.form.get("attachLevels").setValue(null);
        let brand = [];
        this.brands.next(brand);
        getField("records", this.fields).hideExpression = true;
        getField("brand", this.fields).hideExpression = true;
        getField("entities", this.fields).hideExpression = true;
        this.form.get("brand") && this.form.get("brand").setValue(null);
        this.form.get("entities") && this.form.get("entities").setValue(null);
      }
    } else {
      let metals = Array.isArray(this.model.metals)
        ? this.model.metals.join("###")
        : this.model.metals;
      let payload = {
        metals,
        masterProducer: this.user.entityName,
      };
      this.lookupService
        .getBrandDetails(payload)
        .pipe(takeUntil(this._destroy$))
        .subscribe((data) => {
          this.form.get("brand") && this.form.get("brand").setValue(null);
          this.form.get("entities") && this.form.get("entities").setValue(null);
          let brands: any = data;
          let displayableBrand = [];
          brands.forEach((element) => {
            displayableBrand.push({
              name: `${element["brand"]} | ${element.producerName}`,
              inactive:
                element.brandStatus === "DELISTED" ||
                element.brandStatus === "DELISTING_PERIOD" ||
                element.brandStatus === "SUSPENDED" ||
                element.status === false,
              status: element.brandStatus,
            });
          });
          displayableBrand = _.orderBy(displayableBrand, ["name"], ["asc"]);
          // displayableBrand = _.uniqBy(displayableBrand, "name");
          this.brands.next(displayableBrand);
          this.sustainableBrands = [...new Set(data)];
          if (this.editAppended && this.appendedRowData.level === "Brand") {
            this.form.get("brand") &&
              this.form
                .get("brand")
                .setValue(
                  `${this.appendedRowData.brand} | ${this.appendedRowData.producerName}`
                );
            let brandIdx = data.findIndex(
              (row) => row.brand === this.appendedRowData.brand
            );
            let delieverableValue =
              data[brandIdx]?.nonDeliverable === true
                ? "Non-Deliverable"
                : "Deliverable";
            let brandStatusId = DELIVERABLE_VALUES.findIndex(
              (brandStatus) =>
                brandStatus.id === this.appendedRowData.brandStatusDb
            );
            let brandStatus =
              brandStatusId !== -1
                ? DELIVERABLE_VALUES[brandStatusId].value
                : "N/A";
            this.form.get("brandType").setValue(brandStatus);
            this.form.get("asset").setValue(brands[brandIdx].asset);
          }
        });
    }
  }

  getCompaniesEntityLevel() {
    this.lookupService
      .getEntitiesEntityLevel(this.model.metals)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        let metals = [..._.flattenDeep(res)];
        metals = metals.sort();
        this.entities.next([...new Set(metals)]);
        if (!this.editAppended) {
          this.form.get("entities") && this.form.get("entities").setValue(null);
        }
      });
  }

  getCompany(event) {
    let brandStatusId = DELIVERABLE_VALUES.findIndex(
      (brandStatus) => brandStatus.id === this.model.brand.status
    );
    let brandStatus =
      brandStatusId !== -1 ? DELIVERABLE_VALUES[brandStatusId].value : "N/A";
    if (this.model.brand.inactive) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: `The brand you have selected is '${brandStatus}', please select an 'active' brand to proceed and append disclosures.`,
      });

      this.form.get("brand").setValue(null);

      return false;
    }

    getField("brandType", this.fields).hideExpression = false;
    getField("asset", this.fields).hideExpression = false;

    let brandName = this.model.brand.name.split("|")[0].trim();
    const producerName = this.model.brand.name.split("|")[1].trim();
    let brandIdx = this.sustainableBrands.findIndex(
      (el) => el.brand === brandName
    );
    if (this.model.attachLevels.toLowerCase() === "brand") {
      let delieverableValue =
        this.sustainableBrands[brandIdx]?.nonDeliverable === true
          ? "Non-Deliverable"
          : "Deliverable";
      if (delieverableValue === "Non-Deliverable") {
        this.form.get("inheritance").setValue("N");
      } else {
        this.form.get("inheritance").setValue(null);
      }
      setTimeout(() => {
        this.form.get("brandType").setValue(brandStatus);
        this.form.get("asset").setValue(this.sustainableBrands[brandIdx].asset);
      });
    }
    this.lookupService
      .getMasterProducersMultiMetal(this.model.metals, brandName, producerName)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.allMasterProducers = [..._.flattenDeep(res)];
        let selectMasterProducerId: any;
        if (!this.user.platformAdmin) {
          selectMasterProducerId = this.allMasterProducers.findIndex(
            (row) =>
              row.masterProducerName.toLowerCase() ===
              this.user.entityName.toLowerCase()
          );

          this.selectedMasterProducerId =
            this.allMasterProducers[selectMasterProducerId]?.id;
        }
        this.masterProducers = [];
        [..._.flattenDeep(res)].forEach((element) => {
          this.masterProducers.push(element["masterProducerName"]);
        });
        this.entities.next([...new Set(this.masterProducers)]);
        this.form.get("entities") && this.form.get("entities").setValue(null);
      });
  }

  levelUpdate(event?) {
    if (this.model.hasOwnProperty("attachLevels")) {
      if (this.model.attachLevels.toLowerCase() === "brand") {
        if (!this.form.get("metals")?.disabled) {
          this.fields[0].fieldGroup[2].hideExpression = true;
          this.fields[0].fieldGroup[3].hideExpression = false;
        }
        if (this.singleMetal && this.singleMetal.length) {
          setTimeout(() => {
            this.form
              .get("metals")
              ?.setValue([this.singleMetal[0]], { emitEvent: false });
          }, 0);
        }
      } else {
        this.fields[0].fieldGroup[2].hideExpression = false;
        this.fields[0].fieldGroup[3].hideExpression = true;
      }
      switch (this.model.attachLevels.toLowerCase()) {
        case "brand":
          getField("brand", this.fields).hideExpression = false;
          getField("brandType", this.fields).hideExpression = false;
          getField("asset", this.fields).hideExpression = false;
          getField("records", this.fields).hideExpression = true;
          if (this.user.platformAdmin) {
            getField("entities", this.fields).hideExpression = false;
            !this.editAppended &&
              setTimeout(() => this.form.get("entities").setValue(null), 0);
          }
          if (this.disclosureData.inheritance === "Y") {
            getField("inheritance", this.fields).hideExpression = false;
          }

          setTimeout(() => {
            this.form.get("brand").setValue(null);
            this.disclosureData.metals.length > 1 && this.brands.next([]);
            this.form.get("brandType").setValue(null);
            this.form.get("asset").setValue(null);
          }, 0);
          break;
        case "entity":
          this.model.brand = null;
          getField("records", this.fields).hideExpression = true;
          getField("brand", this.fields).hideExpression = true;
          getField("brandType", this.fields).hideExpression = true;
          getField("asset", this.fields).hideExpression = true;
          if (this.disclosureData.inheritance === "Y") {
            getField("inheritance", this.fields).hideExpression = false;
          }
          if (this.user.platformAdmin) {
            getField("entities", this.fields).hideExpression = false;
            !this.editAppended &&
              setTimeout(() => this.form.get("entities").setValue(null), 0);
            if (!this.model.metals) {
              this.lookupService
                .getMasterProducers()
                .pipe(takeUntil(this._destroy$))
                .subscribe((res) => {
                  this.entities.next(res);
                });
            } else {
              this.getCompaniesEntityLevel();
            }
          }
          break;
        case "record":
          getField("brand", this.fields).hideExpression = true;
          getField("brandType", this.fields).hideExpression = true;
          getField("asset", this.fields).hideExpression = true;
          getField("inheritance", this.fields).hideExpression = true;
          if (!this.editAppended) {
            if (
              this.model.metals.length === 0 ||
              (!this.model.entities && this.user.platformAdmin)
            ) {
              let message = this.user.platformAdmin
                ? "Please select a metal and company before selecting a record level."
                : "Please select a metal before selecting a record level.";
              this.coreService.showMessage({
                key: "tc",
                severity: "error",
                summary: "Error",
                detail: message,
              });
              this.form.get("attachLevels").setValue(null);

              return;
            }
          }

          if (!this.editAppended) {
            if (this.user.platformAdmin) {
              this.selectedMasterProducerId = this.masterProducers.findIndex(
                (producer) => producer.name === this.model.entities
              );
            }

            this.showApproved = true;
            this.changeDetectorRef.detectChanges();
          }
          break;
        default:
          break;
      }
    }
  }

  appendDefault() {
    this.form.get("disclosure") &&
      this.form.get("disclosure").setValue(this.appendedRowData.disclosureName);
    this.model.proofPoint.textBox =
      this.appendedRowData.proofText === "null"
        ? ""
        : this.appendedRowData.proofText;
    this.model.proofPoint.url =
      this.appendedRowData.proofUrl === "null"
        ? ""
        : this.appendedRowData.proofUrl;
    if (this.appendedRowData.frameworkAsProof) {
      this.model.frameworkEmployed.sameProofPoint =
        this.appendedRowData.frameworkAsProof;
      this.model.frameworkEmployed.uploadFile = null;
      this.fileTypeFramework = null;
    }
    this.model.frameworkEmployed.textBox =
      this.appendedRowData.frameworkText === "null"
        ? ""
        : this.appendedRowData.frameworkText;
    this.model.frameworkEmployed.url =
      this.appendedRowData.frameworkUrl === "null"
        ? ""
        : this.appendedRowData.frameworkUrl;

    this.model.brand = `${this.appendedRowData.brand} | ${this.appendedRowData.producerName}`;
    this.model.validity = this.appendedRowData.validity;
    this.model.entities = this.appendedRowData.masterProducer;
    if (
      this.model.validity === "Production on & after" ||
      this.model.validity === "Production on or before"
    ) {
      this.model.onAfter = new Date(this.appendedRowData.validityDateFrom);
    } else if (this.model.validity === "Date range") {
      this.model.dateRange = [
        new Date(this.appendedRowData.validityDateFrom),
        new Date(this.appendedRowData.validityDateTo),
      ];
    }
    if (
      this.model.hasOwnProperty("attachLevels") &&
      this.model.attachLevels.toLowerCase() === "record" &&
      !this.editAppended
    ) {
      getField("records", this.fields).hideExpression = false;
      let approvedRecords = this.appendedRowData.appendedRecords.map(
        (record) => record.passportId
      );
      this.approvedRecords.selectedApprovedRecords =
        this.appendedRowData.appendedRecords;
      this.model.records = approvedRecords;
    }
    setTimeout(() => {
      this.form.get("attachLevels") &&
        this.form.get("attachLevels").setValue(this.appendedRowData.level);
      this.form.get("inheritance") &&
        this.form.get("inheritance").setValue(this.appendedRowData.inheritance);
      this.tenureChange();
      this.levelUpdate();
      this.showHideExpiryNotif();
    }, 0);
  }

  showHideExpiryNotif() {
    if (
      this.showExpiryNotifField &&
      (this.appendedRowData.validityDateTo ||
        (this.appendedRowData.validityDateFrom &&
          this.appendedRowData.validityDateTo))
    ) {
      getField("expiryNotification", this.fields).hideExpression = false;
      getField("frequency", this.fields).hideExpression = false;
      getField("threeMonths", this.fields).hideExpression = false;
      getField("oneMonths", this.fields).hideExpression = false;
      getField("twoWeeks", this.fields).hideExpression = false;
      setTimeout(() => {
        if (this.appendedRowData.expiryNotification === null) {
          this.form.get("expiryNotification").setValue(true);
          this.form.get("threeMonths").setValue(false);
          this.form.get("oneMonths").setValue(true);
          this.form.get("twoWeeks").setValue(false);
        } else {
          this.form
            .get("expiryNotification")
            .setValue(this.appendedRowData.expiryNotification);
          this.form
            .get("threeMonths")
            .setValue(this.appendedRowData.threeMonth);
          this.form.get("oneMonths").setValue(this.appendedRowData.oneMonth);
          this.form.get("twoWeeks").setValue(this.appendedRowData.twoWeek);
        }
      }, 0);
    }
  }

  configureProofPoinFields(res) {
    res.proofPoints = res.proofPoints.map((string) => string.trim());
    if (res.proofPoints.includes("TEXT_BOX")) {
      this.fields[1].fieldGroup[2].hideExpression = false;
    } else {
      this.fields[1].fieldGroup[2].hideExpression = true;
    }

    if (res.proofPoints.includes("UPLOAD_DOCUMENT")) {
      if (this.user.redactAppend) {
        this.fields[1].fieldGroup[0].hideExpression = false;
      } else {
        this.fields[1].fieldGroup[0].hideExpression = true;
      }
      this.fields[1].fieldGroup[1].hideExpression = false;
    } else {
      this.fields[1].fieldGroup[0].hideExpression = true;
      this.fields[1].fieldGroup[1].hideExpression = true;
    }

    if (res.proofPoints.includes("WEBLINK")) {
      this.fields[1].fieldGroup[3].hideExpression = false;
    } else {
      this.fields[1].fieldGroup[3].hideExpression = true;
    }
  }

  configureFramworkEmployed(res) {
    res.fraeworkEmployeds = res.fraeworkEmployeds.map((string) =>
      string.trim()
    );
    if (res.fraeworkEmployeds.includes("TEXT_BOX")) {
      this.fields[2].fieldGroup[3].hideExpression = false;
    } else {
      this.fields[2].fieldGroup[3].hideExpression = true;
    }

    if (res.fraeworkEmployeds.includes("UPLOAD_DOCUMENT")) {
      this.fields[2].fieldGroup[2].hideExpression = false;
      if (this.user.redactAppend) {
        this.fields[2].fieldGroup[1].hideExpression = false;
      } else {
        this.fields[2].fieldGroup[1].hideExpression = true;
      }
    } else {
      this.fields[2].fieldGroup[2].hideExpression = true;
      this.fields[2].fieldGroup[1].hideExpression = true;
    }

    if (res.fraeworkEmployeds.includes("WEBLINK")) {
      this.fields[2].fieldGroup[4].hideExpression = false;
    } else {
      this.fields[2].fieldGroup[4].hideExpression = true;
    }
  }

  onThemeSelect() {
    this.selectTheme = false;
    let themeIdx = this.themes.findIndex((theme) => theme.id === this.theme);
    themeIdx !== -1 &&
      (this.disclosures = this.themes[themeIdx].disclosures.filter(
        (disclosure) =>
          disclosure.status === "ACTIVE" &&
          disclosure.active === "Y" &&
          disclosure.type
      ));
    this.initForm();
    if (this.editAppended) {
      if (this.disclosures) {
        let discIdx = this.disclosures.findIndex(
          (disc) => disc.id === this.appendedRowData.disclosureId
        );
        discIdx !== -1 && (this.model.disclosure = this.disclosures[discIdx]);
      }
    }
  }

  onAppendHide(event?, onSave?) {
    if (Object.keys(this.model).length && !onSave) {
      this.confirmationService.confirm({
        message:
          "Unsaved changes will be lost. Are you sure you want to continue?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.hideAppendDialog.emit(event);
        },
      });
    } else {
      this.hideAppendDialog.emit(event);
    }
  }

  checkFileTypeProofFramework() {
    if (
      this.model.proofPoint.hasOwnProperty("uploadFile") &&
      this.model.proofPoint.uploadFile
    ) {
      var ext = this.model.proofPoint.uploadFile.match(/\.([^\.]+)$/)[1];

      if (ext && ext.toLowerCase() !== "pdf") {
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail: "Please upload pdf for proof point, file type not allowed.",
        });

        return false;
      }
    }
    if (
      this.model.frameworkEmployed.hasOwnProperty("uploadFile") &&
      this.model.frameworkEmployed.uploadFile
    ) {
      var ext = this.model.frameworkEmployed.uploadFile.match(/\.([^\.]+)$/)[1];

      if (ext && ext.toLowerCase() !== "pdf") {
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail:
            "Please upload pdf for framework employed, file type not allowed.",
        });

        return false;
      }
    }

    if (
      this.model.proofPoint.hasOwnProperty("uploadFile") &&
      this.model.proofPoint.uploadFile &&
      this.model.frameworkEmployed.hasOwnProperty("uploadFile") &&
      this.model.frameworkEmployed.uploadFile
    ) {
      const fsizeProofPoint = this.fileTypeProofPoint.size;
      const fileProofPoint = Math.round(fsizeProofPoint / 1024);

      const fsizeFramework = this.fileTypeFramework.size;
      const fileFramework = Math.round(fsizeFramework / 1024);

      if (fileProofPoint + fileFramework > 28000) {
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail:
            "Combined file size of proof point and framework employed is greater than the allowed limit of 28 MB",
        });

        return false;
      }
    }

    if (
      this.model.proofPoint.hasOwnProperty("uploadFile") &&
      this.model.proofPoint.uploadFile &&
      !this.model.frameworkEmployed.uploadFile
    ) {
      const fsizeProofPoint = this.fileTypeProofPoint.size;
      const fileProofPoint = Math.round(fsizeProofPoint / 1024);

      if (fileProofPoint > 28000) {
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail:
            "Uploaded file size is greater than the allowed limit of 28 MB",
        });

        return false;
      }
    }

    if (
      !this.model.proofPoint.uploadFile &&
      this.model.frameworkEmployed.hasOwnProperty("uploadFile") &&
      this.model.frameworkEmployed.uploadFile
    ) {
      const fsizeFramework = this.fileTypeFramework.size;
      const fileFramework = Math.round(fsizeFramework / 1024);

      if (fileFramework > 28000) {
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail:
            "Uploaded file size is greater than the allowed limit of 28 MB",
        });

        return false;
      }
    }

    return true;
  }

  returnPreviousLocation(event) {
    event.preventDefault();
    this.model = {};
    this.selectTheme = true;
    this.theme = null;
    this.form = new UntypedFormGroup({});
    this.initForm();
  }

  checkAnyProofPointAdded() {
    let proofPointDocUploaded =
      this.appendedRowData && this.appendedRowData.proofDocument;
    if (
      this.model.proofPoint &&
      !(
        this.model.proofPoint.uploadFile ||
        this.model.proofPoint.textBox ||
        this.model.proofPoint.url ||
        proofPointDocUploaded
      )
    ) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please provide value for any field of proof of point.",
      });
      return false;
    }

    return true;
  }

  checkAnyFrameworkEmployedPointAdded() {
    let frameworkDocUploaded =
      this.appendedRowData && this.appendedRowData.frameworkDocument;
    if (
      this.model.frameworkEmployed &&
      !this.model.frameworkEmployed.sameProofPoint &&
      !(
        this.model.frameworkEmployed.uploadFile ||
        this.model.frameworkEmployed.textBox ||
        this.model.frameworkEmployed.url ||
        frameworkDocUploaded
      )
    ) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail: "Please provide value for any field of framework employed.",
      });
      return false;
    }

    return true;
  }

  fileValidOrInvalid(res, field) {
    if (res) {
      this.generateDataForSubmit();
    } else {
      this.form.get(field).setErrors({
        serverError: {
          message: `File is password protected / encrypted, please upload a different file`,
        },
      });
    }
  }

  checkFrequencySelected() {
    if (
      !this.model.threeMonths &&
      !this.model.oneMonths &&
      !this.model.twoWeeks
    ) {
      this.coreService.showMessage({
        key: "tc",
        severity: "error",
        summary: "Error",
        detail:
          "Please select atleast one frequency for the Expiry Notifications",
      });
      return false;
    }

    return true;
  }

  submit() {
    this.form.markAsTouched();
    (this.myForm as any).submitted = true;
    if (this.form.valid) {
      if (!this.checkAnyProofPointAdded()) {
        return false;
      }

      if (!this.checkAnyFrameworkEmployedPointAdded()) {
        return false;
      }

      if (!this.checkFileTypeProofFramework()) {
        return false;
      }

      if (this.model.expiryNotification) {
        if (!this.checkFrequencySelected()) {
          return false;
        }
      }

      if (
        this.model.hasOwnProperty("validity") &&
        this.model.validity &&
        this.model.validity.toLowerCase() === "date range" &&
        !this.model.dateRange[1]
      ) {
        this.form.get("dateRange").setErrors({
          serverError: {
            message: `Please select the end date as well`,
          },
        });
        return false;
      }

      if (
        this.model.proofPoint.hasOwnProperty("uploadFile") &&
        this.model.proofPoint.uploadFile &&
        this.model.frameworkEmployed.hasOwnProperty("uploadFile") &&
        this.model.frameworkEmployed.uploadFile
      ) {
        this.createImportService
          .validateDocument(this.fileTypeProofPoint, "VALUE-ADDED")
          .pipe(takeUntil(this._destroy$))
          .subscribe((res) => {
            if (res) {
              if (
                this.model.frameworkEmployed.hasOwnProperty("uploadFile") &&
                this.model.frameworkEmployed.uploadFile
              ) {
                this.createImportService
                  .validateDocument(this.fileTypeFramework, "VALUE-ADDED")
                  .pipe(takeUntil(this._destroy$))
                  .subscribe((res) => {
                    this.fileValidOrInvalid(
                      res,
                      "frameworkEmployed.uploadFile"
                    );
                  });
              } else {
                this.generateDataForSubmit();
              }
            } else {
              this.form.get("proofPoint.uploadFile").setErrors({
                serverError: {
                  message: `File is password protected / encrypted, please upload a different file`,
                },
              });
            }
          });
      } else if (
        this.model.proofPoint.hasOwnProperty("uploadFile") &&
        this.model.proofPoint.uploadFile &&
        !this.model.frameworkEmployed.uploadFile
      ) {
        this.createImportService
          .validateDocument(this.fileTypeProofPoint, "VALUE-ADDED")
          .pipe(takeUntil(this._destroy$))
          .subscribe((res) => {
            this.fileValidOrInvalid(res, "proofPoint.uploadFile");
          });
      } else if (
        !this.model.proofPoint.uploadFile &&
        this.model.frameworkEmployed.hasOwnProperty("uploadFile") &&
        this.model.frameworkEmployed.uploadFile
      ) {
        this.createImportService
          .validateDocument(this.fileTypeFramework, "VALUE-ADDED")
          .pipe(takeUntil(this._destroy$))
          .subscribe((res) => {
            this.fileValidOrInvalid(res, "frameworkEmployed.uploadFile");
          });
      } else {
        this.generateDataForSubmit();
      }
    }
  }

  generateDataForSubmit() {
    let membershipConfirm = false;
    if (this.model.hasOwnProperty("membershipConfirm")) {
      membershipConfirm = this.model.membershipConfirm ? true : false;
    }

    let metricPercentage = 0;
    if (this.model.hasOwnProperty("metricPercentage")) {
      metricPercentage = this.model.metricPercentage;
    }

    let certificationConfirm = false;
    if (this.model.hasOwnProperty("certificateConfirm")) {
      certificationConfirm = this.model.certificateConfirm ? true : false;
    }

    let proofUrl = "";
    if (this.model.proofPoint && this.model.proofPoint.hasOwnProperty("url")) {
      proofUrl = this.model.proofPoint.url ? this.model.proofPoint.url : "";
    }

    let proofText = "";
    if (
      this.model.proofPoint &&
      this.model.proofPoint.hasOwnProperty("textBox")
    ) {
      proofText = this.model.proofPoint.textBox;
    }

    let frameworkText = "";
    if (
      this.model.frameworkEmployed &&
      this.model.frameworkEmployed.hasOwnProperty("textBox")
    ) {
      if (this.model.frameworkEmployed.sameProofPoint) {
        frameworkText = this.model.proofPoint.textBox
          ? this.model.proofPoint.textBox
          : "";
      } else {
        frameworkText = this.model.frameworkEmployed.textBox;
      }
    }

    let frameworkUrl = "";
    if (
      this.model.frameworkEmployed &&
      this.model.frameworkEmployed.hasOwnProperty("url")
    ) {
      if (this.model.frameworkEmployed.sameProofPoint) {
        frameworkUrl = this.model.proofPoint.url
          ? this.model.proofPoint.url
          : "";
      } else {
        frameworkUrl = this.model.frameworkEmployed.url
          ? this.model.frameworkEmployed.url
          : "";
      }
    }

    const formData = new FormData();
    let payload = {};
    if (!this.editAppended) {
      let approvedRecord = null;
      if (
        this.model.hasOwnProperty("attachLevels") &&
        this.model.attachLevels.toLowerCase() === "record"
      ) {
        this.changeDetectorRef.detectChanges();
        approvedRecord = this.selectedRecords.map((approved) => approved.id);
      }

      let validityDateFrom = null;
      let validityDateTo = null;
      if (
        this.responseValidities &&
        !this.responseValidities.includes("Perpetuity")
      ) {
        let validity = this.model.validity;
        if (validity === "Production on & after") {
          validityDateFrom = moment(this.model.onAfter).format(
            "YYYY-MM-DDT00:00:00"
          );
        } else if (
          validity.toLowerCase() === "production on or before" ||
          validity.toLowerCase() === "production before"
        ) {
          validityDateFrom = moment(this.model.before).format(
            "YYYY-MM-DDT00:00:00"
          );
        } else {
          validityDateFrom = moment(this.model.dateRange[0]).format(
            "YYYY-MM-DDT00:00:00"
          );
          validityDateTo = moment(this.model.dateRange[1]).format(
            "YYYY-MM-DDT23:59:59"
          );
        }
      }

      let frameworkAsProof = this.model.frameworkEmployed.sameProofPoint
        ? this.model.frameworkEmployed.sameProofPoint
        : false;
      if (approvedRecord) {
        formData.append(
          "selectedRecord",
          this.allRecordsApprovedSelected ? [] : approvedRecord
        );
      }
      formData.append("themeId", this.theme);
      formData.append("disclosureId", this.model.disclosure.id);
      formData.append("themeType", this.model.type);
      formData.append("membershipConfirm", String(membershipConfirm));
      formData.append("metricPercentage", String(metricPercentage));
      formData.append("certificationConfirm", String(certificationConfirm));
      proofUrl && formData.append("proofUrl", proofUrl);

      proofText && formData.append("proofText", proofText);

      frameworkText && formData.append("frameworkText", frameworkText);

      frameworkUrl && formData.append("frameworkUrl", frameworkUrl);

      formData.append("frameworkAsProof", frameworkAsProof);

      formData.append("level", this.model.attachLevels);

      if (this.model.hasOwnProperty("expiryNotification")) {
        formData.append("expiryNotification", this.model.expiryNotification);
        formData.append(
          "threeMonth",
          this.model.expiryNotification ? this.model.threeMonths : false
        );
        formData.append(
          "oneMonth",
          this.model.expiryNotification ? this.model.oneMonths : false
        );
        formData.append(
          "twoWeek",
          this.model.expiryNotification ? this.model.twoWeeks : false
        );
      }

      if (this.model.hasOwnProperty("metals")) {
        let metals = Array.isArray(this.model.metals)
          ? this.model.metals.join("###")
          : this.model.metals;
        formData.append("metals", metals);
      }

      this.model.hasOwnProperty("inheritance") &&
        formData.append("inheritance ", this.model.inheritance);

      if (
        this.model.hasOwnProperty("brand") &&
        this.model.brand &&
        this.model.brand.name
      ) {
        formData.append("brand", this.model.brand.name.split("|")[0].trim());
        formData.append(
          "producerName",
          this.model.brand.name.split("|")[1].trim()
        );
      }

      if (this.user.platformAdmin) {
        this.model.hasOwnProperty("entities") &&
          formData.append("masterProducer ", this.model.entities);
      }

      this.model.hasOwnProperty("validity") &&
        formData.append("validity ", this.model.validity);

      validityDateFrom &&
        formData.append("validityDateFrom ", validityDateFrom);

      validityDateTo && formData.append("validityDateTo ", validityDateTo);

      if (this.allRecordsApprovedSelected) {
        this.allRecordsApprovedSelected &&
          formData.append("allRecords", this.allRecordsApprovedSelected);

        this.allRecordsApprovedSelected &&
          formData.append(
            "filtersStr",
            JSON.stringify(this.approvedRecordsFilter)
          );
      } else {
        formData.append("allRecords", "false");
        formData.append("filtersStr", "");
      }

      this.model.asset && formData.append("asset", this.model.asset);
      if (this.model.brandType) {
        let nonDeliverable =
          this.model.brandType === "Non-Deliverable" ? "true" : "false";
        formData.append("nonDeliverable", nonDeliverable);
      }
      this.addStatus && formData.append("status", "EXPIRED");
    } else {
      let nonDeliverable =
        this.model.brandType === "Non-Deliverable" ? "true" : "false";
      payload = {
        type: this.appendedRowData.themeType,
        asset: this.model.asset ? this.model.asset : null,
        nonDeliverable,
        membershipConfirm,
        metricPercentage,
        certificationConfirm,
        proofUrl,
        proofText,
        frameworkText,
        frameworkUrl,
        frameworkAsProof: this.model.frameworkEmployed.sameProofPoint
          ? this.model.frameworkEmployed.sameProofPoint
          : false,
        expiryNotification: this.model.expiryNotification ? true : false,
        threeMonth: this.model.expiryNotification
          ? this.model.threeMonths
          : false,
        oneMonth: this.model.expiryNotification ? this.model.oneMonths : false,
        twoWeek: this.model.expiryNotification ? this.model.twoWeeks : false,
      };
    }

    if (
      this.model.proofPoint.hasOwnProperty("uploadFile") &&
      this.model.proofPoint.uploadFile
    ) {
      formData.append(
        "proofFile",
        this.fileTypeProofPoint,
        this.fileTypeProofPoint.name
      );
    }

    if (
      this.model.frameworkEmployed.hasOwnProperty("uploadFile") &&
      this.model.frameworkEmployed.uploadFile
    ) {
      formData.append(
        "frameworkFile",
        this.fileTypeFramework,
        this.fileTypeFramework.name
      );
    }

    if (
      this.model.attachLevels &&
      this.model.attachLevels.toLowerCase() === "brand"
    ) {
      formData.append("producerDetailsId", this.selectedMasterProducerId);
    }

    let serviceMethod = this.editAppended
      ? "updateDisclosureVersion2"
      : "appendTheme";

    this.createImportService[serviceMethod](
      this.editAppended ? payload : formData,
      this.appendedRowData ? this.appendedRowData.id : null
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          let upload = 0;
          if (
            res &&
            this.model.proofPoint.hasOwnProperty("uploadFile") &&
            this.model.proofPoint.uploadFile &&
            this.editAppended
          ) {
            upload = 1;
            this.uploadFiles(res, "PROOF");
          }

          if (
            res &&
            this.model.frameworkEmployed.hasOwnProperty("uploadFile") &&
            this.model.frameworkEmployed.uploadFile &&
            this.editAppended
          ) {
            upload = 1;
            this.uploadFiles(res, "FRAMEWORK");
          }

          if (!upload) {
            let appendMessage = "";
            if (this.editAppended) {
              appendMessage =
                "The appended information has been updated successfully.";
            } else {
              let message = res.recordProcessing
                ? `Append Request processed successfully for ${this.model.disclosure.name}, ${res.recordsAppended} records appended successfully.`
                : "The appended information has been processed successfully.";

              appendMessage = res.sync
                ? message
                : "Append initiated for multiple records. This may take several minutes to complete. You will be notified once the appended information has been fully processed.";
            }
            this.coreService.showMessage({
              key: "tc",
              severity: "success",
              summary: "Success",
              detail: appendMessage,
            });

            this.onAppendHide(true, true);
          }
        },
        (err) => {
          let errorMessage = err.error.message.replace(
            "com.markit.cmtk.exception.ServiceException:",
            ""
          );
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: errorMessage,
          });
        }
      );
  }

  uploadFiles(res, type) {
    let recordThemes = this.editAppended
      ? this.appendedRowData.id
      : res.recordThemes.map((response) => response.id).join();
    const formData = new FormData();
    if (type === "FRAMEWORK") {
      formData.append(
        "file",
        this.fileTypeFramework,
        this.fileTypeFramework.name
      );
    } else {
      formData.append(
        "file",
        this.fileTypeProofPoint,
        this.fileTypeProofPoint.name
      );
    }

    formData.append("disclosure", type);
    formData.append("appendedDisclosureId", recordThemes);

    const uploadReq = new HttpRequest(
      "POST",
      `${environment.apiUrl}/doc-upload-service/documentfiles/updatetheme?doctype=VALUE-ADDED`,
      formData
    );

    this.http.request(uploadReq).subscribe(
      (event) => {
        if (
          event.type === 4 &&
          (type === "FRAMEWORK" || !this.model.frameworkEmployed.uploadFile)
        ) {
          let appendMessage =
            "The appended information has been updated successfully.";
          this.coreService.showMessage({
            key: "tc",
            severity: "success",
            summary: "Success",
            detail: this.editAppended
              ? appendMessage
              : "Record(s) appended successfully.",
          });

          this.onAppendHide(true, true);
        }
      },
      (err) => {
        let errorMessage = err.error.message.replace(
          "com.markit.cmtk.exception.ServiceException:",
          ""
        );
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail: errorMessage,
        });
      }
    );
  }

  addFileTypeFramework(event) {
    this.fileTypeFramework = event.target.files[0];
    if (
      this.model.frameworkEmployed &&
      this.model.frameworkEmployed.redactFrameworkEmployed
    ) {
      this.redactDialog = true;
      this.fileToRedact = this.fileTypeFramework;
      this.fileTypeToRedact = "FRAMEWORK";
    }
  }

  addFileTypeProofPoint(event) {
    this.fileTypeProofPoint = event.target.files[0];
    if (this.model.proofPoint && this.model.proofPoint.redactProofPoint) {
      this.redactDialog = true;
      this.fileToRedact = this.fileTypeProofPoint;
      this.fileTypeToRedact = "PROOF_POINT";
    }
  }

  onRedactDialogHide(e) {
    this.redactDialog = false;
  }

  onRedactComplete(file) {
    if (this.fileTypeToRedact === "FRAMEWORK") {
      this.fileTypeFramework = file;
    } else {
      this.fileTypeProofPoint = file;
    }

    this.redactDialog = false;
  }

  tenureChange() {
    switch (this.model.validity) {
      case "Production on & after":
        this.model.dateRange = null;
        this.model.before = null;
        getField("onAfter", this.fields).hideExpression = false;
        getField("before", this.fields).hideExpression = true;
        getField("dateRange", this.fields).hideExpression = true;
        break;
      case "Production on or before":
      case "Production before":
      case "Production Before":
        this.model.dateRange = null;
        this.model.onAfter = null;
        getField("onAfter", this.fields).hideExpression = true;
        getField("before", this.fields).hideExpression = false;
        getField("dateRange", this.fields).hideExpression = true;
        break;
      case "Date range":
        this.model.before = null;
        this.model.onAfter = null;
        getField("onAfter", this.fields).hideExpression = true;
        getField("before", this.fields).hideExpression = true;
        getField("dateRange", this.fields).hideExpression = false;
        break;
      default:
        break;
    }

    if (this.model.validity === "Perpetuity" || !this.editAppended) {
      this.hideExpiryNotifFields();
    }
  }

  updateBrandType(event) {
    if (
      this.model.attachLevels &&
      this.model.attachLevels.toLowerCase() === "brand"
    ) {
      let selectMasterProducerId = this.allMasterProducers.findIndex(
        (row) => row.masterProducerName === this.model.entities
      );

      let brandStatusId = DELIVERABLE_VALUES.findIndex(
        (brandStatus) =>
          brandStatus.id ===
          this.allMasterProducers[selectMasterProducerId]?.brandStatus
      );
      let brandStatus =
        brandStatusId !== -1 ? DELIVERABLE_VALUES[brandStatusId].value : "N/A";
      this.form.get("brandType").setValue(brandStatus);
      this.form
        .get("asset")
        .setValue(this.allMasterProducers[selectMasterProducerId].asset);
      this.selectedMasterProducerId =
        this.allMasterProducers[selectMasterProducerId]?.id;
    } else if (
      this.model.attachLevels &&
      this.model.attachLevels.toLowerCase() === "record"
    ) {
      this.form.get("attachLevels").setValue(null);
      this.form.get("records").setValue(null);
      getField("records", this.fields).hideExpression = true;
    }
  }

  sameProofPointSelected(e) {
    if (this.model.frameworkEmployed.sameProofPoint === true) {
      this.form.get("frameworkEmployed.textBox") &&
        this.form.get("frameworkEmployed.textBox").setValue(null);
      this.form.get("frameworkEmployed.url") &&
        this.form.get("frameworkEmployed.url").setValue(null);

      if (this.form.get("frameworkEmployed.uploadFile")) {
        this.form.get("frameworkEmployed.uploadFile").setValue(null);
        this.fileTypeFramework = null;
        (
          document.getElementById("frameworkEmployedFile") as HTMLInputElement
        ).value = null;
      }
    }
  }

  resetTime(date: Date): void {
    date.setHours(0, 0, 0, 0);
  }

  checkPastDate(event) {
    if (event === "before") {
      const currentDate = new Date();
      this.resetTime(currentDate);
      this.resetTime(this.model.before);
      if (this.model.before < currentDate) {
        return false;
      }
    } else if (event === "range") {
      const currentDate = new Date();
      this.resetTime(this.model.dateRange[1]);
      this.resetTime(currentDate);
      if (this.model.dateRange[1] < currentDate) {
        return false;
      }
    }

    return true;
  }

  dateSelected(event) {
    if (this.showExpiryNotifField && !this.checkPastDate(event)) {
      this.coreService.showMessage({
        key: "tc",
        severity: "info",
        summary: "Info",
        detail:
          "The validity is past dated/expired hence, expiry notifications via email will not be received for the specific disclosure appended",
      });
      this.addStatus = true;
      this.showExpiryNotifField && (this.model.expiryNotification = false);
      getField("expiryNotification", this.fields).hideExpression = true;
      getField("frequency", this.fields).hideExpression = true;
      getField("threeMonths", this.fields).hideExpression = true;
      getField("oneMonths", this.fields).hideExpression = true;
      getField("twoWeeks", this.fields).hideExpression = true;
      return false;
    }

    if (
      (event === "before" || event === "range") &&
      this.showExpiryNotifField
    ) {
      this.model.expiryNotification = true;
      setTimeout(() => {
        getField("expiryNotification", this.fields).hideExpression = false;
        getField("frequency", this.fields).hideExpression = false;
        getField("threeMonths", this.fields).hideExpression = false;
        getField("oneMonths", this.fields).hideExpression = false;
        getField("twoWeeks", this.fields).hideExpression = false;
      }, 0);
    } else {
      this.showExpiryNotifField && (this.model.expiryNotification = false);
      getField("expiryNotification", this.fields).hideExpression = true;
      getField("frequency", this.fields).hideExpression = true;
      getField("threeMonths", this.fields).hideExpression = true;
      getField("oneMonths", this.fields).hideExpression = true;
      getField("twoWeeks", this.fields).hideExpression = true;
    }
    setTimeout(() => {
      this.form.get("threeMonths") &&
        this.form.get("threeMonths").setValue(false);
      this.form.get("oneMonths") && this.form.get("oneMonths").setValue(true);
      this.form.get("twoWeeks") && this.form.get("twoWeeks").setValue(false);
    }, 0);
    this.addStatus = false;
  }

  inputSwitchChange() {
    if (this.model.expiryNotification) {
      getField("frequency", this.fields).hideExpression = false;
      getField("threeMonths", this.fields).hideExpression = false;
      getField("oneMonths", this.fields).hideExpression = false;
      getField("twoWeeks", this.fields).hideExpression = false;
      setTimeout(() => {
        this.form.get("threeMonths") &&
          this.form.get("threeMonths").setValue(false);
        this.form.get("oneMonths") && this.form.get("oneMonths").setValue(true);
        this.form.get("twoWeeks") && this.form.get("twoWeeks").setValue(false);
      }, 0);
    } else {
      this.coreService.showMessage({
        key: "tc",
        severity: "info",
        summary: "Info",
        detail:
          "You will no longer receive expiry notification(s) for the specific disclosure",
      });

      setTimeout(() => {
        this.form.get("threeMonths") &&
          this.form.get("threeMonths").setValue(false);
        this.form.get("oneMonths") &&
          this.form.get("oneMonths").setValue(false);
        this.form.get("twoWeeks") && this.form.get("twoWeeks").setValue(false);
      }, 0);
    }
  }

  initForm() {
    this.fields = [
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            className: "col-12",
            key: "disclosure",
            type: "dict-select",
            templateOptions: {
              label: "Disclosure",
              bindLabel: "name",
              bindValue: "id",
              dataProvided: true,
              items: this.disclosures,
              required: true,
              disabled: this.editAppended,
              change: (event) => this.disclosureChange(),
              appendTo: "body",
            },
          },
          {
            className: "col-12",
            key: "attachLevels",
            type: "dict-select",
            hideExpression: true,
            templateOptions: {
              label: "Level",
              required: true,
              source: this.levels,
              disabled: this.editAppended,
              change: (field, event) => {
                this.levelUpdate(event);
              },
            },
          },
          {
            className: "col-12",
            key: "metals",
            type: "dict-select-all",
            hideExpression: true,
            templateOptions: {
              label: "Metal",
              required: true,
              multiple: true,
              source: this.metals,
              editable: this.editAppended,
              change: (event) => {
                this.getBrands();
              },
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (
                  this.disclosureData &&
                  this.disclosureData.metals.length === 1
                ) {
                  return true;
                } else {
                  return this.editAppended ? true : false;
                }
              },
            },
          },
          {
            className: "col-12",
            key: "metals",
            type: "dict-select",
            hideExpression: true,
            templateOptions: {
              label: "Metal",
              required: true,
              multiple: false,
              source: this.metals,
              editable: this.editAppended,
              change: (event) => {
                this.getBrands();
              },
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (
                  this.disclosureData &&
                  this.disclosureData.metals.length === 1
                ) {
                  return true;
                } else {
                  return this.editAppended ? true : false;
                }
              },
            },
          },
          {
            className: "col-12",
            key: "brand",
            type: "dict-select-template",
            hideExpression: true,
            templateOptions: {
              label: "Brand | Producer Name",
              source: this.brands,
              required: true,
              disabled: this.editAppended,
              bindLabel: "name",
              fieldName: "brand",
              notFoundText: "Please select a Metal to continue",
              change: (field, event) => {
                this.getCompany(event);
              },
            },
          },
          {
            className: "col-12",
            key: "brandType",
            type: "readonly-input",
            hideExpression: true,
            templateOptions: {
              label: "Brand Status",
              editable: false,
              readOnly: true,
              hideOverFlow: false,
            },
          },
          {
            className: "col-12",
            key: "asset",
            type: "readonly-input",
            hideExpression: true,
            templateOptions: {
              label: "Asset",
              editable: false,
              readOnly: true,
              hideOverFlow: false,
            },
          },
          {
            className: "col-12",
            key: "inheritance",
            type: "dict-select",
            hideExpression: true,
            wrappers: ["form-field-horizontal"],
            templateOptions: {
              label: "Inheritance",
              bindLabel: "name",
              bindValue: "id",
              dataProvided: true,
              items: this.inheritance,
              required: true,
              disabled: this.editAppended,
              info: `Select "Y" to append the disclosure to all qualifying brands and records.`,
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (
                  (this.model.inheritance === "N" &&
                    this.model.brandType &&
                    this.model.brandType === "Non-Deliverable") ||
                  this.editAppended
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
          {
            className: "col-12",
            key: "records",
            type: "readonly-input",
            hideExpression: true,
            templateOptions: {
              label: "Selected Records",
              readOnly: true,
              editable: true,
              hideOverFlow: true,
              click: (event) => {
                this.showApproved = true;
              },
            },
          },
          {
            className: "col-12",
            key: "validity",
            type: "dict-select",
            hideExpression: true,
            wrappers: ["form-field-horizontal"],
            templateOptions: {
              label: "Validity",
              source: this.validities,
              required: true,
              multiple: false,
              disabled: this.editAppended,
              change: (event) => this.tenureChange(),
              info: "Select the time parameters that apply to the disclosure.",
            },
          },
          {
            className: "col-12",
            key: "onAfter",
            type: "calendar-input",
            hideExpression: true,
            templateOptions: {
              label: "Production on & after",
              required: true,
              disabled: this.editAppended,
              onSelect: (event) => this.dateSelected("after"),
            },
          },
          {
            className: "col-12",
            key: "before",
            type: "calendar-input",
            hideExpression: true,
            templateOptions: {
              label: "Production on or before",
              required: true,
              disabled: this.editAppended,
              onSelect: (event) => this.dateSelected("before"),
            },
          },
          {
            className: "col-12",
            key: "dateRange",
            type: "calendar-input",
            hideExpression: true,
            templateOptions: {
              label: "Date Range",
              range: "range",
              required: true,
              disabled: this.editAppended,
              onSelect: (event) => this.dateSelected("range"),
            },
          },
          {
            className: "col-4",
            key: "expiryNotification",
            type: "input-switch",
            hideExpression: true,
            templateOptions: {
              label: "Expiry Notifications",
              id: "expiryNotification",
              display: "inline-block",
              change: (event) => {
                this.inputSwitchChange();
              },
            },
          },
          {
            key: "frequency",
            type: "custom",
            hideExpression: true,
          },
          {
            className: "col-2 mr-1",
            key: "threeMonths",
            type: "checkbox",
            hideExpression: true,
            templateOptions: {
              label: "3 months",
              indeterminate: false,
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (this.model.expiryNotification === false) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
          {
            className: "col-2",
            key: "oneMonths",
            type: "checkbox",
            hideExpression: true,
            templateOptions: {
              label: "1 month",
              indeterminate: true,
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (this.model.expiryNotification === false) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
          {
            className: "col-2",
            key: "twoWeeks",
            type: "checkbox",
            hideExpression: true,
            templateOptions: {
              label: "2 weeks",
              indeterminate: false,
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (this.model.expiryNotification === false) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
          {
            className: "col-12",
            key: "entities",
            type: "dict-select",
            hideExpression: true,
            templateOptions: {
              label: "Company",
              source: this.entities,
              bindLabel: "entityName",
              bindValue: "id",
              disabled: this.editAppended,
              required: true,
              multiple: false,
              change: (field, event) => {
                this.updateBrandType(event);
              },
              notFoundText:
                this.model.attachLevels &&
                this.model.attachLevels.toLowerCase() === "entity"
                  ? "Please select a metal to continue."
                  : "Please select a metal and brand to continue.",
            },
          },
          {
            className: "col-12",
            key: "type",
            type: "input",
            hideExpression: true,
            templateOptions: {
              label: "Type",
              disabled: true,
            },
          },
          {
            className: "col-12",
            key: "certificateConfirm",
            type: "checkbox",
            hideExpression: true,
            templateOptions: {
              label:
                "Please confirm the certification applies to the selected criteria?",
              indeterminate: false,
              required: true,
            },
          },
          {
            className: "col-12",
            key: "membershipConfirm",
            type: "checkbox",
            hideExpression: true,
            templateOptions: {
              label: "Confirm the membership applies to the selected metal",
              indeterminate: false,
              required: true,
            },
          },
          {
            className: "col-12",
            key: "metricPercentage",
            type: "input",
            hideExpression: true,
            templateOptions: {
              required: true,
              placeholder: "Up to 2 decimals",
              pattern: /^((?!0)\d{1,8}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/,
              addonRight: {
                text: this.metricLabel,
              },
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number, please fill the field with a number that contains up to 8 integer digits and up to 2 decimal digits.`,
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: "row",
        key: "proofPoint",
        wrappers: ["app-formly-fieldset"],
        templateOptions: {
          label: "Proof Point",
        },
        hideExpression: true,
        fieldGroup: [
          {
            className: "col-12",
            key: "redactProofPoint",
            type: "checkbox",
            hideExpression: true,
            templateOptions: {
              label: "Redact Proof Point Document",
              indeterminate: false,
            },
          },
          {
            className: "col-6 proofpoint-upload",
            type: "upload",
            key: "uploadFile",
            templateOptions: {
              label: "Upload",
              accept: "pdf",
              id: "proofPointFile",
              change: (field, event) => this.addFileTypeProofPoint(event),
              defaultValue:
                this.editAppended &&
                this.appendedRowData &&
                this.appendedRowData.proofDocument
                  ? this.appendedRowData.proofDocument.fileName
                  : null,
            },
          },
          {
            className: "col-6",
            type: "input",
            key: "textBox",
            templateOptions: {
              label: "Text Box(max length 250 characters)",
              maxLength: 250,
            },
          },
          {
            className: "col-6",
            type: "input",
            key: "url",
            templateOptions: {
              placeholder: "Provide Web URL",
              label: "Provide Web URL",
              pattern:
                "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$",
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a url`,
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: "row",
        key: "frameworkEmployed",
        wrappers: ["app-formly-fieldset"],
        templateOptions: {
          label: "Framework Employed",
        },
        hideExpression: true,
        fieldGroup: [
          {
            className: "col-12",
            key: "sameProofPoint",
            type: "checkbox",
            templateOptions: {
              label: "Same as Proof Point",
              indeterminate: false,
              change: (field, event) => this.sameProofPointSelected(event),
            },
          },
          {
            className: "col-12",
            key: "redactFrameworkEmployed",
            type: "checkbox",
            hideExpression: true,
            templateOptions: {
              label: "Redact Framework Employed Document",
              indeterminate: false,
            },
          },
          {
            className: "col-6 framework-upload",
            type: "upload",
            key: "uploadFile",
            templateOptions: {
              label: "Upload",
              accept: "pdf",
              change: (field, event) => this.addFileTypeFramework(event),
              defaultValue: this.frameworkUploadFileDefaultValue(),
              id: "frameworkEmployedFile",
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (
                  this.model.frameworkEmployed &&
                  this.model.frameworkEmployed.sameProofPoint
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
          {
            className: "col-6",
            type: "input",
            key: "textBox",
            templateOptions: {
              label: "Text Box(max length 250 characters)",
              maxLength: 250,
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (
                  this.model.frameworkEmployed &&
                  this.model.frameworkEmployed.sameProofPoint
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
          {
            className: "col-6",
            type: "input",
            key: "url",
            templateOptions: {
              placeholder: "Provide Web URL",
              label: "Provide Web URL",
              pattern:
                "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$",
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a url`,
              },
            },
            expressionProperties: {
              "templateOptions.disabled": (
                model: any,
                formState: any,
                field: FormlyFieldConfig
              ) => {
                if (
                  this.model.frameworkEmployed &&
                  this.model.frameworkEmployed.sameProofPoint
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        ],
      },
    ];
  }
  frameworkUploadFileDefaultValue(): any {
    if (
      this.editAppended &&
      this.appendedRowData &&
      this.appendedRowData.frameworkDocument &&
      !this.appendedRowData.frameworkAsProof
    ) {
      return this.appendedRowData.frameworkDocument.fileName;
    } else {
      return null;
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}

function getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
  for (let i = 0, len = fields.length; i < len; i++) {
    const f = fields[i];
    if (f.key === key) {
      return f;
    }

    if (f.fieldGroup && !f.key) {
      const cf = getField(key, f.fieldGroup);
      if (cf) {
        return cf;
      }
    }
  }
}
