import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { OcrTagggingComponent } from "@module/create-import/components/ocr-taggging/ocr-taggging.component";
import { AppWrapperComponent } from "./core/components/app-wrapper/app-wrapper.component";
import { RegistrationOutsideComponent } from "./registration/registration.component";
import { LandingPageComponentOutside } from "./landing-page/landing-page.component";
import { SustainabilityDisclosuresPublicWrapperComponent } from "@core/components/sustainability-disclosures-public-wrapper/sustainability-disclosures-public-wrapper.component";
import { LiveSustainabilityDisclosuresComponent } from "@shared/components/live-sustainability-disclosures/live-sustainability-disclosures.component";
import { DisclosuresDetailComponent } from "@module/sustainability-disclosure-public-view/components/disclosures-detail/disclosures-detail.component";
import { UserPublicViewComponent } from "@core/components/user-public-view/user-public-view.component";
import { ProducerProfileComponent } from "@module/sustainability-disclosure-public-view/components/producer-profile/producer-profile.component";
import { DisclosureComparisonComponent } from "@module/sustainability-disclosure-public-view/components/disclosure-comparison/disclosure-comparison.component";
import { LiveSustainabilityViewGuard } from "@core/guards/live-sustainability-public-view.guard";
import { DisclosureDetailViewGuard } from "@core/guards/disclosures-detail-public-view.guard";
import { ProducerProfileViewGuard } from "@core/guards/producer-profile-public-view.guard";
import { MetricComparisionViewGuard } from "@core/guards/metric-comparision-public-view.guard";
import { MetricComparisonContainerComponent } from "@module/sustainability-disclosure-public-view/containers/metric-comparison-container/metric-comparison-container.component";
import { DisclosureComparisonThemeComponent } from "@module/sustainability-disclosure-public-view/components/disclosure-comparison-theme/disclosure-comparison-theme.component";
import { RegisterUserComponent } from "@shared/components/register-user/register-user.component";

const AppRoutes: Routes = [
  {
    path: "register",
    component: RegistrationOutsideComponent,
  },
  {
    path: "register-user",
    component: RegisterUserComponent,
  },
  {
    path: "tasks-details/:id/:step/:product/:uuid",
    component: OcrTagggingComponent,
  },
  {
    path: "private",
    component: AppWrapperComponent,
    data: { category: "private" },
    children: [
      /* {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
      }, */
      {
        path: "tasks-details/:id/:step/:product/:uuid",
        component: OcrTagggingComponent,
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "platform-admin",
        loadChildren: () =>
          import("./modules/platform-admin/platform-admin.module").then(
            (m) => m.PlatformAdminModule
          ),
      },
      {
        path: "user-tasks",
        loadChildren: () =>
          import("./modules/individual-user/individual-user.module").then(
            (m) => m.IndividualUserModule
          ),
      },
      {
        path: "admin-tasks",
        loadChildren: () =>
          import("./modules/user-admin/user-admin.module").then(
            (m) => m.UserAdminModule
          ),
      },
      {
        path: "records-management",
        loadChildren: () =>
          import("./modules/create-import/create-import.module").then(
            (m) => m.CreateImportModule
          ),
      },
      {
        path: "my-records",
        loadChildren: () =>
          import("./modules/my-records/my-records.module").then(
            (m) => m.MyRecordsModule
          ),
      },
      {
        path: "owsr",
        loadChildren: () =>
          import("./modules/warehouse-owsr/warehouse-owsr.module").then(
            (m) => m.WarehouseOwsrModule
          ),
      },
      {
        path: "owsr-pa",
        loadChildren: () =>
          import("./modules/pa-owsr/pa-owsr.module").then(
            (m) => m.PaOwsrModule
          ),
      },
      {
        path: "activity-logs",
        loadChildren: () =>
          import("./modules/activity-logs/activity-logs.module").then(
            (m) => m.ActivityLogsModule
          ),
      },
      {
        path: "help-and-downloads",
        loadChildren: () =>
          import("./modules/resources/resources.module").then(
            (m) => m.ResourcesModule
          ),
      },
      {
        path: "public-sustainability-page",
        component: UserPublicViewComponent,
      },
      {
        path: "sustainability-profile",
        loadChildren: () =>
          import(
            "./modules/producer-sustainibility-profile/producer-sustainibility-profile.module"
          ).then((m) => m.ProducerSustainibilityProfileModule),
      },
    ],
  },
  {
    path: "public",
    // component: SustainabilityDisclosuresPublicWrapperComponent,
    component: AppWrapperComponent,
    data: { category: "public" },
    children: [
      {
        path: "live-sustainability-disclosures",
        component: LiveSustainabilityDisclosuresComponent,
        canActivate: [LiveSustainabilityViewGuard],
      },
      {
        path: "disclosures-detail",
        component: DisclosuresDetailComponent,
        canActivate: [DisclosureDetailViewGuard],
      },
      {
        path: "producer-profile",
        component: ProducerProfileComponent,
        canActivate: [ProducerProfileViewGuard],
      },
      {
        path: "producer-profile/:id/:id",
        component: ProducerProfileComponent,
        canActivate: [ProducerProfileViewGuard],
      },
      {
        path: "metric-comparison",
        component: MetricComparisonContainerComponent,
        canActivate: [MetricComparisionViewGuard],
        children: [
          {
            path: "",
            redirectTo: "brand-comparison",
            pathMatch: "full",
          },
          {
            path: "metric-comparison-producer",
            component: DisclosureComparisonComponent,
          },
          {
            path: "brand-comparison",
            component: DisclosureComparisonThemeComponent,
          },
        ],
      },
    ],
  },
  {
    path: "**",
    component: LandingPageComponentOutside,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
