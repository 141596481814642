import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Subject } from "rxjs";

export const PUBLIC_DISCLOSURES =
  "/home-service/publicView/sustainability/fetch";
export const FETCH_PUBLIC_THEMES = "/home-service/publicView/themes/fetch";
export const GET_DISCLOSURES = "/home-service/publicView/themes/flat";
export const GENERATE_KEY = "/home-service/publicView/generateKey";
export const PRODUCERS = "/home-service/publicView/sustainability/producers";
export const DISCLOSURES_DATA =
  "/home-service/publicView/sustainability/producers/fetch";
export const BRAND_DATA =
  "/home-service/publicView/sustainability/brands/fetch";
export const FETCH_EACH_DISCLOSURE = "/home-service/publicView/sustainability/fetchByPost";
export const TARGET_DATA = "/home-service/publicView/producers/targets";

@Injectable({
  providedIn: "root",
})
export class SustainabilityDisclosurePublicService {
  constructor(private http: HttpClient) {}

  updateFilters = new Subject<any>();

  getPublicDisclosures(): any {
    return this.http.get(`${environment.apiUrl}${PUBLIC_DISCLOSURES}`);
  }

  getPieDisclosures(): any {
    return this.http.get(`${environment.apiUrl}${FETCH_PUBLIC_THEMES}`);
  }

  getDisclosures(): any {
    return this.http.get(`${environment.apiUrl}${GET_DISCLOSURES}`);
  }

  generateKey(): any {
    return this.http.get(`${environment.apiUrl}${GENERATE_KEY}`);
  }

  getProducers(): any {
    return this.http.get(`${environment.apiUrl}${PRODUCERS}`);
  }

  getDisclosureData(): any {
    return this.http.get(`${environment.apiUrl}${DISCLOSURES_DATA}`);
  }

  getBrandData(): any {
    return this.http.get(`${environment.apiUrl}${BRAND_DATA}`);
  }

  getDisclosureDataAgainstProducerBrand(producerBrandObj:any):any{
    return this.http.post(`${environment.apiUrl}${FETCH_EACH_DISCLOSURE}`, producerBrandObj);
  }

  getTargetsData(): any {
    return this.http.get(`${environment.apiUrl}${TARGET_DATA}`);
  }
  
  resetFilters() {
    this.updateFilters.next(true);
  }
}
