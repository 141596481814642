import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-field-readonly-input",
  template: `
    <ng-container
      *ngIf="currValue !== null && currValue !== undefined; else PLACEHOLDER"
    >
      <span class="form-control" readonly="readonly">
        <p [ngClass]="{ 'hide-overflow': to.hideOverFlow }">
          <ng-container
            *ngIf="type && type === 'formatted-number'; else REGULAR"
            >{{ currValue | number : "1.0-0" }}</ng-container
          >
          <ng-template #REGULAR>{{ currValue }}</ng-template>
        </p>
        <i
          *ngIf="to.editable"
          (click)="to.click($event)"
          class="fa fa-pencil-square-o action text-center"
          style="position: absolute;right: 19px; top: 43px;"
          aria-hidden="true"
          title="View and Edit"
          role="button"
          tabindex="0"
        ></i>
      </span>
    </ng-container>
    <ng-template #PLACEHOLDER>
      <span
        [ngClass]="{ 'hide-overflow': to.hideOverFlow }"
        class="form-control"
        readonly="readonly"
        style="color: #899097"
      >
        {{ placeholder }}
      </span>
    </ng-template>
    <input type="hidden" [value]="currValue" class="wcag-visuallyhidden" />
  `,
  styles: [
    `
      .hide-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 76%;
      }
    `,
  ],
})
export class FormlyFieldReadOnlyInput extends FieldType implements OnInit {
  public translatePrefix;
  public type;
  public placeholder;

  constructor() {
    super();
  }

  ngOnInit() {
    this.to.placeholder && (this.placeholder = `${this.to.placeholder}`);
    this.type = this.to.type;
  }

  get currValue() {
    return this.model && this.model[this.field.key as string];
  }
}
