import { ITableViewConfig } from "@shared/models/table-view.model";

export const createRecrodListTableConfig: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  columns: [
    {
      field: "coaReference",
      header: "Certificate of Analysis Reference Number",
      width: "220px",
      sortField: "coaReference",
    },
    {
      field: "entityName",
      header: "Company",
      width: "160px",
    },
    {
      field: "stakeHolder",
      header: "Stakeholder Type",
      width: "180px",
    },
    {
      field: "commodity",
      header: "Metal",
      width: "120px",
      sortField: "commodity",
    },
    {
      field: "brand",
      header: "Brand",
      width: "100px",
      sortField: "brand",
    },
    {
      field: "firstName",
      header: "Submitted By",
      width: "200px",
      sortField: "firstName",
    },
    {
      field: "actionReceivedTime",
      header: "Submitted Date/Timestamp",
      width: "180px",
      dateTimeStamp: true,
      sortField: "actionReceivedTime",
    },
    {
      field: "displayableStatus",
      header: "Status",
      width: "100px",
      sortField: "status",
    },
    {
      field: "review",
      header: "REVIEW",
      width: "150px",
    },
  ],
};

export const reviewListTableConfigNonProducer: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  columns: [
    {
      field: "pcr",
      header: "PCR number",
      width: "150px",
    },
    {
      field: "pagePCR",
      header: "Page No. of PCR",
      width: "100px",
    },
    {
      field: "packingFrom",
      header: "Packing form",
      width: "140px",
    },
    {
      field: "productionDateStr",
      header: "Date of production",
      width: "120px",
      dateTime: true,
    },
    {
      field: "productionCountry",
      header: "Country of production",
      width: "120px",
    },
    {
      field: "shape",
      header: "Product shape",
      width: "120px",
    },
    {
      field: "units",
      header: "Quantity (pieces)",
      width: "120px",
      numeric: true,
    },
    {
      field: "grossWeight",
      header: "Total gross weight (MT)",
      width: "120px",
      numeric: true,
    },
    {
      field: "netWeight",
      header: "Total net weight (MT)",
      width: "120px",
      numeric: true,
    },
    {
      field: "industryStandards",
      header: "Industry standards met",
      width: "120px",
    },
    {
      field: "grade",
      header: "Permissible Grade",
      width: "120px",
    },
    {
      field: "displayableStatus",
      header: "Status",
      width: "120px",
    },
    {
      field: "passportId",
      header: "LMEpassport ID",
      width: "200px",
    },
  ],
};

export const reviewListTableConfigProducer: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  columns: [
    {
      field: "pcr",
      header: "PCR number",
      width: "150px",
    },
    {
      field: "packingFrom",
      header: "Packing form",
      width: "140px",
    },
    {
      field: "productionDateStr",
      header: "Date of production",
      width: "120px",
      dateTime: true,
    },
    {
      field: "productionCountry",
      header: "Country of production",
      width: "120px",
    },
    {
      field: "shape",
      header: "Product shape",
      width: "120px",
    },
    {
      field: "units",
      header: "Quantity (pieces)",
      width: "120px",
      numeric: true,
    },
    {
      field: "grossWeight",
      header: "Total gross weight (MT)",
      width: "120px",
      numeric: true,
    },
    {
      field: "netWeight",
      header: "Total net weight (MT)",
      width: "120px",
      numeric: true,
    },
    {
      field: "industryStandards",
      header: "Industry standards met",
      width: "120px",
    },
    {
      field: "grade",
      header: "Permissible Grade",
      width: "120px",
    },
    {
      field: "type",
      header: "Record Type",
      width: "120px",
    },
    {
      field: "displayableStatus",
      header: "Status",
      width: "120px",
    },
    {
      field: "passportId",
      header: "LMEpassport ID",
      width: "200px",
    },
    {
      field: "scrCompliantStr",
      header: "SCR Compliant",
      width: "120px",
    },
  ],
};

export const searchResultsTableConfig: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  columns: [
    {
      field: "commodity",
      header: "Metal",
      width: "100px",
    },
    {
      field: "brand",
      header: "LME Brand",
      width: "100px",
    },
    {
      field: "pcr",
      header: "PCR #",
      width: "100px",
    },
    {
      field: "coaRefernce",
      header: "COA Reference",
      width: "140px",
    },
    {
      field: "displayableStatus",
      header: "Status",
      width: "100px",
    },
    {
      field: "type",
      header: "Record Type",
      width: "120px",
    },
    {
      field: "createdBy",
      header: "Created By",
      width: "120px",
    },
  ],
};

export const searchedDataTableConfig: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  headerCheckboxDisabled: true,
  columns: [
    {
      field: "brand",
      header: "LME Brand",
      width: "100px",
    },
    {
      field: "pcr",
      header: "PCR #",
      width: "100px",
    },
  ],
};

export const additionalDocsTableConfig: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  headerCheckboxDisabled: true,
  columns: [
    {
      field: "coaReference",
      header: "Coa Reference",
      width: "100px",
    },
    {
      field: "documentTitle",
      header: "Document Title",
      width: "100px",
    },
  ],
};

export const approvedRecordsDataTableConfig: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  columns: [
    {
      field: "recordType",
      header: "Record Type",
      width: "140px",
    },
    {
      field: "coaReference",
      header: "Certificate of Analysis Reference Number",
      width: "220px",
    },
    {
      field: "coaDateStr",
      header: "COA Date",
      width: "140px",
      dateTime: true,
    },
    {
      field: "companyName",
      header: "Company",
      width: "180px",
    },
    {
      field: "stakeHolder",
      header: "StakeHolder Type",
      width: "160px",
    },
    {
      field: "commodity",
      header: "Metal",
      width: "180px",
    },
    {
      field: "brand",
      header: "Brand",
      width: "140px",
    },
    {
      field: "scrCompliantStr",
      header: "SCR Compliant",
      width: "140px",
    },
    {
      field: "approvedBy",
      header: "Approved By",
      width: "200px",
    },
    {
      field: "approvedOn",
      header: "Approved Date",
      width: "140px",
      dateTime: true,
    },
  ],
};

export const chemicalCompositionDataTableConfig: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  columns: [
    {
      field: "element",
      header: "Element",
      width: "140px",
    },
    {
      field: "composition",
      header: "Composition (%)",
      width: "140px",
    },
  ],
};

export const toDoListTableConfig: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  headerCheckboxDisabled: true,
  columns: [
    {
      field: "pcr",
      header: "Pcr#",
      width: "150px",
    },
    {
      field: "brand",
      header: "LME Brand",
      width: "160px",
    },
    {
      field: "coaReference",
      header: "Certificate of Analysis Reference Number",
      width: "120px",
    },
    {
      field: "createdBy",
      header: "Created By",
      width: "180px",
    },
    {
      field: "createdOn",
      header: "Date / TimeStamp Added",
      width: "140px",
      dateTimeStamp: true,
      sortField: "createdOn",
    },
    {
      field: "status",
      header: "Status",
      width: "100px",
      sortField: "status",
    },
  ],
};

export const addiotnalDocsTableConfig: ITableViewConfig = {
  paginator: true,
  rowsPerPage: 20,
  headerCheckboxDisabled: true,
  columns: [
    {
      field: "fileName",
      header: "Document Name",
      width: "200px",
    },
  ],
};
