import { CoreService } from "./../../services/core.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { interval, Observable, Subject, timer } from "rxjs";
import { LoaderService } from "@core/services/loader.service";
import { map, startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { LmeCurrentUser } from "@core/models/current-user.model";
import { IUsers } from "@core/models/api.model";
import { MessageService } from "primeng/api";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "@env/environment";
import { ITableViewConfig } from "@shared/models/table-view.model";
import {
  notifListTableConfig,
  suspisiousNotifListTableConfig,
} from "@core/core-config";
import { HOME, email } from "app/app.const";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-app-wrapper",
  templateUrl: "./app-wrapper.component.html",
  styleUrls: ["./app-wrapper.component.scss"],
  providers: [MessageService],
})
export class AppWrapperComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<any>();
  user: IUsers;
  showTimedOutDialog: boolean;
  countDown: any;
  counter$: Observable<number>;
  count: number;
  showTandCDialog: boolean;
  timerRunning: boolean;
  sessionExpired: boolean;
  notificationDialog: boolean;
  notifHeader: any;
  notifMessage: any;
  notifType: any;
  infoDialog: boolean;
  messageId: any;
  bulkNotificationsDialog: boolean;
  tableConfig: ITableViewConfig;
  messages: any;
  suspiciousActivityForTodo: any;
  suspiciousNnotificationDialog: boolean;
  suspicioustableConfig: ITableViewConfig;
  _refreshIntervalDuration = 120000 / 2;
  refreshWindowDialog: boolean;
  reportDownloadDialog: boolean;
  reportId: any;
  bothTypeMessage: boolean = false;
  supportEmail: string;
  accountSuspendedDialog: boolean;
  categoryType: string;
  publicMenuItems = [];

  constructor(
    private coreService: CoreService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe((response) => {
      this.categoryType = response.category;
    });

    this.supportEmail = `mailto: ${email}`;
    this.coreService.countDown.subscribe((data) => {
      if (data && !this.timerRunning) {
        this.count = 60;
        this.showTimedOutDialog = true;
        this.startTimer();
      }
    });

    this.coreService.notifMessage.subscribe((data) => {
      if (data) {
        this.messageService.clear();
        this.messageService.add({ ...data, sticky: true });
      }
    });

    this.coreService
      .getUserDetails()
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (user) => {
          localStorage.removeItem("inactive");
          localStorage.removeItem("idle");
          localStorage.removeItem("suspended");
          localStorage.removeItem("terminated");
          this.coreService.userDetails = new LmeCurrentUser(user);
          this.user = this.coreService.userDetails;
          if (!this.user.termsAccepted) {
            this.showTandCDialog = true;
          }

          this.registerChannel();
          this.getActiveNotification();
          this.loaderService.hide();
          interval(this._refreshIntervalDuration)
            .pipe(
              startWith(0),
              switchMap(() => this.coreService.getPartyDetails())
            )
            .subscribe((party) => {
              console.log(party);
            });
        },
        (err: HttpErrorResponse) => {
          if (
            err.status === 400 &&
            err.error.message &&
            err.error.message.includes("Your account is not active")
          ) {
            localStorage.setItem("inactive", "true");
            setTimeout(() => {
              this.validateLogout();
            }, 0);
          } else if (err.status === 500) {
            localStorage.setItem("suspended", "true");
            this.validateLogout();
          } else if (err.status === 401) {
            localStorage.setItem("terminated", "true");
            this.validateLogout();
          } else {
            this.validateLogout();
          }
        }
      );

    if (this.categoryType === "public") {
      this.getStatistics();
    }
  }

  getStatistics() {
    this.coreService
      .getStats()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.generateMenu(res);
        this.coreService.setProducerProfile(res);
      });
  }

  returnPreviousLocation(e) {
    this.router.navigate([HOME]);
  }

  generateMenu(data) {
    let menuEnabledList = data.publicViewTabsEnabled.split(",");

    if (menuEnabledList[0] === "true") {
      this.publicMenuItems.push({
        name: "Producer Profile",
        path: "/public/producer-profile",
      });
    }

    if (menuEnabledList[1] === "true") {
      this.publicMenuItems.push({
        name: "Live Sustainability Disclosures",
        path: "/public/live-sustainability-disclosures",
      });
    }

    if (menuEnabledList[2] === "true") {
      this.publicMenuItems.push({
        name: "LME’s Sustainability Taxonomy",
        path: "/public/disclosures-detail",
      });
    }

    if (menuEnabledList[3] === "true") {
      this.publicMenuItems.push({
        name: "Side-by-side",
        path: "/public/metric-comparison",
      });
    }
  }

  getActiveNotification() {
    this.coreService
      .getActiveNotifications()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        if (res.length) {
          this.bulkNotificationsDialog = true;
          const { columns, ...config } = notifListTableConfig;
          const _columns = [...notifListTableConfig.columns];
          this.tableConfig = {
            ...config,
            ...{ columns: _columns },
          };

          this.messages = res;

          this.messageId = res.map((notif) => notif.notificationId);
        }
      });
  }

  startTimer() {
    this.timerRunning = true;
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => {
        if (this.count === 1 && this.showTimedOutDialog) {
          localStorage.setItem("idle", "true");
          this.validateLogout();
        }
        return --this.count;
      })
    );
  }

  validateLogout() {
    if (this.user) {
      this.logOut();
    }
  }

  resetSession() {
    this.showTimedOutDialog = false;
    this.timerRunning = false;
    this.coreService.reset();
    this.coreService.countDown.next(null);
  }

  logOut() {
    window.location.href = `https://${window.location.hostname}/redirect_uri?logout=https://${window.location.hostname}/`;
  }

  hideTCDialog() {
    this.coreService
      .acceptTerms()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        console.log(res);
        this.showTandCDialog = false;
      });
  }

  registerChannel() {
    this.coreService
      .getNotificationsChannels()
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        const channel = response[0];
        const notificationUrl = `${environment.apiUrl}/notification-ui-async/channel/`;
        this.coreService.initChannel(notificationUrl + channel, this);
        this.coreService.subscribeChannel();
      });
  }

  onChannelMessage(data) {
    if (data.messageId === "Refresh") {
      this.coreService.refreshCreateImportList();
    } else if (data.type === "INFO") {
      this.infoDialog = true;
      this.notifMessage = data.message;
      this.messageId = [data.messageId];
    } else if (data.title === "UserUpdatedPermissions") {
      this.refreshWindowDialog = true;
    } else if (data.title === "REPORT_READY") {
      this.reportDownloadDialog = true;
      let splitMessage = data.message.split(" ");
      this.reportId = splitMessage[splitMessage.length - 1];
    } else if (data.title === "User Suspended") {
      this.accountSuspendedDialog = true;
    } else if (data.title === "OWSR Inventory Status") {
      this.manageOwsrNotif(data);
    } else if (data.title === "REPORT_ERROR") {
      this.coreService
        .acknowledgeReportNotif(data.message)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: data.message,
          });
        });
    } else {
      if (data.title === "Suspicious Records") {
        const { columns, ...config } = suspisiousNotifListTableConfig;
        const _columns = [...suspisiousNotifListTableConfig.columns];
        this.suspicioustableConfig = {
          ...config,
          ...{ columns: _columns },
        };
        this.suspiciousActivityForTodo = JSON.parse(data.message);
        this.suspiciousNnotificationDialog = true;
        this.notifHeader = data.title;
        this.notifType = data.type;
      } else {
        this.checkNotifType(data);
      }
    }
  }

  manageOwsrNotif(data) {
    if (data.type === "ERROR") {
      this.notificationDialog = true;
      this.notifHeader = data.title;
      const errors = data.message;
      const formattedErrors = errors.replace(",", "<br>");
      this.notifMessage = formattedErrors;
      this.notifType = data.type;
      this.bothTypeMessage = false;
    } else {
      this.coreService.refreshGdlData();
    }
  }

  checkNotifType(data) {
    if (
      data.message.includes(
        "The following LMEpassport ID(s) already exists in your My Records List"
      ) &&
      data.message.includes(
        "The following LMEpassport ID(s) have been added to your My Records List"
      )
    ) {
      this.notificationDialog = true;
      this.notifHeader = data.title;
      this.notifMessage = data.message.split("###");
      this.notifType = data.type;
      this.bothTypeMessage = true;
    } else {
      this.notificationDialog = true;
      this.notifHeader = data.title;
      this.notifMessage = data.message;
      this.notifType = data.type;
      this.bothTypeMessage = false;
    }
  }

  acknowledge() {
    let payload = {
      notificationId: this.messageId,
    };
    this.coreService
      .acknowledgeNotif(payload)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        if (res) {
          this.infoDialog = false;
          this.bulkNotificationsDialog = false;
        }
      });
  }

  downloadReport() {
    this.coreService
      .acknowledgeReportNotif(this.reportId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          window.open(
            `${environment.apiUrl}/report-service/reports/download/${this.reportId}`
          );
          this.reportDownloadDialog = false;
        },
        (err) => {
          let errorMessage = err.error.message.replace(
            "com.markit.cmtk.exception.CMTKException:",
            ""
          );
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: errorMessage,
          });

          this.reportDownloadDialog = false;
        }
      );
  }

  refreshPage() {
    window.location.reload();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
