import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { IUser } from "@core/models/api.model";
import { CoreService } from "@core/services/core.service";
import { environment } from "@env/environment";
import { valueAddThemesListingTableConfig } from "@module/my-records/my-records-config";
import { MyRecordsService } from "@module/my-records/services/my-records.service";
import { ITableViewConfig } from "@shared/models/table-view.model";
import { ConfirmationService } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-value-add-themes",
  templateUrl: "./value-add-themes.component.html",
  styleUrls: ["./value-add-themes.component.scss"],
  providers: [ConfirmationService],
})
export class ValueAddThemesComponent implements OnInit, OnDestroy {
  @Input() valueAddThemeDialog: boolean;
  @Input() appendId: number;

  @Output() valueAdddThemeDialogHide = new EventEmitter<any>();

  @ViewChild("proofPointTemplate", { static: true })
  proofPointTemplate: TemplateRef<any>;
  @ViewChild("frameworkTemplate", { static: true })
  frameworkTemplate: TemplateRef<any>;
  @ViewChild("actionTemplate", { static: true })
  actionTemplate: TemplateRef<any>;

  private _destroy$ = new Subject<any>();
  tableConfig: ITableViewConfig;
  valueAddThemesData: Object;
  appendDialog: boolean;
  appendedRowData: any;
  user: IUser;

  constructor(
    private myRecordsService: MyRecordsService,
    private confirmationService: ConfirmationService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.user = this.coreService.getLoggedInuserDetails();
    const { columns, ...config } = valueAddThemesListingTableConfig;
    const _columns = [...valueAddThemesListingTableConfig.columns];
    this.tableConfig = {
      ...config,
      ...{ columns: _columns },
    };

    _columns.push({
      header: "Proof Point",
      width: "240px",
      templateRef: this.proofPointTemplate,
    });

    _columns.push({
      header: "Framework Employed",
      width: "240px",
      templateRef: this.frameworkTemplate,
    });

    /* if (this.user.platformAdmin) {
      _columns.push({
        header: "Actions",
        width: "120px",
        templateRef: this.actionTemplate,
      });
    } */

    this.initData();
  }

  onValueAddThemeHide() {
    this.valueAdddThemeDialogHide.emit();
  }

  initData() {
    this.myRecordsService
      .getValueAddThemesDisc(this.appendId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        res.forEach((disclosure) => {
          if (
            disclosure.themeType == "Metric" ||
            disclosure.themeType == "METRIC"
          ) {
            disclosure["value"] = disclosure.metricLabel
              ? `${disclosure.metricPercentage} ${disclosure.metricLabel}`
              : `${disclosure.metricPercentage}`;
          }

          if (disclosure.subThemeName) {
            disclosure[
              "themeSubTheme"
            ] = `${disclosure.themeName} - ${disclosure.subThemeName}`;
          } else {
            disclosure["themeSubTheme"] = `${disclosure.themeName}`;
          }
        });
        this.valueAddThemesData = res;
      });
  }

  redirectToUrl(event, url) {
    event.preventDefault();
    if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url;
    }
    return window.open(url);
  }

  downloadDocument(rowData, type) {
    if (type === "FRAMEWORK") {
      window.open(
        `${environment.apiUrl}/doc-upload-service/documentfiles/${rowData.frameworkDocument}`
      );
    } else {
      window.open(
        `${environment.apiUrl}/doc-upload-service/documentfiles/${rowData.proofDocument}`
      );
    }
  }

  editDisclosure(rowData) {
    this.appendDialog = true;
    this.appendedRowData = rowData;
  }

  confirmDeleteDisclosure(rowData) {
    this.confirmationService.confirm({
      message: `
      Are you sure you want to delete?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteAppendedDisclosure(rowData);
      },
    });
  }

  deleteAppendedDisclosure(rowData) {
    let payload = {
      recordThemes: [rowData.id],
    };
    this.myRecordsService
      .deleteAppended(payload)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          if (res) {
            this.coreService.showMessage({
              key: "tc",
              severity: "success",
              summary: "Success",
              detail: "Record deleted successfully.",
            });

            this.initData();
          }
        },
        (err) => {
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: err.error.message,
          });
        }
      );
  }

  onHideAppendDialog() {
    this.appendDialog = false;
    this.initData();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
