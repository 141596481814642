import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import {
  IDisclosure,
  IEntityList,
  ITerminatedUsers,
  IUser,
  IUsers,
} from "@core/models/api.model";
import { CoreService } from "@core/services/core.service";

export const CREATE_ENTITY = "/warehouse-service/entities/createEntity";
export const ENTITIES = "/warehouse-service/entities";
export const ENTITY_BY_ID = "/warehouse-service/entities/";
export const UPDATE_ENTITY = "/warehouse-service/entities/update/";
export const SUSPEND_ENTITY = "/warehouse-service/entities/suspend/";
export const ACTIVATE_ENTITY = "/warehouse-service/entities/activate/";
export const USERS = "/user-service/users?status=ACTIVE,SUSPENDED";
export const USERS_PENDING = "/user-service/users?status=PENDING";
export const USERS_ACTIVE = "/user-service/users?status=ACTIVE";
export const USERS_ALL = "/user-service/users?status=ACTIVE,SUSPENDED,PENDING";
export const CREATE_USER = "/user-service/users/create";
export const USERS_BY_ID = "/user-service/users/";
export const UPDATE_USER = "/user-service/users/update/";
export const TERMINATED = "/user-service/users/findByStatus";
export const BULK_UPDATE = "/user-service/users/bulkUpdate";
export const UPDATE_PERMISSIONS = "/user-service/users/bulkPermission";
export const COMPANIES = "/warehouse-service/entities/stakeholder/";
export const ENTITIES_ACTIVE = "/warehouse-service/entities?active=true";
export const BULK_REVIEW = "/user-service/users/bulkReview";
export const MONITOR_RECORDS =
  "/inventory-service/myRecordsMonitoring/searchMyRecordsMonitoring";
export const MONITOR_DETAILS =
  "/inventory-service/myRecordsMonitoring/getMyRecordsMonitoringDetail";
export const DISCLOSURES = "/admin-service/themes/flat?status=ACTIVE";
export const DISCLOSURES_ALL = "/admin-service/themes/flat";
export const THEMES = "/admin-service/themes/fetch?status=ACTIVE";
export const ADD_THEME = "/admin-service/themes";
export const UPDATE_THEME = "/admin-service/themes/";
export const UPDATE_DISCLOSURE = "/admin-service/disclosures/update";
export const DISCLOSURE = "/admin-service/disclosures/";
export const DELETE_THEME = "/admin-service/themes/delete/";
export const NOTIFICATIONS = "/user-service/notifications/request";
export const DELETE_APPENDED = "/inventory-service/sustainability/delete";
export const EXPORT_APPENDED = "/inventory-service/sustainability/export";
export const APPENDED_RECORDS =
  "/inventory-service/sustainability/appendedrecords/";
export const DELETE_DISCLOSURE = "/admin-service/disclosures/";
export const GET_BRANDS = "/common-service/brands/list";
export const DELETE_BRAND = "/common-service/brands/delete/";
export const EDIT_BRAND = "/common-service/brands/update";
export const ADD_BRAND = "/common-service/brands/add";
export const EXPORT_BRANDS = "/common-service/brands/export";
export const CREATE_STAKEHOLDER = "/warehouse-service/entities/add";
export const DELETE_ENTITY = "/warehouse-service/entities/delete/";
export const EXPORT_ENTITY = "/warehouse-service/entities/export";
export const UPDATE_ENTITY_NEW = "/warehouse-service/entities/update";
export const VALIDATE_BRAND = "/common-service/brands/validate";
export const COMPANIES_PUBLIC =
  "/warehouse-service/entities/stakeholder/public/";
export const TARGETS_LIST = "/admin-service/targets/fetch";
export const CREATE_TARGET = "/admin-service/targets/add";
export const DELETE_TARGET_NAME = "/admin-service/targets/delete/";
export const VALIDATE_DELETE = "/admin-service/targets/validate/";
export const UPDATE_TARGET = "/admin-service/targets/update/";
export const DELETE_PROOF_URL = "/admin-service/producers/targets/delete/";
export const OFF_WARRANT_STOCK =
  "/inventory-service/current/inventory/dashboard";
export const RECONCILED_DATA =
  "/dashboard-service/reconciled/inventory/dashboard";
export const RECONCILE_DATA = "/dashboard-service/reconciled/inventory";
@Injectable({
  providedIn: "root",
})
export class EntityService {
  user: IUser;
  constructor(private http: HttpClient, private coreService: CoreService) {}

  createEntity(params): any {
    if (params.id) {
      return this.http.put(
        `${environment.apiUrl}${UPDATE_ENTITY}${params.id}`,
        params
      );
    } else {
      return this.http.post(`${environment.apiUrl}${CREATE_ENTITY}`, params);
    }
  }

  suspendEntity(params): any {
    return this.http.put(
      `${environment.apiUrl}${SUSPEND_ENTITY}${params.id}`,
      params
    );
  }

  activateEntity(params): any {
    return this.http.put(
      `${environment.apiUrl}${ACTIVATE_ENTITY}${params.id}`,
      params
    );
  }

  getEntityLisiting(active?): Observable<IEntityList[]> {
    if (active) {
      let params = new HttpParams();
      params = params.append("active", active);
      return this.http.get<IEntityList[]>(`${environment.apiUrl}${ENTITIES}`, {
        params,
      });
    } else {
      return this.http.get<IEntityList[]>(`${environment.apiUrl}${ENTITIES}`);
    }
  }

  getEntityById(id): Observable<IEntityList> {
    return this.http.get<IEntityList>(
      `${environment.apiUrl}${ENTITY_BY_ID}${id}`
    );
  }

  getUsersLisiting(managePermission?: boolean): Observable<IUsers[]> {
    this.user = this.coreService.getLoggedInuserDetails();
    if (managePermission) {
      return this.http.get<IUsers[]>(`${environment.apiUrl}${USERS_ACTIVE}`);
    } else {
      if (this.user.entityAdmin) {
        return this.http.get<IUsers[]>(`${environment.apiUrl}${USERS_ALL}`);
      } else {
        return this.http.get<IUsers[]>(`${environment.apiUrl}${USERS}`);
      }
    }
  }

  getPendingUsersLisiting(): Observable<IUsers[]> {
    return this.http.get<IUsers[]>(`${environment.apiUrl}${USERS_PENDING}`);
  }

  getTerminatedUsers(): Observable<ITerminatedUsers[]> {
    let params = new HttpParams();
    params = params.append("status", "TERMINATED");
    return this.http.get<IUsers[]>(`${environment.apiUrl}${TERMINATED}`, {
      params,
    });
  }

  getActiveUsersIndividual(): Observable<ITerminatedUsers[]> {
    let params = new HttpParams();
    params = params.append("status", "ACTIVE");
    return this.http.get<IUsers[]>(`${environment.apiUrl}${TERMINATED}`, {
      params,
    });
  }

  createUser(params, id?: number): any {
    if (id) {
      params.isRejected = params.status === "PENDING" ? true : false;
      return this.http.put(`${environment.apiUrl}${UPDATE_USER}${id}`, params);
    } else {
      return this.http.post(`${environment.apiUrl}${CREATE_USER}`, params);
    }
  }

  getUserById(id): any {
    return this.http.get(`${environment.apiUrl}${USERS_BY_ID}${id}`);
  }

  bulkUpdate(ids, action, isRejected) {
    let params = {
      userIds: ids,
      status: action,
      isRejected,
    };
    return this.http.put(`${environment.apiUrl}${BULK_UPDATE}`, params);
  }

  updatePermissions(payload) {
    return this.http.put(`${environment.apiUrl}${UPDATE_PERMISSIONS}`, payload);
  }

  getCompanies(stakeHolderId): any {
    return this.http.get(`${environment.apiUrl}${COMPANIES}${stakeHolderId}`);
  }

  getProducers(): any {
    return this.http.get(`${environment.apiUrl}${ENTITIES_ACTIVE}`);
  }

  getCompaniesPublic(stakeHolderId): any {
    return this.http.get(
      `${environment.apiUrl}${COMPANIES_PUBLIC}${stakeHolderId}`
    );
  }

  review2Required(payload) {
    return this.http.put(`${environment.apiUrl}${BULK_REVIEW}`, payload);
  }

  getMonitorRecords(filters, pagination): any {
    let params = new HttpParams();
    params = params.append("fromPage", pagination.page);
    params = params.append("pageSize", pagination.size);

    return this.http.post(`${environment.apiUrl}${MONITOR_RECORDS}`, filters, {
      params,
    });
  }

  getMyRecordsMonitoringDetail(pagination, monitoringId): any {
    let params = new HttpParams();
    params = params.append("fromPage", pagination.page);
    params = params.append("pageSize", pagination.size);
    params = params.append("monitoringId", monitoringId);

    if (pagination.sort) {
      params = params.append("sortOnValidSearch", pagination.dir);
    }

    return this.http.get(`${environment.apiUrl}${MONITOR_DETAILS}`, {
      params,
    });
  }

  getDisclosures(status?): Observable<IDisclosure[]> {
    if (status) {
      //let params = new HttpParams();
      //params = params.append("status", status);
      return this.http.get<IDisclosure[]>(
        `${environment.apiUrl}${DISCLOSURES_ALL}`
      );
    } else {
      return this.http.get<IDisclosure[]>(
        `${environment.apiUrl}${DISCLOSURES}`
      );
    }
  }

  getThemes(isAllTheme?): any {
    let params = new HttpParams();
    isAllTheme && (params = params.append("isAllTheme", isAllTheme));
    return this.http.get(`${environment.apiUrl}${THEMES}`, { params });
  }

  addTheme(payload) {
    return this.http.post(`${environment.apiUrl}${ADD_THEME}`, payload);
  }

  updateTheme(payload, id) {
    return this.http.put(
      `${environment.apiUrl}${UPDATE_THEME}${id}/update`,
      payload
    );
  }

  updateDisclosure(payload) {
    return this.http.put(`${environment.apiUrl}${UPDATE_DISCLOSURE}`, payload);
  }

  deleteDisclosure(disclosureId) {
    return this.http.delete(
      `${environment.apiUrl}${DELETE_DISCLOSURE}${disclosureId}/delete`
    );
  }

  getdisclosureData(id): any {
    return this.http.get(`${environment.apiUrl}${DISCLOSURE}${id}`);
  }

  deleteTheme(themeId) {
    return this.http.delete(`${environment.apiUrl}${DELETE_THEME}${themeId}`);
  }

  sendNotification(payload) {
    return this.http.post(`${environment.apiUrl}${NOTIFICATIONS}`, payload);
  }

  deleteAppended(disclosreId) {
    return this.http.delete(
      `${environment.apiUrl}${DELETE_APPENDED}/${disclosreId}`
    );
  }

  export(payload): any {
    return this.http.post(`${environment.apiUrl}${EXPORT_APPENDED}`, payload);
  }

  getRecords(recordId, pagination): any {
    let params = new HttpParams();
    params = params.append("fromPage", pagination.page);
    params = params.append("pageSize", pagination.size);

    if (pagination.sort) {
      params = params.append("sorting", pagination.dir);
    }
    return this.http.get(
      `${environment.apiUrl}${APPENDED_RECORDS}${recordId}`,
      { params }
    );
  }

  getBrandsListing(): any {
    return this.http.get(`${environment.apiUrl}${GET_BRANDS}`);
  }

  deleteBrand(id) {
    return this.http.delete(`${environment.apiUrl}${DELETE_BRAND}${id}`);
  }

  addEditBrand(payload) {
    if (payload.id) {
      return this.http.put(`${environment.apiUrl}${EDIT_BRAND}`, payload);
    } else {
      return this.http.post(`${environment.apiUrl}${ADD_BRAND}`, payload);
    }
  }

  exportBrands(): any {
    return this.http.post(`${environment.apiUrl}${EXPORT_BRANDS}`, {});
  }

  exportEntity(): any {
    return this.http.post(`${environment.apiUrl}${EXPORT_ENTITY}`, {});
  }

  createStakeholder(payload) {
    return this.http.post(
      `${environment.apiUrl}${CREATE_STAKEHOLDER}`,
      payload
    );
  }

  deleteEntity(entityId) {
    return this.http.delete(`${environment.apiUrl}${DELETE_ENTITY}${entityId}`);
  }

  updateEntity(payload) {
    return this.http.put(`${environment.apiUrl}${UPDATE_ENTITY_NEW}`, payload);
  }

  validateBrand(payload): any {
    return this.http.post(`${environment.apiUrl}${VALIDATE_BRAND}`, payload);
  }

  getTargetList(): any {
    return this.http.get(`${environment.apiUrl}${TARGETS_LIST}`);
  }

  createTarget(payload) {
    return this.http.post(`${environment.apiUrl}${CREATE_TARGET}`, payload);
  }

  editTarget(payload, id) {
    return this.http.put(`${environment.apiUrl}${UPDATE_TARGET}${id}`, payload);
  }

  deleteStandardTargetName(id, data) {
    let params = new HttpParams();
    if (data) {
      params = params.append("data", "true");
    }
    return this.http.delete(`${environment.apiUrl}${DELETE_TARGET_NAME}${id}`, {
      params,
    });
  }

  validateDelete(id) {
    return this.http.get(`${environment.apiUrl}${VALIDATE_DELETE}${id}`);
  }

  deleteProofUrl(deleteProofUrl) {
    return this.http.delete(
      `${environment.apiUrl}${DELETE_PROOF_URL}${deleteProofUrl.targetId}`,
      {
        params: { proofId: deleteProofUrl.proofId },
      }
    );
  }

  getOffWarrantStockData(inventoryDate): any {
    let params = new HttpParams();
    params = params.append("inventoryDate", inventoryDate);
    return this.http.post(
      `${environment.apiUrl}${OFF_WARRANT_STOCK}`,
      {},
      { params }
    );
  }

  getReconciledData(inventoryDate): any {
    let params = new HttpParams();
    params = params.append("inventoryDate", inventoryDate);
    return this.http.get(`${environment.apiUrl}${RECONCILED_DATA}`, { params });
  }

  reconcileOffWarrantStock(inventoryDate): any {
    let params = new HttpParams();
    params = params.append("inventoryDate", inventoryDate);
    return this.http.get(`${environment.apiUrl}${RECONCILE_DATA}`, { params });
  }
}
