<p-dialog [(visible)]="infoTriggers" [style]="{ width: '50vw' }"
  [baseZIndex]="10000" class="producers-modal" (onHide)="cancel()" [modal]="true">
  <ng-template pTemplate="header">
    <div class="header-wrapper">
      <p class="producer-header-text"><u>{{sideBySideText}}</u></p>
    </div>
  </ng-template>
    <div class="row mt-3 mb-3">
      <div class="col-md-12">
        <div class="text-justify">The side-by-side tab allows users to easily see LME producers sustainability credentials simultaneously and against each other. You can compare up to 5 producers on the same page at the entity or asset level. Please note you cannot view asset and entity level credentials side-by-side as this is not an applicable comparison.
        </div>
      </div>
    </div>
</p-dialog>