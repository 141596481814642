import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IUsers } from "@core/models/api.model";
import { CoreService } from "@core/services/core.service";
import * as _ from "lodash";
import { MenuItem } from "primeng/api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() user: IUsers;
  menuItems = [];
  currentScreen: string;
  items: MenuItem[];
  fullName: string;
  private _destroy$ = new Subject<any>();
  lastLogin: any;
  mobileName: any;
  constructor(private router: Router, private coreService: CoreService) {}

  ngOnInit() {
    this.coreService
      .getLastLogin()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        this.lastLogin = res?.hasOwnProperty("lastLogin")
          ? res.lastLogin
          : null;
        this.coreService.idleTime = parseInt(res?.logoutTime);
        if (this.coreService.idleTime) {
          this.coreService.setTimer();
        }
      });

    this.fullName = `${this.user.firstName} ${this.user.lastName}`;
    this.mobileName = `${this.user.firstName
      .substring(0, 1)
      .toUpperCase()} ${this.user.lastName.substring(0, 1).toUpperCase()}`;
    let splitUrl = this.router.url.split("/");
    this.currentScreen = splitUrl[1];
    this.getMaintabs();
    this.items = [
      {
        label: "Logout",
        icon: "pi pi-sign-out",
        command: () => {
          this.logout();
        },
      },
    ];
  }

  getMaintabs() {
    this.coreService
      .getTabs()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        let sortedMenu = _.orderBy(res, ["order"], ["asc"]);
        this.menuItems = sortedMenu;
        if (!this.user.write) {
          let idx = this.menuItems.findIndex(
            (menuItem) => menuItem.path === "/private/records-management"
          );
          if (idx !== -1) {
            this.menuItems.splice(idx, 1);
          }
        }

        /* if (this.user.stakeHolder === "PRODUCER" && !this.user.append) {
          let sustainabilityTabIdx = this.menuItems.findIndex(
            (item) => item.path === "/private/sustainability-profile"
          );
          sustainabilityTabIdx !== -1 &&
            this.menuItems.splice(sustainabilityTabIdx, 1);
        } */

        let publicPageIdx = this.menuItems.findIndex(
          (item) => item.path === "/private/public-sustainability-page"
        );

        publicPageIdx !== -1 &&
          (this.menuItems[publicPageIdx].path = "/public/producer-profile");
        if (
          this.user.platformAdmin === false &&
          this.user.entityAdmin === false
        ) {
          this.menuItems.splice(3, 0, {
            moduleLabel: "Company Information",
            moduleName: null,
            moduleType: "TAB",
            path: "/private/user-tasks",
            order: "7",
            tabs: [],
          });
        }

        console.log(this.user);

        if (
          this.user.stakeHolder === "WAREHOUSE_OPERATOR" &&
          this.user.offWarrant === true
        ) {
          this.menuItems.splice(5, 0, {
            moduleLabel: "OWSR",
            moduleName: null,
            moduleType: "TAB",
            path: "/private/owsr",
            order: "7",
            tabs: [],
          });
        }

        if (this.user.platformAdmin) {
          this.menuItems.splice(5, 0, {
            moduleLabel: "OWSR",
            moduleName: null,
            moduleType: "TAB",
            path: "/private/owsr-pa",
            order: "7",
            tabs: [],
          });
        }

        this.coreService.menuItemReceived(sortedMenu);
      });
  }

  skipToMain(event) {
    if (event && event.key === "Enter") {
      event && event.preventDefault();
      document.getElementById("main-content").focus();
    }
  }

  logout() {
    window.location.href = `https://${window.location.hostname}/redirect_uri?logout=https://${window.location.hostname}/`;
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
    this.coreService.countDown.next(null);
  }
}
