<p-dialog header="Append" [modal]="true" [(visible)]="appendDialog" (onHide)="onAppendHide()" styleClass="append-dialog"
  [closeOnEscape]="false" [closable]="false" [style]="{width: '60vw'}">
  <ng-container *ngIf="selectTheme; else QA">
    <ng-select [items]="themes" bindLabel="themeSubTheme" bindValue="id" placeholder="Select Theme" appendTo="body"
      (change)="onThemeSelect()" [(ngModel)]="theme"></ng-select>
  </ng-container>
  <ng-template #QA>
    <div *ngIf="!editAppended" class="back mb-3">
      <a href="#" class="arrow-back" wcag-dummy-link-target role="button" aria-label="Back"
        (click)="returnPreviousLocation($event)" (keypress)="returnPreviousLocation($event)">
        <i class="fa fa-chevron-left float-left back-icon"></i>
        <span>Select Theme</span>
      </a>
    </div>
    <div class="form-group">
      <div class="mb-2">Selected Theme</div>
      <div>
        <ng-select [items]="themes" bindLabel="themeSubTheme" bindValue="id" placeholder="Select Theme" appendTo="body"
          [(ngModel)]="theme" disabled="true"></ng-select>
      </div>
    </div>
    <form (ngSubmit)="submit()" [formGroup]="form" #myForm="ngForm">
      <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
    </form>
  </ng-template>

  <p-footer>
    <button *ngIf="!selectTheme && !editAppended" class="btn btn-primary" (click)="submit()">
      Submit
    </button>
    <button *ngIf="!selectTheme && editAppended" class="btn btn-primary" (click)="submit()">
      Update
    </button>
    <button class="btn btn-primary" (click)="onAppendHide('true')">
      Cancel
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Append" [modal]="true" *ngIf="showApproved" [closeOnEscape]="false" [closable]="false"
  [(visible)]="showApproved" [style]="{ width: '90vw' }">
  <app-approved-records parent="append" [metalFilter]="model.metals || disclosureData.metals"
    [companyFilter]="model.entities" #approvedRecords></app-approved-records>
  <p-footer>
    <button class="btn btn-primary" (click)="appendRecords()">
      Append Record(s)
    </button>
    <button *ngIf="!selectTheme" class="btn btn-primary" (click)="hideApproved()">
      Cancel
    </button>
  </p-footer>
</p-dialog>

<app-redact-file *ngIf="redactDialog" [redactDialog]="redactDialog" [fileToRedact]="fileToRedact"
  (redactHide)="onRedactDialogHide($event)" (redactComplete)="onRedactComplete($event)"></app-redact-file>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>