import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  NgForm,
  ValidationErrors,
} from "@angular/forms";
import { IUser } from "@core/models/api.model";
import { CoreService } from "@core/services/core.service";
import { LookupService } from "@core/services/lookup.service";
import { CreateImportService } from "@module/create-import/services/create-import.service";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { CHEMICAL_COMPOSITION_PATTERN } from "app/app.const";
import * as _ from "lodash";
import * as moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export function CommandValidator(
  control: UntypedFormControl
): ValidationErrors {
  return /^@/.test(control.value) ||
    /^-/.test(control.value) ||
    /^\+/.test(control.value) ||
    /^=/.test(control.value)
    ? { cmd: true }
    : null;
}
@Component({
  selector: "app-edit-coa-record",
  templateUrl: "./edit-coa-record.component.html",
  styleUrls: ["./edit-coa-record.component.scss"],
})
export class EditCoaRecordComponent implements OnInit {
  @Input() selectedRecord: any;
  @Input() editDialog: boolean;
  @Input() newRecord: boolean;
  @Input() recordType: string;
  @Input() standards: any;
  @Input() industryStandardGrades: any;
  @Input() type: string;

  @Output() saveCoa = new EventEmitter<any>();
  @Output() hideEditDialog = new EventEmitter<any>();

  form = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[];
  private _destroy$ = new Subject<any>();

  @ViewChild("myForm", { static: true }) myForm: NgForm;
  types: { id: string; name: string }[];
  user: IUser;
  permissibleGrades = [];
  grades = new BehaviorSubject<any>(null);

  constructor(
    private coreService: CoreService,
    private lookupService: LookupService,
    private createImportService: CreateImportService
  ) {}

  ngOnInit() {
    this.user = this.coreService.getLoggedInuserDetails();
    this.types = [
      {
        id: "BASIC",
        name: "Basic",
      },
    ];

    if (this.user.stakeHolder === "PRODUCER") {
      this.types.push({
        id: "ENHANCED",
        name: "Enhanced",
      });
    }

    this.model = _.cloneDeep(this.selectedRecord);
    if (this.model && this.model.productionDateStr) {
      let originalprodDateStr = this.model.productionDateStr;
      this.model.productionDateStr = moment(
        this.model.productionDateStr,
        "DD-MM-YYYY"
      ).toDate();

      if (isNaN(this.model.productionDateStr)) {
        this.model.productionDateStr = moment(
          originalprodDateStr,
          "DD-MMM-YYYY"
        ).toDate();
      }

      if (isNaN(this.model.productionDateStr)) {
        this.model.productionDateStr = moment(
          originalprodDateStr,
          "DD-MM-YYYY"
        ).toDate();
      }

      if (isNaN(this.model.productionDateStr)) {
        this.model.productionDateStr = moment(
          originalprodDateStr,
          "DD-M-YYYY"
        ).toDate();
      }
    }

    this.initForm();
    this.getPermissibleGrades(true);
    /* if (this.model.grade) {
      let grade = {
        name: this.model.grade,
      };
      this.fields[0].fieldGroup[3].templateOptions.options = [grade];
    } */
  }

  checkChemicalComposition() {
    let hasChecmicalComposition = false;
    for (const key in this.model.chemicalCompositions) {
      if (this.model.chemicalCompositions[key]) {
        hasChecmicalComposition = true;
      }
    }

    return hasChecmicalComposition;
  }

  save() {
    this.form.markAsTouched();
    (this.myForm as any).submitted = true;

    if (this.form.valid) {
      if (this.recordType === "ENHANCED" && !this.checkChemicalComposition()) {
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail: "Please provide atleast one chemical composition.",
        });

        return false;
      }

      if (this.model.chemicalCompositions) {
        this.model.chemicalCompositions.hasOwnProperty("Others Total") &&
          delete this.model.chemicalCompositions["Others Total"];
        this.model.chemicalCompositions.hasOwnProperty("Others Each") &&
          delete this.model.chemicalCompositions["Others Each"];
        this.model.chemicalCompositions.hasOwnProperty(
          "Total All Impurities"
        ) && delete this.model.chemicalCompositions["Total All Impurities"];
      }

      this.saveCoa.emit(this.model);
    }
  }

  hideEditCoa() {
    this.hideEditDialog.emit();
  }

  getPermissibleGrades(init?) {
    let standards = this.industryStandardGrades.filter(
      (standard) => standard.industryStd === this.model.industryStandards
    );

    if (!init) {
      this.form.get("grade") && this.form.get("grade").setValue(null);
    } else {
      this.form.get("grade") &&
        this.form.get("grade").setValue(this.selectedRecord.grade);
    }

    if (standards.length) {
      let grades = [];
      standards.forEach((standard) => {
        if (standard.permissibleGrade !== "NA") {
          grades.push({ name: standard.permissibleGrade });
        }
      });
      this.grades.next(grades);
      /* this.fields[0].fieldGroup[10].templateOptions.options = [...grades];
      this.fields[0].fieldGroup[10].defaultValue = null; */
    } else {
      this.grades.next([]);
      //this.fields[0].fieldGroup[10].templateOptions.options = [];
    }
  }

  initForm() {
    this.fields = [
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            className: "col-6",
            key: "pcr",
            type: "input",
            templateOptions: {
              label: "PCR number",
              required: true,
              disabled: this.type === "approved",
            },
            validators: {
              validation: [CommandValidator],
            },
          },
          {
            className: "col-6",
            key: "pagePCR",
            type: "input",
            hideExpression: this.recordType && this.recordType === "ENHANCED",
            templateOptions: {
              label: "Page No. of PCR",
              required: true,
              pattern: /^\d{0,8}$/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid  Page No. of PCR, maximum allowed digits is 8.`,
              },
            },
          },
          {
            className: "col-6",
            key: "packingFrom",
            type: "input",
            templateOptions: {
              label: "Packing Form",
            },
            validators: {
              validation: [CommandValidator],
            },
          },
          {
            className: "col-6",
            key: "productionDateStr",
            type: "calendar-input",
            templateOptions: {
              label: "Date of Production",
            },
          },
          {
            className: "col-6",
            key: "productionCountry",
            type: "dict-select",
            templateOptions: {
              label: "Country of Production",
              source: this.lookupService.getCountries(),
              bindLabel: "name",
              multiple: false,
            },
          },
          {
            className: "col-6",
            key: "shape",
            type: "dict-select",
            templateOptions: {
              label: "Product Shape",
              bindLabel: "name",
              source: this.lookupService.getShapes(),
            },
          },
          {
            className: "col-6",
            key: "units",
            type: "input",
            templateOptions: {
              label: "Quantity (pieces)",
              pattern: /^[0-9]*$/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `Please enter a positive whole number`,
              },
            },
          },
          {
            className: "col-6",
            key: "grossWeight",
            type: "input",
            templateOptions: {
              label: "Total Gross Weight (MT)",
              pattern: /^\d{0,16}(\.\d{1,4})?$/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number, maximum allowed digits before and after decimal are 16 and 4 respectively.`,
              },
            },
          },
          {
            className: "col-6",
            key: "netWeight",
            type: "input",
            templateOptions: {
              label: "Total Net Weight (MT) ",
              pattern: /^\d{0,16}(\.\d{1,4})?$/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number, maximum allowed digits before and after decimal are 16 and 4 respectively.`,
              },
            },
          },
          {
            className: "col-6",
            key: "industryStandards",
            type: "dict-select",
            templateOptions: {
              label: "Industry Standards Met",
              dataProvided: true,
              multiple: false,
              items: this.standards,
              bindLabel: "name",
              change: (event) => this.getPermissibleGrades(),
              disabled:
                this.type === "approved" &&
                this.recordType &&
                this.recordType === "ENHANCED",
            },
          },
          {
            className: "col-6",
            key: "grade",
            type: "dict-select",
            templateOptions: {
              label: "Permissible Grade",
              multiple: false,
              source: this.grades,
              bindLabel: "name",
              disabled:
                this.type === "approved" &&
                this.recordType &&
                this.recordType === "ENHANCED",
            },
          },
        ],
      },
      {
        fieldGroupClassName: "row",
        key: "chemicalCompositions",
        hideExpression: this.recordType && this.recordType === "BASIC",
        fieldGroup: [
          {
            className: "col-6",
            key: "Ag",
            type: "input",
            templateOptions: {
              label: "Ag",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Al",
            type: "input",
            templateOptions: {
              label: "Al",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "As",
            type: "input",
            templateOptions: {
              label: "As",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "B",
            type: "input",
            templateOptions: {
              label: "B",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Bi",
            type: "input",
            templateOptions: {
              label: "Bi",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "C",
            type: "input",
            templateOptions: {
              label: "C",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Ca",
            type: "input",
            templateOptions: {
              label: "Ca",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Cd",
            type: "input",
            templateOptions: {
              label: "Cd",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Co",
            type: "input",
            templateOptions: {
              label: "Co",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Cr",
            type: "input",
            templateOptions: {
              label: "Cr",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Cu",
            type: "input",
            templateOptions: {
              label: "Cu",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Fe",
            type: "input",
            templateOptions: {
              label: "Fe",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Ga",
            type: "input",
            templateOptions: {
              label: "Ga",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Li",
            type: "input",
            templateOptions: {
              label: "Li",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Mg",
            type: "input",
            templateOptions: {
              label: "Mg",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Mn",
            type: "input",
            templateOptions: {
              label: "Mn",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Na",
            type: "input",
            templateOptions: {
              label: "Na",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Ni",
            type: "input",
            templateOptions: {
              label: "Ni",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "P",
            type: "input",
            templateOptions: {
              label: "P",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Pb",
            type: "input",
            templateOptions: {
              label: "Pb",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "S",
            type: "input",
            templateOptions: {
              label: "S",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Sb",
            type: "input",
            templateOptions: {
              label: "Sb",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Se",
            type: "input",
            templateOptions: {
              label: "Se",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Si",
            type: "input",
            templateOptions: {
              label: "Si",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Sn",
            type: "input",
            templateOptions: {
              label: "Sn",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Te",
            type: "input",
            templateOptions: {
              label: "Te",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Ti",
            type: "input",
            templateOptions: {
              label: "Ti",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "V",
            type: "input",
            templateOptions: {
              label: "V",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Zn",
            type: "input",
            templateOptions: {
              label: "Zn",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "others_each",
            type: "input",
            templateOptions: {
              label: "Others Each",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "others_total",
            type: "input",
            templateOptions: {
              label: "Others Total",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "total_all_impurities",
            type: "input",
            templateOptions: {
              label: "Total all impurities",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Ag+As+Bi+Fe+Pb+S+Sb+Se+Te",
            type: "input",
            templateOptions: {
              label: "Ag+As+Bi+Fe+Pb+S+Sb+Se+Te",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "As+Cd+Cr+Mn+P+Sb",
            type: "input",
            templateOptions: {
              label: "As+Cd+Cr+Mn+P+Sb",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Bi+Se+Te",
            type: "input",
            templateOptions: {
              label: "Bi+Se+Te",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Se+Te",
            type: "input",
            templateOptions: {
              label: "Se+Te",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Co+Fe+Ni",
            type: "input",
            templateOptions: {
              label: "Co+Fe+Ni",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Co+Fe+Ni+Si+Sn+Zn",
            type: "input",
            templateOptions: {
              label: "Co+Fe+Ni+Si+Sn+Zn",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Ag+As+Bi+Fe+Pb+Ni+S+Sb+Se+Sn+Te",
            type: "input",
            templateOptions: {
              label: "Ag+As+Bi+Fe+Pb+Ni+S+Sb+Se+Sn+Te",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Fe+Mn+Cr",
            type: "input",
            templateOptions: {
              label: "Fe+Mn+Cr",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
          {
            className: "col-6",
            key: "Pb+Cd+Fe+Sn+Cu+Al",
            type: "input",
            templateOptions: {
              label: "Pb+Cd+Fe+Sn+Cu+Al",
              pattern: CHEMICAL_COMPOSITION_PATTERN,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid number`,
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            className: "col-6",
            key: "status",
            type: "input",
            hideExpression: this.newRecord,
            templateOptions: {
              label: "Status",
              disabled: true,
            },
          },
          {
            className: "col-6",
            key: "passportId",
            type: "input",
            hideExpression: this.newRecord,
            templateOptions: {
              label: "LMEpassport ID",
              disabled: true,
            },
          },
          {
            className: "col-6",
            key: "scrCompliantStr",
            type: "input",
            hideExpression:
              this.newRecord ||
              (this.recordType && this.recordType === "BASIC") ||
              this.type !== "approved",
            templateOptions: {
              label: "SCR Compliant",
              disabled: true,
            },
          },
          /* {
            className: "col-6",
            key: "type",
            type: "dict-select",
            templateOptions: {
              dataProvided: true,
              label: "Record Type",
              multiple: false,
              items: this.types,
              bindLabel: "name",
              required: true,
            },
          }, */
        ],
      },
    ];
  }
}
