<app-table-filter [fields]="sustainabilityFilters" (filterChanged)="onFilterChanged($event)"
  [disclosures]="disclosuresData" parent="liveSustainability"></app-table-filter>
<div class="container-fluid mt-3 mb-3">
  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-primary mr-2 export-to-excel" (click)="exportExcel()"
        pTooltip="This action will export all records irrespective of the filters selected on the top."
        tooltipPosition="bottom">
        Export to Excel
      </button>
    </div>
  </div>
</div>
<div class="container-fluid mt-3">
  <div class="row mb-4">
    <p class="last-updated mb-2 mt-2">
      Last updated on: {{ lastUpdated | date: "dd MMMM, yyyy h:mm:ss a" }}
    </p>
    <div class="box mr-5 col-12 mb-5">
      <app-table-simple [config]="tableConfig" [tableData]="filterDisclosures" #table></app-table-simple>
    </div>
  </div>
</div>

<ng-template #themeSubThemeTemplate let-rowData>
  <span 
  class="cursor-pointer"
  (click)="navigateToTaxonomyWheel(rowData)"
  [ngClass]="{
      'green-bg': rowData.themeId.themeName.toLowerCase() === 'environmental',
      'purple-bg': rowData.themeId.themeName.toLowerCase() === 'multi-dimensional',
      'orange-bg': rowData.themeId.themeName.toLowerCase() === 'social',
      'pink-bg': rowData.themeId.themeName.toLowerCase() === 'governance'
    }">{{ rowData.themeSubTheme }}</span>
</ng-template>

<ng-template #companyTemplate let-rowData>
  <span
    (click)="(rowData?.entity?.entityName && rowData?.entity?.entityName !== 'N/A') && brandProducerSelectionNavigation(rowData)"
    [ngClass]="{ 'brand-producer' : (rowData?.entity?.entityName && rowData?.entity?.entityName !== 'N/A')}">{{
    rowData?.entity?.entityName ? rowData.entity.entityName : 'N/A' }}</span>
</ng-template>

<ng-template #brandTemplate let-rowData>
  <span (click)="(rowData?.brand && rowData?.brand !== 'N/A') && brandProducerSelectionNavigation(rowData)"
    [ngClass]="{ 'brand-producer' : (rowData?.brand && rowData?.brand !== 'N/A')}">{{
    rowData?.brand ? rowData.brand : 'N/A' }}</span>
</ng-template>

<ng-template #proofPointTemplate let-rowData>
  <ng-container *ngIf="rowData.proofText || rowData.proofUrl || rowData.proofDocument;else NA_TEMP">
    {{ rowData.proofText === "null" ? "" : rowData.proofText }}
    <a class="proof-url" (click)="redirectToUrl($event, rowData.proofUrl)" href="#">{{ rowData.proofUrl === "null" ? ""
      : rowData.proofUrl }}</a>
    &nbsp;
    <i *ngIf="rowData.proofDocument" (click)="downloadDocument(rowData, 'PROOF_POINT')" class="fa fa-file"
      aria-hidden="true" title="Download" style="cursor: pointer"></i>
  </ng-container>
  <ng-template #NA_TEMP>N/A</ng-template>
</ng-template>