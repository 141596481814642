import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-field-custom-textarea",
  templateUrl: "./formly-field-custom-textarea.component.html",
  styleUrls: ["./formly-field-custom-textarea.component.scss"],
})
export class FormlyFieldCustomTextareaComponent
  extends FieldType
  implements OnInit
{
  @ViewChild("textarea") textarea: ElementRef;
  text: string = "";
  placeholder: string = `Example(s): <br>
    <ul>
        <li>
        Executive Director, logistics manager. Certificates of Analysis, review and download digital COA records.
        </li>
        <li>Sustainability Manager, append and review sustainability data.</li>
    </ul>`;
  showPlaceholder: boolean = true;

  ngOnInit() {
    if (this.formControl.value) {
      this.text = this.formControl.value;
      this.showPlaceholder = false;
    } else {
      this.text = this.placeholder;
    }
  }

  onFocus() {
    if (this.text === this.placeholder) {
      this.text = "";
    }
    this.showPlaceholder = false;
  }

  onBlur() {
    if (!this.formControl.value) {
      this.text = this.placeholder;
      this.showPlaceholder = true;
    }
  }
}
