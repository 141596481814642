import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { LoaderState } from "./loader.model";
import { LoaderService } from "@core/services/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  show: boolean;

  private subscription: Subscription;

  constructor(private loaderService: LoaderService, private cdr:ChangeDetectorRef) {}

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state) => {
      this.show = state;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
