import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { environment } from "@env/environment";
import { SustainabilityDisclosurePublicService } from "@module/sustainability-disclosure-public-view/services/sustainability-disclosure-public.service";
import { MessageService, PrimeNGConfig } from "primeng/api";
import { Table } from "primeng/table";
import { MultiSelect } from "primeng/multiselect";
import * as _ from "lodash";
import { CoreService } from "@core/services/core.service";
import {
  DELIVERABLE_VALUES,
  NO_CERTIFICATION_METRICS_AVAILABLE,
  SIDE_BY_SIDE_TEXT,
} from "app/app.const";

@Component({
  selector: "app-disclosure-comparison-theme",
  templateUrl: "./disclosure-comparison-theme.component.html",
  styleUrls: ["./disclosure-comparison-theme.component.scss"],
  providers: [MessageService],
})
export class DisclosureComparisonThemeComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MultiSelect) multiSelect: MultiSelect | undefined;
  @ViewChild("themesTable", { static: false }) table: Table;
  themesList: any;
  producerList: any;
  filteredProducers: any;
  ThemeNameEnm = ThemeNameEn;
  selectedProducer: any = [];
  selectedHeaders: any = [];
  producerDisplayModal: boolean = false;
  brandsList: any = [];
  displaySubGroupDescription: boolean = false;
  subGroupdescription: any;
  env: { production: boolean; apiUrl: string; serviceUrl: string };
  disclosureData: any;
  selectedBrandsAndProducer = [];
  modalProducer: any = null;
  producerBrand: any = [];
  producerColumnWidth: number = 160;
  expandedThemeRows: {} = {};
  infoTriggers = false;
  sideBySideText = SIDE_BY_SIDE_TEXT;
  noCertificationMetricsAvailable = NO_CERTIFICATION_METRICS_AVAILABLE;
  brandExists: any;
  stringifySelectedProducer = [];
  singleProducerSelection = [];
  singleUnProducerSelection = [];
  selectedUid = null;
  singleProducerUnSelection = [];

  constructor(
    private sustainabilityDisclosurePublicService: SustainabilityDisclosurePublicService,
    private primengConfig: PrimeNGConfig,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private coreService: CoreService
  ) {
    this.primengConfig.ripple = true;
  }

  ngOnInit(): void {
    this.env = environment;
    const producerProfileSelectedId = localStorage.getItem("producerId");
    const selectedMetric = JSON.parse(localStorage.getItem("selectedMetric"));
    this.sustainabilityDisclosurePublicService
      .getPieDisclosures()
      .subscribe((themesResponse: any) => {
        const themesData = themesResponse.map((themeResponse) => {
          this.expandedThemeRows[themeResponse.themeName] = true;
          this.expandedThemeRows = Object.assign({}, this.expandedThemeRows);
          if (themeResponse?.disclosures?.length) {
            themeResponse.disclosures.map((disclosure) => {
              disclosure.entities = [];
              return disclosure;
            });
            return themeResponse;
          } else {
            themeResponse.disclosures.push({
              name: this.noCertificationMetricsAvailable,
            });
            return themeResponse;
          }
        });
        this.getSortedData(themesData);
      });
    this.sustainabilityDisclosurePublicService
      .getProducers()
      .subscribe((producerResponse: any) => {
        this.orderBy(producerResponse, "entityName");
        this.producerList = producerResponse;
        this.producerList = producerResponse.filter(
          (producer) => producer.status === "ACTIVE"
        );
        this.filteredProducers = _.cloneDeep(this.producerList);
      });
    this.sustainabilityDisclosurePublicService
      .getBrandData()
      .subscribe((brands: any) => {
        const activeBrands = brands.filter((brand) => brand.status);
        this.orderBy(activeBrands, "brand");
        this.brandsList = activeBrands;
        const appendTextBrands = activeBrands.map((brand: any) => {
          let brandStatusId = DELIVERABLE_VALUES.findIndex(
            (brandStatus) => brandStatus.id === brand.brandStatus
          );
          let brandStatus =
            brandStatusId !== -1
              ? DELIVERABLE_VALUES[brandStatusId].value
              : "N/A";
          const dispayText = `Brand: ${brand.brand} <br> Metal: ${brand.metal} <br> Brand status: ${brandStatus}`;
          const label = `Brand: ${brand.brand} | Metal: ${brand.metal} | Brand status: ${brandStatus}`;
          Object.assign(brand, { dispayText }, { label });
          return brand;
        });
        this.brandsList = appendTextBrands;

        const selectedProducerentityId = selectedMetric?.entity.id;
        setTimeout(() => {
          if (
            (producerProfileSelectedId || selectedProducerentityId) &&
            this.producerList
          ) {
            const selectedProducerId = producerProfileSelectedId
              ? +producerProfileSelectedId
              : selectedProducerentityId;
            const selectedProducerFromStore = this.producerList.filter(
              (producer) => producer.id === selectedProducerId
            )[0];
            this.producerSelection(selectedProducerFromStore);
            this.confirmToDisplay();
            localStorage.removeItem(
              producerProfileSelectedId ? "producerId" : "selectedMetric"
            );
          }
        }, 100);
      });
  }

  getSortedData(themesResponse) {
    const orderByThemeName = [
      "Environmental",
      "Social",
      "Governance",
      "Multi-dimensional",
    ];
    let themesSortedData = [];
    orderByThemeName.forEach((themeName) => {
      themesResponse.forEach((theme) => {
        if (theme.themeName.toLowerCase() === themeName.toLowerCase()) {
          themesSortedData.push(theme);
        }
      });
    });
    const themeIds = themesSortedData.map((theme) => theme.id);
    themesResponse.forEach((theme) => {
      if (!themeIds.includes(theme.id)) {
        themesSortedData.push(theme);
      }
    });
    this.themesList = themesSortedData;
  }

  ngAfterViewInit(): void {
    this.producerColumnWidth =
      (document.getElementById("producers-header").offsetWidth - 400) / 5;
    window.scrollTo(0, 0);
    this.cdr.detectChanges();
  }

  orderBy(sortData, sortColumn) {
    sortData.sort((element1, element2) => {
      let entity1 = element1[sortColumn].trim().toLowerCase();
      let entity2 = element2[sortColumn].trim().toLowerCase();
      if (entity1 < entity2) return -1;
      if (entity1 > entity2) return 1;
      return 0;
    });
  }

  producerSearch(event) {
    let searchString = "";
    if (event.term) {
      searchString = event.term.toLowerCase();
    } else if (event.entityName) {
      searchString = event.entityName.toLowerCase();
    } else {
      searchString = "";
    }
    this.filteredProducers = this.producerList.filter((row) =>
      row.entityName.toLowerCase().includes(searchString)
    );
  }

  producerSelection(producer?: any) {
    const singleProdcuerIds = this.singleProducerSelection.map(
      (producer: any) => producer.id
    );
    const checkSingleExistsId = singleProdcuerIds.indexOf(producer.id);
    if (checkSingleExistsId === -1) {
      Object.assign(producer, { count: 1 });
      this.singleProducerSelection.push(producer);
    } else {
      this.singleProducerSelection[checkSingleExistsId]["count"] += 1;
    }

    const filterSingleProdcuerId = this.singleProducerSelection.filter(
      (eachProducer: any) => eachProducer.id === producer.id
    );
    if (
      filterSingleProdcuerId[0]?.count &&
      filterSingleProdcuerId[0]?.count === 1
    ) {
      const selectedProducerCount = this.selectedHeaders?.length
        ? this.selectedHeaders?.length + this.selectedProducer.length
        : this.selectedProducer.length;
      if (selectedProducerCount >= 5) {
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail:
            "There can be selected up to 5 producers for side-by-side comparisons.",
        });
      } else {
        Object.assign(producer, {
          brandSelection: null,
          uid: Math.floor(Math.random() * 10000),
        });
        this.stringifySelectedProducer.push(JSON.stringify(producer));
        this.selectedProducer = this.stringifySelectedProducer.map(
          (producerData) => JSON.parse(producerData)
        );
      }
    }
  }

  updateBrandList() {
    this.selectedHeaders.forEach((element) => {
      element["brands"] = this.brandsList.filter((brand) => {
        return (
          element.entityName.toLowerCase() ===
          brand.masterProducerName.toLowerCase()
        );
      });
      const entityObj = {
        dispayText: "Entity",
        masterProducerName: element.entityName.toLowerCase(),
        brand: "Entity",
        label: "Entity",
        id: null,
      };
      element["brands"] = [entityObj, ...element["brands"]];
    });
  }

  unSelectProducer(producer: any, status?: any, producerIndex?: any) {
    if (!status) {
      const selectedProducerToRemove = this.selectedHeaders[producerIndex];
      this.selectedUid = selectedProducerToRemove["uid"];
      if (producerIndex >= 0) {
        if (selectedProducerToRemove?.brandLength?.length >= 1) {
          delete selectedProducerToRemove["brandLength"];
          delete selectedProducerToRemove["brandSelection"];
        }
        this.producerBrand[this.selectedUid] = null;
        this.stringifySelectedProducer.splice(producerIndex, 1);
        this.selectedHeaders.splice(producerIndex, 1);
        this.singleProducerSelection = [];
      }
    }

    if (status) {
      const singleUnProdcuerIds = this.singleUnProducerSelection.map(
        (producer: any) => producer.id
      );
      const checkSingleExistsId = singleUnProdcuerIds.indexOf(producer.id);
      if (checkSingleExistsId === -1) {
        Object.assign(producer, { count: 1 });
        this.singleUnProducerSelection.push(producer);
      } else {
        this.singleUnProducerSelection[checkSingleExistsId]["count"] += 1;
      }

      const filterSingleProdcuerId = this.singleUnProducerSelection.filter(
        (eachProducer: any) => eachProducer.id === producer.id
      );

      if (
        filterSingleProdcuerId[0]?.count &&
        filterSingleProdcuerId[0]?.count === 1
      ) {
        const selectedEntityNames = this.selectedProducer.map(
          (eachProducer) => eachProducer.entityName
        );
        const removedProducerIndex = selectedEntityNames.lastIndexOf(
          producer.entityName
        );
        const selectedHeader = this.selectedHeaders[removedProducerIndex]?.uid
          ? this.selectedHeaders[removedProducerIndex]?.uid
          : null;
        this.selectedUid = selectedHeader;
        if (removedProducerIndex >= 0) {
          if (
            this.selectedHeaders[removedProducerIndex]?.brandLength?.length >= 1
          ) {
            delete this.selectedHeaders[removedProducerIndex]["brandLength"];
            delete this.selectedHeaders[removedProducerIndex]["brandSelection"];
          }
          if (selectedHeader) {
            this.producerBrand[this.selectedUid] = null;
          }
          this.stringifySelectedProducer.splice(removedProducerIndex, 1);
          this.selectedProducer.splice(removedProducerIndex, 1);
          this.singleProducerSelection = [];
        }
      }
    }
    this.updateBrandList();
    this.removeEntityFromEntities(producer);
    this.validateProdcuerActiveState(producer);
    if (this.selectedHeaders.length === 0) {
      this.brandExists = false;
    }
    this.expandSubthemes();
  }

  removeEntityFromEntities(producer) {
    this.themesList.map((theme) => {
      if (theme?.disclosures) {
        theme.disclosures.map((disclosure) => {
          if (disclosure?.entities?.length) {
            const entitiesUids = disclosure.entities?.map(
              (entity) => entity.entity.uid
            );
            const identifyEntityIndex = entitiesUids?.indexOf(producer.uid);
            if (identifyEntityIndex !== -1) {
              disclosure.entities.splice(identifyEntityIndex, 1);
            }
          }
        });
      }
    });
    this.selectedUid = null;
  }

  brandSearchFn(term: string, brand: any) {
    return brand.dispayText.toLowerCase().indexOf(term.toLowerCase()) > -1;
  }

  showProducerDialog() {
    this.producerDisplayModal = true;
    this.modalProducer = null;
    const searchTerm = {
      term: "",
      entityName: "",
    };
    this.producerSearch(searchTerm);
  }

  validateProdcuerActiveState(producer: any) {
    if (this.selectedProducer?.length) {
      const producerIds = this.selectedProducer.map(
        (prodcer: any) => prodcer.id
      );
      return producerIds.includes(producer.id);
    } else {
      return false;
    }
  }

  confirmToDisplay() {
    const headerIds = this.selectedHeaders.map((header) => header.uid);
    this.selectedProducer.forEach((producer) => {
      if (!headerIds.includes(producer.uid)) {
        this.selectedHeaders.push(producer);
      }
    });
    this.updateBrandList();
    this.singleProducerSelection = [];
    this.singleUnProducerSelection = [];
    this.stringifySelectedProducer = [];
    this.selectedProducer = [];
    this.brandExists = true;
  }

  cancelToUnSelect() {
    this.singleProducerSelection = [];
    this.singleUnProducerSelection = [];
    this.stringifySelectedProducer = [];
    this.selectedProducer = [];
  }

  subGroupText(disclosureData: any) {
    this.subGroupdescription = disclosureData;
    this.displaySubGroupDescription = true;
  }

  changeProducerAndBrand(producer, header, headerIndex) {
    if (producer) {
      let selectedBrandsList = [];
      let brandObj = {};
      this.selectedHeaders.forEach((headerData, index) => {
        if (
          headerData.entityName.toLowerCase() ===
            producer.masterProducerName.toLowerCase() &&
          headerData.uid === header.uid
        ) {
          brandObj = {
            brand: producer.brand,
            masterProducerName: producer.masterProducerName,
            dispayText: producer.dispayText,
            metal: producer.metal,
          };
          headerData.brandSelection = brandObj;
          headerData.headerIndex = index;
        }
        headerData.brandLength = headerData?.brandSelection
          ? Object.keys(headerData?.brandSelection)
          : [];
      });
      this.selectedHeaders.forEach((header) => {
        if (header.brandSelection) {
          selectedBrandsList.push(header.brandSelection.brand);
        }
      });
      if (selectedBrandsList?.length >= 2) {
        const entitySelectionStatus =
          selectedBrandsList.includes("Entity") &&
          selectedBrandsList.some((brand) => brand !== "Entity");

        const sameBrandObj = {
          brand: header.brandSelection.brand?.toLowerCase(),
          metal: header.brandSelection?.metal?.toLowerCase(),
          masterProducerName:
            header.brandSelection.masterProducerName?.toLowerCase(),
        };

        let sameProducerBrandComparison = {
          status: false,
          count: 0,
        };
        this.selectedHeaders.forEach((headerDataWrapper) => {
          const headerDataObj = {
            brand: headerDataWrapper.brandSelection?.brand?.toLowerCase(),
            metal: headerDataWrapper.brandSelection?.metal?.toLowerCase(),
            masterProducerName: headerDataWrapper.entityName?.toLowerCase(),
          };
          if (JSON.stringify(sameBrandObj) == JSON.stringify(headerDataObj)) {
            sameProducerBrandComparison.status = true;
            sameProducerBrandComparison.count += 1;
          }
        });

        const sameProducerBrandValidation =
          sameProducerBrandComparison.status &&
          sameProducerBrandComparison.count >= 2;

        if (entitySelectionStatus || sameProducerBrandValidation) {
          if (entitySelectionStatus) {
            this.coreService.showMessage({
              key: "tc",
              severity: "error",
              summary: "Error",
              detail: "Can't select combination of brand and entity",
            });
          }
          if (sameProducerBrandValidation) {
            this.coreService.showMessage({
              key: "tc",
              severity: "error",
              summary: "Error",
              detail: "Can't compare same brand of same producer",
            });
          }
          setTimeout(() => {
            const selectedHeader = this.selectedHeaders[headerIndex];
            this.producerBrand[header.uid] = null;
            selectedHeader["brandSelection"] = null;
            selectedHeader["brandLength"] = [];
            sameProducerBrandComparison.status = false;
            sameProducerBrandComparison.count = 0;
          }, 100);
        } else {
          this.disclosuresAPI(producer, header, headerIndex);
        }
      } else {
        this.disclosuresAPI(producer, header, headerIndex);
      }
      this.brandExists = true;
      this.expandSubthemes();
    }
  }

  expandSubthemes() {
    this.themesList.forEach((themeResponse) => {
      this.expandedThemeRows = Object.assign({}, this.expandedThemeRows);
      themeResponse.viewDetails = this.brandSelectionValidation()
        ? true
        : false;
    });
  }

  brandSelectionValidation() {
    return this.selectedHeaders.some((header) => header.brandSelection);
  }

  setCategoryLevel(selectedCategory) {
    if (selectedCategory.includes("brand")) {
      return "brand";
    } else if (selectedCategory === "entity") {
      return "entity";
    } else {
      return "record";
    }
  }

  disclosuresAPI(producer, header, headerIndex) {
    const requestObj = {
      masterProducer: producer.masterProducerName,
      brand: producer.brand,
      metal: producer.metal,
    };
    if (producer.dispayText === "Entity") {
      delete requestObj.brand;
    }
    this.sustainabilityDisclosurePublicService
      .getDisclosureDataAgainstProducerBrand(requestObj)
      .subscribe((brandDisclosures) => {
        this.themesList.forEach((theme) => {
          brandDisclosures.forEach((brandDisclosure) => {
            if (!brandDisclosure.entity) {
              brandDisclosure.entity = {
                entityName: requestObj.masterProducer,
              };
            }
            if (
              theme.id === brandDisclosure.themeId.id &&
              theme.themeName === brandDisclosure.themeId.themeName &&
              theme.subThemeName === brandDisclosure.themeId.subThemeName
            ) {
              theme.disclosures.forEach((themeDisclosures) => {
                const checkEquality =
                  themeDisclosures.id === brandDisclosure.disclosureId.id;
                if (checkEquality) {
                  const headerUid = this.selectedHeaders[headerIndex]["uid"];
                  const entityUids = themeDisclosures.entities.map(
                    (entity) => entity.entity.uid
                  );
                  const themeAttachLevel = themeDisclosures.attachLevel
                    ? themeDisclosures.attachLevel.split(",")
                    : [];
                  const brandMetals = brandDisclosure.disclosureId.metal
                    ? brandDisclosure.disclosureId.metal.split("###")
                    : [];
                  const entityIndex = entityUids.indexOf(headerUid);
                  const producerObj = {
                    brandId: brandDisclosure.id,
                    proofDocument: brandDisclosure.proofDocument,
                    proofText: brandDisclosure.proofText,
                    proofUrl: brandDisclosure.proofUrl,
                    validityDateFrom: brandDisclosure.validityDateFrom,
                    validityDateFromRaw: brandDisclosure.validityDateFromRaw,
                    validityDateTo: brandDisclosure.validityDateTo,
                    validityDateToRaw: brandDisclosure.validityDateToRaw,
                    selfDisclosure: brandDisclosure.disclosureId.selfDisclosure,
                    type: brandDisclosure.disclosureId.type
                      ? brandDisclosure.disclosureId.type.toLowerCase()
                      : null,
                    proofPoint: brandDisclosure.disclosureId.proofPoint,
                    url: brandDisclosure.disclosureId.url,
                    metricLevel: brandDisclosure.disclosureId.metricLevel,
                    metricPercentage: brandDisclosure.metricPercentage,
                    entity: Object.assign(brandDisclosure.entity, {
                      uid: this.selectedHeaders[headerIndex]["uid"],
                    }),
                    metal: this.metalMatch(
                      brandMetals,
                      producer,
                      header,
                      themeAttachLevel
                    ),
                  };

                  entityIndex === -1
                    ? themeDisclosures.entities.push(producerObj)
                    : themeDisclosures.entities.splice(
                        entityIndex,
                        1,
                        producerObj
                      );
                }
              });
            }
          });
        });
      });
  }

  metalMatch(brandMetals, producer, header, themeAttachLevel) {
    const selectedCategory = this.setCategoryLevel(
      producer.dispayText.toLowerCase()
    );
    if (producer.brand.toLowerCase() !== "entity") {
      return this.returnText(
        brandMetals.includes(producer.metal),
        themeAttachLevel,
        selectedCategory
      );
    } else {
      let metalMatchStatus = false;
      let intersectionMetals = [];
      this.selectedHeaders.forEach((eachProducer) => {
        if (eachProducer.id === header.id) {
          const metalsList = [
            ...new Set(header.brands.map((producer) => producer.metal)),
          ];
          intersectionMetals = _.intersection(metalsList, brandMetals);
        }
      });
      metalMatchStatus = intersectionMetals.length >= 1 ? true : false;
      return this.returnText(
        metalMatchStatus,
        themeAttachLevel,
        selectedCategory
      );
    }
  }

  returnText(metalMatchStatus, themeAttachLevel, selectedCategory) {
    const metricLevelTextTransform = themeAttachLevel.map((metricLevel) =>
      metricLevel.toLowerCase()
    );
    return metalMatchStatus &&
      metricLevelTextTransform.includes(selectedCategory)
      ? "This producer has not uploaded any data against this disclosure."
      : "It is not possible for this producer to achieve this specific standard.";
  }

  redirectToUrl(event, url) {
    event.preventDefault();
    if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url;
    }
    return window.open(url);
  }

  downloadDocument(rowData) {
    window.open(
      `${environment.apiUrl}/home-service/publicView/document/download?uuid=${rowData.proofDocument.uuid}&name=${rowData.proofDocument.fileName}`
    );
  }

  scrollDownThemesList() {
    if (this.table) {
      setTimeout(() => {
        const tableEl = this.table.getBlockableElement();
        const tableWrapper: Partial<HTMLElement> =
          tableEl.getElementsByClassName("p-datatable-wrapper")[0];
        tableWrapper.scrollBy({
          top: 450,
          behavior: "smooth",
        });
      });
    }
  }

  infoSideBySide() {
    this.infoTriggers = true;
  }

  dailogClose() {
    this.infoTriggers = false;
  }

  ngOnDestroy(): void {
    this.coreService.countDown.next(null);
  }
}

export enum ThemeNameEn {
  ENVIRONMENTAL = "Environmental",
  GOVERNANCE = "Governance",
  MULTIDIMENSIONAL = "Multi-dimensional",
  SOCIAL = "Social",
}
