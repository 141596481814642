<app-table-filter [fields]="filters" [disclosures]="disclosures" (filterChanged)="onFilterChanged($event)"
  [parent]="parent || aprovedParent" [metalFilter]="metalFilter"></app-table-filter>
<div *ngIf="!parent" [ngClass]="{ 'container-fluid': !parent }" class="mt-3">
  <div class="table-top-content">
    <div class="action-buttons">
      <button id="download-approved-records" *ngIf="user.export && totalElements > 0"
        class="btn btn-primary mr-2 export-to-excel" (click)="downloadReport()">
        Download Report
      </button>
      <button id="delete-approved-records" *ngIf="user.platformAdmin" class="btn btn-primary mr-2"
        (click)="deleteRecordsConfirm()">
        Delete Records
      </button>
    </div>
    <div class="record-note text ml-2">
      The table below displays all the approved records created by your entity.
    </div>
  </div>
</div>
<div *ngIf="
    selectedApprovedRecords.length === results?.length &&
    totalElements > pagination.size &&
    !filterApplied
  " [ngClass]="{'container-fluid': parent !== 'append'}" class="mt-3">
  <div *ngIf="!allRecords" class="all-records">
    All {{ pagination.size }} records on this page are selected.
  </div>
</div>
<div *ngIf="
    selectedApprovedRecords.length === results?.length &&
    totalElements > pagination.size &&
    filterApplied
  " class="container-fluid mt-3">
  <div *ngIf="!allRecords" class="all-records">
    All {{ pagination.size }} records on this page are selected.
    <span role="link" class="link-select-all" (click)="selectAllRecords($event)">Select all
      {{ totalElements | number: "1." }}
      records</span>
  </div>
  <div *ngIf="allRecords" class="all-records">
    All {{ totalElements | number: "1." }} records are selected.
    <span class="link-select-all" role="link" (click)="clearSelection($event)">Clear Selection</span>
  </div>
</div>
<div [ngClass]="{ 'container-fluid': !parent }" class="mt-3 container-fluid">
  <div [ngClass]="{ row: !parent }" class="mb-4">
    <div class="box mr-5 col-12 mb-5">
      <app-table-view [data]="approvedRecords" [config]="tableConfig" [firstItemIndex]="firstItemIndex"
        scrollHeight="calc(100vh - 250px)" [selections]="selectedApprovedRecords"
        (checkboxSelected)="onRowSelect($event)" (paginationChanged)="onPaginationChanged($event)">
      </app-table-view>
    </div>
  </div>
</div>
<ng-template #additionalDocumentsTemplate let-rowData>
  <span (click)="viewAddionalCoaDocs($event, rowData)" (keyup)="viewAddionalCoaDocs($event, rowData)"
    *ngIf="rowData.status !== 'DUPLICATE_APPROVED'" class="link" role="button" tabindex="0">View</span>
</ng-template>

<ng-template #actionTemplate let-rowData>
  <div>
    <ng-select wcag-label [items]="actionItems" placeholder="Actions" bindValue="id" bindLabel="name"
      (change)="triggerActionController($event, rowData)" [clearable]="false" appendTo="body"
      [(ngModel)]="actionModel[rowData.id]" *ngIf="rowData.status !== 'DUPLICATE_APPROVED'"
      [inputAttrs]="{ 'aria-label': 'Actions' }"></ng-select>
  </div>
</ng-template>

<ng-template #ValueAddThemesTemplate let-rowData>
  <span class="link" role="button" tabindex="0"
    *ngIf="rowData.themesAttached && rowData.status !== 'DUPLICATE_APPROVED'"
    (click)="viewValueAddThemes($event, rowData)" (keyup)="viewValueAddThemes($event, rowData)">Details</span>
  <span *ngIf="!rowData.themesAttached">NA</span>
</ng-template>

<ng-template #passportTemplate let-data>
  {{ data.passportId }}
</ng-template>

<app-review *ngIf="reviewRecordDialog" [reviewRecordDialog]="reviewRecordDialog" [reviewData]="reviewData"
  [reviewStatus]="reviewStatus" type="approved" (hideReviewRecord)="hideReviewRecord()"
  (reviewSaved)="onReviewSave($event)" (editComplete)="onEditComplete($event)"></app-review>
<app-additional-documents *ngIf="viewAdditionalDocsDialog" [viewAdditionalDocsDialog]="viewAdditionalDocsDialog"
  [documentId]="additionalDocumentId" [coaReference]="coaReference"
  (additionalDocsDialogHide)="hideAdditionalDocsDialog()"></app-additional-documents>
<app-append *ngIf="appendDialog" [appendDialog]="appendDialog" [selectedApprovedRecords]="selectedApprovedRecords"
  [allRecords]="allRecords" [filters]="appliedFilters" (hideAppendDialog)="onHideAppendDialog($event)"></app-append>
<app-value-add-themes *ngIf="valueAddThemeDialog" [valueAddThemeDialog]="valueAddThemeDialog" [appendId]="appendId"
  (valueAdddThemeDialogHide)="onValueAddThemeDialogHide()"></app-value-add-themes>
<p-confirmDialog [style]="{ width: '27vw' }"></p-confirmDialog>