<div [ngClass]="{
    'container-fluid': !(
      parent == 'toDoList' ||
      parent == 'append' ||
      parent == 'bulkSearchCoa' ||
      parent == 'producerProfile' ||
      parent == 'offWarrantDashboard'
    )
  }">
  <p-fieldset [legend]="filterTitle" [toggleable]="true">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <ng-container *ngFor="let field of sortedFieldsList">
            <ng-container *ngIf="field.fieldType === 'customButton'">
              <div class="col-md-3 col-sm-12 mb-3">
                <button id="custom-btn" type="button" class="btn btn-secondary mr-2" (click)="customButton()"
                  [attr.disabled]="field.data.disabled ? '' : null">
                  {{ field.data.label }}
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="field.fieldType === 'searchBox'">
              <div class="col-md-3 col-sm-12 mb-3 p-0" [class.grow-last]="field.last">
                <div class="col-12 inner-addon right-addon">
                  <i class="glyphicon glyphicon-search"></i>
                  <label for="search" class="wcag-visuallyhidden">Search</label>
                  <input #searchTextBox [(ngModel)]="filterRequest.freeText" type="text" class="form-control mr-2"
                    id="search" placeholder="Search" [pTooltip]="searchBoxPlaceHolder" tooltipPosition="bottom"
                    (keyup)="onSearchFieldKeyUp($event)" cy-id="search" />
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="field.fieldType === 'disclosuresFilter'">
              <ng-container *ngIf="user">
                <div *ngIf="user.stakeHolder === 'PRODUCER'"
                  [ngClass]="{'col-md-4': !user?.platformAdmin && parent === 'myRecords', 'col-md-8': !user?.platformAdmin && parent === 'approvedRecord', 'col-md-3': user?.platformAdmin || parent !== 'myRecords' || parent !== 'approvedRecord'}"
                  class="col-sm-12 mb-3">
                  <ng-select
                    [ngClass]="{'w-74': !user?.platformAdmin && parent === 'myRecords', 'w-35': !user.platformAdmin && parent === 'approvedRecord'}"
                    title="Value-Add Themes and Disclosures" wcag-label [items]="disclosures"
                    [bindLabel]="ngSelectBindLabel" [groupBy]="ngSelectGroupBy" [multiple]="true"
                    [selectableGroup]="true" placeholder="Value-Add Themes and Disclosures"
                    [(ngModel)]="filterRequest['disclosure']" (change)="searchServiceManager('disclosure')"
                    [inputAttrs]="{
                    'aria-label': 'Value Add Themes and Disclosures', 'id': 'valueAddTheme', 'cy-id': 'valueAddTheme'
                  }" [searchFn]="ngSearchFn">
                    <ng-template ng-optgroup-tmp let-item="item">
                      {{ item.themeSubTheme || "Unnamed group" }}
                    </ng-template>
                  </ng-select>
                </div>
                <div *ngIf="user.stakeHolder !== 'PRODUCER' && parent === 'myRecords'" class="col-md-3 col-sm-12 mb-3">
                  <ng-select title="Value-Add Themes and Disclosures" wcag-label [items]="disclosures"
                    [bindLabel]="ngSelectBindLabel" [groupBy]="ngSelectGroupBy" [multiple]="true"
                    [selectableGroup]="true" placeholder="Value-Add Themes and Disclosures"
                    [(ngModel)]="filterRequest['disclosure']" (change)="searchServiceManager('disclosure')"
                    [inputAttrs]="{
                    'aria-label': 'Value Add Themes and Disclosures', 'id': 'valueAddTheme', 'cy-id': 'valueAddTheme'
                  }" [searchFn]="ngSearchFn">
                    <ng-template ng-optgroup-tmp let-item="item">
                      {{ item.themeSubTheme || "Unnamed group" }}
                    </ng-template>
                  </ng-select>
                </div>

                <div *ngIf="user.stakeHolder !== 'PRODUCER' && !user.platformAdmin && parent === 'approvedRecord'"
                  class="col-md-8 col-sm-12 mb-3">
                  <ng-select class="w-35" title="Value-Add Themes and Disclosures" wcag-label [items]="disclosures"
                    [bindLabel]="ngSelectBindLabel" [groupBy]="ngSelectGroupBy" [multiple]="true"
                    [selectableGroup]="true" placeholder="Value-Add Themes and Disclosures"
                    [(ngModel)]="filterRequest['disclosure']" (change)="searchServiceManager('disclosure')"
                    [inputAttrs]="{
                    'aria-label': 'Value Add Themes and Disclosures', 'id': 'valueAddTheme', 'cy-id': 'valueAddTheme'
                  }" [searchFn]="ngSearchFn">
                    <ng-template ng-optgroup-tmp let-item="item">
                      {{ item.themeSubTheme || "Unnamed group" }}
                    </ng-template>
                  </ng-select>
                </div>

                <div *ngIf="user.platformAdmin && parent !== 'myRecords'" class="col-md-3 col-sm-12 mb-3">
                  <ng-select title="Value-Add Themes and Disclosures" wcag-label [items]="disclosures"
                    [bindLabel]="ngSelectBindLabel" [groupBy]="ngSelectGroupBy" [multiple]="true"
                    [selectableGroup]="true" placeholder="Value-Add Themes and Disclosures"
                    [(ngModel)]="filterRequest['disclosure']" (change)="searchServiceManager('disclosure')"
                    [inputAttrs]="{
                    'aria-label': 'Value Add Themes and Disclosures', 'id': 'valueAddTheme', 'cy-id': 'valueAddTheme'
                  }" [searchFn]="ngSearchFn">
                    <ng-template ng-optgroup-tmp let-item="item">
                      {{ item.themeSubTheme || "Unnamed group" }}
                    </ng-template>
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="!user">
                <div class="col-md-3 col-sm-12 mb-3">
                  <ng-select title="Value-Add Themes and Disclosures" wcag-label [items]="disclosures"
                    [bindLabel]="ngSelectBindLabel" [groupBy]="ngSelectGroupBy" [multiple]="true"
                    [selectableGroup]="true" placeholder="Value-Add Themes and Disclosures"
                    [(ngModel)]="filterRequest['disclosure']" (change)="searchServiceManager('disclosure')"
                    [inputAttrs]="{
                      'aria-label': 'Value Add Themes and Disclosures', 'id': 'valueAddTheme', 'cy-id': 'valueAddTheme'
                    }" [searchFn]="ngSearchFn">
                    <ng-template ng-optgroup-tmp let-item="item">
                      {{ item.themeSubTheme || "Unnamed group" }}
                    </ng-template>
                  </ng-select>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="field.fieldType === 'filterListModel'">
              <div class="col-md-3 col-sm-12 mb-3">
                <ng-select wcag-label [placeholder]="field.data.placeholder"
                  [items]="filterFields[field.data.itemsType]" [multiple]="field.data.multiple"
                  [closeOnSelect]="field.data.closeOnSelect" bindLabel="name" bindValue="id"
                  [(ngModel)]="filterRequest[field.data.ngModel]" (change)="
                    searchServiceManager(field.data.ngModel, 'dropdown')
                  " [inputAttrs]="{
                    'aria-label': field.data.placeholder, 'cy-id': field.data.itemsType
                  }" [notFoundText]="field.data.notFoundText || 'No items found'" appendTo="body">
                </ng-select>
              </div>
            </ng-container>
            <ng-container *ngIf="field.fieldType === 'passportIdFilter'">
              <div class="col-md-3 col-sm-12 mb-3 p-0" [class.grow-last]="field.last">
                <div class="col-12 mb-1 inner-addon right-addon">
                  <i class="glyphicon glyphicon-search"></i>
                  <label for="passportId" class="wcag-visuallyhidden">Passport Id</label>
                  <textarea wcag-label #searchTextBox [(ngModel)]="filterRequest.LME_PASSPORT_ID"
                    class="form-control mr-2" id="passportId" placeholder="{{
                      fields.passportIdSearchPlaceHolder ||
                        'Enter LMEpassport ID'
                    }}" (keyup)="onSearchFieldKeyUp($event)" rows="2" pTooltip="{{
                      fields.passportIdSearchToolTipText ||
                        'Example: Brand|PCR, Brand|PCR, Brand|PCR'
                    }}" tooltipPosition="bottom" cy-id="passportId"></textarea>
                </div>
                <div *ngIf="parent !== 'bulkSearchCoa'; else BULK_SEARCH_TEXT_PASSPORT" class="comma-seperated col-12">
                  New rows or comma separated for multiple IDs.
                </div>
                <ng-template #BULK_SEARCH_TEXT_PASSPORT>
                  <div class="comma-seperated mb-2 col-12">Comma separated for multiple IDs.</div>
                </ng-template>
              </div>
            </ng-container>

            <ng-container *ngIf="field.fieldType === 'coaBulkSearch'">
              <div class="col-md-3 col-sm-12 mb-3 p-0" [class.grow-last]="field.last">
                <div class="col-12 mb-1 inner-addon right-addon">
                  <i class="glyphicon glyphicon-search"></i>
                  <label for="coaReferenceNo" class="wcag-visuallyhidden">COA Reference Number</label>
                  <textarea id="coaReferenceNo" cy-id="coaReferenceNo" wcag-label #searchTextBox
                    [(ngModel)]="filterRequest.COA_REFERENCE_NUMBER" class="form-control mr-2" id="coaRefenceNumber"
                    placeholder="{{
                      fields.coaBulkSearchPlaceHolder ||
                        'Enter COA Reference No.'
                    }}" (keyup)="onSearchFieldKeyUp($event)" rows="2"></textarea>
                </div>

                <div class="comma-seperated col-12 mb-2">
                  New rows or comma separated for multiple IDs.
                </div>
                <!-- <ng-template #BULK_SEARCH_TEXT_COA>
                  <div class="comma-seperated mb-2 col-12">Comma separated for multiple IDs.</div>
                </ng-template> -->
              </div>
            </ng-container>
            <ng-container *ngIf="field.fieldType === 'searchButtons'">
              <div class="col-md-3 col-sm-12 mb-3 p-0" [class.grow-last]="field.last">
                <div class="col-5 float-left text-nowrap">
                  <button id="query" type="button" id="searchBtn" class="btn btn-secondary mr-2" *ngIf="
                      !field.options || field.options?.clearButtonOnly === false
                    " (click)="searchServiceManager()">
                    Search
                  </button>
                  <button type="button" id="filter-clear-btn" id="clear" class="btn btn-secondary mr-2"
                    (click)="clearSearch($event)">
                    Clear
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="field.fieldType === 'dateRange'">
              <div class="col-md-3 col-sm-12" [class.grow-last]="field.last">
                <app-date-range [dateFrom]="filterRequest.dateFrom" [dateTo]="filterRequest.dateTo"
                  [dateRangeLabelInput]="fields.dateRangeLabel" id="dateRange"
                  (datesChanged)="onDateRangeChanged($event)">
                </app-date-range>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </p-fieldset>
</div>