import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { LookupService } from "@core/services/lookup.service";
import { EntityService } from "@module/platform-admin/services/entity.service";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { email } from "app/app.const";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-register-user",
  templateUrl: "./register-user.component.html",
  styleUrls: ["./register-user.component.scss"],
})
export class RegisterUserComponent implements OnInit {
  email: string;
  stakeHolder: any;
  entities: any;
  constructor(
    private lookUpService: LookupService,
    private entityService: EntityService
  ) {}

  form = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[];
  entitiesSubj = new BehaviorSubject<any>(null);
  userTypeDialog = false;
  selectedUserType: string;

  private _destroy$ = new Subject<any>();

  ngOnInit(): void {
    this.email = email;
    this.initForm();
  }

  onStakeHolderChange() {
    //this.form.get("country").setValue("");
    this.form.get("entityName").setValue(null);
    this.stakeHolder = this.model.stakeHolderId.stakeholderId;
    if (
      this.stakeHolder === "PRODUCER" ||
      this.stakeHolder === "LME_MEMBER" ||
      this.stakeHolder === "WAREHOUSE_OPERATOR"
    ) {
      this.fields[0].fieldGroup[1].hideExpression = true;
      this.fields[0].fieldGroup[2].hideExpression = false;
      this.entityService
        .getCompaniesPublic(this.model.stakeHolderId.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          this.entities = res;
          this.entitiesSubj.next(res);
        });
    } else {
      this.fields[0].fieldGroup[2].hideExpression = true;
      this.fields[0].fieldGroup[1].hideExpression = false;
    }
  }

  submit() {
    if (this.form.valid) {
      this.userTypeDialog = true;
    }
  }

  registerUser() {}

  initForm() {
    this.fields = [
      {
        fieldGroupClassName: "row",
        fieldGroup: [
          {
            className: "col-md-6 col-sm-12",
            type: "dict-select-template",
            key: "stakeHolderId",
            wrappers: ["form-field"],
            templateOptions: {
              label: "Stakeholder Type",
              required: true,
              multiple: false,
              source: this.lookUpService.getStakeHoldersPublic(),
              bindLabel: "name",
              bindValue: "id",
              change: (event) => this.onStakeHolderChange(),
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "entityName",
            hideExpression: true,
            templateOptions: {
              label: "Company Legal Name",
              required: true,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "dict-select",
            key: "entityName",
            wrappers: ["form-field"],
            templateOptions: {
              label: "Company Legal Name",
              required: true,
              multiple: false,
              source: this.entitiesSubj,
              bindLabel: "entityName",
            },
            expressionProperties: {
              "templateOptions.disabled": "!model.stakeHolderId",
            },
          },
        ],
      },
      {
        className: "",
        template: `<h4 class="col-12 badge-company mt-3 mb-3">Contact Information</h4>`,
      },
      {
        fieldGroupClassName: "row",
        key: "userRegistration",
        wrappers: ["app-formly-fieldset"],
        templateOptions: {
          label: "Main Contact",
        },
        fieldGroup: [
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "firstName",
            templateOptions: {
              label: "First Name",
              maxLength: 100,
              required: true,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "lastName",
            templateOptions: {
              label: "Last Name",
              maxLength: 100,
              required: true,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "userName",
            templateOptions: {
              label: "Email/Username",
              required: true,
              pattern:
                /^(([^!#^<>()[\]\\.,;:\s@\"]+(\.[^!#^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid email address`,
              },
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "phone",
            templateOptions: {
              type: "text",
              min: 0,
              label: "Phone",
              pattern: /^\d{10}$/,
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) =>
                  `"${field.formControl.value}" is not a valid phone number`,
              },
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "dict-select",
            key: "country",
            templateOptions: {
              label: "Country/Territory",
              required: true,
              source: this.lookUpService.getCountries(),
              bindLabel: "name",
              multiple: false,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "city",
            templateOptions: {
              label: "City",
              required: true,
            },
          },
          {
            className: "col-md-6 col-sm-12",
            type: "input",
            key: "jobTitle",
            templateOptions: {
              label: "Job Title",
              required: true,
            },
          },
        ],
      },
    ];
  }
}

function getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
  for (let i = 0, len = fields.length; i < len; i++) {
    const f = fields[i];
    if (f.key === key) {
      return f;
    }

    if (f.fieldGroup && !f.key) {
      const cf = getField(key, f.fieldGroup);
      if (cf) {
        return cf;
      }
    }
  }
}
