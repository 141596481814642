<p-dialog header="Upload Additional Documents" [modal]="true" [style]="{ width: '50vw' }"
  [(visible)]="addtionalDocsDialog" (onHide)="onAdditionalDocsDialogHide($event)">
  <div *ngFor="let additionalFile of additionalFiles; let i = index" class="form-group mt-3">
    <div class="row">
      <div class="col-10">
        <div class="input-group input-file">
          <span class="input-group-btn">
            <button id="select-document-type" class="btn btn-primary btn-upload"
              (click)="selectAdditionalFile($event, i)" (keypress)="selectAdditionalFile($event, i)">
              Browse
            </button>
            <label class="wcag-visuallyhidden" for="{{ i }}">Choose a File</label>
            <input #cmp data-cy="file-input" (change)="addAdditionalFile($event, i)" id="{{ i }}" class="hidden"
              type="file" placeholder="Choose a File" />
          </span>
          <input tabindex="-1" id="input-file" type="text" class="form-control" value="{{ additionalFile?.file?.name }}"
            readonly placeholder="Choose a File" />
        </div>
      </div>
      <div class="col-2">
        <button class="btn btn-primary" *ngIf="i >= 1 && progress !== 100" (click)="removeAdditonalDoc(i)">
          Delete
        </button>
      </div>
    </div>
  </div>
  <div class="mb-3 mt-2" *ngIf="queueInProgress">
    Queue progress: {{ progress }}%
    <div class="progress" style="">
      <div class="progress-bar" role="progressbar" [ngStyle]="{ width: progress + '%' }"></div>
    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary" *ngIf="progress !== 100" (click)="addRow()">
      Add
    </button>
    <button class="btn btn-primary" *ngIf="progress !== 100" (click)="upload()">
      Upload
    </button>
    <button class="btn btn-primary" *ngIf="progress === 100" (click)="onAdditionalDocsDialogHide(true)">
      Ok
    </button>
  </p-footer>
</p-dialog>