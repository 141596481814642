import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { IUser, IUsers } from "@core/models/api.model";
import { CoreService } from "@core/services/core.service";
import { CreateImportService } from "@module/create-import/services/create-import.service";
import { EntityService } from "@module/platform-admin/services/entity.service";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-assign-coa",
  templateUrl: "./assign-coa.component.html",
  styleUrls: ["./assign-coa.component.scss"],
})
export class AssignCoaComponent implements OnInit, OnDestroy {
  @Input() assignDialog: boolean;
  @Input() selectedReviewRecords: any;
  @Input() data: any;

  @Output() hideAssignDialog = new EventEmitter<any>();
  @Output() assigned = new EventEmitter<any>();

  private _destroy$ = new Subject<any>();
  users: IUsers[];
  assignedUser: any;
  user: IUser;

  constructor(
    private entityService: EntityService,
    private coreService: CoreService,
    private createImportService: CreateImportService
  ) {}

  ngOnInit() {
    this.user = this.coreService.getLoggedInuserDetails();
    let serviceMethod = this.user.platformAdmin
      ? "getUsersLisiting"
      : "getActiveUsersIndividual";
    this.entityService[serviceMethod](true)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        res.forEach((user, index) => {
          user["name"] = `${user.firstName} ${user.lastName}`;
        });

        let userIndex;
        if (this.user.platformAdmin || this.user.entityAdmin) {
          userIndex = res.findIndex((user) => user.id === this.user.id);
        } else {
          userIndex = res.findIndex((user) => user.userId === this.user.id);
        }

        if (userIndex !== -1) {
          res.splice(userIndex, 1);
        }

        this.users = res.filter((user) => !user.reviewRequired);
        this.users = _.orderBy(
          this.users,
          [(user) => user.firstName.toLowerCase()],
          "asc"
        );
      });
  }

  onAssignDialogHide(event) {
    this.hideAssignDialog.emit();
  }

  assign() {
    // let records = this.selectedReviewRecords.map((record) => record.id);
    let records = [];
    let payload = {
      allRecords: true,
      documentId: this.data.id,
      uuid: this.data.uuid,
      userId: this.assignedUser.id || this.assignedUser.userId,
      records,
      coaRef: this.data.coaReference,
      coaDocument: this.data.coaDocument,
    };

    this.createImportService
      .assignRecord(payload)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (res) => {
          this.assigned.emit();
        },
        (err) => {
          let errorMessage = err.error.message.replace(
            "com.markit.cmtk.exception.ServiceException:",
            ""
          );
          this.coreService.showMessage({
            key: "tc",
            severity: "error",
            summary: "Error",
            detail: errorMessage,
          });
        }
      );
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
