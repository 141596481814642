import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-field-input",
  template: `
    <p-calendar
      *ngIf="to.range"
      [formControl]="formControl"
      [formlyAttributes]="field"
      dateFormat="dd M yy"
      appendTo="body"
      [selectionMode]="to.range"
      [yearNavigator]="true"
      yearRange="2000:2030"
      [monthNavigator]="true"
      (onSelect)="to.onSelect()"
    ></p-calendar>
    <p-calendar
      *ngIf="!to.range"
      [formControl]="formControl"
      [formlyAttributes]="field"
      dateFormat="dd M yy"
      appendTo="body"
      [yearNavigator]="true"
      yearRange="2000:2030"
      [monthNavigator]="true"
      (onSelect)="to.onSelect()"
    ></p-calendar>
  `,
})
export class FormlyCalendarInput extends FieldType {}
