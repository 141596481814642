import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { CoreService } from "@core/services/core.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ProducerProfileViewGuard implements CanActivate {
  constructor(private coreService: CoreService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const stats = this.coreService.getProducerProfile();
    if (stats) {
      const publicViewEnabledSplit = stats.publicViewTabsEnabled.split(",");
      this.coreService.navigateToActiveTab(publicViewEnabledSplit);
    } else {
      return this.coreService.getStats().pipe(
        map((user) => {
          this.coreService.producerProfile = user;
          return this.coreService.checkProducerProfile();
        })
      );
    }
  }
}
