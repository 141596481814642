import { IUser } from "./api.model";

export class LmeCurrentUser {
  id?: number;
  firstName?: string;
  lastName?: string;
  city?: string;
  country?: string;
  userName?: string;
  partyId?: number;
  entityId?: number;
  entityName?: string;
  status?: string;
  platformAdmin?: boolean;
  entityAdmin?: boolean;
  write?: boolean;
  overwrite?: boolean;
  read?: boolean;
  append?: boolean;
  export?: boolean;
  stakeholderId?: number;
  stakeHolder?: string;
  stakeHolderName?: string;
  lastLogin: any;
  termsAccepted: any;
  reviewRequired?: boolean;
  redactAppend?: boolean;
  sustainability?: boolean;
  coa?: boolean;
  offWarrant?: boolean;
  locations?: any;

  constructor(userData: IUser) {
    if (userData) {
      this.id = userData.id;
      this.firstName = userData.firstName;
      this.lastName = userData.lastName;
      this.city = userData.city;
      this.country = userData.country;
      this.userName = userData.userName;
      this.partyId = userData.partyId;
      this.entityId = userData.entityId;
      this.entityName = userData.entityName;
      this.status = userData.status;
      this.platformAdmin = userData.platformAdmin;
      this.entityAdmin = userData.entityAdmin;
      this.write = userData.write;
      this.overwrite = userData.overwrite;
      this.read = userData.read;
      this.append = userData.append;
      this.export = userData.export;
      this.stakeholderId = userData.stakeholderId;
      this.stakeHolder = userData.stakeHolder;
      this.stakeHolderName = userData.stakeHolderName;
      this.lastLogin = userData.lastLogin;
      this.termsAccepted = userData.termsAccepted;
      this.reviewRequired = userData.reviewRequired;
      this.sustainability = userData.sustainability;
      this.coa = userData.coa;
      this.redactAppend = false;
      this.offWarrant = userData.offWarrant;
      this.locations = userData.locations;
    }
  }
}
