<!-- <nav class="navbar navbar-toggleable-md navbar-main navbar-light navbar-expand-md nav-container">
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
      data-target="#metricComparisonbarNavAltMarkup" aria-controls="metricComparisonbarNavAltMarkup" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="metricComparisonbarNavAltMarkup">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" *ngFor="let menuItem of menuItems">
          <a id="{{ menuItem.moduleLabel }}" [attr.cy-test]="menuItem.moduleLabel" class="nav-link" wcag-dummy-link-target
            [routerLink]="[menuItem.path]" routerLinkActive="active" href="#">{{ menuItem.moduleLabel }}
          </a>
        </li>
      </ul>
    </div>
  </nav> -->
  <router-outlet></router-outlet>