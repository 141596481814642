import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-field-custom",
  template: `
    <span class="mr-3 mb-3">
      Frequency
      <i
        class="fa fa-info-circle"
        pTooltip="Please choose the timeframe for receiving a notification before your appended disclosure expires. Multiple timeframes can be selected at once."
        tooltipPosition="top"
        aria-hidden="true"
      ></i>
      *
    </span>
  `,
})
export class CustomFieldComponent extends FieldType {}
