<div class="container-fluid">
  <p><a (click)="viewDisclosureAbout()" class="mt-5 link">How to operate the interactive sustainability taxonomy
      wheel<i class="ml-2 fa fa-info-circle" aria-hidden="true"></i></a></p>
  <p class="mt-2"><a (click)="additionalInfo()" class="link">Is there a certification or metric that you would like to
      add to the
      sustainability taxonomy wheel?<i class="ml-2 fa fa-info-circle" aria-hidden="true"></i></a></p>
  <div class="row no-gutters mb-4">
    <div class="col-md-8 col-12">
      <div class="chart-container" id="container" #chartContainer></div>
      <a target="_blank"
        href="https://www.lme.com/en/about/responsibility/sustainability/sustainability-disclosures-on-lmepassport"><img
          src="assets/img/LME_SYMBOL_POS.png" id="logoImg" width="200px" height="200px" alt="Disclosure Logo"></a>
    </div>
    <div *ngIf="subThemeClick" class="disc-details col-md-4 col-12">
      <h3 *ngIf="disclosures.length" [ngStyle]="{ 'backgroundColor': selectedBackgroundColor, 'color': selectedColor}"
        class="selected-theme-subtheme mb-2">
        {{ selectedThemeSubThemeName }}
      </h3>
      <ul *ngIf="disclosures.length; else NO_DISCLOSURE" id="table" class="disclosure-list">
        <li class="badge-1" *ngFor="let item of disclosures" [ngStyle]="{
            backgroundColor: item.backgroundColor
          }">
          <span class="disclosure-name" *ngIf="!item.url; else DISCLOSURE_URL">{{
            item.disclosureName
            }}</span>
          <ng-template #DISCLOSURE_URL>
            <a class="disclosure-url" (click)="redirectToUrl($event, item.url)" href="#" target="_blank">{{
              item.disclosureName }}</a>
          </ng-template>
          <p *ngIf="item.description" class="disclosure-descption">{{
            item?.description }}</p>
        </li>
      </ul>
      <ng-template #NO_DISCLOSURE>
        <h3 [ngStyle]="{ 'backgroundColor': selectedBackgroundColor, 'color': selectedColor}"
          class="selected-theme-subtheme mb-2">
          {{ selectedThemeSubThemeName }}
        </h3>
        <ul id="table" class="disclosure-list">
          <li class="disclosure-name no-disclosure">
            There are currently no certifications, metrics or standards under this sub-theme. If you would like to add
            one,
            please email <a [attr.href]="sustainabilityEmailLink" class="contact-us">{{sustainabilityEmail}}</a>. You do
            not
            need to be a producer to suggest a new certification, standard or metric to the taxonomy.</li>
        </ul>
      </ng-template>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p style="font-size: 0.8rem;">* In August 2021, the IAI published its "Good Practice for Calculation of Primary
        Aluminium and Precursor Product Carbon Footprints" (available for disclosure on LMEpassport). For future
        disclosures related to IAI emissions guidance, the LME encourages producers of LME aluminium brands to use this
        most recent guidance.<br><br>

        ** The CRU Emissions Analysis Tool provides independently assessed smelter emission ranges for scopes 1 & 2
        along with an additional scope 3 category related to purchased anodes.</p>
    </div>
  </div>
</div>

<p-dialog [modal]="true" header="How to operate the interactive sustainability taxonomy wheel"
  [(visible)]="disclosureAboutDialog" [style]="{ width: '60vw' }">
  Disclosures are split into following four main themes
  <ul>
    <li>Environmental</li>
    <li>Social</li>
    <li>Governance</li>
    <li>
      Multi-dimensional (encompasses multiple or all aspects of E,S and/or G)
    </li>
  </ul>
  Sub-themes encircle the main themes (E, S, G, or multi-dimensional) within the
  wheel:
  <ul>
    <li>
      Clicking on a sub-theme will open a dialogue box on the right side of the
      screen, enlisting the applicable certifications, metrics and/or standards
    </li>
    <li>
      Clicking on each certification, metric and/or standard will re-direct the
      user to the disclosure standard website
    </li>
  </ul>
</p-dialog>


<p-dialog [modal]="true" header="Is there a certification or metric that you would like to
add to the sustainability taxonomy wheel?" [(visible)]="additionalInfoDialog" [style]="{ width: '60vw' }">
  If you would like to add a standard, certification or metric not currently represented in the taxonomy wheel, please
  email <a [attr.href]="sustainabilityEmailLink" class="contact-us">{{sustainabilityEmail}}</a>

  <p class="mt-3">A full list of taxonomy categories against which you could have achieved standards, certifications or
    metrics is as follows:
  </p>
  <ul>
    <li><span class="bold">Environmental:</span> climate change, pollution mitigation, natural resources, biodiversity,
      circular economy</li>
    <li><span class="bold">Social:</span> diversity and inclusion, human rights, community impact, health and safety
    </li>
    <li><span class="bold">Governance:</span> bribery and corruption mitigation, corporate structure and transparency,
      risk management</li>
    <li><span class="bold">Multi-dimensional:</span> self-assessment framework, pan-ESG standards, miscellaneous
    </li>
  </ul>
</p-dialog>