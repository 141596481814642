import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { filter } from "rxjs";

declare const gtag: Function; // <------------Important: the declartion for gtag is required!
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "cmtk-lme";
  loadingDataImg: boolean = false;

  constructor(
    private router: Router,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    router.events.subscribe((event) => {
      this.navigationInterceptor(event);
    });

    /** START : Code to Track Page View using gtag.js */
    /* this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag("event", "page_view", {
          page_path: event.urlAfterRedirects,
        });
      }); */
    /** END : Code to Track Page View  using gtag.js */
  }

  navigationInterceptor(event: any): void {
    if (event instanceof NavigationStart) {
      this.loadingDataImg = true;
    }
    if (event instanceof NavigationEnd) {
      this.loadingDataImg = false;
    }

    if (event instanceof NavigationCancel) {
      this.loadingDataImg = false;
    }
    if (event instanceof NavigationError) {
      this.loadingDataImg = false;
    }
  }
}
