import { Component, OnDestroy, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "formly-field-dictionary-select-static",
  template: `
    <ng-select
      [items]="items"
      [placeholder]="placeholder"
      [formControl]="formControl"
      [bindLabel]="to.bindLabel"
      [loading]="isLoading"
      [attr.disabled]="to.disabled"
      [class]="to.class"
      [multiple]="to.multiple"
      (change)="to.change && to.change(field, $event)"
      [(ngModel)]="to.defaultValue"
      [inputAttrs]="{ 'aria-label': to.label }"
    >
    </ng-select>
  `,
})
export class FormlyFieldDictionarySelectStatic
  extends FieldType
  implements OnInit, OnDestroy
{
  onDestroy$ = new Subject<void>();

  isLoading = false;
  items = [];
  source: any;
  dataProvided: boolean;
  public placeholder: string;

  public translatePrefix;
  constructor() {
    super();
  }

  ngOnInit() {
    this.source = this.to.source;
    this.dataProvided = this.to.dataProvided;
    if (this.dataProvided) {
      this.loadProvidedData();
    } else {
      this.loadData();
    }
  }

  get disabled() {
    return this.to.disabled;
  }

  private loadData() {
    this.isLoading = true;
    this.source
      .pipe(
        map((data: any) => {
          if (this.to.label === "Break Reason") {
            data = data.filter((br) => {
              return br.value !== "MATCHED";
            });
          }
          return data;
        })
      )
      .subscribe((data) => {
        this.items = data;
        this.isLoading = false;
      });
  }

  private loadProvidedData() {
    this.items = this.to.items;
  }

  ngOnDestroy() {
    this.onDestroy$.complete();
  }
}
