import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { IAdditionalDocs } from "@core/models/api.model";
import { environment } from "@env/environment";
import { addiotnalDocsTableConfig } from "@module/create-import/create-import-config";
import { CreateImportService } from "@module/create-import/services/create-import.service";
import { ITableViewConfig } from "@shared/models/table-view.model";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-additional-documents",
  templateUrl: "./additional-documents.component.html",
})
export class AdditionalDocumentsComponent implements OnInit, OnDestroy {
  @Input() viewAdditionalDocsDialog: boolean;
  @Input() documentId: any;
  @Input() coaReference: string;
  @Input() from?: string;

  @Output() additionalDocsDialogHide = new EventEmitter<any>();

  additonalDocsConfig: ITableViewConfig;
  @ViewChild("additionalDocsActionTemplate", { static: true })
  public additionalDocsActionTemplate: TemplateRef<any>;
  additionalDocsData: IAdditionalDocs;
  private _destroy$ = new Subject<any>();

  constructor(private createImportService: CreateImportService) {}

  ngOnInit() {
    const { columns, ...config } = addiotnalDocsTableConfig;
    const _columns = [...addiotnalDocsTableConfig.columns];

    this.additonalDocsConfig = {
      ...config,
      ...{ columns: _columns },
    };

    _columns.push({
      header: "Actions",
      width: "150px",
      templateRef: this.additionalDocsActionTemplate,
    });

    this.createImportService
      .getAddionalDocs(this.documentId, this.coaReference)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        if (res.length) {
          this.additionalDocsData = res;
          this.viewAdditionalDocsDialog = true;
        }
      });
  }

  onAdditionalDocsDialogHide(event) {
    this.additionalDocsDialogHide.emit();
  }

  downloadAdditionalDoc(rowData) {
    let url = `${environment.apiUrl}/doc-upload-service/documentfiles/${rowData.id}?channel=A
DDITIONAL`;

    /* if (this.from === "review") {
      url += "?channel=ATTACHMENT";
    } */

    window.open(url);
    /* window.open(
      `${environment.apiUrl}/doc-upload-service/documentfiles/${rowData.id}`
    ); */
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
