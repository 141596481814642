import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateImportService } from "@module/create-import/services/create-import.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-ocr-taggging",
  templateUrl: "./ocr-taggging.component.html",
})
export class OcrTagggingComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<any>();
  uuid: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private createImportService: CreateImportService
  ) {}

  ngOnInit() {
    console.log(this.activatedRoute.snapshot.url);
    this.uuid = this.activatedRoute.snapshot.url[4].path;
  }

  returnPreviousLocation(event) {
    event && event.preventDefault();
    this.router.navigate(["/private/create-import"]);
  }

  lockInfoCallBackHandler(lockInfo: any) {
    if (lockInfo["lockedByUser"]) {
      this.createImportService
        .addLock(this.uuid)
        .pipe(takeUntil(this._destroy$))
        .subscribe((res) => {
          console.log("Lock Added");
        });
    } else {
      this.createImportService.unlockDoc(this.uuid);
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
