import {
  HttpInterceptor,
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CoreService } from "@core/services/core.service";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

const preventLogoutEndpoints = [
  "/common-service/notifications/channels",
  "/common-service/dashboard/recordStatistics",
  "/common-service/dashboard/statistics",
  "/common-service/dashboard/topEntities",
  "/common-service/dashboard/topUsers",
  "/common-service/dashboard/suspendStatistics",
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private coreService: CoreService) {}

  private lastRequest: HttpRequest<any>;

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 403) {
      this.router.navigate(["/private/dashboard"], {
        state: {
          accessDenied: "You do not have access to the particular action.",
        },
      });

      return of(err.message);
    }

    if (err.status === 401) {
      window.location.href = `https://${window.location.hostname}/redirect_uri?logout=https://${window.location.hostname}/`;
      localStorage.setItem("terminated", "true");
    }

    if (err.status === 500) {
      if (this.lastRequest.url.includes("/user-service/users/user")) {
        localStorage.setItem("suspended", "true");
        window.location.href = `https://${window.location.hostname}/redirect_uri?logout=https://${window.location.hostname}/`;
      } else {
        this.coreService.showMessage({
          key: "tc",
          severity: "error",
          summary: "Error",
          detail:
            err.error && err.error.message
              ? err.error.message
              : "Server error occured",
        });
      }
    }

    return throwError(err);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.lastRequest = req;
    if (
      req.url.includes("common-service") ||
      req.url.includes("inventory-service") ||
      req.url.includes("report-service")
    ) {
      const headers = {};
      const d = new Date();
      let timeZoneOffset = d.getTimezoneOffset();
      headers["clientTimezoneOffset"] = timeZoneOffset.toString();
      req = req.clone({
        setHeaders: headers,
      });
    }

    if (preventLogoutEndpoints.some((v) => req.url.includes(v))) {
      return next.handle(req);
    }
    return next.handle(req).pipe(catchError((x) => this.handleAuthError(x)));
  }
}
